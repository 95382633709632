import React, { useEffect } from 'react';
import './../../../Styles/Payment/PaymentSuccess.css';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PaymentSuccess() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShareOrderDetails = () => {
        const orderDetails = {
            title: 'Order Confirmation',
            text: 'Your order #12345 for "Product Name" has been successfully placed. Amount: $100.',
            url: 'https://omf.com/order/12345', // Link to the order details page
        };
    
        if (navigator.share) {
            navigator.share(orderDetails)
                .then(() => console.log('Order details shared successfully!'))
                .catch((error) => console.error('Error sharing:', error));
        } else {
            alert('Sharing not supported on this device.'); // Fallback for unsupported devices
        }
    };
    
    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar></SideMenuBar>

                <div className="top-bar-mobile">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>

            <div className="payment-success-svg-text">
                <div className="payment-success">
                    <div className="zoom-container">
                        <svg
                            width="210"
                            height="210"
                            viewBox="0 0 210 210"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="105"
                                cy="105"
                                r="105"
                                fill="#02A750"
                                fillOpacity="0.09"
                            />
                            <path
                                d="M105 17.5C56.7875 17.5 17.5 56.7875 17.5 105C17.5 153.213 56.7875 192.5 105 192.5C153.213 192.5 192.5 153.213 192.5 105C192.5 56.7875 153.213 17.5 105 17.5ZM146.825 84.875L97.2125 134.488C95.9875 135.713 94.325 136.413 92.575 136.413C90.825 136.413 89.1625 135.713 87.9375 134.488L63.175 109.725C60.6375 107.188 60.6375 102.987 63.175 100.45C65.7125 97.9125 69.9125 97.9125 72.45 100.45L92.575 120.575L137.55 75.6C140.088 73.0625 144.288 73.0625 146.825 75.6C149.363 78.1375 149.363 82.25 146.825 84.875Z"
                                fill="#02A750"
                            />
                        </svg>
                    </div>

                    <p className="order-message">
                        {t('payment.your-order-has-been')}{' '}
                        <span className="successfully-placed-span">
                            {t('payment.successfully-placed')}
                        </span>
                    </p>

                    <p className="thankyou-message">{t('payment.thank-you')}</p>

                    <div className="share-container" onClick={handleShareOrderDetails}>
                        <p className="share-order-text">
                            {t('payment.share-your-order-details')}
                        </p>

                        <div className="verticle-line-and-svg">
                            <span className="success-verticle-line"></span>
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="success-svg-icon"
                            >
                                <path
                                    d="M12.1835 14.5776C11.9885 14.5776 11.7935 14.5033 11.645 14.3544C11.3475 14.057 11.3475 13.5748 11.645 13.277L24.6989 0.223087C24.9963 -0.0743624 25.4785 -0.0743624 25.7759 0.223087C26.0734 0.520537 26.0734 1.0027 25.7759 1.30015L12.7216 14.3544C12.5727 14.5033 12.3781 14.5776 12.1831 14.5776H12.1835Z"
                                    fill="#2C3142"
                                />
                                <path
                                    d="M16.6698 26.0001C15.6117 26.0001 14.6703 25.2898 14.3797 24.2729L11.5678 14.4319L1.72719 11.6204C0.710299 11.3298 0 10.3884 0 9.33072C0 8.27308 0.690114 7.33084 1.71691 7.02311L25.0193 0.0320883C25.2878 -0.0482727 25.5788 0.024852 25.7768 0.222898C25.9749 0.420944 26.0484 0.711919 25.9676 0.980423L18.977 24.2828C18.6689 25.3096 17.7419 25.9997 16.6698 25.9997V26.0001ZM24.1022 1.89791L2.15451 8.48217C1.77708 8.59529 1.52343 8.93616 1.52343 9.33034C1.52343 9.72453 1.77937 10.0509 2.14575 10.1557L12.3935 13.0837C12.6464 13.1561 12.8444 13.3537 12.9168 13.607L15.8448 23.8547C15.9496 24.2211 16.2885 24.477 16.6698 24.477C17.051 24.477 17.4052 24.2234 17.5183 23.846L24.1022 1.89791Z"
                                    fill="#2C3142"
                                />
                            </svg>
                        </div>
                    </div>

                    <div className="share-container" onClick={() => navigate('/reels')}>
                        <p className="share-order-text">{t('payment.enjoy-reels')}</p>

                        <div className="verticle-line-and-svg">
                            <span className="success-verticle-line"></span>
                            <svg
                                width="28"
                                height="33"
                                viewBox="0 0 28 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.52539 5.28367C2.52539 5.28367 3.88476 8.92873 9.09853 8.92873C9.09853 8.92873 13.5839 9.3417 16.4976 2.09461C16.4976 2.09461 13.7875 5.95475 11.5993 6.84379C11.5993 6.84379 12.2245 4.81048 11.0745 1.68164C11.0745 1.68164 10.7648 6.06086 10.4005 6.94703C10.4005 6.94703 7.89689 6.06086 7.06235 3.35074C7.06235 3.35074 7.63879 6.94703 8.47333 7.46898C8.47333 7.46898 5.86645 8.09705 2.52826 5.28367H2.52539Z"
                                    fill="#2C3142"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13.4216 30.1463C7.41916 30.1463 2.54953 25.5921 2.54953 19.9768C2.54953 14.3616 7.41629 9.80739 13.4216 9.80739C19.4269 9.80739 24.2937 14.3616 24.2937 19.9768C24.2937 22.4116 23.3788 24.6457 21.8531 26.3951H24.9619C26.1578 24.5166 26.8489 22.3227 26.8489 19.9768C26.8489 13.2947 21.2652 7.82857 14.2275 7.44141C11.3625 9.79879 8.87316 9.56936 8.87316 9.56936C7.92677 9.56936 7.07214 9.48332 6.30069 9.33419C2.51798 11.5568 0 15.4915 0 19.9768C0 26.9113 6.01104 32.5381 13.4245 32.5381C16.582 32.5381 19.4842 31.5142 21.7785 29.805H16.2235C15.3316 30.0258 14.3938 30.1463 13.4273 30.1463H13.4216Z"
                                    fill="#2C3142"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M26.8871 27.0181H19.6859C21.8254 25.3547 23.1905 22.8425 23.1905 20.032C23.1905 15.0218 18.86 10.9609 13.52 10.9609C8.18008 10.9609 3.84961 15.0218 3.84961 20.032C3.84961 25.0421 7.91623 28.8535 13.0325 29.0916L12.9981 29.103H26.89C27.5037 29.103 27.9999 28.6356 27.9999 28.062C27.9999 27.4884 27.5037 27.021 26.89 27.021L26.8871 27.0181ZM18.4327 22.1255C17.492 21.8703 16.1642 20.9497 16.1613 19.9574C16.1613 18.9135 17.492 18.0646 18.4786 17.7893C21.3493 16.992 21.398 22.9285 18.4327 22.1255ZM15.5878 14.1213C16.1384 15.4864 13.7265 19.6907 11.6617 16.1202C10.873 14.7551 11.0307 13.6969 12.3815 13.2179C13.586 12.7935 15.1949 13.1462 15.5878 14.1213ZM11.2745 24.6636C11.5297 23.7229 12.4503 22.3951 13.4426 22.3922C14.4865 22.3922 15.3354 23.7229 15.6107 24.7095C16.408 27.5802 10.4715 27.6289 11.2745 24.6636ZM9.24692 21.7412C7.88182 22.5299 6.82358 22.3722 6.34465 21.0214C5.9202 19.8169 6.27295 18.208 7.24802 17.8151C8.61313 17.2645 12.8174 19.6764 9.24692 21.7412Z"
                                    fill="#2C3142"
                                />
                                <path
                                    d="M17.4749 1.75045C17.6125 1.34035 17.6928 0.913039 17.7072 0.479992C17.7072 0.434106 17.7072 0.385352 17.69 0.342334C17.6699 0.299316 17.6269 0.26777 17.581 0.273506C17.5064 0.287845 17.4921 0.38822 17.4921 0.465652C17.4892 0.743835 17.4118 1.01628 17.3315 1.28586C17.3171 1.33461 17.277 1.39197 17.234 1.36903C17.1996 1.35182 17.2024 1.3002 17.2139 1.26292C17.2454 1.1396 17.3946 0.500067 17.4175 0.247695C17.4204 0.219016 17.406 0.190338 17.3888 0.167395C17.3688 0.14732 17.3343 0.135848 17.3114 0.153055C17.2971 0.164527 17.2913 0.178866 17.2856 0.196073C17.1795 0.491463 17.0447 0.872889 16.9414 1.16828C16.9328 1.18835 16.9271 1.20843 16.9099 1.2199C16.8984 1.2285 16.8841 1.23137 16.8726 1.2285C16.8726 1.2285 16.8611 1.22277 16.8554 1.22277C16.8497 1.22277 16.8439 1.2199 16.8382 1.21703C16.8382 1.21703 16.8181 1.20269 16.8124 1.18835C16.8067 1.16828 16.8124 1.1482 16.821 1.12813C16.9328 0.832738 17.0734 0.457049 17.1823 0.161659C17.1881 0.14732 17.1938 0.130112 17.191 0.112905C17.1852 0.0842267 17.1508 0.0698874 17.125 0.0727553C17.0963 0.078491 17.0676 0.0899624 17.0504 0.115773C16.907 0.325127 16.6059 0.910171 16.5514 1.02202C16.5371 1.05643 16.5055 1.09945 16.4711 1.09085C16.4224 1.07937 16.431 1.00768 16.451 0.96466C16.56 0.70942 16.6776 0.451313 16.8554 0.233355C16.9042 0.17313 16.9587 0.0870946 16.9099 0.0297373C16.8812 -0.00467698 16.8239 -0.00754485 16.7837 0.0125302C16.7436 0.0326052 16.712 0.0698874 16.6833 0.10717C16.4166 0.445577 16.2015 0.824135 16.0438 1.2285C15.9865 1.37476 15.9377 1.5325 15.9463 1.69023C15.9578 1.85083 16.0323 1.99709 16.0725 2.14909C16.127 2.36991 16.0983 2.58787 16.0323 2.80009C16.0266 2.82303 16.6805 2.57066 16.8669 2.43874C16.9959 2.34697 17.1479 2.28388 17.2569 2.16916C17.3688 2.05732 17.429 1.90245 17.4806 1.75332L17.4749 1.75045Z"
                                    fill="#2C3142"
                                />
                                <path
                                    d="M2.91415 5.95443C2.84246 5.91428 2.54133 5.89707 2.45243 5.87413C2.28609 5.83111 2.10541 5.83111 1.93908 5.78235C1.72112 5.71639 1.20491 5.57013 1.03857 5.41814C0.0118765 4.47748 0.158138 3.49954 0.703031 3.0923C0.85216 2.98045 1.05004 2.98906 1.18483 3.00053C1.27947 3.00627 1.37411 3.02634 1.46588 3.05502C1.68671 3.12098 1.88746 3.24143 2.07387 3.37048C2.3119 3.53969 2.53846 3.74044 2.66178 4.00141C2.8568 4.42586 2.74782 4.93347 2.89408 5.37799C2.92276 5.46976 2.96577 5.55293 3.01453 5.63609C3.20954 5.85979 3.07762 6.09782 2.91702 5.95443H2.91415Z"
                                    fill="#2C3142"
                                />
                            </svg>
                        </div>
                    </div>

                    <Link to="/GoogleMap">
                        <div className="success-location-track-container">
                            <p className="track-order-text">{t('payment.track-your-order')}</p>

                            <div className="verticle-line-and-svg">
                                <span className="success-track-verticle-line"></span>
                                <svg
                                    width="34"
                                    height="26"
                                    viewBox="0 0 34 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.55235 23.9998C6.15806 23.9998 5.02334 22.8781 5.02334 21.4998C5.02334 20.1215 6.15806 18.9998 7.55235 18.9998H17.2315C19.7416 18.9998 21.7838 16.9809 21.7838 14.4995C21.7838 12.0182 19.7416 10 17.2315 10H10.4057V11.9995H17.2315C18.6258 11.9995 19.7605 13.1212 19.7605 14.4995C19.7605 15.8779 18.6258 16.9996 17.2315 16.9996H7.55235C5.04222 16.9996 3 19.0184 3 21.4998C3 23.9812 5.04222 26 7.55235 26H24V24.0005H7.55235V23.9998Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M9.39113 1.50619C7.2453 -0.502062 3.75453 -0.502062 1.60937 1.50619C-0.536458 3.51444 -0.536458 6.7814 1.60937 8.78902L4.71491 11.6954C5.14881 12.1015 5.8517 12.1015 6.28559 11.6954L9.39113 8.78902C11.5363 6.7814 11.5363 3.5138 9.39113 1.50619ZM7.95698 7.44744L5.49958 9.74665L3.04218 7.44744C1.6871 6.17924 1.6871 4.11596 3.04218 2.84839C3.71938 2.21461 4.60948 1.89708 5.49958 1.89708C6.38968 1.89708 7.2791 2.21398 7.95698 2.84839C9.31206 4.11659 9.31206 6.17924 7.95698 7.44744Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M32.3912 15.51C30.2459 13.4967 26.7543 13.4967 24.609 15.51C22.4637 17.5234 22.4637 20.7988 24.609 22.8115L27.6579 25.6728C28.1229 26.1091 28.8766 26.1091 29.3416 25.6728L32.3905 22.8115C34.5365 20.7988 34.5365 17.5228 32.3905 15.51H32.3912ZM30.9576 21.4665L28.5001 23.7723L26.0425 21.4665C24.6874 20.1951 24.6874 18.1265 26.0425 16.8557C26.7198 16.2203 27.6099 15.9019 28.5001 15.9019C29.3902 15.9019 30.2797 16.2197 30.9576 16.8557C32.3121 18.1271 32.3121 20.1957 30.9576 21.4665Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>

            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default PaymentSuccess;
