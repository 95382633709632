import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import img1 from '../../../assets/offersforyou1.png';
import star from '../../../assets/Star.png'; 
import CloseIcon from '../../../assets/svg/closeIcon';
import './OfferDetailsModal.css';

const OfferDetailsModal = ({ open = false, handleClose = () => {}, offer }) => {

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                className='offer-detail-popup'
            >
                <div className="title-close-container">
                    <CloseIcon size={30} color='black' onClick={handleClose} className='close-icon'/>
                </div>
                <div className="offer-details-container">
                    <img
                        src={offer?.Banner_Image || img1}
                        alt={offer?.vendor?.Name}
                        onError={(e) => {
                            e.target.src = img1; 
                        }}
                        style={{ width: '100%', borderRadius: '8px' }}
                    />
                    {offer?.Avatar_key && <img
                        src={offer?.Avatar_key}
                        alt={offer?.vendor?.Name}
                        style={{ width: '100px',height:'100px', borderRadius: '8px' }}
                    />}
                    <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {offer?.vendor?.Name}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Address: {offer?.vendor?.Address}
                    </Typography>
                    <div className="Food-dist-layout">
                        <img
                            className="Food-search-rest-star-icon-image"
                            src={star}
                            alt="search-rest-star icon"
                        />
                        <span className="Food-search-rest-reviews">
                            {offer?.vendor?.avg_rating || 0} reviews
                        </span>
                    </div>
                    <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                        {offer?.Title}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Description: {offer?.Description}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Short Description: {offer?.shortdescription}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Maximum Amount : {offer?.Max_amount}
                    </Typography>
                    <Typography variant="h5" sx={{ mt: 2, color: '#ff5722', fontWeight: 'bold' }}>
                        {offer?.percentage}% OFF
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                Redeem Limit: {offer?.Offer_redemlimit}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                Valid From: {offer?.valid_from}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                Valid To: {offer?.valid_to}
                    </Typography>
                </div>
            </Box>
        </Modal>
    );
};

export default OfferDetailsModal;
