import React, { useState } from 'react';
import star from '../../../assets/Star.png';
import restaurant1 from '../../../assets/nbrestaurants1.png';
import './Food.css';
import { CircularProgress } from '@mui/material';
import OrderPopop from '../../../Components/OrderPopop';

function Restaurant({ searchTerm, results, lastElementRef, loading }) {
    const validResults = results || [];
    const [openPopop, setOpenPopop] = useState(false);
    

    const [vendorId, setVendorId] = useState(null);
    const [RestaurantName, setRestaurantName] = useState(null);

    const filteredRestaurants = validResults
        .filter((restaurant) =>
            restaurant?.Vendor_name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .filter((restaurant, index, self) => 
            index === self.findIndex(r => r.VendorID === restaurant.VendorID)
        );

    const handleVendor = (vendorID,vendorName) => {
        setVendorId(vendorID);
        setRestaurantName(vendorName);
        setOpenPopop(!openPopop);

    };

    return (
        <div className='food-container-fake'>
            <div className='Food-Nearby-restaurants-cards-container'>
                {filteredRestaurants.length > 0 ? (
                    filteredRestaurants.map((restaurant, index) => {
                        const isLastElement = index === filteredRestaurants.length - 1;
                        return (
                            <div
                                key={index}
                                className='Food-search-restaurants-card'
                                ref={isLastElement ? lastElementRef : null}
                                onClick={() => handleVendor(restaurant?.VendorID,restaurant?.Vendor_name)}
                            >
                                <img
                                    className='Food-search-restaurants-image'
                                    src={restaurant?.Vendor_Banner_Image || restaurant1}
                                    alt='Food img'
                                    onError={(e) => {
                                        e.target.src = restaurant1;
                                    }}
                                />
                                <div className='Food-search-restaurants-content'>
                                    <span className='Food-search-restaurants-text'>
                                        {restaurant.Vendor_name}
                                    </span>
                                    <div className='Food-search-restaurant-footer'>
                                        <span className='Food-search-restaurant-dist'>
                                            {restaurant.Distance_in_Miles} m
                                        </span>

                                        <div className='Food-dist-layout'>
                                            <img
                                                className='Food-search-rest-star-icon-image'
                                                src={star}
                                                alt='search-rest-star icon'
                                            />
                                            <span className='Food-search-rest-reviews'>
                                                {restaurant.AvgRating || 0} reviews
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h5 className='no-data-found'>No Restaurant Found</h5>
                )}
            </div>
            <div>
                {openPopop && 
                    <OrderPopop
                        restaurantName={RestaurantName}
                        handleClose={()=> {
                            setTimeout(() => {
                                setOpenPopop(!openPopop);
                            }, 500);
                        }}
                        vendorID={vendorId}
                    />
                }
            </div>
            {loading && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
}

export default Restaurant;
