import React from 'react';
import Box from '@mui/material/Box';


import Modal from '@mui/material/Modal';



import '../../VendorStyles/OfferModal.css';

import AddOfferForm from './AddOfferForm';

function OfferModal({open,handleClose,initialData,fetchOffers}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='offer-Model-container'>


                    <AddOfferForm handleClose={handleClose} initialData={initialData}  fetchOffers={fetchOffers}/>


                </Box>
            </Modal>
        </div>
    );
}

export default OfferModal;