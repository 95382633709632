import React from 'react';

function ShareIcon({ handleOpenShare }) {
    return (
        <svg
            width="34"
            height="35"
            viewBox="0 0 34 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="share-svg-icon"
            onClick={handleOpenShare}
            style={{ cursor: 'pointer' }} 
        >
            <path
                d="M32.1736 8.81735L24.8531 0.975172C24.2491 0.328102 23.6364 0 23.0322 0C22.2026 0 21.2338 0.675962 21.2338 2.58104V5.25092C15.9152 5.49925 10.9484 7.83588 7.16018 11.8939C3.14782 16.1918 0.937942 21.9065 0.9375 27.9849C0.937437 28.4216 1.19824 28.8095 1.58492 28.9477C1.68358 28.9829 1.7847 29 1.88482 29C2.17707 29 2.45998 28.8547 2.64245 28.5946C7.11546 22.2163 13.8499 18.4469 21.2338 18.1492V20.7779C21.2338 22.6829 22.2025 23.3589 23.0321 23.359H23.0322C23.6364 23.359 24.2491 23.0309 24.8531 22.3838L32.1735 14.5416C32.8851 13.7795 33.2769 12.7631 33.2769 11.6795C33.2769 10.5959 32.8851 9.57945 32.1736 8.81735ZM30.8337 13.1062L23.5132 20.9485C23.3728 21.099 23.2565 21.194 23.1708 21.2517C23.1482 21.1459 23.1287 20.9906 23.1287 20.778V17.1152C23.1287 16.5547 22.7045 16.1003 22.1812 16.1003C17.9716 16.1003 13.9491 17.1335 10.2256 19.1711C7.55254 20.634 5.10903 22.6031 3.03975 24.9482C4.41632 14.9545 12.476 7.25878 22.1812 7.25878C22.7045 7.25878 23.1287 6.80435 23.1287 6.24382V2.58104C23.1287 2.36837 23.1482 2.21308 23.1707 2.10739C23.2565 2.16511 23.3728 2.26011 23.5132 2.41053L30.8337 10.2528C31.1873 10.6315 31.382 11.1382 31.382 11.6795C31.382 12.2208 31.1873 12.7275 30.8337 13.1062Z"
                fill="white"
            />
        </svg>
    );
}

export default ShareIcon;