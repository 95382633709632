import React, { useRef, useState, useEffect } from 'react';
import '../../VendorStyles/Offers.css';
import axiosVendorInstance from '../../axiosVendorInstance';
import { toast } from 'react-toastify';
import { useAuth } from '../../AuthProvider';
import CloseIcon from '../../assets/svg/closeIcon';
import VendorImageMenuPlaceholderSvg from '../../assets/svg/vendorImageMenuPlaceholder';
function AddOfferForm({ handleClose, initialData, fetchOffers }) {
    const [isLoading, setIsLoading] = useState(false);
    const { userID } = useAuth();

    console.log(initialData);
    const [coupons, setCoupons] = useState([]);
    console.log(coupons);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [previewFile, setPreviewFile] = useState(null); // For preview
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    // const [status,setStatus] = useState('');
    const [Percentage, setPercentage] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    console.log(maxAmount);
    const [redeemLimit, setRedeemLimit] = useState('');
    console.log(redeemLimit);
    const [validFrom, setValidFrom] = useState('');
    const [validTo, setValidTo] = useState('');
    const [noBoundaries, setNoBoundaries] = useState(false);

    const [vendorID, setVendorID] = useState();

    const [TitleError, setTitleError] = useState('');
    const [percentageError, setPercentageError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [shortDescriptionError, setShortDescriptionError] = useState();
    const [maxAmountError, setMaxAmountError] = useState();

    const [redeemLimitError, setRedeemLimitError] = useState('');
    const [validFromError, setValidFromError] = useState('');
    const [validToError, setValidToError] = useState('');

    const validate = () => {
        let isValid = true;
        const nameRegex = /^[a-zA-Z0-9\s]{4,}$/;

        const priceRegex = /^\d+(\.\d{1,2})?$/;

        if (!title.trim()) {
            setTitleError('title  is required.');
            isValid = false;
        } else if (!nameRegex.test(title)) {
            setTitleError('Enter valid title.');
            isValid = false;
        } else {
            setTitleError('');
        }

        if (!Percentage) {
            setPercentageError('Percentage is required.');
            isValid = false;
        } else if (!/^\d+(\.\d{1,2})?$/.test(Percentage)) {
            setPercentageError('Enter a valid percentage (e.g., 20 or 40).');
            isValid = false;
        } else if (parseFloat(Percentage) < 1 || parseFloat(Percentage) > 100) {
            setPercentageError('Percentage must be between 1 and 100.');
            isValid = false;
        } else {
            setPercentageError('');
        }

        if (description.replace(/<(.|\n)*?>/g, '').trim() === '') {
            setDescriptionError('Description is required.');
            isValid = false;
        }

        if (shortDescription.replace(/<(.|\n)*?>/g, '').trim() === '') {
            setShortDescriptionError('Description is required.');
            isValid = false;
        }

        if (!maxAmount) {
            setMaxAmountError('maximum Amount is required.');
            isValid = false;
        } else if (!priceRegex.test(maxAmount)) {
            setMaxAmountError('Field is required.');
            isValid = false;
        } else {
            setMaxAmountError('');
        }

        if (!redeemLimit) {
            setRedeemLimitError('redeem limit is required.');
            isValid = false;
        } else if (!priceRegex.test(redeemLimit)) {
            setRedeemLimitError('Field is required.');
            isValid = false;
        } else {
            setRedeemLimitError('');
        }

        if (!noBoundaries) {
            if (validFrom === '') {
                setValidFromError('Valid From date is required');
                isValid = false;
            } else {
                setValidFromError('');
            }

            if (validTo === '') {
                setValidToError('Valid To date is required');
                isValid = false;
            } else {
                setValidToError('');
            }
        }

        return isValid;
    };

    const handleTitleChange = (e) => {
        const value = e.target.value;
        setTitle(value);

        if (!value.trim()) {
            setTitleError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{4,}$/.test(value)) {
            setTitleError('Food name must be 4 characters.');
        } else {
            setTitleError('');
        }
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);

        if (!value.trim()) {
            setDescriptionError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{10,}$/.test(value)) {
            setDescriptionError('');
        } else {
            setDescriptionError('');
        }
    };
    const handleShortDescriptionChange = (e) => {
        const value = e.target.value;
        setShortDescription(value);

        if (!value.trim()) {
            setShortDescriptionError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{10,}$/.test(value)) {
            setShortDescriptionError('');
        } else {
            setShortDescriptionError('');
        }
    };

    const handlePercentage = (e) => {
        let value = e.target.value;

        value = value.replace(/[^\d.]/g, '');

        setPercentage(value);

        if (e.key === 'ArrowUp') {
            value = parseFloat(value) + 1;
        } else if (e.key === 'ArrowDown') {
            value = parseFloat(value) - 1;
        }

        if (!value.trim()) {
            setPercentageError('Field is required.');
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            setPercentageError('Enter a valid percentage (e.g., 20 or 20.50).');
        } else if (parseFloat(value) < 0 || parseFloat(value) > 100) {
            setPercentageError('Percentage must be between 0 and 100.');
        } else {
            setPercentageError('');
        }
    };

    const handleMaxAmount = (e) => {
        const value = e.target.value;
        setMaxAmount(value);

        if (!value.trim()) {
            setMaxAmountError('Field is required.');
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            setMaxAmountError('Enter a valid price (e.g., 20 or 20.50).');
        } else {
            setMaxAmountError('');
        }
    };

    const handleRedeemLimit = (e) => {
        const value = e.target.value;
        setRedeemLimit(value);

        // setItemPrice(value);
        if (!value.trim()) {
            setRedeemLimitError('Field is required.');
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            setRedeemLimitError('Enter a valid price (e.g., 20 or 20.50).');
        } else {
            setRedeemLimitError('');
        }
    };

    const handleValidFromChange = (e) => {
        const newValidFrom = e.target.value;
        setValidFrom(newValidFrom);
        setValidTo('');
        setValidFromError('');
        setValidToError('');
    };

    const fetchVendorID = async () => {
        try {
            const response = await axiosVendorInstance.get(
                '/vendors/account'
            );
            const apiResponse = response.data.getVendorInfo;

            if (apiResponse.length > 0) {
                setVendorID(apiResponse[0].ID);
            } else {
                toast.error('Vendor ID not found.');
            }
        } catch (error) {
            toast.error('Error fetching vendor ID');
            console.error(error);
        }
    };

    useEffect(() => {
        if (userID) {
            fetchVendorID();
        }
    }, [userID]);

    const fileInputRef = useRef(null);

    const fetchCoupons = async () => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendor/offers/vendor/${vendorID}`
            );
            setCoupons(response.data.offer[0] || []);
          
        } catch (error) {
            toast.error('Error fetching coupons.');
        }
    };

    useEffect(() => {
        if (vendorID) {
            fetchCoupons();
        }
    }, [vendorID]);

    useEffect(() => {
        if (initialData) {
            setTitle(initialData.Title || '');
            setShortDescription(initialData.shortdescription || '');
            setDescription(initialData.Description || '');
            setMaxAmount(initialData.Max_amount);
            setRedeemLimit(initialData.Offer_redemlimit);
            setPercentage(initialData.percentage || '');
            setNoBoundaries(initialData.noboundaries);
            setPreviewFile(initialData.Avatar_key || '');

            const formatDate = (date) => {
                if (date) {
                    const newDate = new Date(date);
                    return newDate.toISOString().slice(0, 16);
                }
                return '';
            };

            setValidFrom(formatDate(initialData.valid_from || ''));
            setValidTo(formatDate(initialData.valid_to || ''));
        }
    }, [initialData]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension !== 'jpg' && fileExtension !== 'png') {
                alert('Please upload a JPG or PNG file.');
                return;
            }

            const maxSize = 10 * 1024 * 1024;
            if (file.size > maxSize) {
                alert('File size should be less than 10 MB.');
                return;
            }

            setUploadedFile(file);

            const previewUrl = URL.createObjectURL(file);
            setPreviewFile(previewUrl);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (isLoading) return;
            setIsLoading(true);

            const formData = new FormData();

            formData.append('Title', title);
            formData.append('Description', description);
            formData.append('Avatar_key', uploadedFile);
            formData.append('Created_at', '2025-02-04 07:17:3');
            formData.append('Created_by', '1');
            formData.append('Updated_at', '2025-02-04 07:17:3');
            formData.append('Updated_by', '1');
            formData.append('Shortdescription', shortDescription);
            formData.append('percentage', Percentage);
            formData.append('Offer_redemlimit', redeemLimit);
            formData.append('Max_amount', maxAmount);
            formData.append('noboundaries', noBoundaries ? '1' : '0');
            formData.append('vendor_id', vendorID);
            formData.append('menu_id', 12345);
            formData.append('status', 'active');

            if (!noBoundaries) {
                formData.append('valid_from', validFrom);
                formData.append('valid_to', validTo);
            }

            try {
                if (initialData) {
                    await axiosVendorInstance.put(
                        `/vendor/offers/${initialData.ID}`,
                        formData,
                    );
                    toast.success('Offer updated successfully!');
                    fetchOffers();
                } else {
                    await axiosVendorInstance.post(
                        `/vendor/offers/vendor/${vendorID}`,
                        formData,
                    );
                    toast.success('Offer created successfully!');
                    fetchOffers();
                }

                setTitle('');
                setDescription('');
                setShortDescription('');
                setPercentage('');
                setRedeemLimit('');
                setMaxAmount('');
                setValidFrom('');
                setValidTo('');
                setUploadedFile(null);
                setPreviewFile(null);
                setNoBoundaries(false);

                handleClose();
            } catch (error) {
                console.error('Error saving offer:', error);
                toast.error('Error saving offer.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
            <div className="title-and-close-icon">
                <h2 className="coupen-offer-heading-text">
                    {initialData ? 'Edit Offer' : 'Add Offer'}
                </h2>
                <CloseIcon
                    height='22'
                    width='22'
                    color='#e13535'
                    onClick={handleClose}
                />
            </div>
            <div className="Offers-new-div">
                <div className="offer-img-and-container">
                    <div className="restaurant-Offers-image-container">
                        <VendorImageMenuPlaceholderSvg
                            onClick={() => fileInputRef.current.click()}
                        />
                        <h5 className="Offers-add-item-update-photo-heading">
              Update Photo
                        </h5>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                        />
                    </div>

                    <div className="image-preview-offers">
                        <img
                            src={previewFile}
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
                <div className="Offers-additem-rightside-content">
                    <div className="Offers-additem-rightside-content-1">
                        <div className="menu-additem-food-name-container">
                            <div className="Offers-additem-food-name-container">
                                <p className="Offers-additem-food-name-text">Title</p>
                                <input
                                    className="Offers-additem-food-name-inputbox"
                                    placeholder="enter title"
                                    value={title}
                                    onChange={handleTitleChange}
                                    required
                                    maxLength={30}
                                ></input>
                            </div>
                            {TitleError && (
                                <p style={{ fontSize: '12px', color: 'red' }}>{TitleError}</p>
                            )}
                        </div>

                        <div className="menu-additem-food-name-container">

                            <div className="Offers-additem-food-name-container">
                                <p className="Offers-additem-food-name-text">Percentage</p>
                                <input
                                    className="Offers-additem-food-name-inputbox"
                                    placeholder=" 1 to 100 "
                                    value={Percentage}
                                    onChange={handlePercentage}
                                    required
                                    maxLength={3}
                                ></input>
                            </div>
                            {percentageError && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {percentageError}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="Offers-additem-rightside-content-1">
                        <div className="menu-additem-food-name-container">

                            <div className="Offers-additem-food-name-container">
                                <p className="Offers-additem-food-name-text">maximum Amount</p>
                                <input
                                    className="Offers-additem-food-name-inputbox"
                                    placeholder="20"
                                    value={maxAmount}
                                    onChange={handleMaxAmount}
                                    required
                                    maxLength={3}
                                ></input>
                            </div>
                            {maxAmountError && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {maxAmountError}
                                </p>
                            )}
                        </div>

                        <div className="menu-additem-food-name-container">

                            <div className="Offers-additem-food-name-container">
                                <p className="Offers-additem-food-name-text">Redeem Limit</p>
                                <input
                                    className="Offers-additem-food-name-inputbox"
                                    placeholder="ex : 10"
                                    value={redeemLimit}
                                    onChange={handleRedeemLimit}
                                    required
                                    maxLength={5}
                                ></input>
                            </div>
                            {redeemLimitError && (
                                <p style={{ fontSize: '12px', color: 'red' }}>
                                    {redeemLimitError}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="no-boundries-label-and-checkbox">
                        <label className="Offers-additem-food-name-text">No Boundaries</label>
                        <input
                            className="no-boundries-checkbox"
                            type="checkbox"
                            checked={noBoundaries}
                            onChange={(e) => setNoBoundaries(e.target.checked)}
                        />
                    </div>

                    {!noBoundaries && (
                        <div className="Offers-additem-rightside-content-1-date">
                            <div className="menu-additem-food-name-container">

                                <div className="Offers-additem-food-name-container">
                                    <p className="Offers-additem-food-name-text">Valid From</p>
                                    <input
                                        type="datetime-local"
                                        className="Offers-additem-food-name-inputbox"
                                        value={validFrom}
                                        onChange={handleValidFromChange}
                                        required
                                    />
                                </div>
                                {validFromError && (
                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                        {validFromError}
                                    </p>
                                )}
                            </div>

                            <div className="menu-additem-food-name-container">

                                <div className="Offers-additem-food-name-container">
                                
                                    <p className="Offers-additem-food-name-text">Valid To</p>
                                    <input
                                        type="datetime-local"
                                        className="Offers-additem-food-name-inputbox"
                                        value={validTo}
                                        onChange={(e) => setValidTo(e.target.value)}
                                        required
                                        min={validFrom} // The 'Valid To' should be after 'Valid From'
                                    />
                                </div>
                                {validToError && (
                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                        {validToError}
                                    </p>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="menu-additem-food-name-container-descriptions">

                        <div className="Offers-additem-food-name-container">
                            <p className="Offers-additem-food-name-text">Description</p>
                            <textarea
                                type="text"
                                placeholder="Enter Description"
                                className="Offers-additem-description-inputbox"
                                value={description}
                                onChange={handleDescriptionChange}
                                required
                                maxLength={1000}
                            />
                        </div>
                        {descriptionError && (
                            <p style={{ fontSize: '12px', color: 'red' }}>
                                {descriptionError}
                            </p>
                        )}
                    </div>


                    <div className="menu-additem-food-name-container-descriptions">
                        <div className="Offers-additem-food-name-container">
                            <label className="Offers-additem-food-name-text">
              Short Description
                            </label>
                            <textarea
                                type="text"
                                placeholder="Enter Short Description"
                                className="Offers-additem-description-inputbox"
                                value={shortDescription}
                                onChange={handleShortDescriptionChange}
                                required
                                maxLength={500}
                            />
                        </div>
                        {shortDescriptionError && (
                            <p style={{ fontSize: '12px', color: 'red' }}>
                                {shortDescriptionError}
                            </p>
                        )}

                    </div>
                    <div className="Offers-additem-btn-container">
                        <button className="Offers-additem-btn" onClick={handleSubmit}>
                            {isLoading
                                ? 'wait...'
                                : initialData
                                    ? 'Update Offer'
                                    : 'Add Offer'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddOfferForm;
