import React, { useState, useEffect } from 'react';

import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import './../../../Styles/Cart/Cart.css';
import backIcon from './../../../assets/backIcon-white.png';
import greenDot from './../../../assets/greendot.svg';
import redDot from './../../../assets/reddot.svg';
import SubTotal from './SubTotal';
import { Link, useNavigate } from 'react-router-dom';
import Address from './AddressListModel';
import { useTranslation } from 'react-i18next';

import emptyCartImg from './../../../assets/empty-cart.png';
import AddOns from './AddOns';
import { toast } from 'react-toastify';
import img1 from '../../../assets/Image1.png';
import { useCart } from '../../../CartProvider';
import FoodMenuDetailsModal from '../Search/FoodMenuDetailsModal';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import NotificationIcon from '../../../assets/svg/notificationIcon';
import WhiteLocationIcon from '../../../assets/svg/WhiteLocationIcon';
import DownArrowIcon from '../../../assets/svg/DownArrowIcon';

function cart() {
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(() => {
        return localStorage.getItem('selectedAddressIndex')
            ? parseInt(localStorage.getItem('selectedAddressIndex'), 10)
            : 0;
    });
    const [deliveryAddress, setDeliveryAddress] = useState('Home');
    const [cartData, setCartData] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedFood, setSelectedFood] = useState(null);
    const [open, setOpen] = useState(false);
    const [vendorID,setVendorID] = useState(null);
    const [openAddressList, setOpenAddressList] = useState(false);
    const { fetchCartCount } = useCart();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const handleOpenAdressList = () => setOpenAddressList(true);
    const handleCloseAddressList = () => setOpenAddressList(false);

    useEffect(() => {
        const storedIndex = localStorage.getItem('selectedAddressIndex');
        if (storedIndex !== null) {
            setSelectedAddressIndex(parseInt(storedIndex, 10));
        }
    }, []);

    useEffect(() => {
        axiosUserInstance.get('/address/account/account_id')
            .then((response) => {
                const addressList = response.data.addresses;
                if (addressList.length > 0) {
                    setDeliveryAddress(addressList[selectedAddressIndex]?.Delivery_name || 'Home');
                }
            })
            .catch((error) => console.error('Error fetching addresses:', error));
    }, [selectedAddressIndex]);

    const handleUpdateDeliveryAddress = (newAddressType) => {
        setDeliveryAddress(newAddressType);
        localStorage.setItem('selectedAddressIndex', selectedAddressIndex);
        toast.success('Updated Delivery Address');
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFood(null);
    };

    const fetchDataFromCart = async () => {
        try {
            const itemResponse = await axiosUserInstance.get('/cartdetails/cartsp');    
            setVendorID(itemResponse?.data?.cartDetails?.[0]?.Cart_Information?.[0]?.Vendor_Id);
            setCartData(itemResponse?.data?.cartDetails?.[0]?.Cart_Information);
            setTotalPrice(itemResponse?.data?.cartDetails?.[0]?.Cart_Total_Price);
        } catch (error) {
            console.log('error in cart', error);
        }
       
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const Increment = async (id) => {
        try {
            await axiosUserInstance.put(
                `/cartdetails/customer/vendor/${vendorID}/item/${id?.Item_ID}`,
                { Quantity: id?.Item_Quantity + 1 }
            );
    
            await fetchDataFromCart();
        } catch (error) {
            console.log('error in menu item increment',error);
        }
    };

    const Decrement = async (id) => {

        const newQuantity = id?.Item_Quantity - 1;


        if (newQuantity > 0) {
            try {
                await axiosUserInstance.put(
                    `/cartdetails/customer/vendor/${vendorID}/item/${id?.Item_ID}`,
                    { Quantity: newQuantity }
                );
    
                await fetchDataFromCart();
            } catch (error) {
                console.log('error in menu item decrement', error);
            }
        } else {
            try {
                await axiosUserInstance.delete(
                    `/cartdetails/customer/vendor/${vendorID}/${id?.Item_ID}`
                );
                
                await fetchDataFromCart();
                await fetchCartCount();
            } catch (error) {
                console.log('item remove from cart error',error);
            }
        }
    };

    const fetchOfferDetails = async (itemId) => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`, {skipAuth: true}
            );
            setSelectedFood(response?.data?.menuItem);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const handleOrderItem = () => {
        navigate('/reels');
    };
    const goBack = () =>{
        window.history.back();
    };

    return (
        <div>
            <div className="topmenubar-cart">
                <SideMenuBar></SideMenuBar>

                <div className="topbar-cart">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>

            <div className="cart-mobile-header">
                <div className="cart-header-item-container">
                    <Link style={{display: 'flex'}}>
                        <img className="backIcon-cart" src={backIcon} alt="backIcon" onClick={goBack}/>
                    </Link>
                    <h2 className="review-order-heading">{t('cart.review-order')}</h2>
                </div>
                <NotificationIcon width={25} height={25}/>
            </div>

            <div className="cart-container">
                <div className="cart-box">
                    {cartData || cartData?.length > 0 ? (
                        <>
                            <div className='cart-items-details'>
                                {cartData?.map((item,index) => {
                                    const shortDescription = item?.MenuItem_Description
                                        ? item?.MenuItem_Description.slice(0, 60)
                                        : 'No description available'; 
                                    return (
                                        <div className="item-container-cart" key={index}>
                                            <div className='cart-img-container'>
                                                <img className="item-img-order" src={item?.MenuItem_Avatar || img1} alt="item" onError={(e) =>{
                                                    e.target.src = img1;
                                                }}/>
                                            </div>
                                            <div className="item-details-order1">
                                                <div>
                                                    <div className="item-row1">
                                                        <div className="row1">
                                                            <img className="item-reddot-order" src={item.MenuItem_Vegetarian === 1 ? greenDot : item.MenuItem_Vegetarian === 0 ? redDot : ''} alt="dot" />
                                                            <h5 className="item-name-order">
                                                                {item?.MenuItem_Name}
                                                            </h5>
                                                        </div>
                                                        <p className="item-cost-order">$ {item?.Total_Price}</p>
                                                    </div>

                                                    <div className="order-item-row2">
                                                        <p className="item-desc-order1">
                                                            {shortDescription.replace(/<[^>]+>/g, '')}...
                                                            <button
                                                                onClick={() => fetchOfferDetails(item?.Item_ID)}
                                                                className="more-btn"
                                                            >
                          More
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="desc-add-item">
                                                    <p></p>
                                                    <div className="quantity-controls">
                                                        <span onClick={() => Decrement(item)}>-</span>
                                                        <div className="quantity-number">{item?.Item_Quantity}</div>
                                                        <span onClick={() => Increment(item)}>+</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {cartData?.length > 0 && (
                                <span className="cart-horizantal-line"></span>
                            )}
                            {cartData?.length > 0 && <AddOns cartData={cartData} fetchDataFromCart={fetchDataFromCart}/>}
                            {cartData?.length > 0 && (
                                <div
                                    className="address-container1"
                                    onClick={() => handleOpenAdressList()}
                                >
                                    <div className="delivery-location-home-desc-container">
                                        <WhiteLocationIcon />

                                        <div className="delivert-to-home-desc-container">
                                            <h3 className="delivery-to-home-text">
                                                {' '}
                                                {t('cart.delivery-to')} {deliveryAddress}
                                            </h3>
                                            <p className="delivery-to-home-address">
                                                {t('cart.for-near-by-restaurants-and-quick-delivery')}
                                            </p>
                                        </div>
                                    </div>

                                    <DownArrowIcon />
                                </div>
                            )}
                            {openAddressList && (
                                <Address
                                    open={openAddressList}
                                    handleClose={handleCloseAddressList}
                                    handleUpdateDeliveryAddress={handleUpdateDeliveryAddress}
                                    selectedAddressIndex={selectedAddressIndex}
                                    setSelectedAddressIndex={setSelectedAddressIndex}
                                />
                            )}
                            <div className="items-andprice-container">
                                {cartData?.map((item, index) => {
                                    return (
                                        <div key={index} className="item-name-and-price-container">
                                            <p>{item?.MenuItem_Name}</p>
                                            <p>${item?.MenuItem_Price * item?.Item_Quantity}</p>
                                        </div>
                                    );
                                })}
                                {cartData?.length > 0 && (
                                    <>
                                        <SubTotal totalPrice={totalPrice}></SubTotal>
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="empty-cart-container">
                            <img
                                className="empty-cart-img"
                                src={emptyCartImg}
                                alt="empty-cart"
                                loading="lazy"
                            />
                            <h1 className="empty-cart-text"> Your Cart Empty</h1>
                            <button className="continue-to-order" onClick={handleOrderItem}>
              Continue to order
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>

            {selectedFood && (
                <FoodMenuDetailsModal
                    open={open}
                    handleClose={handleClose}
                    food={selectedFood}
                    orderBtn = {false}
                />
            )}
        </div>
    );
}

export default cart;
