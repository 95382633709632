import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; 

const axiosUserInstance = axios.create({
    baseURL: API_BASE_URL,
});

axiosUserInstance.interceptors.request.use(
    (config) => {
        config.headers = config.headers || {};

        if (!config.skipAuth) {
            const token = localStorage.getItem('authToken');
            if (token) {
                config.headers['x-access-token'] = token;
            }
        }

        if (config.data instanceof FormData) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else if (!config.headers['Content-Type']) { 
            config.headers['Content-Type'] = 'application/json';
        }

        return config;
    },
    (error) => Promise.reject(error)
);

axiosUserInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken'); 
            window.location.href = '/login'; 
        }
        return Promise.reject(error);
    }
);

export default axiosUserInstance;
