import React from 'react';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import '../../VendorStyles/DeleteOfferModal.css';

function DeleteOfferModal({ open, handleClose, confirmDelete, cancelDelete }) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="offer-delete-container">
                    <span className="close-in-add-offer-form" onClick={handleClose}>
            &times;
                    </span>

                    <div className="delete-modal-content">
                        <p className="offer-text">
              Are you sure you want to delete this offer?
                        </p>

                        <div className="btns-delete-modals">
                            <button className="confirm-delete-btn" onClick={confirmDelete}>
                Delete
                            </button>
                            <button className="cancel-delete-btn" onClick={cancelDelete}>
                Cancel
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default DeleteOfferModal;
