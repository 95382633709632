import React, { useEffect, useState } from 'react';
import '../Styles/Reels/ReelsLeft.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../Redux/Contants/action-types';
import { useNotifications } from '../Views/User/Notification/NotificationContext';

import starPic from './../assets/Star3.svg';

import ProfileAvatar from '../Views/User/ProfileAvatar/ProfileAvatar';
import { useCart } from '../CartProvider';
import authMiddleware from './authMiddleware';
import axiosUserInstance from '../axiosUserInstance';

function ReelsLeft(props) {
    const {
        profilePic,
        userName,
        restaurantName,
        AccountID,
        rating,
        distance,
        description,
        restaurant,
        coordinate,
        vendorID,
        firstName,
        lastName,
        itemName,
        followFlag,
    } = props;

    const navigate = useNavigate();
    const Dispatch = useDispatch();

    const [isFollowing, setIsFollowing] = useState(followFlag === 'true');
    const [isExpanded, setIsExpanded] = useState(false);
    const { currentLoginUserId } = useCart();

    const shouldShowReadMore = description.length > 80;
    // eslint-disable-next-line no-unused-vars
    const { notifications, addNotification, removeNotification } =
    useNotifications();

    useEffect(() => {
        setIsFollowing(followFlag === 'true' || followFlag == true);
    }, [followFlag]);

    const handleFollow = async () => {
        if (!authMiddleware()) {
            return;
        }

        const Data = {
            user_id: AccountID,
        };

        try {
            let res;
            if (!isFollowing) {

                res = await axiosUserInstance.post('/follow/user/userid', Data);
                if (res.status === 201) {
                    setIsFollowing(true);
                }

                const notificationPayload = {
                    from_user_id: currentLoginUserId,
                    to_user_id: AccountID,
                    type: 'follow',
                    action_id: AccountID,
                    notification_boundary: 'private',
                    is_read: false,
                };

                try {
                    const notificationRes = await addNotification(notificationPayload);
                    console.log('Notification response:', notificationRes);
                } catch (notifError) {
                    console.error(
                        'Error sending notification:',
                        notifError.response ? notifError.response.data : notifError.message
                    );
                }
            } else {
                res = await axiosUserInstance.delete(
                    `/follow/user/userid/${AccountID}`
                );
                if (res.status === 200) {
                    setIsFollowing(false);
                }
            }

        } catch (error) {
            console.error('Error in follow/unfollow action:', error);
        }
    };

    const handleRestaurant = (restaurantName) => {
        const filteredPosts = restaurant?.filter((post) =>
            post.Vendor_Info.some(
                (restaurant) => restaurant.Vendor_Name === restaurantName
            )
        );

        if (filteredPosts?.length > 0) {
            const menuItems = filteredPosts[0].Menu_items_Info;
            // setFilteredItems(menuItems);

            const restaurantInfo = filteredPosts[0].Restaurant_Info;

            Dispatch({
                type: ActionTypes.SENDING_DATA_TO_RESTAURANT_PAGE,
                payload: restaurantInfo,
            });

            Dispatch({
                type: ActionTypes.SENDING_DATA_TO_RESTAURANT_ITEMS,
                payload: menuItems,
            });

            navigate(`/restaurant/${vendorID}`);

        } else {
            console.log('No matching restaurant found.');
        }
    };

    return (
        <div className="left-container">
            <div className="reels-left">
                <div className="pic-username">
                    <div className="user-name-and-follow-container">
                        <div
                            className="user-pic-and-username"
                            onClick={() => navigate(`/profile/${userName}`)}
                        >
                            <ProfileAvatar
                                firstName={firstName}
                                lastName={lastName}
                                profilePic={profilePic}
                                height={45}
                                width={45}
                                fontSize={16}
                                className="profile"
                            />
                            <h3 className="user-name">
                                {userName && userName.length > 17
                                    ? userName.slice(0, 17) + '...'
                                    : userName}
                            </h3>
                        </div>
                        {currentLoginUserId != AccountID && (
                            <button className="follow-btn" onClick={() => handleFollow()}>
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                        )}
                    </div>
                </div>

                <div className="rest-name-cont">
                    <button
                        className="restaurant-name-reels"
                        onClick={() => handleRestaurant(restaurantName)}
                    >
                        {restaurantName}
                    </button>

                    <div className="distance-rating-container">
                        <div className="rating-container-2">
                            <img className="start-img" src={starPic} alt="star" />
                            <p className="rating">{rating?.toFixed(1)}</p>
                        </div>
                        {!coordinate?.lat == 0 && !coordinate?.lng == 0 && (
                            <p className="distance">
                                {distance?.toFixed(1)}
                                {''} miles
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className={`desc-cont ${
                        shouldShowReadMore && isExpanded ? 'should-show-read-more is-expanded' : ''
                    }`}
                >
                    <p className="restaurant-description1">
                        {restaurantName}
                        {'\'s'} {itemName}
                    </p>
                    <p
                        className="restaurant-description2"
                        onClick={() => shouldShowReadMore && setIsExpanded((prev) => !prev)}
                        style={{ cursor: shouldShowReadMore ? 'pointer' : 'default' }}
                    >
                        {isExpanded || !shouldShowReadMore
                            ? description
                            : `${description.slice(0, 80)} `}
                        {shouldShowReadMore && !isExpanded && (
                            <span style={{ color: '#e13535', cursor: 'pointer' }}>More</span>
                        )}
                    </p>
                </div>

            </div>
            <div className="shadow-overlay"></div>
        </div>
    );
}

export default ReelsLeft;
