import React from 'react';

function UserHome({ width = 30, height = 40 }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 22 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-red"
        >
            <path
                d="M1 23.5C1 18.1152 5.36522 13.75 10.75 13.75C16.1348 13.75 20.5 18.1152 20.5 23.5M10.75 11.5C13.6495 11.5 16 9.14949 16 6.25C16 3.35051 13.6495 1 10.75 1C7.85051 1 5.5 3.35051 5.5 6.25C5.5 9.14949 7.85051 11.5 10.75 11.5Z"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default UserHome;