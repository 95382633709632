import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import img1 from '../../../assets/offersforyou1.png';
import star from '../../../assets/Star.png';
import './OffersForYou.css';
import { useCart } from '../../../CartProvider';
import { Skeleton } from '@mui/material';
import useWindowSize from './useWindowSize';
import OfferDetailsModal from '../Search/OfferDetailsModal';
import axiosVendorInstance from '../../../axiosVendorInstance';
import axiosUserInstance from '../../../axiosUserInstance';

export default function OffersForYou() {
    const { t } = useTranslation();
    const [offerData, setOfferData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [open, setOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const { coordinate } = useCart();
    const windowSize = useWindowSize();
    
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    const getOffers = async (page) => {
        try {
            const response = await axiosUserInstance.get(
                `/search?searchText=&location=${coordinate?.lat},${coordinate?.lng}&pageNo=${page}&pageSize=10&type=offer`,{skipAuth: true}
            ); 
            const newOffers = response?.data?.data[0]?.Offer_Information || [];
            setOfferData((prevOffers) => [...prevOffers, ...newOffers]); 
            setCurrentPage(page); 
        } catch (error) {
            console.error('Error fetching offers:', error);
        }
    };
    useEffect(() => {
        getOffers(1);
    }, []);

    const handleClose = () => {
        setOpen(false);
        setSelectedOffer(null);
    };

    const fetchOfferDetails = async (offerId) => {
        try {
            const response = await axiosVendorInstance.get(`/vendor/offers/offferbyid/${offerId}`,{skipAuth: true}); 
            setSelectedOffer(response?.data?.offer);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const NextArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-next ${isDisabled ? 'disabled-arrow' : ''}`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowRight />
        </div>
    );
    
    const PrevArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-prev ${isDisabled ? 'disabled-arrow' : ''}`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowLeft />
        </div>
    );

    let slidesToShow = 8;
    let showSlider = offerData?.length > 8;
    const totalSlides = offerData?.length || 0;
    let variableWidth = false;

    if (windowSize.width <= 600) {
        slidesToShow = 2;
        showSlider = offerData?.length >= 2;
        variableWidth = true;
    } else if (windowSize.width <= 700) {
        slidesToShow = 2;
        showSlider = offerData?.length >= 2;
    } else if (windowSize.width <= 1090) {
        slidesToShow = 3;
        showSlider = offerData?.length >= 3;
    } else if (windowSize.width <= 1240) {
        slidesToShow = 4;
        showSlider = offerData?.length >= 4;
    } else if (windowSize.width <= 1363) {
        slidesToShow = 5;
        showSlider = offerData?.length >= 5;
    } else if (windowSize.width <= 1531) {
        slidesToShow = 6;
        showSlider = offerData?.length >= 6;
    } else if (windowSize.width <= 1677) {
        slidesToShow = 7;
        showSlider = offerData?.length >= 7;
    } else if (windowSize.width <= 2560) {
        slidesToShow = 8;
        showSlider = offerData?.length >= 8;
    }


    const settings = {
        dots: false,
        infinite: false,
        centerMode: false,  
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: true,
        initialSlide: 0,
        nextArrow: !isLoading && windowSize.width > 600 ? <NextArrow isDisabled={currentSlide >= totalSlides - slidesToShow} /> : <></>,
        prevArrow: !isLoading && windowSize.width > 600 ? <PrevArrow isDisabled={currentSlide === 0} /> : <></>,
        swipe: true, 
        variableWidth,
        afterChange: (index) =>{
            setCurrentSlide(index);
            if (index >= offerData.length - slidesToShow) {
                getOffers(currentPage + 1);
            }
        },
    };

    return (
        offerData &&
        offerData?.length > 0 && (
            <div className='Offers-Main-container'>
                <div className='title-and-arrows'>
                    <h5 className='offers-for-you-title-homepage-1'>
                        {t('homescreen.offers-for-you-title')}
                    </h5>
                </div>
                <div className='offer-card-1'>
                    {isLoading ? (
                        <Slider {...settings} className='slider-component'>
                            {[...Array(10)].map((_, index) => (
                                <div key={index} className='offer-card-11'>
                                    <div className='offer-for-you-image-container'>
                                        <Skeleton variant='rounded' width={windowSize.width > 600 ? 120 :100} height={windowSize.width > 600 ? 120 : 100} />
                                    </div>
                                    <div className='restaurant-name'>
                                        <Skeleton variant='text' width={80} height={20} />
                                    </div>
                                    <div className='offer-image-footer-review'>
                                        <Skeleton variant='text' width={50} height={20} />
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : showSlider ? (
                        <Slider {...settings} className='slider-component'>
                            {offerData?.map((restaurant, index) => (
                                <div key={index} className='offer-card-11' onClick={() => {
                                    fetchOfferDetails(restaurant?.OfferID);
                                }}>
                                    <div className='offer-for-you-image-container'>
                                        <img
                                            className='offer-for-you-image'
                                            src={restaurant?.Offer_Avatar || img1}
                                            alt='restaurant img'
                                            onError={(e) => {
                                                e.target.src = img1; 
                                            }}
                                        />
                                        <div className='offer-for-bg'>
                                            <p className='offer-for-per'>
                                                {restaurant?.Offer_Percentage || 0}% 
                                            </p>
                                        </div>
                                    </div>
                                    <h6 className='restaurant-name'>
                                        <p className='restaurant-text'>
                                            {restaurant?.Vendor_Name || 'vendor'}
                                        </p>
                                    </h6>
                                    <div className='offer-image-footer-review'>
                                        <img
                                            className='star-icon-image'
                                            src={star}
                                            alt='star icon'
                                        />
                                        <span className='reviews'>
                                            {restaurant?.AvgRating || 0} reviews
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="flex-offers-container">
                            {offerData?.Offer_Information?.map((restaurant, index) => (
                                <div key={index} className='offer-card-11' onClick={() => {
                                    fetchOfferDetails(restaurant?.OfferID);
                                }}>
                                    <div className='offer-for-you-image-container'>
                                        <img
                                            className='offer-for-you-image'
                                            src={restaurant?.Offer_Avatar || img1}
                                            alt='restaurant img'
                                            onError={(e) => {
                                                e.target.src = img1; 
                                            }}
                                        />
                                        <div className='offer-for-bg'>
                                            <p className='offer-for-per'>
                                                {restaurant?.Offer_Percentage || 0}%
                                            </p>
                                        </div>
                                    </div>
                                    <h6 className='restaurant-name'>
                                        <p className='restaurant-text'>
                                            {restaurant?.Vendor_Name || 'vendor'}
                                        </p>
                                    </h6>
                                    <div className='offer-image-footer-review'>
                                        <img
                                            className='star-icon-image'
                                            src={star}
                                            alt='star icon'
                                        />
                                        <span className='reviews'>
                                            {restaurant?.AvgRating || 0} reviews
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {selectedOffer && (
                    <OfferDetailsModal 
                        open={open} 
                        handleClose={handleClose} 
                        offer={selectedOffer}
                    />
                )}
            </div>
        )
    );
}
