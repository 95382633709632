import React, { useEffect, useRef, useState } from 'react';
import './../../../Styles/Inbox/OrderDetails.css';
import image1 from '../../../assets/Image1.png';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosVendorInstance from '../../../axiosVendorInstance';
import OrderInvoicePdf from './OrderInvoicePdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import BackButton from '../../../Components/BackButton';

const OrderDetails = () => {
    const invoiceRef = useRef(null);
    const [orderInvoiceData, setOrderInvoiceData] = useState(null);
    const { id } = useParams();
    const { t } = useTranslation();
    console.log('orderInvoiceData', orderInvoiceData);
    const order = {
        orderId: '#12345',
        orderDate: '2025-02-09',
        restaurant: {
            name: 'XYZ Food Hub',
            address: '123 Main Street, Cityville',
            contact: '+1 234 567 890',
            openingHours: '10:00 AM - 10:00 PM',
        },
        customer: {
            name: 'John Doe',
            address: '456 Elm Street, Cityville',
            contact: '+1 987 654 321',
        },
        orderStatus: 'Pending',
        items: [
            {
                name: 'Margherita Pizza',
                price: 50.0,
                quantity: 3,
                image: image1,
                description: 'Classic pizza with tomato sauce and cheese',
            },
        ],
        totalAmount: 500.0,
        discount: 50.0,
        deliveryCharge: 5.0,
        gst: 25.0,
        packagingFee: 20.0,
        tip: 5.0,
    };

    const fetchInvoiceData = async () => {
        try {
            const response = await axiosVendorInstance.get(
                `/Order/user/orderinvoice/${id}`,
                { skipAuth: true }
            );
            console.log('response-invoice', response);
            setOrderInvoiceData(response?.data?.invoice?.[0]['0']?.Invoice_Details);
        } catch (error) {
            console.error('Error fetching order invoice:', error);
        }
    };

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    function formatDate(dateString) {
        if (!dateString) return '';
        const cleanedDate = dateString.split(' ')[0]; 
        return cleanedDate;
    }

    const total = orderInvoiceData?.Order_Details?.reduce((sum, item) => sum + (item?.Menu_Item_Price * item?.Quantity || 0), 0);

    const calculateTotal = () => {
        return (
            total -
      order.discount +
      order.deliveryCharge +
      order.gst +
      order.packagingFee +
      order.tip
        );
    };

    return (
        <div className='order-details-container'>
            <div className='topmenubar order-mobile-topmenubar'>
                <SideMenuBar />
                <div className='top-bar-mobile'>
                    <TopMenuBar />
                </div>
            </div>
            <div className='order-detail-model'>
                <div className='container'>
                    <BackButton style={{marginBottom:'20px'}}/>
                    <div ref={invoiceRef} style={{marginBottom:'20px'}}>
                        <div className='card'>
                            <h2 className='order-id'>
                Order ID: {orderInvoiceData?.Order_ID}
                            </h2>
                            <div className='order-basic-information'>
                                <div className='left-part'>
                                    <div className='restaurant-details-container'>
                                        <p className='restaurant'>
                      Restaurant:{' '}
                                            {orderInvoiceData?.Restaurant_Details?.Restaurant_Name}
                                        </p>
                                        <p className='restaurant-details'>
                      Address:{' '}
                                            {orderInvoiceData?.Restaurant_Details?.Restaurant_Address}
                                        </p>
                                        <p className='restaurant-details'>
                      Contact:{' '}
                                            {orderInvoiceData?.Restaurant_Details?.Restaurant_Phone}
                                        </p>
                                        <p className='restaurant-details'>
                      Opening Hours:{' '}
                                            {orderInvoiceData?.Restaurant_Details?.openingHours ||
                        '10:00 AM - 10:00 PM'}
                                        </p>
                                    </div>
                                    <div className='customer-details-container'>
                                        <p className='customer'>
                      Customer Name:{' '}
                                            {orderInvoiceData?.Buyer_Details?.First_Name}{' '}
                                            {orderInvoiceData?.Buyer_Details?.Last_Name}
                                        </p>
                                        <p className='customer-details'>
                      Address: {orderInvoiceData?.Buyer_Details?.Address}
                                        </p>
                                        <p className='customer-details'>
                      Contact: {orderInvoiceData?.Buyer_Details?.Mobile}
                                        </p>
                                    </div>
                                </div>
                                <div className='right-part'>
                                    <p className='order-date'>
                    Order Date: {formatDate(orderInvoiceData?.Order_Date)}
                                    </p>
                                    <p className='status'>
                    Order Status: {orderInvoiceData?.Status_Text}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='items'>
                            {orderInvoiceData?.Order_Details?.map((item, index) => (
                                <div key={index} className='item-card'>
                                    <div className='item-image-name-container'>
                                        <img
                                            src={item?.Menu_Item_Image || image1}
                                            alt={item?.Menu_Item_Name}
                                            className='menu-item-image'
                                        />
                                        <div>
                                            <p className='item-name'>{item?.Menu_Item_Name}</p>
                                            <div className='item-description'>
                                                <p className='description-heading'>
                                                    {t('orderDetails.description')}:{' '}
                                                </p>
                                                <p
                                                    className='description-value'
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                              item.Description.length > 45
                                  ? item.Description.slice(0, 45) + '...'
                                  : item.Description,
                                                    }}
                                                />
                                            </div>
                                            <p className='item-quantity'>
                                                {t('orderDetails.quantity')}: {item?.Quantity}
                                            </p>
                                            <p className='item-price'>
                                                {t('orderDetails.Price')}: {item?.Menu_Item_Price}
                                            </p>
                                        </div>
                                    </div>
                                    <p className='item-total-price'>
                                        {t('orderDetails.total-price')}: $
                                        {item?.Menu_Item_Price * item?.Quantity}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className='summary-card'>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.total-amount')}:</p>
                                <p className='value'>${total}</p>
                            </div>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.discount')}:</p>
                                <p className='value'>-${order.discount}</p>
                            </div>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.delivery-charge')}:</p>
                                <p className='value'>+${order.deliveryCharge}</p>
                            </div>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.gst')}:</p>
                                <p className='value'>+${order.gst}</p>
                            </div>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.packaging-fee')}:</p>
                                <p className='value'>+${order.packagingFee}</p>
                            </div>
                            <div className='summary-item'>
                                <p className='heading'>{t('orderDetails.tip')}:</p>
                                <p className='value'>+${order.tip}</p>
                            </div>
                            <div className='summary-item total'>
                                <p className='heading'>{t('orderDetails.final-total')}:</p>
                                <p className='value'>${calculateTotal()}</p>
                            </div>
                        </div>
                    </div>

                    {orderInvoiceData && (
                        <PDFDownloadLink
                            document={<OrderInvoicePdf orderInvoiceData={orderInvoiceData} />}
                            fileName={`Order_${orderInvoiceData?.Order_ID}.pdf`}
                            className="download-btn"
                        >
                            {({ loading }) => (loading ? 'Generating PDF...' : 'Download Invoice')}
                        </PDFDownloadLink>
                    )}
                </div>
            </div>

            <div className='right-side-bar'>
                <RightSideBar />
            </div>
        </div>
    );
};

export default OrderDetails;
