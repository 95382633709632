import React, { useEffect, useState } from 'react';
import '../Styles/Reels/ReelsRight.css';
import Share from '../Views/User/Reels/Share';
import Comments from '../Views/User/Reels/Comments';
import FavoriteIcon from '@mui/icons-material/Favorite';
import authMiddleware from './authMiddleware';
import OrderPopop from './OrderPopop';
import UnMuteIcon from '../assets/svg/unMuteIcon';
import MuteIcon from '../assets/svg/muteIcon';
import SaveIcon from '../assets/svg/saveIcon';
import ShareIcon from '../assets/svg/shareIcon';
import CommentIcon from '../assets/svg/commentIcon';
import axiosUserInstance from '../axiosUserInstance';

function ReelsRight(props) {
    const {
        id,
        vendorID,
        restaurantName,
        isLike,
        setIsLike,
        getLikes,
        likesCount,
        commentsCount,
        commentsList,
        setCommentsList,
        getComments,
        handleMute,
        isVideoFile,
        url,
        reelsId,
        globalMuteIcon,
        toggleMuteIcon,
        restaurant,
        saveFlag,
        animateModal,
        setClosing,
        closing,
        setAnimateModal,
        rating,
        vendorDescription,
        setCommentsCount,
    } = props;
    const [openPopop, setOpenPopop] = useState(false);
    const [showCommentPopup, setShowCommentPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isFilled, setIsFilled] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const vendorNames = restaurant?.map((vendor) => vendor?.Vendor_Info?.[0]);
    const handleOpenShare = () => setOpenShare(true);
    const handleCloseShare = () => setOpenShare(false);
    const handleOpenCommentPopup = () => {
        if (!authMiddleware()) {
            return;
        }
        setAnimateModal(false);
        setShowCommentPopup(true);

        setTimeout(() => {
            setAnimateModal(true);
        }, 100);
    };

    const handleCloseCommentPopup = () => setShowCommentPopup(false);

    useEffect(() => {
        if (saveFlag !== undefined) {
            setIsFilled(saveFlag === 'true' || saveFlag === true);
        }
    }, [saveFlag]);

    const handleSaveClick = async () => {
        if (!authMiddleware()) {
            return;
        }

        if (loading) return;
        setLoading(true);

        const toggleState = !isFilled;
        const postData = {
            Post_id: id,
            Created_by: 1,
            Updated_by: 1,
            toggle: toggleState,
        };

        try {
            const res = await axiosUserInstance.post('/post/savepost', postData);

            if (res.status === 200 || res.status === 201) {
                setIsFilled(toggleState);
            } else {
                console.error('Unexpected response from server');
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleOrderClick = () => {
        setOpenPopop(!openPopop);
    };

    const handleLike = async () => {
        if (!authMiddleware()) {
            return;
        }

        if (loading) return;
        setLoading(true);

        const newLikeState = !isLike;
        const LikePost = {
            toggle_like: newLikeState,
        };

        try {
            const res = await axiosUserInstance.post(
                `/customers/likes/account/userid/post/${id}`,
                LikePost
            );

            if (res.status === 200 || res.status === 201) {
                setIsLike(newLikeState);
                await getLikes();
            } else {
                console.error('Unexpected response from server');
            }
        } catch (error) {
            console.error('API Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="right-container">
                <div className="icons">
                    {isVideoFile(url) && (
                        <div>
                            {globalMuteIcon ? (
                                <MuteIcon
                                    className="mute"
                                    onClick={() => {
                                        console.log('Mute toggled');
                                        handleMute();
                                        toggleMuteIcon();
                                    }}
                                />
                            ) : (
                                <UnMuteIcon
                                    className="mute"
                                    onClick={() => {
                                        console.log('Unmute toggled');
                                        handleMute();
                                        toggleMuteIcon();
                                    }}
                                />
                            )}
                        </div>
                    )}
                    <div className="svg-text-container">
                        <FavoriteIcon
                            fontSize="large"
                            className="like-svg-icon"
                            style={{
                                color: isLike ? 'red' : 'white',
                                cursor: loading ? 'not-allowed' : 'pointer',
                                fontSize: '35px',
                            }}
                            onClick={loading ? null : handleLike}
                        />
                        <p className="paragraph-color">{likesCount ? likesCount : '0'}</p>
                    </div>
                    <div className="svg-text-container" style={{ gap: '4px' }}>
                        <CommentIcon handleOpenCommentPopup={handleOpenCommentPopup} />
                        {showCommentPopup && (
                            <Comments
                                id={id}
                                open={showCommentPopup}
                                handleClose={handleCloseCommentPopup}
                                commentsList={commentsList}
                                setCommentsList={setCommentsList}
                                getComments={getComments}
                                animateModal={animateModal}
                                setClosing={setClosing}
                                closing={closing}
                                setAnimateModal={setAnimateModal}
                                setCommentsCount={setCommentsCount}
                            />
                        )}

                        <p className="paragraph-color">
                            {commentsCount ? commentsCount : '0'}
                        </p>
                    </div>

                    <div className="svg-text-container">
                        <ShareIcon handleOpenShare={handleOpenShare} />
                        {openShare && (
                            <Share
                                handleClose={handleCloseShare}
                                open={openShare}
                                // url={`${window.location.href}/${reelsId}`}
                                url={`${window.location.href.replace(/\/(search|profile)/, '/reels')}/${reelsId}`}
                            />
                        )}
                    </div>
                    <div className="svg-text-container" style={{ color: 'white' }}>
                        <SaveIcon
                            loading={loading}
                            handleSaveClick={handleSaveClick}
                            isFilled={isFilled}
                        />
                    </div>
                </div>
                <button
                    className="order-btn"
                    style={{ marginBottom: '20px' }}
                    onClick={() => handleOrderClick(restaurantName)}
                >
          Order
                </button>
            </div>

            <div>
                {openPopop && (
                    <OrderPopop
                        restaurantName={restaurantName}
                        handleClose={() => {
                            setTimeout(() => {
                                setOpenPopop(!openPopop);
                            }, 500);
                        }}
                        restaurant={vendorNames}
                        vendorID={vendorID}
                        rating={rating}
                        vendorDescription={vendorDescription}
                    />
                )}
            </div>
        </div>
    );
}

export default ReelsRight;
