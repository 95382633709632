import React, { lazy, useState, useEffect, useRef, useCallback } from 'react';
import './Tabs.css';

const Food = lazy(() => import('./Food'));
const Restaurant = lazy(() => import('./Restaurant'));
const Post = lazy(() => import('./Post'));
const Blogger = lazy(() => import('./Blogger'));
const Offer = lazy(() => import('./Offer'));

import foodicon from '../../../assets/foodicon.png';
import restauranticon from '../../../assets/restaurenticon.png';
import reelspostsicon from '../../../assets/reelspostsicon.png';
import bloggersicon from '../../../assets/bloggers.png';
import offericon from '../../../assets/offersicon.png';
import SearchHome from './SearchHomePage';
import { useTranslation } from 'react-i18next';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import Loader from '../../../Components/Loader/Loader';
import axiosUserInstance from '../../../axiosUserInstance';

function Tabs({ tabName, setSearchQuery, setTabName, searchQuery }) {

    const [activeTab, setActiveTab] = useState(tabName?.toLowerCase() || 'restaurant');
    const [restaurantResults, setRestaurantResults] = useState([]);
    const [foodResults, setFoodResults] = useState([]);
    const [offerResults, setOfferResults] = useState([]);
    const [postResults, setPostResults] = useState([]);
    const [userResults, setUserResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
    const { t } = useTranslation();

    const observer = useRef();
    const pageSize = 10;

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchQuery(searchQuery);
        }, 1000); 

        return () => {
            clearTimeout(handler); 
        };
    }, [searchQuery]);

    const fetchData = async (type, pageNum) => {
        if (!hasMore && pageNum !== 1) return;  
        setLoading(true);
        setError(null);
    
        try {
            const response = await axiosUserInstance.get(
                `/search?searchText=${debouncedSearchQuery}&location=&pageNo=${pageNum}&pageSize=${pageSize}&type=${type}`
            );
    
            const postData = response?.data?.posts || [];
            const restaurantData = response?.data?.data?.[0]?.Vendor_Information || [];
            const foodData = response?.data?.data?.[0]?.Offer_Information || [];
            const offerData = response?.data?.data?.[0]?.Offer_Information || [];
            const userData = response?.data?.data || [];
    
            switch (type) {
            case 'posts':
                setPostResults((prev) => (pageNum === 1 ? postData : [...prev, ...postData]));
                if (postData.length < pageSize) setHasMore(false);
                break;
            case 'restaurant':
                setRestaurantResults((prev) => (pageNum === 1 ? restaurantData : [...prev, ...restaurantData]));
                if (restaurantData.length < pageSize) setHasMore(false);
                break;
            case 'restaurant_food':
                setFoodResults((prev) => (pageNum === 1 ? foodData : [...prev, ...foodData]));
                if (foodData.length < pageSize) setHasMore(false);
                break;
            case 'offer':
                setOfferResults((prev) => (pageNum === 1 ? offerData : [...prev, ...offerData]));
                if (offerData.length < pageSize) setHasMore(false);
                break;
            case 'users':
                setUserResults((prev) => (pageNum === 1 ? userData : [...prev, ...userData]));
                if (userData.length < pageSize) setHasMore(false);
                break;
            default:
                break;
            }
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to fetch results');
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        setPage(1);
        setHasMore(true);
        setError(null); 
        switch (activeTab) {
        case 'restaurant':
            setRestaurantResults([]);
            break;
        case 'restaurant_food':
            setFoodResults([]);
            break;
        case 'posts':
            setPostResults([]);
            break;
        case 'users':
            setUserResults([]);
            break;
        case 'offer':
            setOfferResults([]);
            break;
        default:
            break;
        }
        fetchData(activeTab, 1);
    }, [activeTab, debouncedSearchQuery]);

    const lastElementRef = useCallback((node) => {
        if (loading || !hasMore) return;  
    
        if (observer.current) observer.current.disconnect(); 
    
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMore) {
                setPage((prevPage) => prevPage + 1);
            }
        });
    
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);
    

    useEffect(() => {
        if (page > 1 && hasMore) fetchData(activeTab, page);
    }, [page]);

    const renderTabContent = () => {
        if (loading && page === 1) return <Loader />;
        if (error) return <p>Error: {error}</p>;

        switch (activeTab) {
        case 'SearchHome':
            return <SearchHome />;
        case 'restaurant_food':
            return <Food searchTerm={debouncedSearchQuery} results={foodResults} lastElementRef={lastElementRef} loading={loading}/>;
        case 'restaurant':
            return (
                <Restaurant
                    searchTerm={debouncedSearchQuery}
                    results={restaurantResults}
                    lastElementRef={lastElementRef}
                    loading={loading}
                />
            );
        case 'posts':
            return <Post results={postResults} searchTerm={debouncedSearchQuery} lastElementRef={lastElementRef} loading={loading}/>;
        case 'users':
            return <Blogger searchTerm={debouncedSearchQuery} results={userResults} lastElementRef={lastElementRef} loading={loading}/>;
        case 'offer':
            return (
                <Offer searchTerm={debouncedSearchQuery} results={offerResults} lastElementRef={lastElementRef} loading={loading}/>
            );
        default:
            return null;
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setTabName(tab ? tab.charAt(0).toUpperCase() + tab.slice(1) : '');
    };
    return (
        <div className="tabs-total-main-container">
           

            <SideMenuBar />

            <TopMenuBar
                tabName={tabName}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                isTab={true}
            />

            <div className="tabs-main-container">
                <div className="icon-and-tabs-container">
                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor:
                activeTab === 'restaurant_food' ? '#EEEDEB' : '#FFFFFF',
                        }}
                        onClick={() => handleTabClick('restaurant_food')}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={foodicon}
                            alt="food"
                        />
                        <div
                            style={{ color: activeTab === 'restaurant_food' ? 'black' : '' }}
                            className="text-search-overlay-tab"
                        >
                            {t('searchscreen.food-btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor:
                activeTab === 'restaurant' ? '#EEEDEB' : '#FFFFFF',
                        }}
                        onClick={() => handleTabClick('restaurant')}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={restauranticon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'restaurant' ? '' : '' }}
                            className="text-search-overlay-tab"
                        >
                            {t('searchscreen.restaurants-btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'posts' ? '#EEEDEB' : '#FFFFFF',
                        }}
                        onClick={() => handleTabClick('posts')}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={reelspostsicon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'posts' ? '' : '' }}
                            className="text-search-overlay-tab"
                        >
                            {t('searchscreen.reels-and-posts--btn')}
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'users' ? '#EEEDEB' : '#FFFFFF',
                        }}
                        onClick={() => handleTabClick('users')}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={bloggersicon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'users' ? '' : '' }}
                            className="text-search-overlay-tab"
                        >
                            Users
                        </div>
                    </div>

                    <div
                        className="icon-and-text-container-search"
                        style={{
                            backgroundColor: activeTab === 'offer' ? '#EEEDEB' : '#FFFFFF',
                        }}
                        onClick={() => handleTabClick('offer')}
                    >
                        <img
                            className="food-icon-search-overlay-search"
                            src={offericon}
                            alt="restaurant"
                        />
                        <div
                            style={{ color: activeTab === 'offer' ? '' : '' }}
                            className="text-search-overlay-tab"
                        >
                            {t('searchscreen.offers-btn')}
                        </div>
                    </div>
                </div>
                <div className="tab-content-search-overlay-fake">
                    {renderTabContent()}
                </div>
            </div>
           

            <RightSideBar />
        </div>
    );
}

export default Tabs;
