import React from 'react';

const WhiteNotificationIcon = () => {
    return (
        <svg
            width="27"
            height="33"
            viewBox="0 0 27 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.4638 20.4199L10.8783 30.2964C11.4416 31.0466 12.3389 31.5319 13.3497 31.5319C14.3604 31.5319 15.2577 31.0466 15.821 30.2964L23.2355 20.4199C24.7829 18.359 25.6999 15.7977 25.6999 13.023C25.6999 6.20809 20.1708 0.683594 13.3502 0.683594C6.52965 0.683594 1 6.20809 1 13.023C1 15.7977 1.91698 18.359 3.46438 20.4199H3.4638Z"
                stroke="white"
                strokeWidth="1.33"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16.4363 13.0183C16.4363 14.7218 15.0539 16.103 13.349 16.103C11.6441 16.103 10.2617 14.7218 10.2617 13.0183C10.2617 11.3149 11.6441 9.93359 13.349 9.93359C15.0539 9.93359 16.4363 11.3149 16.4363 13.0183Z"
                stroke="white"
                strokeWidth="1.33"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default WhiteNotificationIcon;
