import React, { useState , useRef,useEffect} from 'react';
import './../../../Styles/Reels/Comment.css';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toast } from 'react-toastify';
import { useCart } from '../../../CartProvider';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import CloseIcon from '../../../assets/svg/closeIcon';
import axiosVendorInstance from '../../../axiosVendorInstance';

function Comment({
    handleClose,
    id,
    commentsList,
    setCommentsList,
    getComments,
    setCommentsCount
}) {
    const [comment, setComment] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCommentId, setSelectedCommentId] = useState(null);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {profileImage, userData} = useCart();

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setSelectedCommentId(id);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const commentsEndRef = useRef(null); // Reference for scrolling

    const handleEdit = () => {
        const commentToEdit = commentsList.find(
            (comment) => comment.ID === selectedCommentId
        );
        if (commentToEdit) {
            setComment(commentToEdit.Comment);
            setEditIndex(selectedCommentId);
        }
        handleMenuClose();
    };

    const handleDelete = async (commentId) => {
        try {
            await axiosVendorInstance.delete(`/comments/user/post/${id}/comment/${commentId}`);
    
            setCommentsList(prevComments => {
                const updatedComments = prevComments.filter(comment => comment.ID !== commentId);
                setCommentsCount(updatedComments.length); 
                return updatedComments;
            });
    
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };
    
    
    const handleSubmit = async () => {
        if (comment === '') {
            return;
        }
        setLoading(true);
        const currentTime = new Date().toISOString();
        try {
            if (editIndex !== null) {
                const updatedComment = {
                    Comment: comment,
                    Updated_at: currentTime,
                    Updated_by: 789,
                };
                await axiosVendorInstance.put(
                    `/comments/user/post/${id}/${editIndex}`,
                    updatedComment
                );
                setLoading(false);
                setCommentsList(
                    commentsList.map((c) =>
                        c.ID === editIndex ? { ...c, Comment: comment } : c
                    )
                );
                setEditIndex(null);
            } else {
                // Add new comment
                const newComment = {
                    Comment: comment,
                    Created_at: currentTime,
                    Created_by: 456,
                    Updated_at: currentTime,
                    Updated_by: 789,
                };
                const response = await axiosVendorInstance.post(
                    `/comments/user/post/${id}`,
                    newComment
                );
                setLoading(false);
                setCommentsList([...commentsList, response.data]);

                
                getComments();

            }
            setComment('');
        } catch (error) {
            toast.error(error?.response?.data?.error);
            console.error('There was an error saving the comment!', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (commentsList.length > 0 && commentsEndRef.current) {
            commentsEndRef.current.scrollTop = 0;
        }
    }, [commentsList]); 

    const handleProfile = () =>{
        navigate('/profile');

    };

    return (
        <div className="comments-main-container">

            <div className="comment-and-crossIcon-container">
                <Typography id="modal-modal-title" variant="h6" component="h2" className='comment-title'>
                    {t('reels.comments-title')}
                </Typography>

                <CloseIcon onClick={() => handleClose()} className="cross-icon-location" />
            </div>

            
            {commentsList.length === 0 ? <p className="no-comment">No Comments yet</p> :<div className="user-comments-list">
                {commentsList?.map((commentItem) => {
                    const isEditable = dayjs().diff(dayjs(commentItem.Created_at), 'minute') <= 15;
                    return(
                        <div key={commentItem.ID} className="user-comments-container">
                            <div className="user-profile-and-name">
                                <ProfileAvatar firstName={userData?.first_name} lastName={userData?.last_name} profilePic={profileImage} height={30} width={30} fontSize={16} className='' onClick={() => handleProfile()}/>
                                <div className="user-name-verified">
                                    <h5>{userData?.username}</h5>
                                </div>
                            
                                <p className="user-comment-time">
                                    {dayjs(commentItem.Created_at).format('dddd, h:mm A')}
                                </p>
                               
                                <MoreVertIcon
                                    onClick={(e) => handleMenuClick(e, commentItem.ID)}
                                    fontSize="1.2rem"
                                    className="three-dot"
                                />
                                <Menu
                                    id="basic-menu"
                                    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && selectedCommentId === commentItem.ID}
                                    onClose={handleMenuClose}
                                >
                                    {isEditable && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
                                    <MenuItem onClick={() => handleDelete(selectedCommentId)}>Delete</MenuItem>
                                </Menu>
                                
                            </div>
                            <div className="user-comment">
                                <p className="user-comment-text">{commentItem.Comment}</p>
                            </div>
                        </div>
                    );
                }
                )}

                <div ref={commentsEndRef} /> 
            </div>}
            <div className="comment-input-container">
                <ProfileAvatar firstName={userData?.first_name} lastName={userData?.last_name} profilePic={profileImage} height={30} width={40} fontSize={16} className='' onClick={() => handleProfile()}/>
                <input
                    className="comment-input"
                    type="text"
                    placeholder="Add a Comment..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !loading && comment.trim() !== '') {
                            handleSubmit();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    className="comment-post-button"
                    type='submit'
                    onClick={handleSubmit}
                    disabled={loading || comment.trim() === ''}
                    style={{
                        backgroundColor: 'red', 
                        color: '#FFFFFF',
                        cursor: comment.trim() === '' || loading ? 'not-allowed' : 'pointer',
                        opacity: comment.trim() === '' || loading ? 0.7 : 1 
                    }}    
                >
                    {loading ? <CircularProgress size={20} color="inherit" /> : editIndex !== null ? 'Update' : 'Post'}
                </Button>
            </div>
        </div>
    );
}

export default Comment;
