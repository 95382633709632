import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';
import Comment from './Comment';

function Comments({
    handleClose,
    open,
    id,
    commentsList,
    setCommentsList,
    getComments,
    animateModal,
    setClosing,
    closing,
    setCommentsCount
}) {
    const handleModalClose = () => {
        setClosing(true); 
        setTimeout(() => {
            handleClose(); 
            setClosing(false);
        }, 500); 
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={`style comment-box ${animateModal ? 'animate' : ''} ${closing ? 'close' : ''}`}>
                    <Comment
                        handleClose={handleModalClose}
                        id={id}
                        commentsList={commentsList}
                        setCommentsList={setCommentsList}
                        getComments={getComments}
                        setCommentsCount={setCommentsCount} 
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default Comments;
