import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Link } from 'react-router-dom';
import '../User/profilepage/Profilesettings.css';
import bell from '../../assets/notification.png';
import gal from '../../assets/gallery.png';
import { useTranslation } from 'react-i18next';
import axiosVendorInstance from '../../axiosVendorInstance';
import { toast } from 'react-toastify';


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { Skeleton } from '@mui/material'; 
import DefaultBannerImge from '../../assets/vendortopbackground.png';



export default function VendorProfile() {


    
   

   

    const [isSubmit, setIsSubmit] = useState(false); 

    const [restaurantImage, setRestaurantImage] = useState(null); 
    const [restaurantImagePreview, setRestaurantImagePreview] = useState(); 


    const [bannerImage, setBannerImage] = useState(null); 

    console.log(bannerImage);


    const [bannerImagePreview, setBannerImagePreview] = useState(); 

  
    const { t } = useTranslation();

  

    const [businessName, setBusinessName] = useState('');

    const [legalBusinessName, setLegalBusinessName] = useState('');

    const [address, setAddress] = useState('');

    const [city, setCity] = useState('');

    const [state, setState] = useState('');

    const [country, setCountry] = useState('');
    const [zipcode, setZipcode] = useState('');

    const [phoneNumber, setPhoneNumber] = useState('');


    const [representativeName, setRepresentativeName] = useState('');


    const [DOB, setDOB] = useState('');
    const [email, setEmail] = useState('');
    const [taxID, setTaxID] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
   

    const [businessNameError, setBusinessNameError] = useState('');
    const [legalBusinessNameError, setLegalBusinessNameError] = useState('');

    const [addressError, setAddressError] = useState('');

    const [cityError, setCityError] = useState('');

    const [stateError, setStateError] = useState('');

    const [countryError, setCountryError] = useState('');
    const [zipcodeError, setZipcodeError] = useState('');

    const [phoneNumberError, setPhoneNumberError] = useState('');

    const [representativeNameError, setRepresentativeNameError] = useState('');


    const [DOBError, setDOBError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [taxIDError, setTaxIDError] = useState('');
    const [routingNumberError, setRoutingNumberError] = useState('');
    const [accountNumberError, setAccountNumberError] = useState('');



    const [isLoading, setIsLoading] = useState(true);  // Track loading state
   
  
  
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);  // Set loading to false after data is "loaded"
        }, 2000); // Adjust the timeout as per your loading time
    }, []);


    useEffect(() => {
   
        axiosVendorInstance
            .get('/vendors/account')
            .then((response) => {
                const data = response.data.getVendorInfo[0];
                console.log(data);
                setBusinessName(data.Name || '');
                setLegalBusinessName(data.Legal_Business_Name || '');
                setAddress(data.Address || '');
                setCity(data.city);
                setState(data.state);
                setCountry(data.country);
                setZipcode(data.pincode);
                setPhoneNumber(data.Phone_number);
                setRepresentativeName(data.Representative_Name || '');

               
                const formattedDate = data.Date_of_birth 
                    ? data.Date_of_birth.split(' ')[0]
                    : '';
            
                setDOB(formattedDate);
                setEmail(data.Email);
                setTaxID(data.EIN_or_Tax_ID);
                setRoutingNumber(data.Routing_Number);
                setAccountNumber(data.Account_Number);
                setRestaurantImagePreview(data.Avatar_key);
                setBannerImagePreview(data.Banner_Image);
               

                console.log(formattedDate);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);



    const handleRestaurantImageClick = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            // Get file extension
            const fileExtension = file.name.split('.').pop().toLowerCase();
            
            // Valid image extensions
            const validImageTypes = ['jpg', 'jpeg', 'png'];
    
            // Check if file extension is valid
            if (!validImageTypes.includes(fileExtension)) {
                alert('Please upload a JPG, JPEG, or PNG file.');
                setRestaurantImagePreview(null); // Clear preview if file is invalid
                return; // Exit the function if file type is invalid
            }
    
            // Check file size (10MB limit)
            const MAX_SIZE = 10 * 1024 * 1024; // 10MB in bytes
            if (file.size > MAX_SIZE) {
                alert('File size must be less than 10MB.');
                setRestaurantImagePreview(null); // Clear preview if file is too large
                return; // Exit the function if file size exceeds limit
            }
    
            // Set the file object for uploading later
            setRestaurantImage(file);
    
            // For preview purposes, convert the image to base64
            const reader = new FileReader();
            reader.onload = () => {
                // Update the UI with the image preview
                setRestaurantImagePreview(reader.result); // New state for image preview (optional)
            };
            reader.readAsDataURL(file);
        }
    };
    
    



    const handleBannerClick = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            // Get file extension
            const fileExtension = file.name.split('.').pop().toLowerCase();
            
            // Valid image extensions
            const validImageTypes = ['jpg', 'jpeg', 'png'];
    
            // Check if file extension is valid
            if (!validImageTypes.includes(fileExtension)) {
                alert('Please upload a JPG, JPEG, or PNG file.');
                setBannerImagePreview(null); // Clear preview if file is invalid
                return; // Exit the function if file type is invalid
            }
    
            // Check file size (10MB limit)
            const MAX_SIZE = 10 * 1024 * 1024; // 10MB in bytes
            if (file.size > MAX_SIZE) {
                alert('File size must be less than 10MB.');
                setBannerImagePreview(null); // Clear preview if file is too large
                return; // Exit the function if file size exceeds limit
            }
    
            // Set the file object for uploading later
            setBannerImage(file);
    
            // For preview purposes, convert the image to base64
            const reader = new FileReader();
            reader.onload = () => {
                // Update the UI with the image preview
                setBannerImagePreview(reader.result); // New state for image preview (optional)
            };
            reader.readAsDataURL(file);
        }
    };
    

    const handleBusinessNameChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z0-9\s]{3,}$/.test(value)) {
            setBusinessNameError('Enter valid business name');
        } else {
            setBusinessNameError('');
        }
        setBusinessName(value);
    };

    const handleLegalBusinessNameChange = (e) => {
        const value = e.target.value;
        if (!/^[a-zA-Z0-9\s]{3,}$/.test(value)) {
            setLegalBusinessNameError('Enter valid legal business name');
        } else {
            setLegalBusinessNameError('');
        }
       
        setLegalBusinessName(value);
    };


    const handleAddressChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z0-9\s,:-]{10,}$/.test(value)) {
            setAddressError('Enter valid Address');
        } else {
            setAddressError('');
        }
      
       
        setAddress(value);
    };

    const handleCityChange = (e) => {
        const value = e.target.value;

        

        
        if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
            setCityError('Enter valid city');
            
        } else {
            setCityError('');
        }
        
        setCity(value);
    };


    const handleStateChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
            setStateError('Enter valid state');
            
        } else {
            setStateError('');
        }
       
        setState(value);
    };

    const handleCountryChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
            setCountryError('Enter valid country');
            
        } else {
            setCountryError('');
        }
       
        setCountry(value);
    };

    const handleZipcodeChange = (e) => {
        const value = e.target.value;

        if (!/^[0-9]{5}$/.test(value)) {
            setZipcodeError('Enter valid zipcode');
            
        } else {
            setZipcodeError('');
        }
       
        setZipcode(value);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;

        

        if (!/^\d{10}$/.test(value)) {
            setPhoneNumberError('Enter valid phone number');
            
        } else {
            setPhoneNumberError('');
        }
       
        setPhoneNumber(value);
    };

    const handleRepresentativeNameChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z\s]{3,}$/.test(value)) {
            setRepresentativeNameError (' Enter Valid Representative name ');
        } else {
            setRepresentativeNameError('');
        }
       
        setRepresentativeName(value);
    };

    const validateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        
        // Calculate age
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
    
        // Adjust age if birthday hasn't occurred this year yet
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
    
        return age >= 18; // Must be at least 18 years old

    };




    const handleDOBChange = (value) => {
        if (!value) {
        
            const defaultDate = dayjs('01-01-2000', 'YYYY-MM-DD').format('YYYY-MM-DD');
            setDOB(defaultDate);
        } else {
            
            if(validateAge(value)) {
                const formattedDate = dayjs(value).format('YYYY-MM-DD');
                setDOB(formattedDate);
                setDOBError('');
                
            } else  {
                setDOBError('Invalid date format');
                const defaultDate = dayjs('01-01-2000', 'YYYY-MM-DD').format('YYYY-MM-DD');
                setDOB(defaultDate);
            }
        }
    };

   


    const handleEmailChange = (e) => {
        const value = e.target.value;

        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setEmailError('Enter Valid email');
        } else {
            setEmailError('');
        }
       
        setEmail(value);
    };


    const handleTaxIDChange = (e) => {
        let value = e.target.value;

        // Remove all non-digit characters (except hyphen if already exists)
        value = value.replace(/\D/g, '');
    
        // Insert hyphen after the first two digits if the value is long enough
        if (value.length > 2) {
            value = value.substring(0, 2) + '-' + value.substring(2, 9); // Ensure the 7 digits after hyphen
        }
     
        // Set value and validate if the format is correct (XX-XXXXXXX)
        if (!/^\d{2}-\d{7}$/.test(value)) {
            setTaxIDError('Please use the format: XX-XXXXXXX.');
        } else {
            setTaxIDError('');
        }
       
        setTaxID(value);
    };


    const validateRoutingNumber = (routingNumber) => {
        if (!/^\d{9}$/.test(routingNumber)) return false; // Must be exactly 9 digits
        
        const digits = routingNumber.split('').map(Number);
        const checksum = (3 * (digits[0] + digits[3] + digits[6])) +
                         (7 * (digits[1] + digits[4] + digits[7])) +
                         (1 * (digits[2] + digits[5] + digits[8]));
        return checksum % 10 === 0; // Valid if divisible by 10
    };

    const handleRoutingNumberChange = (e) => {
        let value = e.target.value;

        // Remove all non-digit characters
        value = value.replace(/\D/g, '');
    
        // Check validity of routing number
        if (value.length === 9 && validateRoutingNumber(value)) {
           
            setRoutingNumberError('invalid routing number');
        } else {
 
            setRoutingNumberError('');
             
            if (value.length === 9 && !validateRoutingNumber(value)) {
                setRoutingNumberError('invalid routing number');
            } else {
                setRoutingNumberError('');
            }
        }
        
        setRoutingNumber(value);
    };

    const handleAccountNumberChange = (e) => {
        const value = e.target.value;

        if (!/^\d{8,17}$/.test(value)) {
            setAccountNumberError('Enter Valid Account Number');
        } else {
            setAccountNumberError('');
        }
       
        setAccountNumber(value);
    };

    const handleSaveChanges = () => {
        let isValid = true;

        if (!businessName) {
            setBusinessNameError('business name cannot be blank');
            isValid = false;
        } else if (businessName.length > 50) {
            setBusinessNameError('First name cannot be longer than 50 characters');
            isValid = false;
        }

        if (!legalBusinessName) {
            setLegalBusinessNameError('legal business name required');
            isValid = false;
        } else if (legalBusinessName.length > 25) {
            setLegalBusinessNameError('legal business name cannot be longer');
            isValid = false;
        }

        if (!address) {
            setAddressError('Last name cannot be blank');
            isValid = false;
        } else if (address.length > 100) {
            setAddressError('Address cannot be longer than 100 characters');
            isValid = false;
        }

        if (!city) {
            setCityError('Last name cannot be blank');
            isValid = false;
        } else if (city.length > 50) {
            setCityError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!state) {
            setStateError('Last name cannot be blank');
            isValid = false;
        } else if (state.length > 20) {
            setStateError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!country) {
            setCountryError('Last name cannot be blank');
            isValid = false;
        } else if (country.length > 30) {
            setCountryError('Address cannot be longer than 25 characters');
            isValid = false;
        }

        if (!zipcode) {
            setZipcodeError('Last name cannot be blank');
            isValid = false;
        } else if (zipcode.length > 30) {
            setZipcodeError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!phoneNumber) {
            setPhoneNumberError('Last name cannot be blank');
            isValid = false;
        } else if (phoneNumber.length > 20) {
            setPhoneNumberError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!representativeName) {
            setRepresentativeNameError('Last name cannot be blank');
            isValid = false;
        } else if (representativeName.length > 30) {
            setRepresentativeNameError('Address cannot be longer than 25 characters');
            isValid = false;
        }

        if (!DOB) {
            setDOBError('Please enter date of birth');
            isValid = false;
        }


        if (!email) {
            setEmailError('Last name cannot be blank');
            isValid = false;
        } else if (email.length > 30) {
            setEmailError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!taxID) {
            setTaxIDError('Last name cannot be blank');
            isValid = false;
        } else if (taxID.length > 30) {
            setTaxIDError('Address cannot be longer than 25 characters');
            isValid = false;
        }



        if (!routingNumber) {
            setRoutingNumberError('Last name cannot be blank');
            isValid = false;
        } else if (routingNumber.length > 30) {
            setRoutingNumberError('Address cannot be longer than 25 characters');
            isValid = false;
        }


        if (!accountNumber) {
            setAccountNumberError('Last name cannot be blank');
            isValid = false;
        } else if (accountNumber.length > 50) {
            setAccountNumberError('Address cannot be longer than 25 characters');
            isValid = false;
        }

        if (isValid) {
            
            if (isSubmit) return; // Prevent save if already loading
            setIsSubmit(true); // Set loading to true when the request starts

            const formData = new FormData();

            // Append text fields
            formData.append('Name', businessName);
            formData.append('Address', address);
            formData.append('city', city);
            formData.append('state', state);
            formData.append('country', country);
            formData.append('pincode', zipcode);
            formData.append('Phone_number', phoneNumber);
            formData.append('Date_of_birth', DOB);

            // Append images (Ensure restaurantImage & bannerImage are `File` objects)
            if (restaurantImage) {
                formData.append('Avatar_key', restaurantImage);
            }
            if (bannerImage) {
                formData.append('Banner_Image', bannerImage);
            }

            // Send updated data to the server


            axiosVendorInstance
                .put('/vendors/account',formData)
                .then((response) => {
                    console.log('Response data:', response.data);
                    toast.success('Your profile updated successfully');
                })
                .catch((error) =>  {
                    console.error('Error saving changes:', error);
                }).finally(()=>{

                    setIsSubmit(false); // Reset loading to false when the request ends

                });
        }
    };

    const imageURL = restaurantImagePreview?.trim() ? restaurantImagePreview : null;
    const fallbackLetter = (businessName || '').toUpperCase();


    return (
        <>
            <div className="editprofile-top-main">
               
                <div className="edit-profile-main-c">
                    <div className="edit-profile-top1">
                        <Link to="/profilesettings">
                            <div className="ico1">
                                <svg
                                    width="22"
                                    height="25"
                                    viewBox="0 0 42 78"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M38.9909 3L3 39L39 75"
                                        stroke="#464B59"
                                        strokeWidth="5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </Link>
                        <div className="edit-profile-text-layout">
                            <h5 className="edit-profile-text">
                               Edit Vendor
                            </h5>
                        </div>
                        <img src={bell} alt="bell" className="msg-icon" />
                    </div>
                    <div className="edit-profile-bottom">

                        <div className='banner-img-background'>

                         
                            {isLoading ? (
                                <Skeleton variant="rectangular" height={150} width="100%" />
                            ) : (
                                <img src={bannerImagePreview || DefaultBannerImge }  className='banner-image' alt="Profile" />
                            )}

                            <div className="banner-gallary-container">
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleBannerClick}
                                />
                                <label htmlFor="fileInput">
                                    <img src={gal} alt="gallery-icon" className="banner-gallary-img" />
                                </label>
                            </div>

                        </div>
                        <div className="restaurant-profile-gallary-container">
                           
                            {isLoading ? (
                                <Skeleton variant="square" height={130} width="130px" border />
                            ) : (

                                imageURL ? (
                                    <img src={restaurantImagePreview} className="restaurant-edit-profile-photo" alt="Profile" style={{ width: '100%', height: '100%', objectFit:'cover' }} />
                                ):(
                                    <span className='fall-back-letter-vendor'>{fallbackLetter}</span>
                                )
                            )}
                          
                            <div className="restaurant-gallary-container">
                                <input
                                    type="file"
                                    id="fileInputimg"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleRestaurantImageClick}
                                />
                                <label htmlFor="fileInputimg">
                                    <img src={gal} alt="gallery-icon" className="restaurant-gallary-img" />
                                </label>
                            </div>
                        </div>
                        <form className="restaurant-data-container">
                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                       Business Name
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={businessName}
                                        onChange={handleBusinessNameChange}
                                        error={!!businessNameError}
                                        helperText={businessNameError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                       Legal Business Name
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={legalBusinessName}
                                        onChange={handleLegalBusinessNameChange}
                                        error={!!legalBusinessNameError}
                                        helperText={legalBusinessNameError}
                                        InputProps={{ 
                                            readOnly: true ,
    
                                            sx: { backgroundColor: '#f5f5f5', color: '#757575' }, // Light gray background & gray text
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                      Address
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={address}
                                        onChange={handleAddressChange}
                                        error={!!addressError}
                                        helperText={addressError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                       city
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={city}
                                        onChange={handleCityChange}
                                        error={!!cityError}
                                        helperText={cityError}
                                    />
                                </div>
                            </div>

                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                      state
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={state}
                                        onChange={handleStateChange}
                                        error={!!stateError}
                                        helperText={stateError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                       country
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={country}
                                        onChange={handleCountryChange}
                                        error={!!countryError}
                                        helperText={countryError}
                                    />
                                </div>
                            </div>

                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                      zipcode
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={zipcode}
                                        onChange={handleZipcodeChange}
                                        error={!!zipcodeError}
                                        helperText={zipcodeError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                       phone number
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                        error={!!phoneNumberError}
                                        helperText={phoneNumberError}
                                    />
                                </div>
                            </div>

                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                      Representative Name
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={representativeName}
                                        onChange={handleRepresentativeNameChange}
                                        error={!!representativeNameError}
                                        helperText={representativeNameError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                       Date of Birth
                                    </p>
                                   
                                    
                                                                    
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={DOB ? dayjs(DOB, 'YYYY-MM-DD') : null}
                                            onChange={handleDOBChange}
                                            slotProps={{
                                                textField: {
                                                    error: !!DOBError,
                                                    helperText: DOBError,
                                                    fullWidth: true
                                                }
                                            }}
                                            maxDate={dayjs()}
                                            format="YYYY-MM-DD"
                                        />
                                    </LocalizationProvider>
                                                                
                                </div>
                            </div>
                            <div className="username-container">
                                <p className="profile-head-text">
                                   Email
                                </p>
                                <TextField
                                    placeholder={t('editprofile.username-placeholder')}
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={!!emailError}
                                    helperText={emailError}
                                    InputProps={{ 
                                        readOnly: true ,

                                        sx: { backgroundColor: '#f5f5f5', color: '#757575' }, // Light gray background & gray text
                                    }}
                                />
                            </div>
                            <div className="dob-container">
                                <p className="profile-head-text">
                                    EIN/Tax ID
                                </p>
                                <TextField
                                    placeholder={t('editprofile.dob-placeholder')}
                                    value={taxID}
                                    onChange={handleTaxIDChange}
                                    error={!!taxIDError}
                                    helperText={taxIDError}
                                    InputProps={{ 
                                        readOnly: true ,

                                        sx: { backgroundColor: '#f5f5f5', color: '#757575' }, // Light gray background & gray text
                                    }} 
                                />
                            </div>

                            <div className="username-container">
                                <p className="profile-head-text">
                                   Routing Number
                                </p>
                                <TextField
                                    placeholder={t('editprofile.username-placeholder')}
                                    value={routingNumber}
                                    onChange={handleRoutingNumberChange}
                                    error={!!routingNumberError}
                                    helperText={routingNumberError}
                                    InputProps={{ 
                                        readOnly: true ,

                                        sx: { backgroundColor: '#f5f5f5', color: '#757575' }, // Light gray background & gray text
                                    }}
                                />
                            </div>
                            <div className="username-container">
                                <p className="profile-head-text">
                                   Account Number
                                </p>
                                <TextField
                                    placeholder={t('editprofile.username-placeholder')}
                                    value={accountNumber}
                                    onChange={handleAccountNumberChange}
                                    error={!!accountNumberError}
                                    helperText={accountNumberError}
                                    InputProps={{ 
                                        readOnly: true ,

                                        sx: { backgroundColor: '#f5f5f5', color: '#757575' }, 
                                    }} 
                                />
                            </div>

                            <div>
                                <p>
                                    <b>Note :</b> To change more information please contact  <a href=''>admin@omyfood.io</a>
                                </p>
                            </div>
                            <div className="restaurant-save-changes-layout">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: 'var(--color4)', color: '#FFFFFF' }}
                                    onClick={handleSaveChanges}
                                    className="save-changes-button"
                                >
                                   
                                    {isSubmit ? 'updating...' : 'Save Changes'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
