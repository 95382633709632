import { useEffect, useState } from 'react';
import '../Login/Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import leftArrow from '../../assets/vector.png';
import { useAuth } from '../../AuthProvider';
import axiosUserInstance from '../../axiosUserInstance';

function ForgetPassword({setReset}) {
    const { userID } = useAuth();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    const location = useLocation();
    const isVerify = location.state;

    useEffect(() => {
        if (userID) {
            navigate('/dashboard'); 
        }
    }, [userID, navigate]); 
    
    useEffect(() => {
        if(!isVerify){
            navigate('/login');
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const emailVerification = (value)=> {
        const newErrors = { ...errors };
        const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

        if (!value.trim()) {
            newErrors.email = 'Please enter your email.';
        } else if (!emailRegex.test(value)) {
            newErrors.email = 'Invalid email address.';
        } else {
            delete newErrors.email;
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        emailVerification(value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setReset('reset');
        const isValid = emailVerification(email);

        const now = new Date();
        const item = {
            value: email,
            expiry: now.getTime() + 15 * 60 * 1000, // Convert minutes to milliseconds
        };
   
        if (isValid) {
            try {
                setIsLoading(true);
                const res = await axiosUserInstance.post('/auth/forgotpassword', {email: email}, {skipAuth: true});
                setIsLoading(false);
                toast.success(res?.data?.message);
                localStorage.setItem('forgetPasswordEmail', item.value);
                if(res?.statusText == 'OK'){
                    navigate('/verify-otp', { state: true });
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Signup',error);
            }
        }
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className='login-page'>
            {isLoading && <Loader/>}
            <div className='background'></div>
            {isMobile ? <div className="mobile-login-header">
                <div className="login-title">
                    <img src={leftArrow} alt="left-arrow" className='left-side-arrow' onClick={goBack}/>
                    <h2>Forgot Password</h2>
                    <div></div>
                </div>
                <div className="login-text">
                    <p>Forgot your password? No worries! Enter your email to receive a reset link and regain access to your account in no time.</p>
                </div>
            </div> : <></>}
            <div className='login-container'>
                {!isMobile && <h2 className='heading'>Forgot Password</h2>}
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='email'>Email</label>
                        <input 
                            type='email' 
                            id='email' 
                            name='email'  
                            value={email}
                            placeholder='Enter your email' 
                            onChange={(e) => handleInputChange(e)}
                            className={errors.email ? 'input-error' : ''}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </div>
                    <button type='submit' className='login-btn'>
                        Get Verification Code
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ForgetPassword;