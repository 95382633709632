import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import image1 from '../../../assets/Image1.png';
import companyLogo from '../../../assets/OhMyFoodWithBlackText.png';

const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontFamily: 'Helvetica',
        fontSize: 12,
        position: 'relative',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
        borderBottom: '2 solid #333333',
    },
    content: {
        flex: 1, 
    },
    logoSection: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    logo: {
        width: 150,
        height: 100,
        objectFit: 'contain'
    },
    companyInfo: {
        alignItems: 'flex-end',
    },
    orderTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 10,
        marginBottom: 2,
        color: '#333333',
    },
    invoiceTitle: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#333333',
    },
    section: {
        marginBottom: 15,
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#f9f9f9',
        borderBottom: '1 solid #dddddd',
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 8,
        color: '#333333',
        paddingBottom: 5,
        borderBottom: '1 solid #dddddd',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    itemsHeader: {
        flexDirection: 'row',
        borderBottom: '1 solid #dddddd',
        paddingBottom: 5,
        marginBottom: 10,
        fontWeight: 'bold',
    },
    itemImageCol: {
        width: '20%',
    },
    itemDetailsCol: {
        width: '60%',
        paddingLeft: 10,
    },
    itemPriceCol: {
        width: '20%',
        textAlign: 'right',
    },
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 12,
        paddingBottom: 12,
        borderBottom: '1 solid #eeeeee',
    },
    itemImage: {
        width: 60,
        height: 60,
        objectFit: 'cover',
        borderRadius: 5,
    },
    itemDetails: {
        paddingLeft: 10,
    },
    itemName: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 3,
    },
    boldText: {
        fontWeight: 'bold',
    },
    summarySection: {
        marginTop: 10,
        backgroundColor: '#f5f5f5',
        padding: 15,
        borderRadius: 5,
    },
    summaryRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontWeight: 'bold',
        fontSize: 16,
        marginTop: 10,
        paddingTop: 10,
        borderTop: '2 solid #333333',
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 30,
        right: 30,
        textAlign: 'center',
        fontSize: 10,
        color: '#666666',
        borderTop: '1 solid #dddddd',
        paddingTop: 10,
    },
    thankyouMessage: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    contactInfo: {
        fontSize: 10,
        color: '#666666',
        marginTop: 5,
    },
    highlight: {
        backgroundColor: '#f8f4e5',
        padding: 8,
        borderRadius: 5,
        marginTop: 5,
    },
});

const fetchImageAsBase64 = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Error loading image:', error);
        return image1;
    }
};

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    });
};

const formatCurrency = (amount) => {
    return parseFloat(amount).toFixed(2);
};

const OrderInvoicePdf = ({ orderInvoiceData }) => {
    const [imageBase64Map, setImageBase64Map] = useState(null);

    useEffect(() => {
        const loadImages = async () => {
            const imageMap = {};
            for (const item of orderInvoiceData?.Order_Details || []) {
                imageMap[item.Menu_Item_ID] = await fetchImageAsBase64(item.Menu_Item_Image);
            }
            setImageBase64Map(imageMap);
        };
        loadImages();
    }, [orderInvoiceData]);

    if (!imageBase64Map) {
        return <Text>Loading PDF...</Text>;
    }

    // Calculate final total
    const totalAmount = orderInvoiceData?.totalAmount || 500;
    const discount = orderInvoiceData?.discount || 50;
    const deliveryCharge = orderInvoiceData?.deliveryCharge || 5;
    const gst = orderInvoiceData?.gst || 25;
    const packagingFee = orderInvoiceData?.packagingFee || 20;
    const tip = orderInvoiceData?.tip || 5;
    const finalTotal = totalAmount - discount + deliveryCharge + gst + packagingFee + tip;

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.content}>

                    {/* Header with Logo and Title */}
                    <View style={styles.headerContainer}>
                        <View style={styles.logoSection}>
                            <Image src={companyLogo} style={styles.logo} />
                            {/* <OhMyFoodLogo /> */}
                            <Text style={styles.orderTitle}>
                                {orderInvoiceData?.Restaurant_Details?.Restaurant_Name}
                            </Text>
                        </View>
                        <View style={styles.companyInfo}>
                            <Text style={styles.invoiceTitle}>ORDER INVOICE</Text>
                            <Text>Order #: {orderInvoiceData?.Order_ID}</Text>
                            <Text>Date: {formatDate(orderInvoiceData?.Order_Date)}</Text>
                            <View style={styles.highlight}>
                                <Text>Status: {orderInvoiceData?.Status_Text}</Text>
                            </View>
                        </View>
                    </View>
        
                    {/* Customer Information */}
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Customer Details</Text>
                        <View style={styles.row}>
                            <Text style={styles.boldText}>Name:</Text>
                            <Text>{orderInvoiceData?.Buyer_Details?.First_Name} {orderInvoiceData?.Buyer_Details?.Last_Name}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.boldText}>Address:</Text>
                            <Text>{orderInvoiceData?.Buyer_Details?.Address}</Text>
                        </View>
                        <View style={styles.row}>
                            <Text style={styles.boldText}>Contact:</Text>
                            <Text>{orderInvoiceData?.Buyer_Details?.Mobile}</Text>
                        </View>
                    </View>

                    {/* Order Items */}
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Order Items</Text>
          
                        {/* Items Header */}
                        <View style={styles.itemsHeader}>
                            <Text style={styles.itemImageCol}>Item</Text>
                            <Text style={styles.itemDetailsCol}>Description</Text>
                            <Text style={styles.itemPriceCol}>Amount</Text>
                        </View>
          
                        {/* Items List */}
                        {orderInvoiceData?.Order_Details?.map((item, index, array) => (
                            <View key={index} style={[
                                styles.itemContainer, 
                                index === array.length - 1 ? { borderBottomWidth: 0 } : {}
                            ]}>
                                <View style={styles.itemImageCol}>
                                    <Image src={imageBase64Map[item.Menu_Item_ID] || image1} style={styles.itemImage} />
                                </View>
                                <View style={styles.itemDetailsCol}>
                                    <Text style={styles.itemName}>{item?.Menu_Item_Name}</Text>
                                    <Text>Qty: {item?.Quantity} × ${formatCurrency(item?.Menu_Item_Price)}</Text>
                                </View>
                                <View style={styles.itemPriceCol}>
                                    <Text>${formatCurrency(item?.Menu_Item_Price * item?.Quantity)}</Text>
                                </View>
                            </View>
                        ))}
                    </View>

                    {/* Order Summary */}
                    <View style={styles.summarySection}>
                        <Text style={styles.sectionTitle}>Order Summary</Text>
                        <View style={styles.summaryRow}>
                            <Text>Subtotal:</Text>
                            <Text>${formatCurrency(totalAmount)}</Text>
                        </View>
                        <View style={styles.summaryRow}>
                            <Text>Discount:</Text>
                            <Text style={styles.discount}>-${formatCurrency(discount)}</Text>
                        </View>
                        <View style={styles.summaryRow}>
                            <Text>Delivery Charge:</Text>
                            <Text>+${formatCurrency(deliveryCharge)}</Text>
                        </View>
                        <View style={styles.summaryRow}>
                            <Text>GST:</Text>
                            <Text>+${formatCurrency(gst)}</Text>
                        </View>
                        <View style={styles.summaryRow}>
                            <Text>Packaging Fee:</Text>
                            <Text>+${formatCurrency(packagingFee)}</Text>
                        </View>
                        <View style={styles.summaryRow}>
                            <Text>Tip:</Text>
                            <Text>+${formatCurrency(tip)}</Text>
                        </View>
                        <View style={styles.totalRow}>
                            <Text>TOTAL AMOUNT:</Text>
                            <Text>${formatCurrency(finalTotal)}</Text>
                        </View>
                    </View>
                </View>

                {/* Footer */}
                <View style={styles.footer}>
                    <Text style={styles.thankyouMessage}>Thank you for ordering with us!</Text>
                    <Text>We hope you enjoyed your meal.</Text>
                    <Text style={styles.contactInfo}>
            For any inquiries, please contact us at {orderInvoiceData?.Restaurant_Details?.Restaurant_Contact || 'support@restaurant.com'}
                    </Text>
                </View>
            </Page>
        </Document>
    );
};

export default OrderInvoicePdf;