
import React, { useState, useEffect } from 'react';
import '../../../Styles/Payment/Payment.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import { useTranslation } from 'react-i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import CouponModal from './CouponModal';
import axiosUserInstance from '../../../axiosUserInstance';
import { useLocation } from 'react-router-dom';
import BackButton from '../../../Components/BackButton';
import CouponIcon from '../../../assets/svg/CouponIcon';
import RightArrow from '../../../assets/svg/RightArrow';

const stripePromise = loadStripe(
    'pk_test_51Q5f2hGU8f05p0J99Rt422pCwQfd4RRboUWadP767jIp0Mv6fGGP4Nchfz7DZAYhuYxj1V1i7OnPp7PyBm70OuGD00QI2P0gdW'
);

function Payment() {

    const [selectedOption, setSelectedOption] = useState('delivery');
    const { t } = useTranslation();
    const location = useLocation();
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [selectedOfferPercentage, setSelectedOfferPercentage] = useState(null);
    const [selectedCouponAmount, setSelectedCouponAmount] = useState(null);

    const [couponID,setCouponID] = useState(null);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [totalPrice, setTotalPrice] = useState(0);
    const [couponsID, setCouponsID] = useState(null);
    const [offersID, setOffersID] = useState(null);
    const [vendorID,setVendorID] = useState(null);
    const cookingInstructionValue = location.state?.cookingInstructionValue || '';
    const deliveryInstructionValue = location.state?.selectedInstructions || '[]';

    const getTotalPrice = async () => {
        const itemResponse = await axiosUserInstance.get('/cartdetails/cartsp');
        const data = itemResponse.data.cartDetails[0].Cart_Total_Price;
        setVendorID(itemResponse.data.cartDetails[0].Cart_Information[0].Vendor_Id);
       
        console.log(data);
      
        setTotalPrice(data);
    };
       

    useEffect(() => {
        getTotalPrice();
    }, []);

    // const savings = 2;
    const deliveryCharges = 8;
    const price = totalPrice; 
    
    const discountPercent = selectedOfferPercentage || 0; 
    const couponDiscountAmount = selectedCouponAmount || 0; 
    
    
    let discountAmount = 0;
    
    if (discountPercent > 0) {
        
        discountAmount = totalPrice * (discountPercent / 100);
      
    } else if (couponDiscountAmount > 0) {
       
        discountAmount = couponDiscountAmount;
       
    }
    
   
    const total = price - discountAmount  + deliveryCharges;
    
  




    const handleRemoveCoupon = () => {
        setSelectedCoupon(null);
        setSelectedOfferPercentage(null);
        setSelectedCouponAmount(null);
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar />
                <div className="top-bar-mobile">
                    <TopMenuBar goBackCart={goBack}/>
                </div>
            </div>

            <div className="payment-main-container">
                <div>
                    <BackButton/>
                    <p className="have-a-coupon-text">{t('payment.have-a-coupon')}</p>

                    <div className="coupon-bg" onClick={handleOpen}>
                        <div className="text-and-svg">
                            <CouponIcon />
                            <p className="apply-coupon-text">
                                {selectedCoupon ? selectedCoupon : t('payment.apply-coupon')}
                            </p>
                        </div>

                        {selectedCoupon && (
                            <button className="remove-btn" onClick={handleRemoveCoupon}>
                                Remove
                            </button>
                        )}

                        <RightArrow />
                    </div>

                    {open && (
                        <CouponModal
                            open={open}
                            handleClose={handleClose}
                            vendorId={vendorID}
                            setSelectedCoupon={setSelectedCoupon}
                            setSelectedOfferPercentage={setSelectedOfferPercentage}
                            selectedCouponAmount={selectedCouponAmount}
                            setSelectedCouponAmount={setSelectedCouponAmount}
                            setCouponID={setCouponID}
                            setOffersID={setOffersID}
                            setCouponsID={setCouponsID}
                        />
                    )}

                    <div className="delivery-pickup-container">
                        <div
                            className={`delivery-container ${selectedOption === 'delivery' ? 'selected' : ''}`}
                            onClick={() => setSelectedOption('delivery')}
                        >
                            <input
                                className="input-radio"
                                name="food"
                                id="delivery"
                                type="radio"
                                checked={selectedOption === 'delivery'}
                            />
                            <label htmlFor="delivery" className="text">
                                {t('payment.delivery')}
                            </label>
                        </div>

                        <div
                            className={`pickup-container ${selectedOption === 'pickup' ? 'selected' : ''}`}
                            onClick={() => setSelectedOption('pickup')}
                        >
                            <input
                                className="input-radio"
                                name="food"
                                id="pickup"
                                type="radio"
                                checked={selectedOption === 'pickup'}
                            />
                            <label htmlFor="pickup" className="text">
                                {t('payment.pickup')}
                            </label>
                        </div>
                    </div>

                    <div className="bill-container">
                        <p className="bill-summary-text">{t('payment.bill-summary')}</p>
                        <div className="bill-bg">
                            <div className="price-saving-total-container">
                                <div className="price-container">
                                    <h4 className="price-text">{t('payment.price')}</h4>
                                    <p className="amount-text">{price === 0 ? 0 : price} $</p>
                                </div>
                                {/* <div className="price-container">
                                    <h4 className="price-text">{t('payment.savings')}</h4>
                                    <p className="amount-text">{price === 0 ? 0 : -savings} $</p>
                                </div> */}

                                <div className="price-container">
                                    <h4 className="price-text">{t('payment.delivery-charges')}</h4>
                                    <p className="amount-text">{price === 0 ? 0 : deliveryCharges} $</p>
                                </div>

                              


                                {/* Show discount based on selection */}
                                {/* {selectedOfferPercentage  ? (
                                    <div className="price-container">
                                        <h4 className="price-text">Discount</h4>
                                        <p className="amount-text">
            -{selectedOfferPercentage}% (-{((totalPrice * selectedOfferPercentage) / 100).toFixed(2)}$)
                                        </p>
                                    </div>
                                ) : selectedCouponAmount ? (
                                    <div className="price-container">
                                        <h4 className="price-text">Discount</h4>
                                        <p className="amount-text">- {selectedCouponAmount.toFixed(2)}$</p>
                                    </div>
                                ) : null} */}

                                {selectedOfferPercentage  && (
                                    <div className="price-container">
                                        <h4 className="price-text">Discount</h4>
                                        <p className="amount-text">
            -{selectedOfferPercentage}% (-{((totalPrice * selectedOfferPercentage) / 100).toFixed(2)}$)
                                        </p>
                                    </div>
                                )} 

                                { selectedCouponAmount && (
                                    <div className="price-container">
                                        <h4 className="price-text">Discount</h4>
                                        <p className="amount-text">- {selectedCouponAmount.toFixed(2)}$</p>
                                    </div>
                                )}



                               

                                <div className="horizantal-line"></div>

                                <div className="total-container">
                                    <h4 className="price-text">{t('payment.total')}</h4>
                                    <p className="amount-text">{price === 0 ? 0 : total.toFixed(2)} $</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="payment-form-component-container">
                        <Elements stripe={stripePromise}>
                            <PaymentForm total={total} couponID={couponID} couponsID={couponsID}  offersID={offersID} cookingInstructionValue= {cookingInstructionValue} deliveryInstructionValue={deliveryInstructionValue}/>
                        </Elements>
                    </div>
                </div>
            </div>

            <div className="right-side-bar">
                <RightSideBar />
            </div>
        </div>
    );
}

export default Payment;
