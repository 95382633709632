import React, { useState, useEffect } from 'react';

import '../../../Styles/Payment/PaymentForm.css';

import { useTranslation } from 'react-i18next';
import { useNotifications } from '../Notification/NotificationContext';

import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import { useCart } from '../../../CartProvider';
import PaypalIcon from '../../../assets/svg/PaypalIcon';
import CardIcon from '../../../assets/svg/CardIcon';
import CashIcon from '../../../assets/svg/CashIcon';



const CARD_ELEMENT_OPTIONS = {
    showIcon: true,
    style: {
        base: {
            fontSize: '16px',
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
                color: '#aab7c4',
            },
        },

        invalid: {
            color: '#9e2146',
        },
    },
};

const PaymentForm = ({ total, couponID, couponsID, offersID, cookingInstructionValue, deliveryInstructionValue }) => {
    console.log(couponID);

    //const {currentLoginUserId} = useCart();

    const token = localStorage.getItem('authToken');
    const decodedToken = jwtDecode(token);

    console.log(decodedToken?.id);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [cartData, setCartData] = useState([]);
    const [vendorID, setVendorID] = useState(null);
    const { setCartCount } = useCart();

    const fetchDataFromCart = async () => {
        const itemResponse = await axiosUserInstance.get('/cartdetails/cartsp');

        setCartData(itemResponse.data.cartDetails[0].Cart_Information);
        setVendorID(itemResponse.data.cartDetails[0].Cart_Information[0].Vendor_Id);
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const [paymentMethod, setPaymentMethod] = useState('');

    const stripe = useStripe();
    const elements = useElements();
    const [clientSecret, setClientSecret] = useState('');
    console.log(clientSecret);
    const [cardHolderName, setCardHolderName] = useState('');

    const [isProcessing, setIsProcessing] = useState(false);
    const { addNotification } = useNotifications();

    // Call the backend to create a PaymentIntent and get the clientSecret
    useEffect(() => {
    // Function to fetch the clientSecret from the backend
        const createPaymentIntent = async () => {
            try {
                const { data } = await axiosUserInstance.post(
                    '/payment/create-payment-intent',
                    {
                        amount: total * 100, // Send the amount to charge, here 5000 cents = $50
                        currency: 'usd', // Specify the currency
                        description: 'payment created',
                    },
                    { skipAuth: true }
                );

                setClientSecret(data.clientSecret); // Set the clientSecret in state
                setIsProcessing(false); // Disable loading once clientSecret is received
            } catch (error) {
                console.error('Error creating PaymentIntent', error);
            }
        };

        createPaymentIntent(); // Call the function when the component mounts
    }, []); // Empty dependency array means this only runs once when the component mounts

    
    const handlePayment = async () => {
        if (paymentMethod === 'card') {
            

            if (!stripe || !elements) {
                return; 
            }

            setIsProcessing(true);

            try {
                // Confirm payment with card details and cardholder's name
                const { error, paymentIntent } = await stripe.confirmCardPayment(
                    clientSecret,
                    {
                        payment_method: {
                            card: elements.getElement(CardNumberElement), // Card details via CardNumberElement
                            billing_details: {
                                name: cardHolderName, // Send cardholder's name
                            },
                        },
                    }
                );

                if (error) {
                    console.log(error);
                    // setError(error.message);
                    setIsProcessing(false);
                } else if (paymentIntent.status === 'succeeded') {
                    console.log(paymentIntent);

                    try {
                       
                        const getPaymentTypeId = () => {
                            if (paymentMethod === 'cash') return 1;
                            if (paymentMethod === 'card') return 2;
                            if (paymentMethod === 'other') return 3;
                            return null; 
                        };

                        
                        const orderData = {
                            Order_Info: 'Payment success and order placed',
                            Order_type_id: 1,
                            Payment_type_id: getPaymentTypeId(),
                            Total_price: total,
                            Comments: 'Please deliver by 6 PM',
                            Status_id: 2,
                            Created_by: 3,
                        };

                        
                        const itemsData = cartData?.map((item) => ({
                            Price: item.Total_Price,
                            Quantity: item.Item_Quantity,
                            Description: item.MenuItem_Description,
                            Instructions: 'No bell peppers',
                            Menu_item_id: item.Item_ID,
                        }));

                       
                        const dataToSend = {
                            cookingInstructions: cookingInstructionValue,
                            deliveryInstructions: deliveryInstructionValue,
                            orderData,
                            itemsData,
                        };

                        
                        await axiosVendorInstance
                            .post(
                                `/Order/vendor/${vendorID}/user/${decodedToken?.id}`,
                                dataToSend
                            )
                            .then((response) => {
                                console.log('Order placed successfully:', response.data);
                                toast.success('Order placed successfully');
                            });
                    } catch (error) {
                        console.error('There was an error placing the order:', error);
                        toast.error('failed to place the order');
                    }


                    // **Coupon or Offer Redemption Check**
                    if (couponsID) {
                        // Handle coupon redemption
                        const couponData = {
                            coupon_id: couponsID,
                            user_id: decodedToken?.id,
                            amount: total, 
                    
                        };

                        // Redeem the coupon
                        await axiosVendorInstance.post(
                            `/vendor/couponredeption/${vendorID}`,
                            couponData,
                            { skipAuth: true }
                        );
                        console.log('Coupon redeemed successfully');
                    } else if (offersID) {
                        // Handle offer redemption
                        const offerData = {
                            offer_id: offersID,
                            user_id: decodedToken?.id,
                            amount: total,
                   
                        };

                        // Redeem the offer
                        await axiosVendorInstance.post(
                            `/vendor/offers/vendor/${vendorID}`,
                            offerData,
                            { skipAuth: true }
                        );
                        console.log('Offer redeemed successfully');
                    } else {
                        console.log('No coupon or offer selected');
                    }


                    try {
                        const paymentDetails = {
                            user_id: decodedToken?.id,
                            Vendor_id: vendorID,
                            Order_id: 763674734,
                            Invoice_id: 20201030876,
                            Amount: total,
                            Tax: 2,
                            Convence_fee: 3,
                            Total_amount: total,
                            Status_id: 1,
                            Created_by: 1,
                        };

                        // Sending the POST request with axios
                        await axiosUserInstance
                            .post('/payment/savePaymentDb', paymentDetails, {
                                skipAuth: true,
                            })
                            .then((response) => {
                                console.log('payment info saved successfully:', response.data);
                            
                            });
                    } catch (error) {
                        console.error('Payment Info saved successfully:', error);
                        
                    }
                    setCartData([]);
                    toast.success('payment success');
                    navigate('/PaymentSuccess');
                    
                    setIsProcessing(false);
                } else {
                    toast.error('Payment Failed');
                    console.log(error);
                    setIsProcessing(false);
                    navigate('/PaymentFail');
                }
            } catch (error) {
             
                toast.error('Payment Failed');
                console.log(error);
                setIsProcessing(false);
                navigate('/PaymentFail');
            }

            try {
                await axiosUserInstance.delete(
                    '/cartdetails/customer/delteallByCustomerid'
                );
            } catch (error) {
                console.error(error);
            }
        } else if (paymentMethod === 'paypal') {
            // Handle PayPal payment 
        } else if (paymentMethod === 'cash') {
            try {
               
                const getPaymentTypeId = () => {
                    if (paymentMethod === 'cash') return 1;
                    if (paymentMethod === 'card') return 2;
                    if (paymentMethod === 'other') return 3;
                    return null; 
                };

                // Order data, you may get this from the form or app state
                const orderData = {
                    Order_Info: 'Payment success and order placed',
                    Order_type_id: 1,
                    Payment_type_id: getPaymentTypeId(),
                    Total_price: total,
                    Comments: 'Please deliver by 6 PM',
                    Status_id: 1,
                    Created_by: 3,
                };

                // Mapping through the cart items and structuring itemsData
                const itemsData =
          cartData?.map((item) => ({
              Price: item.Total_Price,
              Quantity: item.Item_Quantity,
              Description: item.MenuItem_Description,
              Instructions: 'No bell peppers',
              Menu_item_id: item.Item_ID,
          })) || [];

                // Prepare the payload
                const dataToSend = {
                    cookingInstructions: cookingInstructionValue,
                    deliveryInstructions: `${deliveryInstructionValue}`,
                    orderData,
                    itemsData,
                };

               
                await axiosVendorInstance
                    .post(
                        `/Order/vendor/${vendorID}/user/${decodedToken?.id}`,
                        dataToSend
                    )
                    .then((response) => {
                        console.log('Order placed successfully:', response.data);
                        toast.success('Order placed successfully');
                    });
                const notificationPayload = {
                    from_user_id: decodedToken.id,
                    to_user_id: vendorID, 
                    type: 'order_placed',
                    action_id: vendorID, 
                    notification_boundary: 'private',
                    is_read: false,
                };
    
                console.log('Sending notification:', notificationPayload);
                // Send the first notification for the user (order_placed)
                await addNotification(notificationPayload);
    
                // Create the second notification specifically for the vendor
                const vendorNotificationPayload = {
                    from_user_id: decodedToken.id, // The user who placed the order (same as above)
                    type: 'vendor_order_type', // Type set to 'vendor_order_type'
                    action_id: vendorID, // This can be the vendor ID or the order ID depending on your logic
                    notification_boundary: 'private', // You can adjust this if necessary (public/private)
                    is_read: false, // Initially, the notification is unread
                    notes: `You have received a new order from ${decodedToken.username}`, // A dynamic note for the vendor
                };
    
                console.log('Sending vendor notification:', vendorNotificationPayload);
                // Send the second notification for the vendor
                await addNotification(vendorNotificationPayload);
            } catch (error) {
                console.error('There was an error placing the order:', error);
                toast.error('failed to place the order');
            }

            // **Coupon or Offer Redemption Check**
            if (couponsID) {
                // Handle coupon redemption
                const couponData = {
                    coupon_id: couponsID,
                    user_id: decodedToken?.id,
                    amount: total, 
                    
                };

                // Redeem the coupon
                await axiosVendorInstance.post(
                    `/vendor/couponredeption/${vendorID}`,
                    couponData,
                    { skipAuth: true }
                );
                console.log('Coupon redeemed successfully');
            } else if (offersID) {
                // Handle offer redemption
                const offerData = {
                    offer_id: offersID,
                    user_id: decodedToken?.id,
                    amount: total,
                   
                };

                // Redeem the offer
                await axiosVendorInstance.post(
                    `/vendor/offers/vendor/${vendorID}`,
                    offerData,
                    { skipAuth: true }
                );
                console.log('Offer redeemed successfully');
            } else {
                console.log('No coupon or offer selected');
            }

            try {
                const paymentDetails = {
                    user_id: decodedToken?.id,
                    Vendor_id: vendorID,
                    Order_id: 763674734,
                    Invoice_id: 20201030876,
                    Amount: total,
                    Tax: 2,
                    Convence_fee: 3,
                    Total_amount: total,
                    Status_id: 1,
                    Created_by: 1,
                };

                
                await axiosUserInstance
                    .post('/payment/savePaymentDb', paymentDetails, { skipAuth: true })
                    .then((response) => {
                        console.log('payment info saved successfully:', response.data);
                        
                    });
            } catch (error) {
                console.error('Payment Info saved successfully:', error);
             
            }

            try {
                await axiosUserInstance.delete(
                    '/cartdetails/customer/delteallByCustomerid'
                );
                setCartCount(0);
            } catch (error) {
                console.error(error);
            }

            

            setCartData([]);

          
            
            navigate('/PaymentSuccess');
           
            setIsProcessing(false);
           
        }
    };

    const handlePaymentMethodChange = (method) => {
        setPaymentMethod(method);
    };

    return (
        <div className="payment-mode-container">
            <h4 className="payment-mode-text">{t('payment.preferred-mode')}</h4>

            <div
                className={
                    paymentMethod === 'paypal'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('paypal')}
            >
                <div className="svg-and-label-text">
                    <PaypalIcon />

                    <label htmlFor="paypal" className="payment-label-text">
                        {t('payment.paypal')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="paypal"
                    type="radio"
                    value="paypal"
                    checked={paymentMethod === 'paypal'}
                    onChange={() => handlePaymentMethodChange('paypal')}
                />
            </div>
            <div
                className={
                    paymentMethod === 'card'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('card')}
            >
                <div className="svg-and-label-text">
                    <CardIcon />

                    <label htmlFor="card" className="payment-label-text">
                        {t('payment.card')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="card"
                    type="radio"
                    value="card"
                    checked={paymentMethod === 'card'}
                    onChange={() => handlePaymentMethodChange('card')}
                />
            </div>

            <div
                className={
                    paymentMethod === 'cash'
                        ? 'payment-container selected'
                        : 'payment-container'
                }
                onClick={() => handlePaymentMethodChange('cash')}
            >
                <div className="svg-and-label-text">
                    <CashIcon />

                    <label htmlFor="cash" className="payment-label-text">
                        {t('payment.cash')}
                    </label>
                </div>
                <input
                    className="payment-radio-btn"
                    id="cash"
                    type="radio"
                    value="cash"
                    checked={paymentMethod === 'cash'}
                    onChange={() => handlePaymentMethodChange('cash')}
                />
            </div>

            {paymentMethod === 'card' && (
                <div>
                    <h3>Enter Card Details</h3>

                    <div className="card-label-input">
                        <label className="card-label-name">
                            {t('payment.name-on-card')}
                        </label>
                        <input
                            className="card-input-field"
                            type="text"
                            name="holderName"
                            placeholder="Card Holder Name"
                            value={cardHolderName}
                            onChange={(e) => setCardHolderName(e.target.value)}
                        />
                    </div>

                    {/* Card Number */}
                    <div className="card-label-input">
                        <label className="card-label-name">Card Number</label>
                        <CardNumberElement
                            className="Input-Card"
                            options={CARD_ELEMENT_OPTIONS}
                        />
                    </div>

                    <div className="expiry-and-cvv-container">
                        {/* Expiry Date */}
                        <div className="card-label-input">
                            <label className="card-label-name">Expiry Date</label>
                            <CardExpiryElement
                                className="Input"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>

                        {/* CVC */}

                        <div className="card-label-input">
                            <label className="card-label-name">CVC</label>
                            <CardCvcElement
                                className="Input"
                                options={CARD_ELEMENT_OPTIONS}
                            />
                        </div>
                    </div>
                </div>
            )}

            <button
                className="make-payment-btn"
                onClick={() => {
                    handlePayment();
                }}
                disabled={!stripe || isProcessing}
            >
                {isProcessing ? 'Processing...' : 'Make Payment'}
            </button>
        </div>
    );
};

export default PaymentForm;
