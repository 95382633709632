import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import greenDot from '../../../assets/greendot.svg';
import redDot from '../../../assets/reddot.svg';
import star from '../../../assets/Star.png'; 
import CloseIcon from '../../../assets/svg/closeIcon';
import './FoodMenuDetailsModal.css';

const OfferDetailsModal = ({ open = false, handleClose = () => {}, food, handleOrderClick = () => {}, orderBtn = true }) => {

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                className='food-detail-popup'
            >
                <div className="title-close-container">
                    <CloseIcon size={30} color='black' onClick={handleClose} className='close-icon'/>
                </div>
                <div className="food-details-container">
                    {food?.Avatar_key && <img
                        src={food?.Avatar_key}
                        alt={food?.name}
                        style={{ width: '100%',height:'300px', borderRadius: '8px',objectFit:'cover' }}
                    />}
                    <div className='food-item-name-btn'>
                        <div></div>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {food?.Item_name}
                        </Typography>
                        <div>
                            {orderBtn && <button
                                className='order-popup-btn'
                                onClick={handleOrderClick}
                            >
          Order
                            </button>}
                        </div>
                    </div>

                    <div className="reddot-iten-name">
                        <img className="item-reddot-order" src={food.vegetarian ? greenDot : redDot} alt="dot" />
                        <h5 className="items-name-order">{food.menuItemName}</h5>
                    </div>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Restaurant: {food?.VendorName}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Address: {food?.VendorAddress}
                    </Typography>
                    <div className="Food-dist-layout">
                        <img
                            className="Food-search-rest-star-icon-image"
                            src={star}
                            alt="search-rest-star icon"
                        />
                        <span className="Food-search-rest-reviews">
                            {food?.VendorAvgRating || 0} reviews
                        </span>
                    </div>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Description: <span dangerouslySetInnerHTML={{ __html: food?.Description }}></span>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    Ingredients: <span dangerouslySetInnerHTML={{ __html: food?.Ingridents }}></span>
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                Price : ${food?.Price}
                    </Typography>
                    
                </div>
            </Box>
        </Modal>
    );
};

export default OfferDetailsModal;
