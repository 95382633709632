// import React from 'react';
// import '../../VendorStyles/AddCouponForm.css';
// import CommonButton from '../../Components/commonButton';
// import CloseIcon from '../../assets/svg/closeIcon';

// function AddCouponForm({
//     handleClose,
//     description,
//     shortDescription,
//     title,
//     validFrom,
//     validTo,
//     setValidTo,
//     couponCode,
//     setCouponCode,
//     autoGenerateCode,
//     handleAddOrUpdateCoupon,
//     handleAutoGenerateCodeChange,
//     isEditing,
//     status,
//     setStatus,
//     noBoundaries,
//     setNoBoundaries,
//     maxAmount,
//     redeemLimit,
//     titleError,
//     descriptionError,
//     shortDescriptionError,
//     redeemLimitError,
//     maxAmountError,
//     handleTitleChange,
//     handleDescriptionChange,
//     handleShortDescriptionChange,
//     handleRedeemLimit,
//     handleMaxAmount,
//     handleValidFromChange,
//     validFromError,
//     validToError,
//     couponError,
// }) {
//     return (
//         <div className="popup-container">
//             <div className='coupen-offer-heading'>
//                 <h2 className='coupen-offer-heading-text'>{isEditing ? 'Edit Coupon' : 'Add Coupon'}</h2>
//                 <CloseIcon
//                     height='26'
//                     width='26'
//                     color='#e13535'
//                     onClick={handleClose}
//                 />
//             </div>
//             <div className="coupon-form-container">
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Title :</div>
//                     <input type="text" value={title} onChange={handleTitleChange} />
//                     {titleError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>{titleError}</p>
//                     )}
//                 </div>
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Description :</div>
//                     <input
//                         type="text"
//                         value={description}
//                         onChange={handleDescriptionChange}
//                     />
//                     {descriptionError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>{descriptionError}</p>
//                     )}
//                 </div>
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Short Description : </div>
//                     <input
//                         type="text"
//                         value={shortDescription}
//                         onChange={handleShortDescriptionChange}
//                     />
//                     {shortDescriptionError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>
//                             {shortDescriptionError}
//                         </p>
//                     )}
//                 </div>
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Discount Amount : </div>
//                     <input
//                         type="text"
//                         value={maxAmount}
//                         onChange={handleMaxAmount}
//                         maxLength={3}
//                     />
//                     {maxAmountError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>{maxAmountError}</p>
//                     )}
//                 </div>
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Redeem Limit : </div>
//                     <input
//                         type="text"
//                         value={redeemLimit}
//                         onChange={handleRedeemLimit}
//                         maxLength={5}
//                     />
//                     {redeemLimitError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>{redeemLimitError}</p>
//                     )}
//                 </div>
//                 <div className="generate-coupon-code">
//                     <div className="add-coupon-offer-field-txt">Auto-generate Coupon Code :</div>
//                     <input
//                         className="checkbox-auto-generate"
//                         type="checkbox"
//                         checked={autoGenerateCode}
//                         onChange={handleAutoGenerateCodeChange}
//                     />
//                 </div>
//                 <div className="coupon-form-group">
//                     <div className="add-coupon-offer-field-txt">Coupon Code :</div>
//                     <input
//                         type="text"
//                         value={couponCode}
//                         onChange={(e) => setCouponCode(e.target.value)}
//                         required
//                     />
                
//                     {couponError && (
//                         <p style={{ fontSize: '12px', color: 'red' }}>{couponError}</p>
//                     )}
//                 </div>
//                 <div className="no-boundries-label-and-checkbox">
//                     <div className="no-boundries-label">No Boundaries</div>
//                     <input
//                         className="no-boundries-checkbox"
//                         type="checkbox"
//                         checked={noBoundaries}
//                         onChange={(e) => setNoBoundaries(e.target.checked)}
//                     />
//                 </div>
//                 {!noBoundaries && (
//                     <>
//                         <div className="coupon-form-group">
//                             <div className="add-coupon-offer-field-txt">Validity From :  </div>
//                             <input
//                                 className="date-mis-align-coupon"
//                                 type="datetime-local"
//                                 value={validFrom}
//                                 onChange={handleValidFromChange}
//                             />
                      
//                             {validFromError && (
//                                 <p style={{ fontSize: '12px', color: 'red' }}>{validFromError}</p>
//                             )}
//                         </div>
//                         <div className="coupon-form-group">
//                             <div className="add-coupon-offer-field-txt">Validity To : </div>
//                             <input
//                                 className="date-mis-align-coupon"
//                                 type="datetime-local"
//                                 value={validTo}
//                                 onChange={(e) => setValidTo(e.target.value)}
//                                 min={validFrom}
//                             />
                       
//                             {validToError && (
//                                 <p style={{ fontSize: '12px', color: 'red' }}>{validToError}</p>
//                             )}
//                         </div>
//                     </>
//                 )}

//                 {isEditing && (
//                     <div className="coupon-form-group">
//                         <div className="add-coupon-offer-field-txt">Status:</div>
//                         <select
//                             value={status}
//                             onChange={(e) => setStatus(e.target.value)}
//                             style={{ width: '100px' }}
//                         >
//                             <option value="Active">Active</option>
//                             <option value="Deactive">Deactive</option>
//                         </select>
//                     </div>
//                 )}
//             </div>
//             <div className="vendor-coupon-offer-button">
//                 <CommonButton
//                     onClick={handleAddOrUpdateCoupon}
//                     label={isEditing ? 'Update' : 'Add'}
//                     style={{minWidth:'100px'}}
//                 />
//             </div>
//         </div>
//     );
// }

// export default AddCouponForm;




import React from 'react';
import '../../VendorStyles/AddCouponForm.css';
import CloseIcon from '../../assets/svg/closeIcon';
import { 
    TextField, Checkbox, FormControlLabel, Select, MenuItem, Button
} from '@mui/material';

function AddCouponForm({
    handleClose,
    description,
    shortDescription,
    title,
    validFrom,
    validTo,
    setValidTo,
    couponCode,
    setCouponCode,
    autoGenerateCode,
    handleAddOrUpdateCoupon,
    handleAutoGenerateCodeChange,
    isEditing,
    status,
    setStatus,
    noBoundaries,
    setNoBoundaries,
    maxAmount,
    redeemLimit,
    titleError,
    descriptionError,
    shortDescriptionError,
    redeemLimitError,
    maxAmountError,
    handleTitleChange,
    handleDescriptionChange,
    handleShortDescriptionChange,
    handleRedeemLimit,
    handleMaxAmount,
    handleValidFromChange,
    validFromError,
    validToError,
    couponError,
}) {
    return (
        <div className="popup-container">
            <div className='coupen-offer-heading'>
                <h2 className='coupen-offer-heading-text'>{isEditing ? 'Edit Coupon' : 'Add Coupon'}</h2>
                <CloseIcon
                    height='22'
                    width='22'
                    color='#e13535'
                    onClick={handleClose}
                />
            </div>
            <div className="coupon-form-container">
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Title :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={title} 
                        onChange={handleTitleChange} 
                        error={!!titleError} 
                        helperText={titleError} 
                    />
                </div>
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Description :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={description} 
                        onChange={handleDescriptionChange} 
                        error={!!descriptionError} 
                        helperText={descriptionError} 
                    />
                </div>
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Short Description :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={shortDescription} 
                        onChange={handleShortDescriptionChange} 
                        error={!!shortDescriptionError} 
                        helperText={shortDescriptionError} 
                    />
                </div>
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Discount Amount :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={maxAmount} 
                        onChange={handleMaxAmount} 
                        inputProps={{ maxLength: 3 }} 
                        error={!!maxAmountError} 
                        helperText={maxAmountError} 
                    />
                </div>
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Redeem Limit :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={redeemLimit} 
                        onChange={handleRedeemLimit} 
                        inputProps={{ maxLength: 5 }} 
                        error={!!redeemLimitError} 
                        helperText={redeemLimitError} 
                    />
                </div>
                <div className="generate-coupon-code">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={autoGenerateCode} 
                                onChange={handleAutoGenerateCodeChange} 
                            />
                        }
                        label="Auto-generate Coupon Code"
                    />
                </div>
                <div className="coupon-form-group">
                    <div className="add-coupon-offer-field-txt">Coupon Code :</div>
                    <TextField 
                        fullWidth 
                        size="small" 
                        value={couponCode} 
                        onChange={(e) => setCouponCode(e.target.value)} 
                        error={!!couponError} 
                        helperText={couponError} 
                        required 
                    />
                </div>
                <div className="no-boundries-label-and-checkbox">
                    <FormControlLabel
                        control={
                            <Checkbox 
                                checked={noBoundaries} 
                                onChange={(e) => setNoBoundaries(e.target.checked)} 
                            />
                        }
                        label="No Boundaries"
                    />
                </div>
                {!noBoundaries && (
                    <>
                        <div className="coupon-form-group">
                            <div className="add-coupon-offer-field-txt">Validity From :</div>
                            <TextField 
                                type="datetime-local" 
                                fullWidth 
                                size="small" 
                                value={validFrom} 
                                onChange={handleValidFromChange} 
                                error={!!validFromError} 
                                helperText={validFromError} 
                            />
                        </div>
                        <div className="coupon-form-group">
                            <div className="add-coupon-offer-field-txt">Validity To :</div>
                            <TextField 
                                type="datetime-local" 
                                fullWidth 
                                size="small" 
                                value={validTo} 
                                onChange={(e) => setValidTo(e.target.value)} 
                                inputProps={{ min: validFrom }} 
                                error={!!validToError} 
                                helperText={validToError} 
                            />
                        </div>
                    </>
                )}
                {isEditing && (
                    <div className="coupon-form-group">
                        <div className="add-coupon-offer-field-txt">Status:</div>
                        <Select 
                            value={status} 
                            onChange={(e) => setStatus(e.target.value)} 
                            size="small" 
                            style={{ width: '100px' }} 
                        >
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Deactive">Deactive</MenuItem>
                        </Select>
                    </div>
                )}
            </div>
            <div className="vendor-coupon-offer-button">
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleAddOrUpdateCoupon} 
                    style={{ minWidth: '100px' }}
                >
                    {isEditing ? 'Update' : 'Add'}
                </Button>
            </div>
        </div>
    );
}

export default AddCouponForm;
