import React from 'react';
import './../../../Styles/Cart/DeliveryInstModal.css';
import Box from '@mui/material/Box';

import Modal from '@mui/material/Modal';

import DeliveryInstruction from './DeliveryInstruction';

function DeliveryInstModal({
    handleClose,
    open,
    selectedInstructions,
    handleInstructionClick = () =>{},
}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className='Delivery-inst-modal'>
                    <DeliveryInstruction
                        handleClose={handleClose}
                        selectedInstructions={selectedInstructions}
                        handleInstructionClick={handleInstructionClick}
                    />
                </Box>
            </Modal>
        </div>
    );
}

export default DeliveryInstModal;
