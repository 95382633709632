import React, { useEffect, useState } from 'react';
import '../../../Styles/RestaurantPage/RestaurantPage.css';
import { useSelector } from 'react-redux';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../../../Redux/Contants/action-types';

import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';

import restaurantLogo from './../../../assets/restaurant-logo.png';
import img1 from './../../../assets/Image1.png';

import starImg from './../../../assets/Star3.svg';
import starPic from './../../../assets/Star.png';
import redDot from './../../../assets/reddot.svg';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axiosUserInstance from '../../../axiosUserInstance';

function RestaurantPage() {

    const vendorId = localStorage.getItem('selectedvendorid');
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const { t } = useTranslation();

  

    const [data, setData] = useState([]);
    const [cartCount, setCartCount] = useState(0);

    const fetchDataFromCart = async () => {
    // Fetch item data from the API using the itemID
        const itemResponse = await axiosUserInstance.get('/cartdetails/cartsp/1',{skipAuth: true});
        const data = itemResponse.data.cartDetails[0].Cart_Information;
        console.log(itemResponse.data.cartDetails[0].Cart_Information);

        setData(data);
        setCartCount(
            itemResponse.data.cartDetails[0].Cart_Information?.length
        );

    
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const restaurant = useSelector((state) => {
        return state.ItemReducer.restaurantpage;
    });

    const restaurantItems = useSelector((state) => {
        return state.ItemReducer.restaurantitems;
    });

    console.log(restaurant);
    console.log(restaurantItems);

   

    const addItemToCartFromRestaurantPage = async (itemID) => {
        console.log(itemID);

        try {
           

            const isItemInCart = data.find(
                (cartItem) => cartItem.Item_ID === parseInt(itemID)
            );

            console.log(isItemInCart);

            if (isItemInCart) {
                toast.info('Item is already in the cart');
                return;
            }

            const itemIdToSend = {
                Item_id: itemID,
                Quantity: 1,
            };

            await axiosUserInstance.post(
                `/cartdetails/customer/1/vendor/${vendorId}/item/${itemID}`,
                itemIdToSend, {skipAuth: true}
            );
            // console.log(response);

            toast.success('Item Added Successfully');
        } catch (error) {
            toast.error('failed to add item');
        }
    };

    const handleMoreInfo = (data) => {
        dispatch({
            type: ActionTypes.SELECTED_ITEM_DATA,
            payload: data,
        });

        navigate('/FoodDescription');
    };

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar></SideMenuBar>

                <div className="top-bar-mobile">
                    <TopMenuBar></TopMenuBar>
                </div>
            </div>
            <div className="restaurant-page-container">
                {restaurant[0]?.map((restaurant, index) => {
                    return (
                        <div className="restaurant-main-container" key={index}>
                            <div className="restaurant-logo-cover-img">
                                <img
                                    className="restaurant-cover-image"
                                    src="https://www.alyonascooking.com/wp-content/uploads/2018/01/chow-mein-Asian-noodles-12-480x270.jpg"
                                    alt="coverImg"
                                ></img>
                                <img
                                    className="restaurant-logo"
                                    src={restaurantLogo}
                                    alt="logo"
                                ></img>
                            </div>

                            <div className="restaurant-name-star-rating-container">
                                <Link to="/FoodDescription">
                                    <h4 className="restaurant-name-in-rest-page">
                                        {restaurant.Vendor_Name}
                                    </h4>
                                </Link>
                                <div className="restaurant-star-rating-container">
                                    <img
                                        className="star-img-in-res-page"
                                        src={starImg}
                                        alt="star"
                                    />
                                    <p className="restaurant-rating-count">
                                        {restaurant.Vendor_Rating}
                                    </p>
                                    <p className="restaurant-reviews">
                    12 {t('restaurantpage.reviews-text')}
                                    </p>
                                </div>
                            </div>
                            <p className="restaurant-description">
                                {restaurant.Vendor_Description}
                            </p>

                            <div className="restaurant-page-horizantal-line"></div>

                            <h3 className="res-page-offers-for-heading">
                                {t('restaurantpage.offers-for')}
                            </h3>

                            <div className="filters-scroll">
                                <div className="filters">
                                    <div className="bg-css-res-page-container">
                                        <svg
                                            width="20"
                                            height="18"
                                            viewBox="0 0 20 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M18.7112 1.63175H7.10963C6.79067 0.68443 5.89464 0 4.84113 0C3.78762 0 2.89159 0.68443 2.57263 1.63175H0.761719C0.341047 1.63175 0 1.9728 0 2.39347C0 2.81414 0.341047 3.15519 0.761719 3.15519H2.57268C2.89164 4.10252 3.78767 4.78695 4.84118 4.78695C5.89469 4.78695 6.79072 4.10252 7.10968 3.15519H18.7112C19.1319 3.15519 19.4729 2.81414 19.4729 2.39347C19.4729 1.9728 19.1319 1.63175 18.7112 1.63175ZM4.84113 3.26351C4.3614 3.26351 3.97109 2.8732 3.97109 2.39347C3.97109 1.91374 4.3614 1.52344 4.84113 1.52344C5.32086 1.52344 5.71116 1.91374 5.71116 2.39347C5.71116 2.8732 5.32086 3.26351 4.84113 3.26351Z"
                                                fill="#53565A"
                                            />
                                            <path
                                                d="M18.7112 8.1591H16.9002C16.5812 7.21177 15.6852 6.52734 14.6317 6.52734C13.5782 6.52734 12.6822 7.21177 12.3633 8.1591H0.761719C0.341047 8.1591 0 8.50014 0 8.92082C0 9.34149 0.341047 9.68253 0.761719 9.68253H12.3633C12.6822 10.6299 13.5783 11.3143 14.6318 11.3143C15.6852 11.3143 16.5813 10.6299 16.9003 9.68253H18.7112C19.1319 9.68253 19.4729 9.34149 19.4729 8.92082C19.4729 8.50014 19.1319 8.1591 18.7112 8.1591ZM14.6318 9.79085C14.152 9.79085 13.7617 9.40055 13.7617 8.92082C13.7617 8.44109 14.152 8.05078 14.6318 8.05078C15.1115 8.05078 15.5018 8.44109 15.5018 8.92082C15.5018 9.40055 15.1115 9.79085 14.6318 9.79085Z"
                                                fill="#53565A"
                                            />
                                            <path
                                                d="M18.7112 14.6845H10.3732C10.0542 13.7372 9.1582 13.0527 8.10469 13.0527C7.05118 13.0527 6.15515 13.7372 5.83619 14.6845H0.761719C0.341047 14.6845 0 15.0255 0 15.4462C0 15.8669 0.341047 16.2079 0.761719 16.2079H5.83619C6.15515 17.1552 7.05118 17.8397 8.10469 17.8397C9.1582 17.8397 10.0542 17.1552 10.3732 16.2079H18.7112C19.1319 16.2079 19.4729 15.8669 19.4729 15.4462C19.4729 15.0255 19.1319 14.6845 18.7112 14.6845ZM8.10469 16.3163C7.62496 16.3163 7.23465 15.926 7.23465 15.4463C7.23465 14.9665 7.62496 14.5762 8.10469 14.5762C8.58442 14.5762 8.97472 14.9665 8.97472 15.4462C8.97472 15.9259 8.58442 16.3163 8.10469 16.3163Z"
                                                fill="#53565A"
                                            />
                                        </svg>
                                        <p className="filter-text-in-res-page">
                                            {t('restaurantpage.filter')}
                                        </p>
                                    </div>

                                    <p className="bg-css-res-page">
                                        {t('restaurantpage.starters')}
                                    </p>
                                    <p className="bg-css-res-page">
                                        {t('restaurantpage.main-course')}
                                    </p>
                                    <p className="bg-css-res-page">
                                        {t('restaurantpage.top-rated')}
                                    </p>
                                    <p className="bg-css-res-page">
                                        {t('restaurantpage.main-course')}
                                    </p>
                                </div>
                            </div>

                            <div className="scrollable-container">
                                {restaurantItems[0]?.map((data) => {
                                    const shortDescription = data.Menu_item_Description
                                        ? data.Menu_item_Description.slice(0, 60)
                                        : 'No description available'; // Truncate description

                                    return (
                                        <>
                                            <div
                                                className="item-container-order"
                                                key={data.Menu_item_ID}
                                            >
                                                <div>
                                                    <img
                                                        className="item-img-order"
                                                        src={img1}
                                                        alt="item"
                                                    />
                                                </div>
                                                <div className="item-details-order">
                                                    <div className="item-row1">
                                                        <div className="reddot-iten-name">
                                                            <img
                                                                className="item-reddot-order"
                                                                src={redDot}
                                                                alt="reddot"
                                                            />
                                                            <h5 className="items-name-order">
                                                                {data.Menu_item_Name}
                                                            </h5>
                                                        </div>
                                                        <p className="item-cost-order">$ {data.Price}</p>
                                                    </div>

                                                    <div className="item-row2">
                                                        <div className="star-rating-item">
                                                            <img
                                                                className="item-star-order"
                                                                src={starPic}
                                                                alt="star"
                                                            />
                                                            <p className="item-rating-order">
                                                                {/* {data.itemRating} */}
                                4.5
                                                            </p>
                                                        </div>

                                                        <p className="item-review-order">
                                                            {/* {data.itemReview}{' '} */}
                              31 {''}
                                                            {t('reelsright.reviews-txt')}
                                                        </p>
                                                    </div>

                                                    <div className="desc-add-item">
                                                        <p className="item-desc-order">
                                                            {shortDescription}...
                                                            <button
                                                                onClick={() => handleMoreInfo(data)}
                                                                className="more-btn"
                                                            >
                                More
                                                            </button>
                                                        </p>

                                                        <button
                                                            className="item-add-btn"
                                                            onClick={() => {
                                                                addItemToCartFromRestaurantPage(
                                                                    data.Menu_item_ID
                                                                );
                                                            }}
                                                        >
                                                            {t('reelsright.add-button')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}

                                {/* <div>
                                                <span id='horizantal-line-order'></span>
                                            </div> */}
                            </div>

                            {cartCount > 0 && (
                                <div className="red-box-review-cart">
                                    <h5>
                                        {cartCount} {t('restaurantpage.item-added')}
                                    </h5>
                                    <Link className="review-cart-link" to="/cart">
                                        {t('restaurantpage.review-cart')}
                                    </Link>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>
        </div>
    );
}

export default RestaurantPage;
