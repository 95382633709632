
import React from 'react';
import {useTranslation} from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ic1 from '../../assets/editprofile.png';
import ic2 from '../../assets/paymentsettings.png';
import clockIcon from '../../assets/clock.svg';

import { useNavigate } from 'react-router-dom';
import '../User/profilepage/Profilesettings.css';
import '../../VendorStyles/Vendorsettings.css';
import VendorEditProfile from './VendorEditProfile';
import VendorProfile from './VendorProfile';
import ChangePassword from './ChangePassword';
import BusinessHours from './BusinessHours';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{paddingTop:6}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VendorsettingsMain() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const {t} = useTranslation();

    const initialState = isDesktop ? 0 : undefined;
    const [value, setValue] = React.useState(initialState);    
    const navigate = useNavigate();
    const mobtabs = ['edit-profile','change-password','payment-settings','change-language','update-addresses','chat-with-us','privacy-policy','terms-and-conditions','switch-account','report-a-problem','logout'];

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const screenWidth = window.innerWidth; 
        if (screenWidth < 600) {
            navigate(`/${mobtabs[newValue]}`);
        }
    };
    return (
        
        <div className='profile-settings-home-vendor'>
            <div className='Tabs-container'>

                <Box
                    className='left-panel-vendor'
                    
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className='Tabs-css'
                                    
                                    
                         
                    >
                        <Tab
                            icon={<img src={ic1} alt="profile"    className='tab-icon-img'/>}
                            label={t('profilesettings.edit-profile-tab')}
                            className='left-tabs1'
  
                            {...a11yProps(0)}
                                         
                        />

                        <Tab
                            icon={<img src={ic1} alt="profile"    className='tab-icon-img'/>}
                            label='Edit Vendor'
                            className='left-tabs2'
  
                            {...a11yProps(1)}
                                         
                        />
                                    
                        <Tab
                            icon={<img src={ic2} alt="Password" width='22px' height='17.71px'  className='tab-icon-img'/>}
                            label={t('profilesettings.change-password-tab')}
                            className='left-tabs2'

                            {...a11yProps(2)}
                                       
                        />
                        <Tab
                            icon={<img src={clockIcon} alt="Password" width='22px' height='17.71px'  className='tab-icon-img'/>}
                            label='Business Hours'
                            className='left-tabs2'

                            {...a11yProps(3)}
                                       
                        />
                        
                    </Tabs>
                </Box>
                {window.innerWidth >= 600 && (
                    <Box className='editprofile-main' sx={{ display: 'flex', flexDirection: 'column' }}>
                        <TabPanel value={value} index={0} sx={{ gap: '20px' }}>
                            <VendorEditProfile></VendorEditProfile>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <VendorProfile></VendorProfile>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <ChangePassword></ChangePassword>
                        </TabPanel>    
                        <TabPanel value={value} index={3}>
                            <BusinessHours></BusinessHours>
                        </TabPanel>                   
                    </Box>
                )}

            </div>
        </div>
                     
    );
}
















