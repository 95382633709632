
import React, { useState, useEffect } from 'react';
import './../../../Styles/Inbox/Inbox.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import RateOrder from './RateOrderModel';
import { useTranslation } from 'react-i18next';
import image1 from '../../../assets/Image1.png';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import RightIcon from '../../../assets/svg/RightIcon';
import { useNavigate } from 'react-router-dom';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useCart } from '../../../CartProvider';

function Inbox() {
    const { t } = useTranslation();

    const [previousOrders, setPreviousOrders] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rateOrder, setRateOrder] = useState([]);
    const [vendorID ,setVendorID] = useState();
    const [openRateOrder, setOpenRateOrder] = useState(false);
    const [loading, setLoading] = useState(true); 
    const navigate = useNavigate();
    const { fetchCartCount } = useCart();

    const fetchPreviousOrders = async () => {
        try {
            setLoading(true); 
            const response = await axiosUserInstance.get(
                `/orders/previous-orders-with-vendor?pageNo=${pageNo}&pageSize=4`
            );
            setPreviousOrders(response?.data?.orders || []);
            setTotalPages(response?.data?.pagination?.totalPages || 1);
            setPageNo(response?.data?.pagination?.currentPage || 1);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setPreviousOrders([]); 
            setTotalPages(1);
        } finally {
            setTimeout(() => {
                setLoading(false); 
            }, 1000);
        }
    };

    useEffect(() => {
        fetchPreviousOrders();
    }, [pageNo]);

    const handlePageChange = (event, value) => {
        setPageNo(value);
    };

    const handleOpenRateOrder = (id) => {
        console.log(id);
        const filteredData = previousOrders.find(order => order.OrderInfo?.OrderID === id);

        if (filteredData) {
            const menuItems = filteredData.OrderInfo.OrderInfo.flatMap(order => order.MenuItems);
            const restaurantVendorId = filteredData.OrderInfo.vendorID;
            setRateOrder(menuItems);
            setVendorID(restaurantVendorId);
            setOpenRateOrder(true);
        } else {
            setRateOrder([]);
        }

        setOpenRateOrder(true);
    };

    const handleCloseRateOrder = () => setOpenRateOrder(false);

    const handleOrderDetails = (orderId) =>{
        navigate(`/orders/${orderId}`);
    };

    const handleOrderAgain = async (e, orderId) => {
        e.stopPropagation();

        try {
            setLoading(true);
            const response = await axiosVendorInstance.get(
                `/order/reorder/ordersdata?order_id=${orderId}`, {skipAuth: true}
            );
            if(response?.status == 200){
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
                navigate('/cart');
                await fetchCartCount();
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching previous orders again', error);
        }
    };

    return (
        <div>
            <div className="topmenubar">
                <SideMenuBar />
                <div className="top-bar-mobile">
                    <TopMenuBar />
                </div>
            </div>

            <div className="order-card-main-container">
                <h1 className="previous-orders-heading">Previous Orders</h1>

                {/* Show loader in the middle, below the title, before orders load */}
                {loading && (
                    <Box display="flex" justifyContent="center" alignItems="center" margin="50px 0">
                        <CircularProgress /> 
                    </Box>
                )}

                {!loading && previousOrders?.length > 0 ? (
                    <>
                        <div className="order-list-container">
                            {previousOrders.map((order, index) => (
                                <div key={index} className="order-card-container" onClick={() => handleOrderDetails(order?.OrderInfo?.OrderID)}>
                                    <img className="restaurant-img" src={order?.OrderInfo?.OrderInfo?.[0]?.MenuItems?.[0]?.MenuItemAvatarKey || image1} alt="restaurant" onError={(e) => {
                                        e.target.src = image1;
                                    }}/>
                                    <div className="restaurant-information-container">
                                        <div style={{display:'flex', justifyContent:'space-between'}}>
                                            <div>
                                                <h4 className="restaurant-title">{order?.OrderInfo?.restaurantName || 'Unknown Restaurant'}</h4>
                                                <p className="restaurant-area">{order?.OrderInfo?.address || 'No address available'}</p>
                                            </div>
                                            <div className="restaurant-name-delivered-container">
                                                <p className="delivered-status"><RightIcon /> {order?.OrderInfo?.OrderStatus || 'Pending'}</p>
                                            </div>
                                        </div>
                                        <div className="restuarant-item-container">
                                            {order?.OrderInfo?.OrderInfo?.map((item, subIndex) => (
                                                <p key={subIndex}>{item?.MenuItems?.[0]?.MenuItemName || 'Lasagna al Forno'} ({item?.OrderInfoQuantity})</p>
                                            ))}
                                        </div>
                                        <div className="price-and-btns-container">
                                            <h4>${order?.OrderInfo?.Prize || 0}</h4>
                                            <div className="two-btns">
                                                <button className="btn-rate-order" onClick={(e) => { 
                                                    e.stopPropagation(); 
                                                    handleOpenRateOrder(order.OrderInfo.OrderID); 
                                                }}>
                                                    {t('inbox.rateorder-btn')}
                                                </button>
                                                {openRateOrder && (
                                                    <RateOrder
                                                        rateOrder={rateOrder}
                                                        vendorID={vendorID}
                                                        open={openRateOrder}
                                                        handleClose={handleCloseRateOrder}
                                                    />
                                                )}
                                                <button className="btn-reorder" onClick={(e) => handleOrderAgain(e, order.OrderInfo.OrderID)}>
                                                    {t('inbox.Reorder-btn')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <Stack spacing={2} className="pagination-controls" style={{marginTop:'15px'}}>
                            <Pagination count={totalPages} page={pageNo} onChange={handlePageChange} showFirstButton showLastButton />
                        </Stack>
                    </>
                ) : (
                    !loading && <h5 className='no-data-found'>No Orders Found</h5>
                )}
            </div>

            <div className="right-side-bar">
                <RightSideBar />
            </div>
        </div>
    );
}

export default Inbox;

