import React from 'react';

const CardIcon = () => {
    return (
        <svg
            width="19"
            height="16"
            viewBox="0 0 64 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="payment-svg-icon"
        >
            <path
                d="M55.5651 0H8.46317C4.04736 0 0.472656 3.46182 0.472656 7.73818V37.0618C0.472656 41.3382 4.04736 44.8 8.46317 44.8H55.5651C59.981 44.8 63.5557 41.3382 63.5557 37.0618V7.73818C63.5557 3.46182 59.981 0 55.5651 0ZM59.3501 37.0618C59.3501 39.0778 57.6469 40.7273 55.5651 40.7273H8.46317C6.38143 40.7273 4.67819 39.0778 4.67819 37.0618V7.73818C4.67819 5.72218 6.38143 4.07273 8.46317 4.07273H55.5651C57.6469 4.07273 59.3501 5.72218 59.3501 7.73818V37.0618Z"
                fill="#02A750"
            />
            <path
                d="M19.3966 32.5801H10.9856C9.82443 32.5801 8.88281 33.492 8.88281 34.6164C8.88281 35.7409 9.82443 36.6528 10.9856 36.6528H19.3966C20.5578 36.6528 21.4994 35.7409 21.4994 34.6164C21.4994 33.492 20.5578 32.5801 19.3966 32.5801Z"
                fill="#02A750"
            />
            <path
                d="M53.0409 10.1855H10.9856C9.82443 10.1855 8.88281 11.0974 8.88281 12.2219C8.88281 13.3464 9.82443 14.2583 10.9856 14.2583H53.0409C54.2021 14.2583 55.1437 13.3464 55.1437 12.2219C55.1437 11.0974 54.2021 10.1855 53.0409 10.1855Z"
                fill="#02A750"
            />
        </svg>
    );
};

export default CardIcon;
