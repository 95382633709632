import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../Login/Login.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import leftArrow from '../../assets/vector.png';
import axiosUserInstance from '../../axiosUserInstance';

function ResetPassword() {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [credentials, setCredentials] = useState({
        password: '',
        confirmPassword: '',
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isVerify = location.state;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    useEffect(() => {
        if(!isVerify){
            navigate('/login');
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleValidation = (name, value) => {
        const newErrors = { ...errors };
    
        switch (name) {
        case 'password': {
            const passwordRegex =
                    /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/;
            if (!value) {
                newErrors.password = 'Please enter your password.';
            } else if (value.length < 8 || value.length > 20) {
                newErrors.password = 'Password must be 8-20 characters long.';
            } else if (!passwordRegex.test(value)) {
                newErrors.password =
                        'Password must include uppercase, lowercase, number, and special character.';
            } else {
                delete newErrors.password;
            }
            break;
        }
        case 'confirmPassword': {
            if (!value) {
                newErrors.confirmPassword = 'Please enter your confirm password.';
            } else if (value !== credentials.password) {
                newErrors.confirmPassword = 'Passwords do not match.';
            } else {
                delete newErrors.confirmPassword;
            }
            break;
        }
        default:
            break;
        }
    
        setErrors(newErrors);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = {};
        if (!credentials.password) {
            validationErrors.password = 'Please enter your password.';
        }
        if (!credentials.confirmPassword) {
            validationErrors.confirmPassword = 'Please enter your confirm password.';
        }
    
        Object.keys(credentials).forEach((key) => {
            handleValidation(key, credentials[key]);
            if (errors[key]) {
                validationErrors[key] = errors[key];
            }
        });
            
        setErrors(validationErrors);
        
        if (Object.keys(validationErrors).length === 0) {
            handleReset();
        } else {
            return;
        }    
    };

    const handleReset = async () => {
        const email = localStorage.getItem('forgetPasswordEmail');

        const data = {
            email: email,
            password: credentials.password,
            confirmPassword: credentials.confirmPassword,
        };

        try {
            setIsLoading(true);
            const res = await axiosUserInstance.post('/auth/reset-password', data, {skipAuth: true});
            setIsLoading(false);
            toast.success('Password reset successfully!');
            if(res?.statusText == 'OK'){
                localStorage.removeItem('forgetPasswordEmail');
                navigate('/login');
            } else {
                return;
            }
        } catch (error) {
            setIsLoading(false);
            
            if (error.response) {
                toast.error(error.response.data.error || 'An error occurred.');
            } else {
                toast.error('Unable to connect to the server.');
            }
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
    
        setCredentials((prev) => ({
            ...prev,
            [name]: value,
        }));
    
        handleValidation(name, value);
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <div className='login-page'>
            {isLoading && <Loader/>}
            <div className='background'></div>
            {isMobile ? <div className="mobile-login-header">
                <div className="login-title">
                    <img src={leftArrow} alt="left-arrow" className='left-side-arrow' onClick={goBack}/>
                    <h2>Reset Password</h2>
                    <div></div>
                </div>
                <div className="login-text">
                    <p>Create a new password to secure your account. Make sure it’s strong and unique for better protection.</p>
                </div>
            </div> : <></>}
            <div className='login-container'>
                {!isMobile &&<h2 className='heading'>Reset Password</h2>}
                <form onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='email'>Password</label>
                        <div className='password-container'>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id='password'
                                name='password'
                                placeholder='Enter your password'
                                value={credentials.password}
                                onChange={handleOnChange}
                                className={errors.password ? 'input-error' : ''}
                            />
                            <span
                                className='toggle-password'
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ cursor: 'pointer' }}
                            >
                                {showPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {errors.password && <p className="error-message">{errors.password}</p>}
                    </div>
                    <div className='form-group'>
                        <label className='label-text' htmlFor='password'>confirm Password</label>
                        <div className='password-container'>
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                id='confirmPassword'
                                name='confirmPassword'
                                placeholder='Re-enter password'
                                onChange={handleOnChange}
                                className={errors.confirmPassword ? 'input-error' : ''}
                            />
                            <span
                                className='toggle-password'
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                style={{ cursor: 'pointer' }}
                            >
                                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                            </span>
                        </div>
                        {errors.confirmPassword && (
                            <p className="error-message">{errors.confirmPassword}</p>
                        )}
                    </div>
                    <button type='submit' className='login-btn'>
                        Reset Password
                    </button>
                </form>
            </div>
        </div>
    );
}

export default ResetPassword;