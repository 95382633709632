import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import { Link } from 'react-router-dom';
import './Profilesettings.css';
import bell from '../../../assets/notification.png';
import gal from '../../../assets/gallery.png';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Loader from '../../../Components/Loader/Loader';
import logoIocn from '../../../assets/svg/logo.svg';
import { useCart } from '../../../CartProvider';
import axiosUserInstance from '../../../axiosUserInstance';
import { toast } from 'react-toastify';

export default function Editprofile() {
    const [profileImage, setProfileImage] = useState(null); 
    const [profileImagePreview, setProfileImagePreview] = useState();

    console.log(profileImage);
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [username, setUsername] = useState('');

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [dobError, setDobError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { fetchUserProfile, setUserData } = useCart();
    
    const nameRegex = /^[A-Za-z]{2,}$/;

    const [disableButton, setDisableButton] = useState(true);
    const [initialValues, setInitialValues] = useState({});

    useEffect(() => {
        if (firstNameError || lastNameError || usernameError || !firstName || !lastName || !username) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [firstNameError, lastNameError, usernameError, firstName, lastName, username]);

    useEffect(() => {
        axiosUserInstance.get('/customers/account')
            .then((response) => {
                const data = response.data.profileDetails[0];
    
                const formattedDate = data.DOB ? data.DOB.split('T')[0] : '';
    
                const initialData = {
                    firstName: data.first_name || '',
                    lastName: data.last_name || '',
                    username: data.username || '',
                    dob: formattedDate,
                    profileImagePreview: data.profile_picture,
                };
    
                setFirstName(initialData.firstName);
                setLastName(initialData.lastName);
                setUsername(initialData.username);
                setDob(initialData.dob);
                setProfileImagePreview(initialData.profileImagePreview);
                setInitialValues(initialData);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);

    useEffect(() => {
        const isChanged =
            firstName !== initialValues.firstName ||
            lastName !== initialValues.lastName ||
            username !== initialValues.username ||
            dob !== initialValues.dob ||
            profileImage !== null; 
    
        if (firstNameError || lastNameError || usernameError || dobError || !isChanged) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [firstName, lastName, username, dob, profileImage, firstNameError, lastNameError, usernameError, dobError]);

    const handleGalleryClick = (e) => {
        const file = e.target.files[0]; 
        if (file) {
            setProfileImage(file);
            const reader = new FileReader();
            reader.onload = () => {
                setProfileImagePreview(reader.result); 
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFirstNameChange = (e) => {
        const value = e.target.value.trim();
        if (!value) {
            setFirstNameError('First name cannot be blank');
        } else if (!nameRegex.test(value)) {
            setFirstNameError('Invalid First name');
        } else if (value.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
        } else {
            setFirstNameError('');
        }
        setFirstName(value);
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value.trim();
        if (!value) {
            setLastNameError('Last name cannot be blank');
        } else if (!nameRegex.test(value)) {
            setLastNameError('Invalid Last name');
        }else if (value.length > 50) {
            setLastNameError('Last name cannot be longer than 50 characters');
        } else {
            setLastNameError('');
        }
        setLastName(value);
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value.trim();
        const usernameRegex = /^[a-z0-9_]+$/;    
        if (!value) {
            setUsernameError('Please enter username');
        } else if (!usernameRegex.test(value)) {
            setUsernameError('Invalid username. Only a-z ,0-9 & UnderScores "_" are allowed.');
        } else {
            setUsernameError('');
        }
        setUsername(value);
    };

    const handleDobChange = (newValue) => {
        if (!newValue) {
            setDob('');
            setDobError('');
        } else {
            try {
                const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                setDob(formattedDate);
                setDobError('');
            } catch (error) {
                setDobError('Invalid date format');
            }
        }
    };
    

    const handleSaveChanges = () => {
        let isValid = true;
        const usernameRegex = /^[a-zA-Z0-9_]+$/;


        if (!firstName) {
            setFirstNameError('First name cannot be blank');
            isValid = false;
        } else if (!nameRegex.test(firstName)) {
            setFirstNameError('Invalid First name');
            isValid = false;
        }else if (firstName.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
            isValid = false;
        }

        if (!lastName) {
            setLastNameError('Last name cannot be blank');
            isValid = false;
        } else if (!nameRegex.test(lastName)) {
            setLastNameError('Invalid Last name');
        }else if (lastName.length > 25) {
            setLastNameError('Last name cannot be longer than 25 characters');
            isValid = false;
        }

        if (!username) {
            setUsernameError('Please enter username');
            isValid = false;
        } else if (!usernameRegex.test(username)) {
            setUsernameError('Invalid username. Only a-z ,0-9 & UnderScores "_" are allowed.');
        }

        if (!dob) {
            setDobError('Date of Birth cannot be empty');
            isValid = false;
        }

        if (isValid) {
            
            const formData = new FormData();
            formData.append('First_name', firstName);
            formData.append('Last_name', lastName);
            formData.append('username', username);
            formData.append('Date_of_birth', dob);
            formData.append('Updated_by', 1);

            if (profileImage) {
                formData.append('file', profileImage); 
            }

            setIsLoading(true);
            axiosUserInstance
                .put('/customers/editaccountdeatails', formData)
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.profile_picture) {
                        fetchUserProfile();
                    }
                    setUserData(response.data);
                    toast.success('Profile updated successfully');

                    const updatedData = {
                        firstName,
                        lastName,
                        username,
                        dob,
                        profileImagePreview: profileImage ? URL.createObjectURL(profileImage) : profileImagePreview,
                    };
        
                    setInitialValues(updatedData);
                    setDisableButton(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.error('Error saving changes:', error);
                });
        }
    };

    const imageURL = profileImagePreview?.trim() ? profileImagePreview : null;
    const fallbackLetter = (firstName.charAt(0) + (lastName?.charAt(0) || '')).toUpperCase();

    return (
        <>
            <div className="editprofile-top-main">
                {isLoading && (
                    <div>
                        <Loader/>
                    </div>
                )}
                <div className="sidebar">
                    <SideMenuBar />
                </div>
                <div className="top-bar">
                    <TopMenuBar />
                </div>
                <div className="edit-profile-main-c">
                    <div className="edit-profile-top1">
                        <Link to="/profilesettings">
                            <div className="ico1">
                                <svg
                                    width="22"
                                    height="25"
                                    viewBox="0 0 42 78"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M38.9909 3L3 39L39 75"
                                        stroke="#464B59"
                                        strokeWidth="5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </Link>
                        <div className="edit-profile-text-layout">
                            <h5 className="edit-profile-text">
                                {t('editprofile.editprofile-title')}
                            </h5>
                        </div>
                        <img src={bell} alt="bell" className="msg-icon" />
                    </div>
                    <div className="edit-profile-bottom">
                        <div className="profile-gallary-container">
                            <div className='edit-profile-photo'>
                                {imageURL ? (
                                    <img src={imageURL} className="profile-photo" alt="Profile" 
                                        onError={(e) => {
                                            e.target.src = logoIocn; 
                                            e.target.style.backgroundColor = 'white';
                                        }} 
                                    />
                                ) : (
                                    <span>{fallbackLetter}</span>
                                )}
                            </div>
                            <div className="gallary-container">
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleGalleryClick}
                                />
                                <label htmlFor="fileInput" className="edit-profile-upload">
                                    <img src={gal} alt="gallery-icon" className="gallary-img" />
                                </label>
                            </div>
                        </div>
                        <form className="person-data-container">
                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                        {t('editprofile.first-name-title')}
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        error={!!firstNameError}
                                        helperText={firstNameError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                        {t('editprofile.last-name-title')}
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                    />
                                </div>
                            </div>
                            <div className="username-container">
                                <p className="profile-head-text">
                                    {t('editprofile.username-title')}
                                </p>
                                <TextField
                                    placeholder={t('editprofile.username-placeholder')}
                                    value={username}
                                    onChange={handleUsernameChange}
                                    error={!!usernameError}
                                    helperText={usernameError}
                                />
                            </div>
                            <div className="dob-container"> 
                                <p className="profile-head-text">
                                    {t('editprofile.dob-title')}
                                </p>
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dob ? dayjs(dob, 'YYYY-MM-DD') : null}
                                        onChange={handleDobChange}
                                        slotProps={{
                                            textField: {
                                                error: !!dobError,
                                                helperText: dobError,
                                                fullWidth: true
                                            }
                                        }}
                                        maxDate={dayjs()}
                                        format="YYYY-MM-DD"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="save-changes-layout">
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: 'var(--color4)', 
                                        color: '#FFFFFF',
                                        cursor: disableButton ? 'not-allowed' : 'pointer',
                                        opacity: disableButton ? 0.7 : 1 
                                    }}
                                    onClick={handleSaveChanges}
                                    className="save-changes-button"
                                    disabled={disableButton}
                                >
                                    {t('editprofile.save-changes-button')}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
