import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import VendorOrders from './Views/Vendor/VendorOrders';

// const VendorDashboard = lazy(()=>import('./Views/Vendor/VendorDashboard'));
import VendorDashboard from './Views/Vendor/VendorDashboard';

// const Vendorsettings = lazy(()=>import('./Views/Vendor/Vendorsettings'));
import Vendorsettings from './Views/Vendor/Vendorsettings';

// const VendorRegistrationBG = lazy(()=>import('./Views/Vendor/VendorRegistration/VendorRegistrationBG'));
import VendorRegistrationBG from './Views/Vendor/VendorRegistration/VendorRegistrationBG';

// const VendorCouponsOffers = lazy(()=>import('./Views/Vendor/VendorCouponsOffers'));
import VendorCouponsOffers from './Views/Vendor/VendorCouponsOffers';
import Menu from './Views/Vendor/Menu';
import Dashboard from './Views/Vendor/Dashboard';

import { LoginRedirect } from './LoginRedirect';
// import PageNotFound from './Views/User/PageNotFound/PageNotFound';
// 

import { AuthProvider } from './AuthProvider';

import { OrderProvider } from './ordersCount';



function Vendorrouter() {

    return (
        <div>
            <BrowserRouter>
                <AuthProvider>
                    <OrderProvider>
                        <Routes>
                            <Route
                                path="/vendorkycregistration"
                                element={<VendorRegistrationBG></VendorRegistrationBG>}
                            ></Route>
                            <Route
                                path="/Home"
                                element={<VendorDashboard></VendorDashboard>}
                            ></Route>
                            {/* <Route path='/Menu' element={<VendorDashboard></VendorDashboard>}></Route> */}
                            {/* <Route path='/VendorOrders' element={<VendorOrders ></VendorOrders>}></Route> */}
                            <Route path="/Menu" element={<Menu></Menu>}></Route>
                            <Route path="/menu/updateItem/:id" element={<Menu />} />
                            <Route path="/Orders" element={<Dashboard></Dashboard>}></Route>
                            <Route
                                path="/Vendorsettings"
                                element={<Vendorsettings></Vendorsettings>}
                            ></Route>
                            {/* <Route path='/switch-account' element={<Switchaccount/>} /> */}
                            {/* <Route path='SwithAccountBtns' element={<SwitchAccountBtns/>} /> */}
                            <Route
                                path="/VendorCouponsOffers"
                                element={<VendorCouponsOffers></VendorCouponsOffers>}
                            ></Route>
                            <Route path="*" element={<LoginRedirect />} />
                        
                        </Routes>
                    </OrderProvider>
                </AuthProvider>
            </BrowserRouter>
        </div>
    );
}

export default Vendorrouter;
