import React,{useState} from 'react';
import './../../../Styles/RestaurantPage/FoodDescription.css';
import starImg from './../../../assets/Star3.svg';
import SideMenuBar  from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';
import greenDot from '../../../assets/greendot.svg';
import redDot from '../../../assets/reddot.svg';
import foodPlaceHolder from '../../../assets/food-img-placeholder.jpg';

function FoodDescription() {

    const selectedItemData= useSelector((state)=>{
        return state?.ItemReducer.selecteditemdata;
    });


    console.log('wgsyudysuhvuywdqstaufhxd 5tyhgwd',selectedItemData);

    const [activeTab, setActiveTab] = useState(1);
    const {t} = useTranslation();

    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    return (
        <div>
            <div className="topmenubar-cart">
                <SideMenuBar></SideMenuBar>
                <div className='topbar-food-desc'>
                    <TopMenuBar></TopMenuBar>
                </div> 
            </div> 
            <div className='tabs-content-and-carousel-container'>
                <div className="particular-menu-item-image">
                    <img 
                        src={selectedItemData?.foodImage ? selectedItemData?.foodImage : foodPlaceHolder} 
                        alt="menu-item-image" 
                        onError={(e) => (e.target.src = foodPlaceHolder)}
                    />
                </div>
                <div className='svg-and-title'>
                    <div className='svg-icon-classs'>
                        {selectedItemData?.vegetarian !== null && (
                            <img src={selectedItemData.vegetarian === true ? greenDot : selectedItemData.vegetarian === false ? redDot : ''} alt="dot" />
                        )}
                    </div>
                    <h1 className='food-name'>{selectedItemData.menuItemName}</h1>
                </div>
                <p className='food-description'>
                    {selectedItemData?.menuItemDescription
                        ? selectedItemData.menuItemDescription.length > 100 
                            ? `${selectedItemData.menuItemDescription.substring(0, 100)}...` 
                            : selectedItemData.menuItemDescription
                        : ''}
                </p>
                <div className='star-rating-container'>
                    <div className='star-rating-particular-food'>
                        <img className='star-img-food-desc' src={starImg} alt="starPic" />
                        <p className='food-desc-rating-text'>{selectedItemData.vendorRating ? selectedItemData.vendorRating : 0}</p>
                    </div>
                    <p className='food-desc-reviews'>{selectedItemData.vendorReview ? selectedItemData.vendorReview : 0}{t('restaurantpage.reviews')}</p>
                </div>
                <div className='tabs-and-content-container'>
                    <div className="tabs">
                        <button className={activeTab === 1 ? 'tab-active1' : 'tab1'} onClick={() => handleTabClick(1)}>{t('restaurantpage.description')}</button>
                        <button className={activeTab === 2 ? 'tab-active2' : 'tab2'} onClick={() => handleTabClick(2)}>{t('restaurantpage.ingredients')}</button>
                    </div>
                    <div className="tab-content">
                        {activeTab === 1 && (
                            <div className='ingrediants-description'>
                                <div className='ingridents-heading'>{t('restaurantpage.description-title')}</div>
                                <p dangerouslySetInnerHTML={{ __html: selectedItemData.menuItemDescription }}></p>
                            </div>
                        )}
                        {activeTab === 2 && (
                            <div className='ingrediants-description'>
                                <div className='ingridents-heading'>{t('restaurantpage.ingredients-title')}</div>
                                <div dangerouslySetInnerHTML={{ __html: selectedItemData.menuIngridents }}></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="right-side-bar">
                <RightSideBar></RightSideBar>
            </div>

        </div>
    );
}

export default FoodDescription;