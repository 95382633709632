import { Box, Button, Modal } from '@mui/material';
import '../../VendorStyles/DeleteItem.css';
import { toast } from 'react-toastify';
import axiosVendorInstance from '../../axiosVendorInstance';
import CloseIcon from '../../assets/svg/closeIcon';
import RoundDeleteIconSvg from '../../assets/svg/roundDeleteIcon';
function DeleteItem({ onClose, deleteID,fetchMenuTypes,fetchMenuItems }){

    const handleDelete = async () => {
        try {
            await axiosVendorInstance.delete(`/vendors/menuitems/menu/${deleteID}`,{skipAuth:true});
            toast.success('Item deleted successfully');
            onClose();
            fetchMenuTypes();
            fetchMenuItems();
        } catch (error) {
            toast.error('Error deleting item');
        }
    };
    return(
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="menu-Model-container">

                    <div className="add-edit-menu-close-icon">      
                        <CloseIcon
                            height='26'
                            width='26'
                            className={'menu-add-menu-popup-cross-icon'}
                            color='#e13535'
                            onClick={onClose}
                        />
                    </div>
                    <div className="edit-popup">
                        <RoundDeleteIconSvg/>
                        <h5 className="menu-deletepopup-delete-text">You are about to delete an item?</h5>
                        <p className='menu-deletepopup-small-heading'>This will be delete permanently 
                from the menu </p>
                        <div className='menu-deletepopup-btns-container'>
                            <Button onClick={onClose} className={'menu-deletepopup-cancel-btn'}>Cancel</Button>
                            <Button onClick={handleDelete} className={'menu-deletepopup-delete-btn'}>Delete</Button>
                        </div>

                    </div>
                </Box>
            </Modal>
        </div>
        
    );
}
export default DeleteItem;