
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Box, Dialog, DialogTitle, DialogContent, Grid, RadioGroup, FormControlLabel, Radio, useTheme, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import starPic from './../assets/Star.png';
import filterIcon from './../assets/Filtericon.png';
import foodPlaceHolder from './../assets/food-img-placeholder.jpg';
import greenDot from './../assets/greendot.svg';
import redDot from './../assets/reddot.svg';
import cartArrow from './../assets/vectorGreater.png';
import Loader from './Loader/Loader';
import { useTranslation } from 'react-i18next';
import authMiddleware from './authMiddleware';
import { ActionTypes } from '../Redux/Contants/action-types';
import { useDispatch } from 'react-redux';
import '../Styles/Reels/OrderPopup.css';
import { useCart } from '../CartProvider';
import ConfirmationModal from './ConfirmationModal';
import { toast } from 'react-toastify';
import axiosUserInstance from '../axiosUserInstance';
import axiosVendorInstance from '../axiosVendorInstance';
import CloseIcon from '../assets/svg/closeIcon';

const OrderPopop = ({
    vendorID,
    restaurantName,
    handleClose,
    rating,
    vendorDescription,
}) => {
    const isDesktop = useMediaQuery('(min-width:601px)'); 
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('Relevance');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filteredItems, setFilteredItems] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [cartCount, setCartCount] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [animateModal, setAnimateModal] = useState(false);
    const [closing, setClosing] = useState(false);
    const [openOrderPopup, setOpenOrderPopup] = useState(false);
    const handleOpenOrderPopup = () => setOpenOrderPopup(true);

    const [selectedMenuName, setSelectedMenuName] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalResolve, setModalResolve] = useState(null);
    const [data, setData] = useState([]);

    const { fetchCartCount } = useCart();
    const scrollRef = useRef(null);

    const handleMenuClick = (menuName) => {
        setSelectedMenuName(prev => 
            prev.includes(menuName) 
                ? prev.filter(name => name !== menuName)
                : [...prev, menuName] 
        );
    };

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        if (!scrollContainer) return;
    
        const handleWheelScroll = (event) => {
            if (event.deltaY !== 0) {
                event.preventDefault();
                scrollContainer.scrollLeft += event.deltaY * 2;
            }
        };
    
        scrollContainer.style.overflowX = 'scroll';
        scrollContainer.addEventListener('wheel', handleWheelScroll, { passive: false });
    
        return () => {
            scrollContainer.removeEventListener('wheel', handleWheelScroll);
        };
    }, []);
    
    const displayedItems = filteredItems
        .filter(item => 
            selectedMenuName.length === 0 || 
        selectedMenuName.some(name => item.menuName?.toLowerCase() === name.toLowerCase())
        )
        .filter(item => {
            if (selectedFilter === 'Pure Veg') return item.vegetarian === true;
            if (selectedFilter === 'Non-Veg') return item.vegetarian === false;
            return true; 
        })
        .sort((a, b) => {
            if (selectedFilter === 'priceLowToHigh') return a.menuItemPrice - b.menuItemPrice;
            if (selectedFilter === 'priceHighToLow') return b.menuItemPrice - a.menuItemPrice;
            return 0;
        });

    useEffect(() => {
        const totalCount = Object.values(quantities).reduce((sum, qty) => sum + qty, 0);
        setCartCount(totalCount);
    }, [quantities]);

    const increaseQuantity = async (itemID, restaurantID) => {
        const newQuantity = (quantities[itemID] || 0) + 1;
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemID]: newQuantity,
        }));

        await updateCartAPI(itemID, restaurantID, newQuantity);
    };

    const decreaseQuantity = async (itemID, restaurantID) => {
        const newQuantity = Math.max(0, (quantities[itemID] || 0) - 1);
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemID]: newQuantity,
        }));

        await updateCartAPI(itemID, restaurantID, newQuantity);
    };

    const updateCartAPI = async (itemID, restaurantID, quantity) => {
        try {
            if (quantity === 0) {
                await axiosUserInstance.delete(`/cartdetails/customer/vendor/${restaurantID}/${itemID}`);
            } else {
                await axiosUserInstance.put(
                    `/cartdetails/customer/vendor/${restaurantID}/item/${itemID}`,
                    { Quantity: quantity },
                );
            }

            await fetchDataFromCart(); 
            await fetchCartCount();
        } catch (error) {
            toast.error('Failed to update cart');
        }
    };

    const handleMoreInfo = (data) => {
        dispatch({
            type: ActionTypes.SELECTED_ITEM_DATA,
            payload: data,
        });

        navigate('/FoodDescription');
    };

    useEffect(() => {
        if (!vendorID || !authMiddleware()) return;
    
        const fetchMenuItems = async () => {
            setIsLoading(true);
    
            try {
                const response = await axiosVendorInstance.get(`/vendors/menuitems/${vendorID}`, {
                    params: {
                        page_no: 1,
                        page_size: 100,
                    }, 
                }, {skipAuth: true});
    
                if (response.data && response.data.menuItems) {
                    const menuItems = response.data.menuItems.map((item) => ({
                        menuItemId: item?.ID,
                        vendorDesc: item?.Vendor_Info?.Vendor_Description || 'N/A',
                        vendorRating: item?.vendor_menu?.Rating || 'N/A',
                        vendorReview: item?.vendor_menu?.Review || 'N/A',
                        foodImage: item?.Avatar_key,
                        vegetarian: item?.Vegetarian,
                        menuItemName: item?.Item_name,
                        menuItemDescription: item?.Description,
                        menuItemPrice: item?.Price,
                        menuName: item?.vendor_menu?.Menu_name,
                        menuIngridents : item?.Ingridents,
                    }));
                    setFilteredItems(menuItems);
                }
            } catch (error) {
                console.error('Error fetching restaurant details:', error);
                setFilteredItems([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMenuItems();
        setClosing(false);
        handleOpenOrderPopup();
    
        setTimeout(() => {
            setAnimateModal(true);
        }, 100);
    }, [restaurantName, vendorID]); 
    
    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
        handleFilterPopupClose(); 
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleFilterPopupClose = () => {
        setOpen(false);
    };

    const showConfirmationPopup = () => {
        return new Promise((resolve) => {
            setModalOpen(true);
            setModalResolve(() => resolve);
        });
    };

    const handleCloseModal = (confirmed) => {
        setModalOpen(false);
        if (modalResolve) modalResolve(confirmed);
        setModalResolve(null);
    };

    const handleConfirmModal = async () => {
        handleCloseModal(true);
    };

    const fetchDataFromCart = async () => {
        try {
            const response = await axiosUserInstance.get('/cartdetails/cartsp');
            const data = response?.data?.cartDetails[0]?.Cart_Information;
            setData(data);

            const initialQuantities = {};
            data?.forEach((cartItem) => {
                initialQuantities[cartItem.Item_ID] = cartItem.Item_Quantity;
            });
            setQuantities(initialQuantities);
        } catch (error) {
            console.error('Error fetching cart data:', error);
        }
    };

    useEffect(() => {
        fetchDataFromCart();
    }, []);

    const addToCart = async (itemID, restaurantID) => {
        try {
            const differentRestaurantItem = data?.some(
                (cartItem) => cartItem.Vendor_Id !== restaurantID
            );
            
            if (differentRestaurantItem) {
                const confirmed = await showConfirmationPopup(
                    'Your cart contains items from another restaurant. Would you like to reset your cart for adding items from this restaurant?'
                );
    
                if (!confirmed) {
                    await fetchDataFromCart(); // Refresh cart data to ensure UI consistency
                    return;
                }
    
                await axiosUserInstance.delete('/cartdetails/customer/delteallByCustomerid');
            }
    
            const isItemInCart = data?.find(
                (cartItem) => cartItem.Item_ID === parseInt(itemID)
            );

            if (isItemInCart) {
                toast.info('Item is already in the cart');
                return;
            }
    
            const itemIdToSend = {
                Item_id: itemID,
                Quantity: 1,
            };
    
            await axiosUserInstance.post(
                `/cartdetails/customer/vendor/${restaurantID}/item/${itemID}`,
                itemIdToSend
            );
    
            await fetchDataFromCart();
            await fetchCartCount();
    
            dispatch({
                type: ActionTypes.SELECTED_VENDOR_ID,
                payload: restaurantID,
            });

            setQuantities((prevQuantities) => ({
                ...prevQuantities,
                [itemID]: 1,
            }));
        } catch (error) {
            toast.error('Failed to add item');
        }
    };

    return (
        <div>
            <>
                <Modal 
                    open={openOrderPopup} 
                    onClose={()=>{ 
                        setClosing(true);
                        handleClose();
                    }}>
                    <Box className={`style ${animateModal ? 'animate' : ''} ${closing ? 'close' : ''}`}>
                        {isLoading && <Loader />}
                        <div className="restaurant-tittle-container">
                            <div className='restaurant-name-rating'>
                                <h1 className="order-restaurant-name">{restaurantName}</h1>
                                <div className="res-star-rating">
                                    <div className="img-rating-restaurant">
                                        <img className="star-img-order" src={starPic} alt="star" />
                                        <p className="rating-order">{Number(rating || 0).toFixed(1)} {t('restaurantpage.ratings-text')}</p>
                                    </div>
                                </div>
                            </div>
                            <CloseIcon
                                size={30}
                                color='black'
                                onClick={handleClose}
                                className='close-icon'
                            />
                        </div>
                        <div>
                            <p className="rest-description">{vendorDescription || ''}</p>
                            <div ref={scrollRef} className="order-filters-scroll">
                                <div className="filters">
                                    {[...new Set(filteredItems?.map(item => item?.menuName))]?.map((menuName, index) => (
                                        <p
                                            key={index}
                                            className={`bg-css ${selectedMenuName.includes(menuName) ? 'active-filter' : ''}`}
                                            onClick={() => handleMenuClick(menuName)}
                                        >
                                            {menuName}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className="restaurant-common-filter">
                                <div></div>
                                <p className='bg-css'
                                    onClick={handleOpen}
                                >
                                    <img src={filterIcon} alt="filter-icon" className='filter-icon' />
                Filter
                                </p>
                            </div>

                            <div className="scrollable-container" style={{ height: isDesktop ? (cartCount > 0 ? '280px' : '340px') : (cartCount > 0 ? '250px' : '322px') }}>
                                {displayedItems.length > 0 && (
                                    displayedItems.map((data) => {
                                        const shortDescription = data.menuItemDescription ? data.menuItemDescription.slice(0, 130) : 'No description available';
                                        const shouldShowMore = data.menuItemDescription && data.menuItemDescription.length > 120;
                                        return (
                                            <>
                                                <div key={data.menuItemId} className="item-container-order">
                                                    <div>
                                                        <img className="item-img-order" src={data.foodImage || foodPlaceHolder} onClick={() => handleMoreInfo(data)} style={{cursor: 'pointer'}} alt="menu" onError={(e) => (e.target.src = foodPlaceHolder)} />
                                                    </div>
                                                    <div className="item-details-order">
                                                        <div className="item-row1">
                                                            <div className="reddot-iten-name" style={{cursor: 'pointer'}} onClick={() => handleMoreInfo(data)}>
                                                                {data.vegetarian !== null &&(
                                                                    <img className="item-reddot-order" src={data.vegetarian === true ? greenDot : data.vegetarian === false ? redDot : ''} alt="dot" />
                                                                )}
                                                                <h5 className="items-name-order">{data.menuItemName}</h5>
                                                            </div>
                                                            <p className="item-cost-order">$ {parseFloat(data.menuItemPrice).toFixed(1)}</p>
                                                        </div>
                                                        <div className="order-item-row2">
                                                            <p className="item-desc-order" style={{width: '100%', cursor: 'pointer'}} onClick={() => handleMoreInfo(data)}>
                                                                <span dangerouslySetInnerHTML={{ __html: shortDescription }} style={{display:'inline'}}></span>
                                                                {shouldShowMore && (
                                                                    <button onClick={() => handleMoreInfo(data)} className="more-btn">More</button>
                                                                )}
                                                            </p>
                                                        </div>
                                                        <div className="desc-add-item">
                                                            <p></p>
                                                            {quantities[data.menuItemId] > 0 ? (
                                                                <div className="quantity-controls">
                                                                    <span onClick={() => decreaseQuantity(data.menuItemId, vendorID)}>-</span>
                                                                    <div className="quantity-number">{quantities[data.menuItemId]}</div>
                                                                    <span onClick={() => increaseQuantity(data.menuItemId, vendorID)}>+</span>
                                                                </div>
                                                            ) : (
                                                                <button className="item-add-btn" onClick={() => addToCart(data.menuItemId, vendorID)}>
                                                                    {t('reelsright.add-button')}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='border'></div>
                                            </>
                                        );
                                    })
                                )}
                                {!isLoading && displayedItems.length === 0 && (
                                    <p className='no-item-found'>{t('reelsright.no-item-found')}</p>
                                )}
                            </div>
                        </div>
                        {cartCount > 0 && (
                            <div className="red-box-review-cart">
                                <h5 className='red-box-count'>{cartCount} {t('reelsright.item-added-title')}</h5>
                                <Link className="review-cart-link" to="/cart">{t('reelsright.review-cart-title')}<img src={cartArrow} alt="arrow" className='cart-arrow' /></Link>
                            </div>
                        )}
                    </Box>
                </Modal>

                {!isDesktop && (
                    <Modal open={open} onClose={handleClose} >
                        <Box className={`style1 ${animateModal ? 'animate' : ''}`}>
                            <h2 className='filter-title'>{t('homescreen.filter-title')}</h2>
                            <Grid container spacing={2} className="width-of-dialog-box">
                                <Grid item xs={isMobile ? 6 : 7}>
                                    <div className="css-mhc70k-MuiGrid-root MuiGrid-item">
                                        <RadioGroup value={selectedFilter} onChange={handleFilterChange}>
                                            <FormControlLabel onClick={() => setSelectedMenuName(null)}
                                                value="Relevance"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val1')} />
                                            <FormControlLabel
                                                value="priceLowToHigh"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val4')} />
                                            <FormControlLabel
                                                value="priceHighToLow"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val5')} />
                                            <FormControlLabel
                                                value="Pure Veg"
                                                control={<Radio />}
                                                label={t('homescreen.veg')} />
                                            <FormControlLabel
                                                value="Non-Veg"
                                                control={<Radio />}
                                                label={t('homescreen.nonveg')} />
                                            <FormControlLabel
                                                value="Veg and Non-Veg"
                                                control={<Radio />}
                                                label={t('homescreen.veg-or-nonveg-tab-val3')} />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                )}

                {isDesktop && (
                    <Dialog open={open} onClose={handleClose} width="70%" height="300px" >
                        <DialogTitle className="filters-title">
                            {t('homescreen.filter-title')}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} className="width-of-dialog-box">
                                <Grid item xs={isMobile ? 6 : 7}>
                                    <div className="css-mhc70k-MuiGrid-root MuiGrid-item">
                                        <RadioGroup value={selectedFilter} onChange={handleFilterChange}>
                                            <FormControlLabel onClick={() => setSelectedMenuName(null)}
                                                value="Relevance"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val1')} />
                                            <FormControlLabel
                                                value="priceLowToHigh"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val4')} />
                                            <FormControlLabel
                                                value="priceHighToLow"
                                                control={<Radio />}
                                                label={t('homescreen.sort-by-tab-val5')} />
                                            <FormControlLabel
                                                value="Pure Veg"
                                                control={<Radio />}
                                                label={t('homescreen.veg')} />
                                            <FormControlLabel
                                                value="Non-Veg"
                                                control={<Radio />}
                                                label={t('homescreen.nonveg')} />
                                            <FormControlLabel
                                                value="Veg and Non-Veg"
                                                control={<Radio />}
                                                label={t('homescreen.veg-or-nonveg-tab-val3')} />
                                        </RadioGroup>
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                )}
            </>

            <ConfirmationModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmModal}
                message="Your cart contains items from another restaurant. Would you like to reset your cart to add items from this restaurant?"
            />
        </div>
    );
};

export default OrderPopop;