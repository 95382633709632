import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify';
import './../../../Styles/Inbox/RateOrderModel.css';
import axiosVendorInstance from '../../../axiosVendorInstance';
import CloseIcon from '../../../assets/svg/closeIcon';
function RateOrderModel({ handleClose, open, rateOrder }) {
    const { t } = useTranslation();
    const [ratings, setRatings] = useState({});
    const [review, setReview] = useState('');
    const [loading, setLoading] = useState(false);
    // const [deliveryRating, setDeliveryRating] = useState(0);
    const [reviewError, setReviewError] = useState('');

    console.log('rateOrder', rateOrder);

    const handleReviewChange = (e) => {
        if (e.target.value.length <= 2200) {
            setReviewError('');
        } else {
            setReviewError('Review cannot exceed 2200 characters.');
        }
        setReview(e.target.value);
    };

    const handleRatingChange = (menuItemId, newValue) => {
        setRatings((prev) => ({ ...prev, [menuItemId]: newValue }));
    };

    const handleSubmit = async () => {
        if (!review.trim()) {
            toast.error('Please write a review before submitting.');
            return;
        }

        setLoading(true);
        try {
            const foodRateArray = rateOrder.map((item) => ({
                menu_item_id: item?.MenuItemID,
                item_rating: ratings[item.MenuItemID] || 0,
            }));
            console.log('foodRateArray', foodRateArray);

            const payload = {
                // Rating: deliveryRating,
                food_rate: foodRateArray,
                Review_comment: review,
                Created_by: 1,
            };

            await axiosVendorInstance.post(
                '/menuitems/reviews/menu_item_id',
                payload
            );

            toast.success('Review submitted successfully!');
            handleClose();
        } catch (error) {
            console.error('Error submitting review:', error);
            toast.error('Failed to submit review.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onClose={(event) => {
                event.stopPropagation();
                handleClose();
            }}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{ '& > .MuiBackdrop-root': { backgroundColor: 'rgba(0,0,0,0.1)' } }}
        >
            <Box
                className='rate-order-model-style'
                onClick={(e) => e.stopPropagation()}
            >
                <div className='title-cross-svg-container'>
                    <h1 className='title'>{t('inbox.rate-your-order-title')}</h1>
                    <CloseIcon
                        size={30}
                        color='black'
                        onClick={handleClose}
                        className='close-icon'
                    />
                </div>

                <div className='rating-item-container'>
                    <p className='food-heading'>{t('inbox.how-was-your-food-heading')}</p>
                    <div className='food-name-and-rating'>
                        {rateOrder.map((item, index) => (
                            <div key={index} className='res-itm-name-and-rating'>
                                <p>
                                    {item.MenuItemName}({item?.OrderInfoQuantity})
                                </p>
                                <Box sx={{ '& > legend': { mt: 2 } }}>
                                    <Rating
                                        name={`rating-${item.MenuItemID}`}
                                        value={ratings[item.MenuItemID] || 0}
                                        onChange={(event, newValue) =>
                                            handleRatingChange(item.MenuItemID, newValue)
                                        }
                                    />
                                </Box>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <div className='delivery-rating'>
                    <p className='delivery-experience-title'>
                        {t('inbox.how-was-the-delivery-exp')}
                    </p>
                    <Box sx={{ '& > legend': { mt: 2 } }}>
                        <Rating
                            name='delivery-rating'
                            value={deliveryRating}
                            onChange={(event, newValue) => setDeliveryRating(newValue)}
                        />
                    </Box>
                </div> */}

                <div className='review-text-container'>
                    <p className='write-a-review'>{t('inbox.write-a-review')}</p>
                    <textarea
                        className='review-input'
                        placeholder={t('inbox.write-a-review-placeholder')}
                        value={review}
                        onChange={(e) => handleReviewChange(e)}
                    />
                    {reviewError && <p className='error-message'>{reviewError}</p>}
                </div>

                <button
                    className='submit-btn'
                    onClick={handleSubmit}
                    disabled={loading || reviewError}
                    style={{
                        cursor: reviewError ? 'not-allowed' : 'pointer',
                        opacity: reviewError ? 0.7 : 1,
                    }}
                >
                    {loading ? 'Submitting...' : t('inbox.submit-btn')}
                </button>
            </Box>
        </Modal>
    );
}

export default RateOrderModel;
