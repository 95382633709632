import React, { useState } from 'react';
import './../Styles/LandingPage.css';
import {useNavigate} from 'react-router-dom';
import { ReactComponent as OhMyFoodLogoWithTitle } from '../assets/svg/ohMyFoodLogoWithTitle.svg';

function LandingPage() {
    const [selectedRole, setSelectedRole] = useState('vendor');
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login');
    };
    const handleSignup = () =>{
        navigate('/signup');
    };
    const handlePartner = () =>{
        setSelectedRole('vendor');
        navigate('/signup', {state: {role: selectedRole}});
    };
    const handleSkip = () =>{
        localStorage.removeItem('authToken');
        localStorage.removeItem('accountID');
        localStorage.removeItem('signupEmail'); 
        navigate('/reels');
    };

    return (
        <div className='landing-page-container'>

           
            <div className='landingpage-overlay'></div>
            <div>

                <div className='omf-logo-title-container'>
                    <div className="omf-logo-title">
                        <OhMyFoodLogoWithTitle className="omf-logo-title" />
                    </div>

                    <h4 className='landing-page-quote'>“One cannot think well, love well, sleep well, if one has not dined well.”</h4>


                    <button className='landing-page-login-btn' onClick={handleLogin}>Login</button>
                    <button className='landing-page-signup-btn' onClick={handleSignup}>Sign Up</button>
                    <button className='landing-page-partner-btn' onClick={handlePartner}>Partner With us</button>


                    <div className='skip-container' onClick={handleSkip}>
                        <p className='skip-text'>Skip</p>
                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.04284 14L15.5745 7.5L9.04118 1M1.57617 7.5H15.5762H1.57617Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>

                </div>
            </div>


        </div>
    );
}

export default LandingPage;