import React, { useEffect, useState } from 'react';
import './Blogger.css';
import useWindowSize from '../Homepage/useWindowSize';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { useNavigate } from 'react-router-dom';
import authMiddleware from '../../../Components/authMiddleware';
import axiosUserInstance from '../../../axiosUserInstance';

function Blogger({ searchTerm, results, lastElementRef }) {

    const windowSize = useWindowSize();
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState({});
    
    const filteredUsers = userData.filter((user) =>
        (user?.first_name + ' '+ user?.last_name)?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setUserData(results || []);
    }, [results]);

    const handleFollow = async (id,followFlag) => {
        if (!authMiddleware()) {
            return;
        }
        setLoadingUsers((prev) => ({ ...prev, [id]: true }));
        try {
            if (followFlag == 'false') {
                const response = await axiosUserInstance.post('/follow/user/userid', {
                    user_id: id,
                });
                if (response.status === 201) {
                    setUserData((prev) =>
                        prev.map((user) =>
                            user.ID === id
                                ? { ...user, is_following: 'true'}
                                : user
                        )
                    );
                }
               
            } else {
                const response = await axiosUserInstance.delete(`/follow/user/userid/${id}`);
                if (response.status === 200) {
                    setUserData((prev) =>
                        prev.map((user) =>
                            user.ID === id
                                ? { ...user, is_following: 'false'}
                                : user
                        )
                    );
                }
            }
        } catch (error) {
            setLoadingUsers((prev) => ({ ...prev, [id]: false }));
            console.error('Error in follow/unfollow', error);
        } finally {
            setLoadingUsers((prev) => ({ ...prev, [id]: false }));
        }

    };

    const handleProfilePage = (userName) => {
        navigate(`/profile/${userName}`);
    };

    const formatFollowers = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return count;
    };

    return (
        <div className="bloggers-main-container">
            {filteredUsers?.length > 0 ? (
                filteredUsers?.map((user, index) => {
                    const isLastElement = index === filteredUsers.length - 1;
                    return(<div key={user?.ID} className='person-card' ref={isLastElement ? lastElementRef : null}>
                        <ProfileAvatar
                            firstName={user?.first_name}
                            lastName={user?.last_name}
                            profilePic={user?.profile_picture}
                            height={windowSize.width > 600 ? 100 :60}
                            width={windowSize.width > 600 ? 100 :60}
                            fontSize={windowSize.width > 600 ? 40 :25}
                            onClick={() => handleProfilePage(user?.username)}
                        />
                        <h6 className='channel-name'>
                            {user.first_name} {user?.last_name}
                        </h6>
                        <p className='followers-count'>
                            {formatFollowers(user?.Follower_count)}
                        </p>
                        <button
                            className='Follow_Button'
                            onClick={() => handleFollow(user?.ID,user?.is_following)}
                            disabled={loadingUsers[user?.ID]}
                        >
                            {loadingUsers[user?.ID]
                                ? <><i className="fa fa-spinner fa-spin"></i>&nbsp;Loading</>
                                : user?.is_following == 'true' ? 'Unfollow' : 'Follow'}
                        </button>
                    </div>);
                })
            ) : (
                <h5 className="no-data-found">No users found</h5>
            )}
        </div>
    );
}

export default Blogger;
