import React from 'react';

const DownArrowIcon = () => {
    return (
        <svg
            width="17"
            height="10"
            viewBox="0 0 17 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 1.50743L8.5 10L1.01273e-07 1.50743L1.50875 1.92534e-06L8.5 6.98514L15.4913 2.09208e-06L17 1.50743Z"
                fill="white"
            />
        </svg>
    );
};

export default DownArrowIcon;
