import React from 'react';

const UploadImgIcon = () => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M30.1867 13.8058C29.6465 11.6463 28.4002 9.72911 26.6459 8.35878C24.8916 6.98844 22.7297 6.24348 20.5036 6.24219C18.2775 6.2409 16.1148 6.98335 14.3589 8.35164C12.6029 9.71994 11.3545 11.6356 10.8117 13.7946C8.2515 14.0348 5.88225 15.2521 4.19608 17.1936C2.50992 19.135 1.63645 21.6514 1.75712 24.2201C1.87779 26.7887 2.98332 29.2121 4.84406 30.987C6.70481 32.7618 9.17775 33.7516 11.7492 33.7508H15.4992C15.8307 33.7508 16.1487 33.6191 16.3831 33.3847C16.6175 33.1503 16.7492 32.8323 16.7492 32.5008C16.7492 32.1693 16.6175 31.8513 16.3831 31.6169C16.1487 31.3825 15.8307 31.2508 15.4992 31.2508H11.7492C10.7643 31.2537 9.78847 31.0625 8.87743 30.6883C7.96639 30.314 7.13798 29.764 6.43951 29.0696C5.02888 27.6672 4.23314 25.7618 4.22734 23.7727C4.22154 21.7836 5.00615 19.8736 6.40857 18.463C7.81099 17.0524 9.71634 16.2566 11.7055 16.2508C12.0262 16.275 12.3445 16.1791 12.5985 15.9819C12.8526 15.7846 13.0244 15.5 13.0805 15.1833C13.335 13.3984 14.2249 11.7652 15.5869 10.5837C16.9488 9.40225 18.6912 8.75177 20.4942 8.75177C22.2972 8.75177 24.0396 9.40225 25.4016 10.5837C26.7635 11.7652 27.6534 13.3984 27.908 15.1833C27.9733 15.489 28.1428 15.7626 28.3874 15.9573C28.632 16.1519 28.9366 16.2557 29.2492 16.2508C31.2383 16.2508 33.146 17.041 34.5525 18.4475C35.959 19.854 36.7492 21.7617 36.7492 23.7508C36.7492 25.7399 35.959 27.6476 34.5525 29.0541C33.146 30.4606 31.2383 31.2508 29.2492 31.2508H25.4992C25.1677 31.2508 24.8498 31.3825 24.6153 31.6169C24.3809 31.8513 24.2492 32.1693 24.2492 32.5008C24.2492 32.8323 24.3809 33.1503 24.6153 33.3847C24.8498 33.6191 25.1677 33.7508 25.4992 33.7508H29.2492C31.8019 33.7241 34.2477 32.7222 36.0854 30.9503C37.9231 29.1785 39.0137 26.7709 39.1335 24.2209C39.2534 21.6709 38.3935 19.1716 36.7301 17.2352C35.0666 15.2988 32.7256 14.0719 30.1867 13.8058Z" fill="#A1A1A1"/>
            <path d="M25.8665 24.6354C26.1023 24.8631 26.418 24.9891 26.7458 24.9863C27.0735 24.9834 27.387 24.852 27.6188 24.6202C27.8505 24.3884 27.982 24.0749 27.9849 23.7472C27.9877 23.4194 27.8617 23.1037 27.634 22.8679L21.384 16.6179C21.1496 16.3836 20.8317 16.252 20.5003 16.252C20.1688 16.252 19.8509 16.3836 19.6165 16.6179L13.3665 22.8679C13.1388 23.1037 13.0128 23.4194 13.0157 23.7472C13.0185 24.0749 13.15 24.3884 13.3817 24.6202C13.6135 24.852 13.927 24.9834 14.2548 24.9863C14.5825 24.9891 14.8983 24.8631 15.134 24.6354L19.2503 20.5192V36.2517C19.2503 36.5832 19.382 36.9011 19.6164 37.1356C19.8508 37.37 20.1687 37.5017 20.5003 37.5017C20.8318 37.5017 21.1497 37.37 21.3841 37.1356C21.6186 36.9011 21.7503 36.5832 21.7503 36.2517V20.5192L25.8665 24.6354Z" fill="#A1A1A1"/>
        </svg>

    );
};

export default UploadImgIcon;
