
import React, { useState, useEffect } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField, IconButton, Button, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import axiosVendorInstance from '../../axiosVendorInstance';


const BusinessHours = () => {
    const defaultTimeFrom = dayjs().hour(9).minute(0);
    const defaultTimeTo = dayjs().hour(21).minute(0);
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const [hours, setHours] = useState(
        days.map((day) => ({ day, from: defaultTimeFrom, to: defaultTimeTo }))
    );
    const [isUpdating, setIsUpdating] = useState(false);


    useEffect(() => {
        axiosVendorInstance.get('/business-hours/vendor/26')
            .then((response) => {
                console.log(response);
                if (response.data && response.data.business_hours.length > 0) {
                    setHours(response.data.business_hours.map(hour => ({
                        day: hour.day,
                        from: hour.open_time ? dayjs(hour.open_time, 'hh:mm A') : null,
                        to: hour.close_time ? dayjs(hour.close_time, 'hh:mm A') : null
                    })));
                    
                    setIsUpdating(true);
                }
            })
            .catch((error) => console.error('Error fetching business hours:', error));
    }, []);
    

    const handleTimeChange = (index, type, newValue) => {
        const updatedHours = [...hours];
        updatedHours[index][type] = newValue;
        setHours(updatedHours);
    };

    const clearTime = (index) => {
        const updatedHours = [...hours];
        updatedHours[index] = { day: days[index], from: null, to: null };
        setHours(updatedHours);
    };

    const handleSubmit = () => {
        const payload = {
            vendor_id: 26,
            business_hours: hours.map(hour => ({
                day: hour.day,
                open_time: hour.from && dayjs(hour.from).isValid() ? hour.from.format('HH:mm') : '',
                close_time: hour.to && dayjs(hour.to).isValid() ? hour.to.format('HH:mm') : '',
            }))
        };
    
        const url = isUpdating 
            ? '/business-hours/vendor/26' 
            : '/business-hours/vendor/26';
        const method = isUpdating ? axiosVendorInstance.put : axiosVendorInstance.post;
    
        method(url, payload)
            .then(() => alert(`Business hours ${isUpdating ? 'updated' : 'submitted'} successfully!`))
            .catch(error => console.error('Error updating business hours:', error));
    };
    
    

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={3} justifyContent="center">
               
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                Select Working Hours
                    </Typography>
                </Grid>

                {days.map((day, index) => (
                    <Grid container item spacing={1} key={day} alignItems="center" style={{ marginLeft: '10%' }}>
                        <Grid item xs={2}>
                            <Typography>{day}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TimePicker
                                label="From"
                                value={hours[index].from}
                                onChange={(newValue) => handleTimeChange(index, 'from', newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth style={{ minHeight: 40 }} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimePicker
                                label="To"
                                value={hours[index].to}
                                onChange={(newValue) => handleTimeChange(index, 'to', newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} fullWidth style={{ minHeight: 40 }} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={() => clearTime(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}

                {/* Centered Button */}
                <Grid item xs={12} container justifyContent="center">
                    <Button 
                        variant="contained" 
                        color="primary" 
                        style={{ marginTop: 20, width: 150, marginBottom:20 }}
                        onClick={handleSubmit}
                    >
                        {isUpdating ? 'Update' : 'Submit'}
                    </Button>
                </Grid>
            </Grid>
        </LocalizationProvider>

    );
};

export default BusinessHours;
