import React from 'react';

function UserPostIconMobile() {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="plus-svg-icon">
            <path d="M11.73 6.969H7.084V11.73H4.646V6.969H0V4.761H4.646V0H7.084V4.761H11.73V6.969Z" fill="#2C3142"/>
        </svg>
    );
}

export default UserPostIconMobile;