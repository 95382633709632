import React, { useEffect, useState } from 'react';
import './VerifyOTP.css'; 
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import leftArrow from '../../assets/vector.png';
import axiosUserInstance from '../../axiosUserInstance';

const VerifyOTP = ({isReset}) => {
    const registeredEmail = localStorage.getItem('signupEmail');
    const email = localStorage.getItem('forgetPasswordEmail');
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const isVerify = location.state;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    
    useEffect(() => {
        if(!isVerify){
            navigate('/login');
        }
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!code.trim()) {
            setError('Please enter your OTP');
            toast.error('Please enter your OTP');
            return; 
        }
    
        if (code.length < 6) {
            setError('OTP must be exactly 6 digits.');
            toast.error('OTP must be exactly 6 digits.');
            return; 
        }
        
        
        if (isReset === 'reset') {
            const data = {
                email:  email,
                otp: code
            };

            try {
                setIsLoading(true);
                const res = await axiosUserInstance.post('/auth/verify-otp', data, {skipAuth: true});
                setIsLoading(false);
                toast.success(res?.data?.message);
                if(res?.statusText == 'OK'){
                    navigate('/reset-password', { state: true });
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Reset password',error);
            }
        } else {
            const data = {
                email: registeredEmail,
                otp: code
            };

            try {
                setIsLoading(true);
                const res = await axiosUserInstance.post('/auth/verify-otp', data, {skipAuth: true});
                setIsLoading(false);
                toast.success(res?.data?.message);
                if(res?.statusText == 'OK'){
                    localStorage.removeItem('signupEmail');
                    navigate('/login');
                } else {
                    return;
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
                console.error('Error from Signup',error);
            }
            
        }
    };

    const handleChange = (e) => {
        const input = e.target.value;
        if (/^\d{0,6}$/.test(input)) {
            setCode(input);
            if (input.length < 6) {
                setError('OTP must be exactly 6 digits.');
            }else if(!input.trim()){
                setError('Please enter your OTP');
            } else {
                setError('');
            }
        }
    };

    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            <div className="verify-container">
                {isLoading && <Loader/>}
                <div className='back-drop'></div>
                {isMobile ? <div className="mobile-verify-account-header">
                    <div className="verify-account-title">
                        <img src={leftArrow} alt="left-arrow" className='left-side-arrow' onClick={goBack}/>
                        <h2>Verify Account</h2>
                        <div></div>
                    </div>
                    <div className="verify-account-text">
                        <p>Sign up to food deliver right to your doorstep with just a few taps on your phone, saving you time and effort.</p>
                    </div>
                </div> : <></>}
                <div className="verify-card">
                    {!isMobile && 
                    <>
                        <h2>Verify Account</h2>
                        <p>Sign up to food delivery right to your doorstep with just a few taps on your phone, saving you time and effort.</p>
                    </>}
                    <p>Code has been sent to <strong>{isReset === 'reset' ? email : registeredEmail }</strong>. Enter the code to verify your account.</p>

                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label className='label-text' htmlFor='email'>Enter Code</label>
                            <input
                                type="text"
                                placeholder="6 Digit Code"
                                value={code}
                                onChange={handleChange}
                                className={error ? 'input-error' : ''}
                            />
                            {error && <span className="error-message">{error}</span>}
                        </div>
                        <button
                            type="submit" 
                            className="submit-button" 
                        >
                                    Submit
                        </button>
                    </form>
                    
                </div>
            </div>  
        </>
    );
};

export default VerifyOTP;
