import React, { createContext, useReducer, useContext, useCallback, useEffect,useState } from 'react';

import axiosUserInstance from '../../../axiosUserInstance'; // Import axios to make the API call


// Define action types
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const FLUSH_NOTIFICATIONS = 'FLUSH_NOTIFICATIONS';

// Create the context
const NotificationContext = createContext();

// Reducer to handle notification actions
function notificationReducer(state, action) {
    switch (action.type) {
    case ADD_NOTIFICATION:
        return [...state, action.payload];
    case REMOVE_NOTIFICATION:
        return state.filter((notification) => notification.id !== action.payload);
    case FLUSH_NOTIFICATIONS:
        return []; // Clear all notifications
    default:
        return state;
    }
}

// Provider component
export function NotificationProvider({ children }) {
    const [notifications, dispatch] = useReducer(notificationReducer, []);
    const [notificationCountvalue, setNotificationCount] = useState(0);
    const[notifcationvalue,setNotificationValue]=useState();
    // Add a new notification with extra action data if needed
    
    const fetchNotifications = async () => {
        try {
            const res = await axiosUserInstance.get('/notifications/notifications');
            if (res.status === 200) {
                const fetchedNotifications = res.data.notifications || [];
                dispatch({ type: ADD_NOTIFICATION, payload: fetchedNotifications });
                setNotificationCount(res.data.notificationCount);
                setNotificationValue(res.data.notifications);
                console.log('Fetched Notifications12345:', res.data.notifications
                );
                return fetchedNotifications;
                
            }
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    
    useEffect(() => {
        fetchNotifications();
    }, []);
    
    const addNotification = useCallback(async (notification) => {
    // Ensure each notification has a unique id
        // eslint-disable-next-line no-unused-vars
        const id = notification.id || Date.now();
        const notificationPayload = { ...notification };

        // First, dispatch to update the local state
        dispatch({ type: ADD_NOTIFICATION, payload: notificationPayload });
        console.log('Notification added to local state:', notificationPayload);

        try {
            // Make the API call to create the notification

            const response = await axiosUserInstance.post('/notifications/create', notificationPayload);


            // Check for success response
            if (response.status === 200 || response.status === 201) {
                console.log('Notification successfully created on the server:', response.data);
                setNotificationCount(notificationCountvalue + 1);
            } else {
                console.error('Error creating notification on the server:', response);
            }
        } catch (error) {
            console.error('Error sending notification to API:', error);
        }
    }, [fetchNotifications]); // Empty dependency array to ensure it's not recreated on every render
    

    // Remove a specific notification by id
    const removeNotification = useCallback(async (currentUserId) => {
        try {
            // 1. Perform the delete operation on the server
            const deleteUrl = `https://customers-api.omyfood.io/api/notifications/notifications/read?uid=${currentUserId}`;
            await axiosUserInstance.delete(deleteUrl);
            console.log(`Notification with ID: ${currentUserId} deleted from the server`);

            // 2. Remove the notification locally
            dispatch({ type: REMOVE_NOTIFICATION, payload: currentUserId });

            // 3. Update the notification count
            setNotificationCount(0);  // Decrease the count by 1
        } catch (error) {
            console.error(`Error deleting notification with ID: ${currentUserId}`, error);
        }
    }, [fetchNotifications()]);

    // Function to flush notifications (log them and then clear)
    const flushNotifications = useCallback(() => {
        if (notifications.length > 0) {
            console.log('Flushing notifications:', notifications);
            // Here you can process notifications if needed.
            // After processing, clear the notifications:
            dispatch({ type: FLUSH_NOTIFICATIONS });
        } else {
            console.log('No notifications to flush.');
        }
    }, [notifications]);

    // Call flushNotifications every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            flushNotifications();
        }, 5000);
        return () => clearInterval(interval); // Clean up on unmount
    }, [flushNotifications]);

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification, flushNotifications,notificationCountvalue,fetchNotifications ,notifcationvalue}}>
            {children}
        </NotificationContext.Provider>
    );
}

// Custom hook for easy access to the notification context
export function useNotifications() {
    const context = useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotifications must be used within a NotificationProvider');
    }
    return context;
}
