import React, { useState, useEffect, useRef } from 'react';
import { Modal, Box, TextField, Chip, Typography } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../Styles/CreatePostUi/CreatePost.css';
import CustomAutocomplete from './CustomAutoComplete';
import Loader from '../../../Components/Loader/Loader';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import UploadImgIcon from '../../../assets/svg/UploadImgIcon';
import Slider from 'react-slick';
import LeftSliderArrow from '../../../assets/leftSliderArrow.svg';
import RightSliderArrow from '../../../assets/RightSliderArrow.svg';
import ChangeImgOrVideo from '../../../assets/ChangeImageOrVideo.svg';
import { IoPlayOutline } from 'react-icons/io5';
import NotificationIcon from '../../../assets/svg/notificationIcon';
import Notification from '../../User/Notification/Notification';
import BackIcon from '../../../assets/svg/backIcon';
import CloseIcon from '../../../assets/svg/closeIcon';


const CreatePost = ({
    openInitialPopup,
    setOpenInitialPopup,
    selectedFiles,
    setSelectedFiles,
    setFilters,
}) => {
    const [selectedFilter, setSelectedFilter] = useState({
        label: 'Original',
        class: 'original',
    });
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false); 
    console.log(formSubmitted);
    const [restaurantOptions, setRestaurantOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [menuItemsOptions, setMenuItemsOptions] = useState([]);
    const [selectedRestaurant, setSelectedRestaurant] = useState('');
    const [selectedMenuItem, setSelectedMenuItem] = useState('');
    const [menuLoading, setMenuLoading] = useState(false);
    const [allMenuItems, setAllMenuItems] = useState([]);
    const [itemIds, setItemIds] = useState();
    const [menuItemSearchQuery, setMenuItemSearchQuery] = useState(null);
    const [vendorId, setVendorId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isPlaying, setIsPlaying] = useState(false);
    const [NotificationOverlay, setNotificationOverlay] = useState(false);
    const [notificationcount, setNotificationCount] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [isTablet, setIsTablet] = useState(window.innerWidth < 750);
    const [imageHeight, setImageHeight] = useState(364);
    const [tagError, setTagError] = useState('');
    const containerRef = useRef(null);
    const imageRef = useRef(null);
    
    const fileInputRef = useRef(null);
    const overlayRef = useRef(null);
    const videoLoadedRef = useRef(false);

    useEffect(() => {
        if (openInitialPopup) {
            const handleScroll = () => {
                if (containerRef.current) {
                    const scrollPosition = containerRef.current.scrollTop;
    
                    // Define min and max height
                    const minHeight = 100;
                    const maxHeight = 364;
    
                    // Reduce height as scrolling happens, proportionally
                    const newHeight = Math.max(
                        minHeight,
                        maxHeight - scrollPosition
                    );
    
                    setImageHeight(newHeight);
                }
            };
    
            const container = containerRef.current;
            if (container) {
                container.addEventListener('scroll', handleScroll);
            }
    
            return () => {
                if (container) {
                    container.removeEventListener('scroll', handleScroll);
                }
            };
        }
    }, [openInitialPopup,selectedFiles]);
    
    useEffect(() => {
        if (selectedFiles?.type?.startsWith('video/') && videoRef.current && !videoLoadedRef.current) {
            videoRef.current.load();
            videoLoadedRef.current = true;
        }
    }, [selectedFiles]);
    

    const closenotificationoverlay = () => {
        setNotificationOverlay(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 601);
            setIsTablet(window.innerWidth >= 601 && window.innerWidth <= 751);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const PrevArrow = ({ onClick }) => {
        return (
            <div className='custom-arrow left-arrow' onClick={onClick}>
                <img src={LeftSliderArrow} alt='' />
            </div>
        );
    };

    // Custom Right Arrow Component
    const NextArrow = ({ onClick }) => {
        return (
            <div className='custom-arrow right-arrow' onClick={onClick}>
                <img src={RightSliderArrow} alt='' />
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: isMobile? 3.9 : isTablet ? 3 : 3.7,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    };

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!description || !description.trim()) {
            newErrors.description = 'Description is required.';
        } else if (description.length > 2200) {
            newErrors.description = 'Description cannot exceed 2200 characters.';
        }

        if (!selectedRestaurant || !selectedRestaurant.trim()) {
            newErrors.selectedRestaurant = 'Restaurant name is required.';
        }

        if (!menuItemSearchQuery || !menuItemSearchQuery.trim()) {
            newErrors.menuItemSearchQuery = 'Menu item is required.';
        } else if (
            !menuItemsOptions
                .map((option) => option?.Item_name)
                ?.includes(menuItemSearchQuery !== '' && menuItemSearchQuery)
        ) {
            newErrors.menuItemSearchQuery =
        'Selected menu item not found in restaurant.';
        }

        if (!selectedFiles) {
            newErrors.selectedFiles = 'Please upload an image or video.';
        } else if (
            !['image/', 'video/'].some((type) =>
                selectedFiles.type.startsWith(type)
            )
        ) {
            newErrors.selectedFiles = 'Invalid file type. Please select an image or video.';
        }
    
        if (tags.length > 30) {
            newErrors.tags = 'You can only add up to 30 tags.';
        }
    
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const videoRef = useRef(null); 

    const handleClose = () => {
        setOpenInitialPopup(false);
        setSelectedFiles(null);
        setFilters([]);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (!file) return;
    
        if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                selectedFiles: 'Please select a valid image or video file.',
            }));
            return;
        }
    
        setErrors((prevErrors) => ({
            ...prevErrors,
            selectedFiles: '',
        }));
    
        setSelectedFiles(file);

        if (file.type.startsWith('video/')) {
            videoLoadedRef.current = false;
        }
    
        e.target.value = null;
    };

    const handlePostSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true); 
        if (!validateForm()) {
            return;
        }

        try {
            const formData = new FormData();

            if (selectedFiles) {
                formData.append('attachment_keys', selectedFiles);
            } else {
                alert('No files selected for upload.');
                return;
            }

            formData.append('description', description);
            formData.append('vendor_id', vendorId);
            formData.append('tags', tags);
            formData.append('item_ids', itemIds);
            formData.append('posted_as_role_id', 2);
            formData.append('filter', selectedFilter.class);

            if (itemIds !== 'Item not found') {
                setIsLoading(true);
                await axiosUserInstance.post('/posts/', formData);

                setIsLoading(false);
                setOpenInitialPopup(false);

                window.location.reload();
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    menuItemSearchQuery: 'Selected menu item not found in restaurant',
                }));
            }
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching posts:', error);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            event.preventDefault();
            const newTag = inputValue.trim();
            setTagError('');

            if (tags.length >= 30) {
                setTagError('You can only add up to 30 tags.');
                return;
            }
    
            if (/\s/.test(newTag)) {
                setTagError('Hashtag cannot contain spaces.');
                return;
            }

            const validHashtagPattern = /^#?[A-Za-z0-9_]+$/;
            if (!validHashtagPattern.test(newTag)) {
                setTagError('Only letters, numbers, and underscores (_) allowed.');
                return;
            }
    
            if (newTag.length > 55) {
                setTagError('Hashtag cannot exceed 30 characters.');
                return;
            }
    
            if (tags.includes(newTag)) {
                setTagError('This tag already exists.');
                return;
            }
     
            setTags([...tags, newTag]);
            setInputValue('');
        }
    };

    const handleDelete = (tagToDelete) => {
        setTags(tags.filter((tag) => tag !== tagToDelete));
    };

    const handleSearch = async (query) => {
        if (query) {
            setLoading(true);
            try {
                const response = await axiosVendorInstance.get(
                    `/vendors/search/searchrestaurants/${query}`,
                    { skipAuth: true }
                );
                setRestaurantOptions(response.data.List_of_restaurants || []);
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setRestaurantOptions([]);
            setSelectedRestaurant(null);
            setMenuItemsOptions([]);
            setMenuItemSearchQuery('');
        }
    };

    const handleSelectRestaurant = (value) => {
        if (!value) {
            setSelectedRestaurant(null);
            setMenuItemsOptions([]);
            setMenuItemSearchQuery('');
            return;
        }

        const selectedRestaurant = restaurantOptions.find(
            (restaurant) => restaurant.Name === value
        );
        setSelectedRestaurant(selectedRestaurant.Name || null);
        if (selectedRestaurant) {
            setVendorId(selectedRestaurant.vendorid);
            handleSearchMenuItem(selectedRestaurant.vendorid);
        }
    };

    const handleSelectMenuItem = (value) => {
        if (!value) {
            setMenuItemSearchQuery('');
            return;
        }
        const selectedRestaurant = allMenuItems.find(
            (menuItem) => menuItem.Item_name === value
        );
        setSelectedMenuItem(selectedRestaurant.Item_name || null);
        setMenuItemSearchQuery(selectedRestaurant.Item_name || null);
    };

    const handleSearchMenuItem = async (vendorId) => {
        setMenuLoading(true);
        if (vendorId != undefined) {
            try {
                const response = await axiosVendorInstance.get(
                    `/vendors/menuitems/${vendorId}`,
                    { skipAuth: true }
                );
                const menuItems = response.data.menuItems || [];
                const matchingItem = menuItems.find(
                    (item) => item.Item_name === selectedMenuItem
                );
                const relatedID = matchingItem ? matchingItem.ID : 'Item not found';
                setItemIds(relatedID);
                setAllMenuItems(menuItems);
                setMenuItemsOptions(menuItems);
            } catch (error) {
                console.error('Error fetching menu items:', error);
                setMenuItemsOptions([]);
                setAllMenuItems([]);
            } finally {
                setMenuLoading(false);
            }
        }
    };

    const handleMenuItemSearch = (query) => {
        setMenuItemSearchQuery(query);

        if (!query.trim() || query.trim().length <= 2) {
            setMenuItemsOptions(allMenuItems);
            return;
        }

        const filteredItems = allMenuItems.filter((item) =>
            item.Item_name.toLowerCase().includes(query.toLowerCase())
        );

        setMenuItemsOptions(filteredItems);
    };

    useEffect(() => {
        handleSearchMenuItem(vendorId);
    }, [selectedRestaurant, selectedMenuItem]);

    const fetchNotifications = async () => {
        try {
            const url = '/notifications/notifications';
            const res = await axiosUserInstance.get(url);
            setNotificationCount(res.data.notificationCount);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };
    
    useEffect(() => {
        fetchNotifications();
    }, []);

    if (isLoading) return <Loader />;

    const images = [
        { label: 'Original', class: 'original' },
        { label: 'Brightness', class: 'brightness' },
        { label: 'Contrast', class: 'contrast' },
        { label: 'Saturation', class: 'saturation' },
        { label: 'Hue', class: 'hue' },
        { label: 'Blur', class: 'blur' },
        { label: 'Sepia', class: 'sepia' },
        { label: 'Grayscale', class: 'grayscale' },
        { label: 'Invert', class: 'invert' },
        { label: 'Opacity', class: 'opacity' },
        { label: 'Sharpen', class: 'sharpen' },
    ];

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);
        
        if (value.trim() !== '' && value.length <= 2200) {
            setErrors((prevErrors) => ({ ...prevErrors, description: null }));
        }
    };


    return (
        <div>
            <Modal open={openInitialPopup} onClose={handleClose}>
                <Box className='post-module-popup'>
                    {isMobile ? (
                        <div className='create-post-header-mobile'>
                            <div className='back-post'>
                                <BackIcon onClick={handleClose}/>
                                <h2 className='create-post-title-mobile'>Create Post</h2>
                            </div>
                            <div>
                                <NotificationIcon color={'white'} className="notification-icon" notificationcount={notificationcount}  onClick={() => setNotificationOverlay(!NotificationOverlay)}/>
                                {NotificationOverlay && (
                                    <>
                                        <div className="arrow-to-point-notification"></div>
                                        <div className="notification-overlay" ref={overlayRef}>
                                            <Notification closeOverlay={closenotificationoverlay} />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className='create-post-header'>
                            <h2 className='create-post-title'>Create New Post</h2>
                            <CloseIcon size={30} color='black' onClick={handleClose} className='close-icon'/>
                        </div>
                    )}
                    <div className='create-post-line'></div>
                    <div className='create-new-post'  ref={containerRef}>
                        <div className='select-img-container-first-popup' >
                            <div
                                className={'img-video-select-container'}
                                ref={imageRef}
                                style={{
                                    height: `${imageHeight}px`,
                                    transition: 'height 0.1s linear',
                                }}
                            >
                                <input
                                    id='fileUpload'
                                    type='file'
                                    accept='image/*,video/*'
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                />
                                <label
                                    htmlFor={!selectedFiles ? 'fileUpload' : ''}
                                    className='select-img-video-btn'
                                    onClick={(e) => selectedFiles && e.preventDefault()}
                                >
                                    <div className='image-upload-part'>
                                        {selectedFiles && selectedFiles.type ? (
                                            <>
                                                {selectedFiles.type.startsWith('image/') ? (
                                                    <img
                                                        src={URL.createObjectURL(selectedFiles)}
                                                        className={`${selectedFilter?.class}`}
                                                        alt='Uploaded'
                                                    />
                                                ) : (
                                                    <div className='video-container'>
                                                        <video
                                                            ref={videoRef}
                                                            onClick={() => {
                                                                videoRef.current.pause();
                                                                setIsPlaying(false);
                                                            }}
                                                        >
                                                            <source
                                                                src={URL.createObjectURL(selectedFiles)}
                                                                type={selectedFiles.type}
                                                            />
                                                        </video>
                                                        {!isPlaying && (
                                                            <button
                                                                onClick={handlePlay}
                                                                className='play-btn'
                                                            >
                                                                <IoPlayOutline color='white' fontSize={34} />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                                <button
                                                    className='change-img-btn'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        if (fileInputRef.current) {
                                                            fileInputRef.current.click();
                                                        }
                                                    }}
                                                >
                                                    <img src={ChangeImgOrVideo} alt='change-img' />
                                                </button>
                                            </>
                                        ) : (
                                            <div className='upload-section'>
                                                <UploadImgIcon />
                                                <p>Upload Your Video Or Photo</p>
                                            </div>
                                        )}
                                    </div>
                                </label>
                            </div>
                            {errors.selectedFiles && (
                                <span style={{ color: 'red', fontSize: '10px' }}>
                                    {errors.selectedFiles}
                                </span>
                            )}
                            {selectedFiles &&
                selectedFiles.type &&
                selectedFiles.type.startsWith('image/') && (
                                <div className='image-slider-container'>
                                    <div className='slider-container'>
                                        <Slider {...settings}>
                                            {images?.map((img, index) => (
                                                <div
                                                    key={index}
                                                    className='image-slide'
                                                    onClick={() => setSelectedFilter(img)}
                                                >
                                                    <img
                                                        src={URL.createObjectURL(selectedFiles)}
                                                        alt={img.label}
                                                        className={`slide-image ${img?.class}`}
                                                    />
                                                    <p
                                                        className={`label ${
                                                            selectedFilter.class === img.class
                                                                ? 'selected-filter-label'
                                                                : ''
                                                        }`}
                                                    >
                                                        {img.label}
                                                    </p>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='post-details-1'>
                            <Box className='post-details-box'>
                                <div>
                                    <h2 className='post-details-name'>Post Details</h2>
                                    <TextField
                                        className='description-box'
                                        label='Description'
                                        variant='outlined'
                                        focused
                                        fullWidth
                                        multiline
                                        rows={3}
                                        placeholder='Enter Description'
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        sx={{
                                            mb: '25px',
                                            '& .MuiOutlinedInput-root': {
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #E5E5E5',
                                                },
                                            },
                                            '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                        {
                            borderColor: 'red !important',
                        },
                                            '& .MuiFormLabel-root.Mui-error': {
                                                color: 'red !important',
                                            },
                                        }}
                                        error={!!errors.description}
                                        helperText={errors.description}
                                    />

                                    <Box className='tag-box-container'>
                                        <Typography variant='caption' className='tags-label'>
                      Tags
                                        </Typography>

                                        <Box className='tags-box'>
                                            {tags.map((tag, index) => (
                                                <Chip
                                                    key={index}
                                                    label={tag}
                                                    onDelete={() => handleDelete(tag)}
                                                    sx={{ marginBottom: '4px' }}
                                                />
                                            ))}
                                            <TextField
                                                variant='standard'
                                                placeholder={tags.length >= 30 ? ' ' : 'Enter a tag and press Enter'}
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                                onBlur={() => {
                                                    setInputValue('');
                                                }}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                sx={{
                                                    flex: 1,
                                                    minWidth: '120px',
                                                }}
                                                disabled={tags.length >= 30} 
                                            />
                                        </Box>
                                        {tagError && <Typography variant="caption" color="error">{tagError}</Typography>}
                                    </Box>

                                    <div>
                                        <CustomAutocomplete
                                            id='restaurant-search-autocomplete'
                                            label='Restaurant Name'
                                            placeholder='Enter Restaurant Name'
                                            options={restaurantOptions}
                                            loading={loading}
                                            focused={true}
                                            onInputChange={handleSearch}
                                            onSelect={handleSelectRestaurant}
                                            value={selectedRestaurant?.Name || ''}
                                            error={!!errors.selectedRestaurant}
                                            message={'No Restaurants available'}
                                            setErrors={setErrors}
                                            errors={errors}
                                        />

                                        <CustomAutocomplete
                                            id='menu-item-search-autocomplete'
                                            label='Menu Item'
                                            placeholder='select menu item'
                                            options={menuItemsOptions.map(
                                                (option) => option?.Item_name
                                            )}
                                            loading={menuLoading}
                                            focused={true}
                                            onInputChange={(value) => handleMenuItemSearch(value)}
                                            onSelect={handleSelectMenuItem}
                                            value={menuItemSearchQuery || ' '}
                                            disabled={!selectedRestaurant}
                                            error={!!errors.menuItemSearchQuery}
                                            message={'No Menu Items available'}
                                            setErrors={setErrors}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className='post-btn'>
                                    <button
                                        onClick={handlePostSubmit}
                                        className='next-btn-create-post'
                                    >
                    Post
                                    </button>
                                </div>
                            </Box>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default CreatePost;
