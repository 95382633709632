import React, { useState, useEffect, useRef } from 'react';
import { TextField, Paper, List, ListItem, ListItemText } from '@mui/material';

const CustomAutocomplete = ({
    id,
    label,
    options = [],
    loading = false,
    onInputChange,
    onSelect,
    disabled = false,
    value,
    message,
    setErrors,  
    errors,
    focused= true,
    placeholder 
}) => {
    const [inputValue, setInputValue] = useState(value || '');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options]);

    const handleChange = (event) => {
        const query = event.target.value;
        setInputValue(query);
        onInputChange(query);
        setOpen(query.length > 0);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id === 'restaurant-search-autocomplete' ? 'selectedRestaurant' : 'menuItemSearchQuery']: '',
        }));
    };

    const handleBlur = () => {
        const fieldKey = id === 'restaurant-search-autocomplete' ? 'selectedRestaurant' : 'menuItemSearchQuery';

        if (!inputValue.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldKey]: `${label} is required.`,  // Set error if field is empty
            }));
        }
    };

    const handleSelect = (option) => {
        setInputValue(option);
        setOpen(false);
        onSelect(option);

        // Clear error when user selects a value
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id === 'restaurant-search-autocomplete' ? 'selectedRestaurant' : 'menuItemSearchQuery']: '',
        }));
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <div style={{ width: '100%', position: 'relative',marginBottom:'25px' }}>
            <TextField
                id={id}
                className='common-restaurant-box'
                placeholder={placeholder}
                focused={focused}
                label={label}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled}
                fullWidth
                autoComplete="off"
                error={!!errors[id === 'restaurant-search-autocomplete' ? 'selectedRestaurant' : 'menuItemSearchQuery']}
                helperText={errors[id === 'restaurant-search-autocomplete' ? 'selectedRestaurant' : 'menuItemSearchQuery']}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #E5E5E5',
                        },
                    },
                    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red !important',
                    },
                    '& .MuiFormLabel-root.Mui-error': {
                        color: 'red !important',
                    },
                }}
            />
            {open && (
                <Paper
                    ref={dropdownRef}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        maxHeight: '200px',
                        overflowY: 'auto',
                        zIndex: 10,
                    }}
                >
                    <List>
                        {loading ? (
                            <ListItem>
                                <ListItemText primary="Loading..." />
                            </ListItem>
                        ) : filteredOptions.length > 0 ? (
                            filteredOptions.map((option, index) => (
                                <ListItem button key={index} onClick={() => handleSelect(option.Name || option)}>
                                    <ListItemText primary={option.Name || option} />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText primary={message} />
                            </ListItem>
                        )}
                    </List>
                </Paper>
            )}
        </div>
    );
};

export default CustomAutocomplete;
