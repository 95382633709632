import React, { useEffect, useState } from 'react';
import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
import '../../VendorStyles/VendorDashboard.css';
import { useLocation } from 'react-router-dom';
import Offers from './Offers';
import Coupons from './Coupons';

function VendorDashboard() {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0);
    const [coupons, setCoupons] = useState([]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');
        if (tab) {
            setActiveTab(parseInt(tab));
        }
    }, [location]);

    const handleAddCoupon = (newCoupon) => {
        setCoupons([...coupons, newCoupon]);
    };

    const handleUpdateCoupon = (updatedCoupon, index) => {
        const updatedCoupons = [...coupons];
        updatedCoupons[index] = updatedCoupon;
        setCoupons(updatedCoupons);
    };

    const handleDeleteCoupon = (index) => {
        const updatedCoupons = coupons.filter((_, i) => i !== index);
        setCoupons(updatedCoupons);
    };

    const tabContents = [
        <Coupons
            key="Coupons"
            coupons={coupons}
            onAddCoupon={handleAddCoupon}
            onUpdateCoupon={handleUpdateCoupon}
            onDeleteCoupon={handleDeleteCoupon}
        />,
        <Offers key="Offers" coupons={coupons} />
    ];
    console.log(tabContents);

    return (
        <div>
            <VendorSideMenuBar />
            <VendorTopMenuBar />
            <div className="dashboard-container">
                <div className="vendor-common-container">
                    <div className="coupons-offers-dashboard-left">
                        <div className="VendorCouponsOffers">
                            <div className="coupons-offers-tabs-container">
                                <button
                                    style={{width:'150px'}}
                                    className={`tab-button ${activeTab === 0 ? 'active' : ''}`}
                                    onClick={() => setActiveTab(0)}
                                >
                                    <h5 className='tabs-text'>Coupons</h5>
                                </button>
                                <button
                                    style={{width:'150px'}}
                                    className={`tab-button ${activeTab === 1 ? 'active' : ''}`}
                                    onClick={() => setActiveTab(1)}
                                >
                                    <h5 className='tabs-text'>Offers</h5>
                                </button>
                            </div>
                            <div className="content">
                                {tabContents[activeTab]}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorDashboard;
