import React from 'react';

function LocationIcon({className}) {
    return (
        <svg
            width="25"
            height="30"
            viewBox="0 0 25 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M3.23596 18.9151L9.96486 27.8783C10.476 28.5592 11.2904 28.9996 12.2077 28.9996C13.1249 28.9996 13.9393 28.5592 14.4504 27.8783L21.1793 18.9151C22.5837 17.0448 23.4158 14.7204 23.4158 12.2023C23.4158 6.01754 18.3981 1.00391 12.2082 1.00391C6.01831 1.00391 1 6.01754 1 12.2023C1 14.7204 1.83218 17.0448 3.23649 18.9151H3.23596Z"
                stroke="#2C3142"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.0098 12.1979C15.0098 13.7438 13.7552 14.9974 12.208 14.9974C10.6608 14.9974 9.40625 13.7438 9.40625 12.1979C9.40625 10.652 10.6608 9.39844 12.208 9.39844C13.7552 9.39844 15.0098 10.652 15.0098 12.1979Z"
                stroke="#2C3142"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default LocationIcon;