import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import './../../../Styles/Payment/CouponModal.css';

import { jwtDecode } from 'jwt-decode';
import { CircularProgress } from '@mui/material';
import CloseIcon from '../../../assets/svg/closeIcon';


function CouponModal({ open, handleClose, vendorId, setSelectedCoupon, setSelectedOfferPercentage,setCouponID,setCouponsID,setOffersID,setSelectedCouponAmount }) {


    
    
    
    
    const token = localStorage.getItem('authToken'); 

    const [loadingCoupons, setLoadingCoupons] = useState(true);
    const [loadingOffers, setLoadingOffers] = useState(true);
    const decodedToken = jwtDecode(token);
    const [coupons, setCoupons] = useState([]);
    const [offers, setOffers] = useState([]);
    const [activeTab, setActiveTab] = useState('coupons'); // default tab is 'coupons'
    const [inputCoupon, setInputCoupon] = useState(''); // To store inputted coupon code
    const [errorMessage, setErrorMessage] = useState(''); // To display error message if coupon is invalid


    const handleCouponCode =(e)=>{
        setInputCoupon(e.target.value);
        if (errorMessage) {
            setErrorMessage('');
        }
    };


    const fetchCoupons = async () => {
        try {
            setLoadingCoupons(true);
            const response = await axios.get(`https://vendors-api.omyfood.io/api/vendor/coupon/vendor/${vendorId}?limit=50`);
            console.log(response);
            setCoupons(response.data.coupons);
        } catch (error) {
            console.error('Error fetching coupons:', error);
        } finally {
            setLoadingCoupons(false);
        }
    };

    const fetchOffers = async () => {
        try {
            setLoadingOffers(true);
            const response = await axios.get(`https://vendors-api.omyfood.io/api/vendor/offers/vendor/${vendorId}`);
            setOffers(response.data.offer);
        } catch (error) {
            console.error('Error fetching offers:', error);
        }finally {
            setLoadingOffers(false);
        }
    };

    useEffect(() => {
        fetchCoupons();
        fetchOffers();
    }, [vendorId]);

    // Function to validate the coupon redemption status (check if used already)
    const validateCoupon = async (couponID) => {
        try {
            const response = await axios.post(`https://vendors-api.omyfood.io/api/vendor/vendors/${vendorId}/check-coupon-redemption`, {
                coupon_id: couponID,
                user_id: decodedToken?.id, // Replace with dynamic user ID
                
            });
            return response.data; // Assuming it returns 'used' or 'not used'
        } catch (error) {
            console.error('Error validating coupon:', error);
            return { used: false }; // Default to not used if there's an error
        }
    };


    // Function to validate the offer redemption status (check if used already)
   
    const validateOffer = async (offerID) => {           
        try {
            const response = await axios.post(`https://vendors-api.omyfood.io/api/vendor/vendors/${vendorId}/check-offer-redemption`, {
                offer_id: offerID,
                user_id: decodedToken?.id, // Replace with dynamic user ID
                
            });
            return response.data; // Assuming it returns 'used' or 'not used'
        } catch (error) {
            console.error('Error validating coupon:', error);
            return { used: false }; // Default to not used if there's an error
        }
    };

    // Handle applying offer from the list of available coupons
    const handleApplyOffer = async (offerID, offerTitle, offerPercentage) => {
        const redemptionStatus = await validateOffer(offerID);
        setCouponID(offerID);
        setOffersID(offerID);
        console.log(redemptionStatus);

        if (redemptionStatus.error === 'Offer has already been redeemed by you') {
            setErrorMessage('offer not applicable!');
        } else {
           
            setSelectedCoupon(offerTitle);
            setSelectedOfferPercentage(offerPercentage);
            setErrorMessage(''); // Clear error if coupon is valid and not used
            handleClose(); // Close modal after applying coupon
        }
    };

    // Handle applying coupon from the list of available coupons
    const handleApplyCoupon = async (couponID, couponTitle, discountAmount) => {
        const redemptionStatus = await validateCoupon(couponID);
        setCouponID(couponID);
        setCouponsID(couponID);
        console.log(redemptionStatus);

        if (redemptionStatus.error === 'Coupon has already been redeemed by you') {
            setErrorMessage('coupon not applicable!');
        } else {
            // setCouponsID(couponID);
            setSelectedCoupon(couponTitle);
            setSelectedCouponAmount(discountAmount);
            setErrorMessage(''); // Clear error if coupon is valid and not used
            handleClose(); // Close modal after applying coupon
        }
    };

    // Handle applying coupon from input manually
    const handleApplyInputCoupon = async () => {
        // Define the coupon validation regex
        const couponRegex =/^(?!-)(?!.*--)[A-Z0-9-]{5,20}(?<!-)$/;
    
        // Check if the input is empty or invalid
        if (!inputCoupon.trim()) {
            setErrorMessage('Please enter a coupon code.');
            return; 
        }
    
        if (!couponRegex.test(inputCoupon)) {
            setErrorMessage('Invalid format! Use 5-20 letters, numbers, or hyphens.');
            return;
        }
    
        // Check if the coupon exists in the list
        const coupon = coupons?.find(c => c.Coupon_code?.toUpperCase() === inputCoupon.toUpperCase());
    
        if (coupon) {
            const redemptionStatus = await validateCoupon(coupon?.ID);
            console.log(redemptionStatus);
    
            if (redemptionStatus.error === 'Coupon has already been redeemed by you') {
                setErrorMessage('Coupon not applicable!');
            } else {
                setSelectedCoupon(coupon?.Title);
                setSelectedCouponAmount(coupon?.max_amount);
                setErrorMessage(''); // Clear error if coupon is valid and not used
                handleClose(); // Close modal after applying coupon
            }
        } else {
            setErrorMessage('Enter a valid coupon code.');
        }
    };
    

    if (!open) return null;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="coupon-model-pop-up">
                <div className='title-cross-container'>
                    <h1 className='apply-coupon-title'>Apply Coupon</h1>
                    <CloseIcon onClick={() => handleClose()} className="cross-icon-location" />
                </div>

                <div className='coupon-offer-container'>
                    {/* Input Box */}
                    <div className="coupon-input-and-btn">
                        <input
                            type="text"
                            placeholder="Enter your coupon code"
                            className="coupon-input"
                            value={inputCoupon}
                            onChange={handleCouponCode}
                            maxLength={20}
                        />
                        <button onClick={handleApplyInputCoupon} className="apply-coupon-btn">
                            Apply
                        </button>
                    </div>

                    {/* Display error message if coupon is invalid */}
                    <div style={{ minHeight: '20px' }}>
                        {errorMessage && <div className="error-message-coupons-and-offers">{errorMessage}</div>}
                    </div>
                    {/* Or text */}
                    <div className="or-text">
                        <span>Or</span>
                    </div>

                    {/* Tabs for Offers and Coupons */}
                    <div className="tabs">
                        <button
                            className={`offers-button ${activeTab === 'offers' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('offers')}
                        >
                            Offers
                        </button>
                        <button
                            className={`coupons-button ${activeTab === 'coupons' ? 'active-tab' : ''}`}
                            onClick={() => setActiveTab('coupons')}
                        >
                            Coupons
                        </button>
                    </div>

                    {activeTab === 'coupons' ? (
                        <div className="coupon-modal">
                            <h3 className='offers-and-coupon-title '>Available Coupons</h3>
                            { loadingCoupons ? (
                                <div className='coupons-loader'>
                                    <CircularProgress />

                                </div>
                            ) : (
                                <ul className='coupon-main-container'>

                                    {coupons?.map((coupon) => (
                                        <li key={coupon?.ID} className='coupon-card-container'>
                                            <div>
                                                <img className='offer-image' src={coupon?.Avatar_key || 'https://t4.ftcdn.net/jpg/04/66/17/19/240_F_466171967_4aFatBB2WFDrmkoX4dgaUfQx2QbbunMY.jpg' } alt={coupon.Title} />
                                            </div>
                                            <div className='offer-details-containers'>
                                                <h2 className='offer-title'>Title: {coupon?.Title}</h2>
                                                <h3 className='offers-percentage'>Coupon code: {coupon?.Coupon_code}</h3>
                                                <h3 className='offers-percentage'>Discount Amount: {coupon?.max_amount}</h3>
                                                <h6 className='offer-valifrom'>Valid from: {coupon?.valid_from}</h6>
                                                <h6 className='offer-validto'>Valid to: {coupon?.valid_to}</h6>
                                                <button
                                                    className='apply-btn-coupon'
                                                    onClick={() => handleApplyCoupon(coupon?.ID, coupon?.Title, coupon?.max_amount)}
                                                >
                                                Apply
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ) : (
                        <div className="coupon-modal">
                            <h3 className='offers-and-coupon-title '>Available Offers</h3>
                            { loadingOffers ? (
                                <div className='coupons-loader'>
                                    <CircularProgress />

                                </div>
                            ) : (
                                <ul className='coupon-main-container'>
                                    {offers?.map((offer) => (
                                        <li key={offer?.ID} className='coupon-card-container'>
                                            <div>
                                                <img className='offer-image' src={offer?.Avatar_key || 'https://t4.ftcdn.net/jpg/04/66/17/19/240_F_466171967_4aFatBB2WFDrmkoX4dgaUfQx2QbbunMY.jpg'} alt={offer.Title} />
                                            </div>
                                            <div className='offer-details-containers'>
                                                <h2 className='offer-title'>Title: {offer?.Title}</h2>
                                                <h3 className='offers-percentage'>Percentage: {offer?.percentage}</h3>
                                                <h6 className='offer-valifrom'>Valid from: {offer?.valid_from}</h6>
                                                <h6 className='offer-validto'>Valid to: {offer?.valid_to}</h6>
                                                <button
                                                    className='apply-btn-coupon'
                                                    onClick={() => handleApplyOffer(offer?.ID, offer?.Title, offer?.percentage)}
                                                >
                                                Apply
                                                </button>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )}

                </div>

            </Box>
        </Modal>
    );
}

export default CouponModal;




