import React from 'react';

function PlusIconSvg() {
    return (
        <svg
            className="menu-plus-icon"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.98879 6.49317L7.98864 0.893473C7.98872 0.529146 7.69426 0.234678 7.32993 0.234759C6.96569 0.234755 6.67123 0.529217 6.67123 0.893459L6.67129 6.49324L1.07159 6.49309C0.707267 6.49318 0.41289 6.78755 0.412894 7.15179C0.412813 7.51612 0.707281 7.81059 1.07161 7.81051L6.6713 7.81065L6.67144 13.4103C6.67136 13.7747 6.96583 14.0691 7.33016 14.0691C7.51177 14.0691 7.67674 13.9954 7.79599 13.8762C7.91525 13.7569 7.98886 13.5921 7.98886 13.4104L7.98872 7.81067L13.5885 7.81072C13.7702 7.81073 13.9351 7.73711 14.0543 7.61786C14.1736 7.49861 14.2472 7.33372 14.2472 7.15202C14.2473 6.7877 13.9528 6.49323 13.5885 6.49331L7.98879 6.49317Z"
                fill="#02A750"
            />
        </svg>
    );
}

export default PlusIconSvg;