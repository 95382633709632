import React from 'react';

const CashIcon = () => {
    return (
        <svg
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="payment-svg-icon"
        >
            <path
                d="M20.3 0.75H5.95C5.7567 0.75 5.6 0.9067 5.6 1.1C5.6 1.2933 5.7567 1.45 5.95 1.45H8.06575C6.51248 2.43838 5.58002 4.15904 5.6 6C5.58002 7.84096 6.51248 9.56162 8.06575 10.55H0.7V1.45H5.95C6.1433 1.45 6.3 1.2933 6.3 1.1C6.3 0.9067 6.1433 0.75 5.95 0.75H0.7C0.313401 0.75 0 1.0634 0 1.45V10.55C0 10.9366 0.313401 11.25 0.7 11.25H20.3C20.6866 11.25 21 10.9366 21 10.55V1.45C21 1.0634 20.6866 0.75 20.3 0.75ZM6.3 6C6.3 3.4912 8.18405 1.45 10.5 1.45C12.816 1.45 14.7 3.4912 14.7 6C14.7 8.5088 12.816 10.55 10.5 10.55C8.18405 10.55 6.3 8.5088 6.3 6ZM20.3 10.55H12.9343C14.4875 9.56162 15.42 7.84096 15.4 6C15.42 4.15904 14.4875 2.43838 12.9343 1.45H20.3V10.55Z"
                fill="#FF9D01"
            />
            <path
                d="M11.55 4.77461C11.55 4.96791 11.7067 5.12461 11.9 5.12461C12.0933 5.12461 12.25 4.96791 12.25 4.77461C12.2162 3.99005 11.6276 3.34123 10.85 3.23146V3.19961C10.85 3.00631 10.6933 2.84961 10.5 2.84961C10.3067 2.84961 10.15 3.00631 10.15 3.19961V3.23146C9.37242 3.34123 8.78379 3.99005 8.75 4.77461C8.78379 5.55917 9.37242 6.20799 10.15 6.31776V8.04571C9.76051 7.95735 9.47562 7.62318 9.45 7.22461C9.45 7.03131 9.2933 6.87461 9.1 6.87461C8.9067 6.87461 8.75 7.03131 8.75 7.22461C8.78379 8.00917 9.37242 8.65799 10.15 8.76776V8.79961C10.15 8.99291 10.3067 9.14961 10.5 9.14961C10.6933 9.14961 10.85 8.99291 10.85 8.79961V8.76776C11.6276 8.65799 12.2162 8.00917 12.25 7.22461C12.2162 6.44005 11.6276 5.79123 10.85 5.68146V3.95351C11.2395 4.04187 11.5244 4.37604 11.55 4.77461ZM9.45 4.77461C9.47562 4.37604 9.76051 4.04187 10.15 3.95351V5.59571C9.76051 5.50735 9.47562 5.17318 9.45 4.77461ZM11.55 7.22461C11.5244 7.62318 11.2395 7.95735 10.85 8.04571V6.40351C11.2395 6.49187 11.5244 6.82604 11.55 7.22461Z"
                fill="#FF9D01"
            />
            <path
                d="M17.85 9.85H19.25C19.4433 9.85 19.6 9.6933 19.6 9.5V8.1C19.6 7.9067 19.4433 7.75 19.25 7.75C19.0567 7.75 18.9 7.9067 18.9 8.1V9.15H17.85C17.6567 9.15 17.5 9.3067 17.5 9.5C17.5 9.6933 17.6567 9.85 17.85 9.85Z"
                fill="#FF9D01"
            />
            <path
                d="M1.75039 7.75C1.55709 7.75 1.40039 7.9067 1.40039 8.1V9.5C1.40039 9.6933 1.55709 9.85 1.75039 9.85H3.15039C3.34369 9.85 3.50039 9.6933 3.50039 9.5C3.50039 9.3067 3.34369 9.15 3.15039 9.15H2.10039V8.1C2.10039 7.9067 1.94369 7.75 1.75039 7.75Z"
                fill="#FF9D01"
            />
            <path
                d="M3.15039 2.15039H1.75039C1.55709 2.15039 1.40039 2.30709 1.40039 2.50039V3.90039C1.40039 4.09369 1.55709 4.25039 1.75039 4.25039C1.94369 4.25039 2.10039 4.09369 2.10039 3.90039V2.85039H3.15039C3.34369 2.85039 3.50039 2.69369 3.50039 2.50039C3.50039 2.30709 3.34369 2.15039 3.15039 2.15039Z"
                fill="#FF9D01"
            />
            <path
                d="M17.85 2.85039H18.9V3.90039C18.9 4.09369 19.0567 4.25039 19.25 4.25039C19.4433 4.25039 19.6 4.09369 19.6 3.90039V2.50039C19.6 2.30709 19.4433 2.15039 19.25 2.15039H17.85C17.6567 2.15039 17.5 2.30709 17.5 2.50039C17.5 2.69369 17.6567 2.85039 17.85 2.85039Z"
                fill="#FF9D01"
            />
        </svg>
    );
};

export default CashIcon;
