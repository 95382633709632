


import { createContext, useContext, useState, useEffect } from 'react';
import axiosVendorInstance from './axiosVendorInstance';
import { jwtDecode } from 'jwt-decode';

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {

 

    const token = localStorage.getItem('authToken'); 

    // Check if token exists and is valid before decoding
    let decodedToken = null;
    if (token) {
        try {
            decodedToken = jwtDecode(token);
        } catch (error) {
            console.error('Token decoding error:', error.message);
        }
    }

    const [newOrdersCount, setNewOrdersCount] = useState(0);
    const [preparingCount, setPreparingCount] = useState(0);
    const [pushToPickUpCount, setPushToPickUp] = useState(0);
    const [inTransitCount, setInTransitCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [declinedCount, setDeclinedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [orders, setOrders] = useState([]);
    const [vendorID, setVendorID] = useState();

    const fetchVendorID = async () => {
        if (decodedToken?.id) {
            const vendorResponse = await axiosVendorInstance.get(
                '/vendors/account'
            );
            setVendorID(vendorResponse.data?.getVendorInfo[0]?.ID);
        } else {
            console.error('Decoded token is invalid or missing.');
        }
    };

    // const fetchNewOrders = async () => {
    //     if (!vendorID) return;  // Don't fetch if vendorID is not available

      
    // };

    useEffect(() => {
        fetchVendorID();
    }, [token]);





    const fetchTotalCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}`);
            const incomingTotalCountData = response.data.totalOrders;
            const incomingOrders = response.data.allOrdersDetails;
            setTotalCount(incomingTotalCountData);
            setOrders(incomingOrders);


            const revenue = orders
                .filter((order) => order.Status_id === 6)
                .reduce((total, order) => total + parseFloat(order.Total_price), 0); // Summing total_price for orders
            const totalRevenue = revenue.toFixed(2);

            setTotalAmount(totalRevenue);
            
        } catch (error) {

            console.error(error);
            
        }

    };

    // const fetchTotalAmount= async ()=>{


    //     try {

    //         const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=6`,{skipAuth:true});
    //         const incomingTotalAmountData = response.data.totalOrders;
    //         setTotalAmount(incomingTotalAmountData.reduce((total, order) => total + parseFloat(order.Total_price), 0));
            
            
    //     } catch (error) {

    //         console.error(error);
            
    //     }

    // };

    const fetchNewOrdersCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=1`);
            const incomingNewOrdersData = response.data.totalOrders;
            setNewOrdersCount(incomingNewOrdersData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    const fetchPreparingCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=2`);
            const incomingPreparingData = response.data.totalOrders;
            setPreparingCount(incomingPreparingData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    const fetchPushToPickUpCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=5`);
            const incomingPushToPickUpData = response.data.totalOrders;
            setPushToPickUp(incomingPushToPickUpData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    const fetchInTransitCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=7`);
            const incomingInTransitData = response.data.totalOrders;
            setInTransitCount(incomingInTransitData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    const fetchDeliveredCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=6`);
            const incomingDeliveredData = response.data.totalOrders;
            setDeliveredCount(incomingDeliveredData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    const fetchDeclinedCount= async ()=>{


        try {

            const response = await axiosVendorInstance.get(`/Order/vendor/${vendorID}?statusId=3`);
            const incomingDeclinedData = response.data.totalOrders;
            setDeclinedCount(incomingDeclinedData);
            
            
        } catch (error) {

            console.error(error);
            
        }

    };

    useEffect(() => {
        if (vendorID) {

            fetchNewOrdersCount();
            fetchPreparingCount();
            fetchPushToPickUpCount();
            fetchDeliveredCount();
            fetchDeclinedCount();
            fetchTotalCount();
            fetchInTransitCount();
            
    
        }
        
    }, [vendorID,newOrdersCount,preparingCount,pushToPickUpCount,deliveredCount,declinedCount,totalCount,totalAmount,inTransitCount,setInTransitCount]);

    return (
        <OrderContext.Provider value={{ newOrdersCount, preparingCount, pushToPickUpCount, deliveredCount, declinedCount,setNewOrdersCount,setPreparingCount,setPushToPickUp,totalCount,setTotalCount,totalAmount,setTotalAmount,inTransitCount,setInTransitCount }}>
            {children}
        </OrderContext.Provider>
    );
};

export const OrderContextuseOrders = () => {
    return useContext(OrderContext);
};

