import React from 'react';

const RightArrow = () => {
    return (
        <svg
            width="12"
            height="21"
            viewBox="0 0 12 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.80892 -1.01642e-06L12 10.5L1.80892 21L1.74441e-06 19.1363L8.38216 10.5L2.34404e-07 1.86375L1.80892 -1.01642e-06Z"
                fill="white"
            />
        </svg>
    );
};

export default RightArrow;
