import React, { useState, useEffect } from 'react';
import axiosVendorInstance from '../../axiosVendorInstance';
import '../../VendorStyles/Coupons.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CouponModal from './CouponModal';
import { useAuth } from '../../AuthProvider';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchIconSvg from '../../assets/svg/searchIcon';
import { CircularProgress, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import EditPencilIconSvg from '../../assets/svg/editPencilIcon';
import DeleteIconSvg from '../../assets/svg/deleteIcon';
import { styled } from '@mui/material/styles';
import CommonButton from '../../Components/commonButton';


function Coupons({ onAddCoupon, onUpdateCoupon, onDeleteCoupon }) {
    const { userID } = useAuth();
    const [coupons, setCoupons] = useState([]);
    const [filteredCoupons, setFilteredCoupons] = useState([]);
    const [couponID, setCouponID] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [status, setStatus] = useState('Active');
    const [maxAmount, setMaxAmount] = useState('');
    const [redeemLimit, setRedeemLimit] = useState('');
    const [validFrom, setValidFrom] = useState('');
    const [validTo, setValidTo] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [autoGenerateCode, setAutoGenerateCode] = useState(false);
    const [noBoundaries, setNoBoundaries] = useState(false);
    const [vendorID, setVendorID] = useState();
    const [TitleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [shortDescriptionError, setShortDescriptionError] = useState();
    const [maxAmountError, setMaxAmountError] = useState();
    const [redeemLimitError, setRedeemLimitError] = useState('');
    const [couponError, setCouponError] = useState('');
    const [validFromError, setValidFromError] = useState('');
    const [validToError, setValidToError] = useState('');
    const [filterTitle, setFilterTitle] = useState('');
    const [filterCouponCode, setFilterCouponCode] = useState('');
    const [filterOffer, setFilterOffer] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterValidFrom, setFilterValidFrom] = useState('');
    const [filterValidTo, setFilterValidTo] = useState('');
    const [loading, setLoading] = useState(true);
    
    const validate = () => {
        let isValid = true;
        const nameRegex = /^[a-zA-Z0-9\s]{4,}$/;
        const longFieldRegex = /^[a-zA-Z0-9\s]{10,}$/;
        const priceRegex = /^\d+(\.\d{1,2})?$/;

        if (!title.trim()) {
            setTitleError('title  is required.');
            isValid = false;
        } else if (!nameRegex.test(title)) {
            setTitleError('Enter valid title.');
            isValid = false;
        } else {
            setTitleError('');
        }

        if (description.replace(/<(.|\n)*?>/g, '').trim() === '') {
            setDescriptionError('Description is required.');
            isValid = false;
        }

        if (!shortDescription.trim()) {
            setShortDescriptionError('short description is required.');
            isValid = false;
        } else if (!longFieldRegex.test(shortDescription)) {
            setShortDescriptionError('Field is required.');
            isValid = false;
        } else {
            setShortDescriptionError('');
        }

        if (!maxAmount) {
            setMaxAmountError('maximum Amount is required.');
            isValid = false;
        } else if (!priceRegex.test(maxAmount)) {
            setMaxAmountError('Field is required.');
            isValid = false;
        } else {
            setMaxAmountError('');
        }

        if (!redeemLimit) {
            setRedeemLimitError('redeem limit is required.');
            isValid = false;
        } else if (!priceRegex.test(redeemLimit)) {
            setRedeemLimitError('Field is required.');
            isValid = false;
        } else {
            setRedeemLimitError('');
        }

        if (!couponCode) {
            setCouponError('Coupon code is required');
        } else {
            setCouponError('');
        }

        if (!noBoundaries) {
            if (validFrom === '') {
                setValidFromError('Valid From date is required');
                isValid = false;
            } else {
                setValidFromError('');
            }

            if (validTo === '') {
                setValidToError('Valid To date is required');
                isValid = false;
            } else {
                setValidToError('');
            }
        }

        return isValid;
    };

    const handleTitleChange = (e) => {
        const value = e.target.value;
        setTitle(value);

        if (!value.trim()) {
            setTitleError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{4,}$/.test(value)) {
            setTitleError('Food name must be 4 characters.');
        } else {
            setTitleError('');
        }
    };

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setDescription(value);

        if (!value.trim()) {
            setDescriptionError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{10,}$/.test(value)) {
            setDescriptionError('');
        } else {
            setDescriptionError('');
        }
    };
    const handleShortDescriptionChange = (e) => {
        const value = e.target.value;
        setShortDescription(value);

        if (!value.trim()) {
            setShortDescriptionError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{10,}$/.test(value)) {
            setShortDescriptionError('short description must be 10 characters.');
        } else {
            setShortDescriptionError('');
        }
    };

    const handleMaxAmount = (e) => {
        const value = e.target.value;
        setMaxAmount(value);

        if (!value.trim()) {
            setMaxAmountError('Field is required.');
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            setMaxAmountError('Enter a valid price (e.g., 20 or 20.50).');
        } else {
            setMaxAmountError('');
        }
    };

    const handleRedeemLimit = (e) => {
        const value = e.target.value;
        setRedeemLimit(value);

        if (!value.trim()) {
            setRedeemLimitError('Field is required.');
        } else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            setRedeemLimitError('Enter a valid price (e.g., 20 or 20.50).');
        } else {
            setRedeemLimitError('');
        }
    };

    const handleValidFromChange = (e) => {
        const newValidFrom = e.target.value;
        setValidFrom(newValidFrom);
        setValidTo('');
        setValidFromError('');
        setValidToError('');
    };

    const fetchVendorID = async () => {
        try {
            const response = await axiosVendorInstance.get(
                '/vendors/account'
            );
            const apiResponse = response.data.getVendorInfo;

            if (apiResponse.length > 0) {
                setVendorID(apiResponse[0].ID);
            } else {
                toast.error('Vendor ID not found.'); 
            }
        } catch (error) {
            toast.error('Error fetching vendor ID');
            console.error(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (userID) {
            fetchVendorID();
        }
    }, [userID]);

    const [couponModal, setCouponModal] = useState(false);

    const handleOpenCouponModal = () => {
        resetForm();
        setIsEditing(false);
        setEditIndex(null);
        setCouponModal(true);
    };
    const handleCloseCouponModal = () => setCouponModal(false);

    const notifyDelete = () => toast.success('Coupon deleted successfully');
    const notifyAdd = () => toast.success('Coupon added successfully');
    const notifyUpdate = () => toast.success('Coupon updated successfully');
    const notifyFillDetails = () => toast.error('Please fill in all fields');

    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const getCoupons = () => {
        setLoading(true); 
        const params = {
            page: pageNo,
            limit: 10,
            title: filterTitle || '',
            coupon_code: filterCouponCode || '',
            offer_percent: filterOffer || '',
            status: filterStatus || '',
            valid_from: filterValidFrom || '',
            valid_through: filterValidTo || '',
        };

        axiosVendorInstance
            .get(`/vendor/coupon/vendor/${vendorID}`, { params })
            .then((response) => {
                setCoupons(response.data.coupons || []);
                setFilteredCoupons(response.data.coupons || []);
                setTotalPages(response.data.pagination.totalPages || 1);
               
            })
            .catch((error) => {
                console.error('Error fetching coupons:', error);
                setTotalPages(1);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSearch = () => {
        getCoupons();
    };

    const handleFilterTitleChange = (e) => {
        setFilterTitle(e.target.value);
    };

    const handleFilterCouponCodeChange = (e) => {
        setFilterCouponCode(e.target.value);
    };

    const handleFilterStatusChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleFilterValidFromChange = (e) => {
        setFilterValidFrom(e.target.value);
    };

    const handleFilterValidToChange = (e) => {
        setFilterValidTo(e.target.value);
    };

    useEffect(() => {
        if (vendorID) {
            getCoupons();
        }
    }, [vendorID, pageNo]);

    const handlePageChange = (event, value) => {
        setPageNo(value);
    };

    const handleAddOrUpdateCoupon = () => {
        if (validate()) {
            const newCouponCode = autoGenerateCode ? couponCode : couponCode;

            const data = {
                ID: couponID,
                Coupon_code: newCouponCode,
                Title: title,
                Description: description,
                max_amount: maxAmount,
                RedeemLimitCoupon: redeemLimit,
                shortdescription: shortDescription,
            };

            if (!noBoundaries) {
                data['Valid_from'] = validFrom;
                data['Valid_through'] = validTo;
            }

            if (isEditing) {
                const url = `/vendor/coupons/${couponID}`;
                axiosVendorInstance
                    .put(url, data)
                    .then((response) => {
                        const updatedCoupon = { ...response.data, ID: couponID };
                        onUpdateCoupon(updatedCoupon, editIndex);
                        setIsEditing(false);
                        setEditIndex(null);

                        setCoupons((prevCoupons) => {
                            const updatedCoupons = [...prevCoupons];
                            updatedCoupons[editIndex] = updatedCoupon;
                            return updatedCoupons;
                        });

                        resetForm();
                        setCouponModal(false);
                        notifyUpdate();
                        getCoupons();
                    })
                    .catch((error) => {
                        console.error('Error updating coupon:', error);
                        toast.error('Error updating coupon');
                    });
            } else {
                const url = `/vendor/coupon/vendor/${vendorID}`;
                axiosVendorInstance
                    .post(url, data)
                    .then((response) => {
                        onAddCoupon(response.data);
                        setCoupons((prevCoupons) => [...prevCoupons, response.data]);
                        resetForm();
                        setCouponModal(false);
                        notifyAdd();
                        getCoupons();
                    })
                    .catch((error) => {
                        console.error('Error adding coupon:', error);
                        toast.error('Error adding coupon');
                    });
            }
        } else {
            notifyFillDetails();
        }
    };

    const handleEditCoupon = (index) => {
        

        const coupon = coupons[index];
        

        setCouponID(coupon.ID);
        setTitle(coupon.Title);
        setDescription(coupon.Description);
        setMaxAmount(coupon.max_amount);
        setRedeemLimit(coupon.RedeemLimitCoupon);
        setShortDescription(coupon.shortdescription);

        const formatDate = (date) => {
            if (date) {
                const newDate = new Date(date);
                return newDate.toISOString().slice(0, 16);
            }
            return '';
        };

        setValidFrom(formatDate(coupon.Valid_from || ''));
        setValidTo(formatDate(coupon.Valid_through || ''));

        setCouponCode(coupon.Coupon_code);

        setIsEditing(true);
        setEditIndex(index);
        setCouponModal(true);
    };

    const handleDeleteCoupon = (id) => {
       

        const url = `/vendor/coupons/${id}`;
        axiosVendorInstance
            .delete(url)
            .then(() => {
                onDeleteCoupon(id);
                setCoupons((prevCoupons) => prevCoupons.filter((_, i) => i !== id));
                notifyDelete();
                getCoupons();
            })
            .catch((error) => {
                console.error('Error deleting coupon:', error);
                toast.error('Error deleting coupon');
            });
    };

    const resetForm = () => {
        setCouponID('');

        setTitle('');
        setDescription('');
        setShortDescription('');
        setMaxAmount('');
        setRedeemLimit('');
        setValidFrom('');
        setValidTo('');
        setCouponCode('');
        setAutoGenerateCode(false);
    };

    const isExpired = (validTo) => {
        if (!validTo) return false;
        const currentDate = new Date().toISOString().split('T')[0];
        return validTo < currentDate;
    };

    const generateCouponCode = () => {
        return Math.random().toString(36).substring(2, 10).toUpperCase();
    };

    const handleAutoGenerateCodeChange = (e) => {
        const checked = e.target.checked;
        setAutoGenerateCode(checked);
        if (checked) {
            const generatedCode = generateCouponCode();
            setCouponCode(generatedCode);
            setCouponError('');
        } else {
            setCouponCode('');
            setCouponError('');
        }
    };

    const handleClearFilters = () => {
        getCoupons();
        setFilterTitle('');
        setFilterCouponCode('');
        setFilterOffer('');
        setFilterStatus('');
        setFilterValidFrom('');
        setFilterValidTo('');
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#e5e8f1',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif',
    }));
      

    return (
        <div className="coupon-container">
            <div className="add-button-coupen-offer">
                <div></div>
                <h2 className='coupons-heading'>Coupons</h2>
                <CommonButton
                    onClick={handleOpenCouponModal}
                    label="Add Coupon"
                />
            </div>
            <div className="coupons-filters">
                <TextField
                    label="Title"
                    variant="outlined"
                    size="small"
                    value={filterTitle}
                    onChange={handleFilterTitleChange}
                />
                <TextField
                    label="Coupon Code"
                    variant="outlined"
                    size="small"
                    value={filterCouponCode}
                    onChange={handleFilterCouponCodeChange}
                />
                <TextField
                    select
                    label="Status"
                    variant="outlined"
                    size="small"
                    value={filterStatus}
                    onChange={handleFilterStatusChange}
                >
                    <MenuItem value="">Status</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="expiry">Expired</MenuItem>
                </TextField>
                <TextField
                    type="date"
                    label="Valid From"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={filterValidFrom}
                    onChange={handleFilterValidFromChange}
                />
                <TextField
                    type="date"
                    label="Valid To"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={filterValidTo}
                    onChange={handleFilterValidToChange}
                />
                <div className="search-icon-coupons" onClick={handleSearch}>
                    <SearchIconSvg
                        color='white'
                        height={'20px'}
                        width={'20px'} />
                </div>
                <div className='add-button' onClick={handleClearFilters}>
        Clear
                </div>
            </div>

            <div className="table-container">
                {loading ? (
                    <div className="loader-container">
                        <CircularProgress />
                    </div>
                ):
                    filteredCoupons?.length === 0 ? (
                        <p className="no-coupons">No coupons found.</p>
                    ) : filteredCoupons?.length > 0 ? (
                        <>
                            <TableContainer
                                component={Paper} 
                                sx={{ 
                                    borderRadius: 2, 
                                    boxShadow: 3, 
                                    maxHeight: 500, 
                                    overflowX: 'auto',
                                    overflowY: 'auto', 
                                    width: '100%'
                                }}
                            >
                                <Table stickyHeader sx={{ minWidth: 900 }}>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>ID</StyledTableCell>
                                            <StyledTableCell>Title</StyledTableCell>
                                            <StyledTableCell>Description</StyledTableCell>
                                            <StyledTableCell>Short Description</StyledTableCell>
                                            <StyledTableCell>Coupon Code</StyledTableCell>
                                            <StyledTableCell>Status</StyledTableCell>
                                            <StyledTableCell>Valid From</StyledTableCell>
                                            <StyledTableCell>Valid To</StyledTableCell>
                                            <StyledTableCell>Actions</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredCoupons.map((coupon, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    backgroundColor: isExpired(coupon.Valid_through ? coupon.Valid_through.split('T')[0] : '') ? '#f8d7da' : 'white',
                                                }}
                                            >
                                                <TableCell>{coupon.ID}</TableCell>
                                                <TableCell>{coupon.Title}</TableCell>
                                                <TableCell>{coupon.Description}</TableCell>
                                                <TableCell>{coupon.shortdescription}</TableCell>
                                                <TableCell>{coupon.Coupon_code}</TableCell>
                                                <TableCell>
                                                    {isExpired(coupon.Valid_through ? coupon.Valid_through.split('T')[0] : '') ? 'Expired' : 'Active'}
                                                </TableCell>
                                                <TableCell>
                                                    {coupon.Valid_from 
                                                        ? new Date(coupon.Valid_from).toLocaleDateString('en-GB')
                                                        : 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    {coupon.Valid_through 
                                                        ? new Date(coupon.Valid_through).toLocaleDateString('en-GB')
                                                        : 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    <div className="edit-and-delete-icons-container">
                                                        <EditPencilIconSvg 
                                                            onClick={() => handleEditCoupon(index)}
                                                            color='green'
                                                        />
                                                        <DeleteIconSvg 
                                                            onClick={() => handleDeleteCoupon(coupon.ID)}
                                                            color='#e13535'
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Stack spacing={2}>
                                <Pagination
                                    sx={{
                                        '& .MuiPagination-ul': {
                                            color: '#333',
                                            padding: 1,
                                            borderRadius: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            listStyleType: 'none',
                                        },
                                    }}
                                    count={totalPages}
                                    page={pageNo}
                                    onChange={handlePageChange}
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </>
                    ) :
                        null
                }
            </div>
            {couponModal && (
                <CouponModal
                    open={couponModal}
                    handleClose={handleCloseCouponModal}
                    handleAddOrUpdateCoupon={handleAddOrUpdateCoupon}
                    handleAutoGenerateCodeChange={handleAutoGenerateCodeChange}
                    title={title}
                    setTitle={setTitle}
                    validFrom={validFrom}
                    setValidFrom={setValidFrom}
                    validTo={validTo}
                    setValidTo={setValidTo}
                    couponCode={couponCode}
                    setCouponCode={setCouponCode}
                    autoGenerateCode={autoGenerateCode}
                    isEditing={isEditing}
                    description={description}
                    setDescription={setDescription}
                    shortDescription={shortDescription}
                    setShortDescription={setShortDescription}
                    status={status}
                    setStatus={setStatus}
                    noBoundaries={noBoundaries}
                    setNoBoundaries={setNoBoundaries}
                    maxAmount={maxAmount}
                    setMaxAmount={setMaxAmount}
                    redeemLimit={redeemLimit}
                    setRedeemLimit={setRedeemLimit}
                    titleError={TitleError}
                    descriptionError={descriptionError}
                    shortDescriptionError={shortDescriptionError}
                    maxAmountError={maxAmountError}
                    redeemLimitError={redeemLimitError}
                    handleTitleChange={handleTitleChange}
                    handleDescriptionChange={handleDescriptionChange}
                    handleShortDescriptionChange={handleShortDescriptionChange}
                    handleMaxAmount={handleMaxAmount}
                    handleRedeemLimit={handleRedeemLimit}
                    handleValidFromChange={handleValidFromChange}
                    validFromError={validFromError}
                    validToError={validToError}
                    couponError={couponError}
                />
            )}
        </div>
    );
}

export default Coupons;