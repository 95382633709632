import React from 'react';
import './backButton.css';
import backIcon from '../../src/assets/vector.png';

const goBack = () => {
    window.history.back();
};

function BackButton({style,onClick}) {
    return (
        <div style={style}>
            <button className="place-order-previous-btn" onClick={onClick ? onClick : goBack}>
                <img className="back-icon1" src={backIcon} alt="backIcon" />
            </button>
        </div>
    );
}

export default BackButton;