import React from 'react';

import 'react-phone-input-2/lib/style.css';
import '../../../VendorStyles/vendorregistrationvalidation.css';

import { states } from './states';

function PersonalInformationForm({ formData, handleChange, errors }) {
    const handleKeyPress = (event) => {
        const charCode = event.which || event.keyCode;

        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    };

    return (
        <div className="personal-information-main-container">
            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="businessName">
          Business Name
                </label>
                <input
                    className="full-name-input"
                    placeholder="Enter your business name"
                    id="businessName"
                    name="businessName"
                    value={formData.businessName}
                    onChange={handleChange}
                    maxLength={50}
                    required
                />
                {errors.businessName && (
                    <p className="error-text">{errors.businessName}</p>
                )}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="address">
          Address
                </label>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <input
                        className="full-name-input"
                        type="text"
                        id="address"
                        name="address"
                        placeholder="Address (House no, Area and Street)"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                </div>
                {errors.address && <p className="error-text">{errors.address}</p>}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="city">
          City
                </label>
                <input
                    className="full-name-input"
                    type="text"
                    id="city"
                    name="city"
                    placeholder="Enter Your city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                />
                {errors.city && <p className="error-text">{errors.city}</p>}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="state">
          State
                </label>
                <select
                    className="full-name-input"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select a State</option>
                    {states.map((state) => (
                        <option key={state.value} value={state.value}>
                            {state.label}
                        </option>
                    ))}
                </select>
                {errors.state && <p className="error-text">{errors.state}</p>}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="country">
          Country
                </label>
                <input
                    className="full-name-input"
                    type="text"
                    id="country"
                    name="country"
                    placeholder="Enter Your Country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                />
                {errors.country && <p className="error-text">{errors.country}</p>}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="pincode">
          Zipcode
                </label>
                <input
                    className="full-name-input"
                    type="text"
                    id="pincode"
                    name="pincode"
                    placeholder="Enter Your pincode"
                    onKeyPress={handleKeyPress}
                    value={formData.pincode}
                    onChange={handleChange}
                    maxLength={5}
                    required
                />
                {errors.pincode && <p className="error-text">{errors.pincode}</p>}
            </div>

            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="email">
          Email
                </label>
                <input
                    className="full-name-input"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    readOnly
                    required
                />
                {errors.email && <p className="error-text">{errors.email}</p>}
            </div>
        </div>
    );
}

export default PersonalInformationForm;
