import React, { useState, useRef, useEffect } from 'react';
import '../../Styles/TopMenuBar.css';
import { useTranslation } from 'react-i18next';
import backIcon from './../../assets/vector.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Notification from '../../Views/User/Notification/Notification';
import {
    LoadScript,
    StandaloneSearchBox,
    GoogleMap,
} from '@react-google-maps/api';
import axios from 'axios';
import { useCart } from '../../CartProvider';
import ProfileAvatar from '../../Views/User/ProfileAvatar/ProfileAvatar';
import foodicon from '../../assets/foodicon.png';
import restauranticon from '../../assets/restaurenticon.png';
import reelspostsicon from '../../assets/reelspostsicon.png';
import bloggersicon from '../../assets/bloggers.png';
import offericon from '../../assets/offersicon.png';
import threeDot from '../../assets/3Dots.png';
import whiteLocationIcon from '../../assets/whiteLocationIcon.png';
import { Box, Modal } from '@mui/material';
import CloseIcon from '../../assets/svg/closeIcon';
import LocationIcon from '../../assets/svg/locationIcon';
import RoundDownArrow from '../../assets/svg/roundDownArrow';
import NotificationIcon from '../../assets/svg/notificationIcon';

import MicIconSvg from '../../assets/svg/micIcon';
import SearchIconSvg from '../../assets/svg/searchIcon';
import { FaLocationDot } from 'react-icons/fa6';
import { useNotifications } from '../../Views/User/Notification/NotificationContext';

function TopMenuBar({ tabName, searchQuery, setSearchQuery, backIconVisible = true,goBackCart }) {
    const {currentLoginUserId, profileImage, userData, setIsLocation, isLocation } = useCart();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [pincode, setPincode] = useState('');
    const [address, setAddress] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const [animateModal, setAnimateModal] = useState(false);
    const [closing, setClosing] = useState(false);
    const [isListening, setIsListening] = useState(false);
    const recognitionRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);
    const [NotificationOverlay, setNotificationOverlay] = useState(false);
    
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [mapRef, setMapRef] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const inputRef = useRef();
    const modalRef = useRef();
    const overlayRef = useRef(null);
    const location = useLocation();
    
    
    const { notificationCountvalue } = useNotifications();
    
    
    

    const closenotificationoverlay = () => {
        setNotificationOverlay(false);
    };
    const handlePlaceChanged = async() => {
        if (inputRef.current) {
            const places = inputRef.current.getPlaces();

            if (!places || places.length === 0) {
                console.error('No places found.');
                return;
            }

            const place = places[0];

            if (!place.formatted_address) {
                console.error('No formatted address found.');
                return;
            }

            setInputValue(place.formatted_address);

            if (!place.geometry || !place.geometry.location) {
                console.error('No location data available.');
                return;
            }

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setSelectedPlace({ lat, lng });

            const postalCodeComponent = place.address_components?.find((component) =>
                component.types.includes('postal_code')
            );

            let newPincode = postalCodeComponent ? postalCodeComponent.long_name : null;
            if (!newPincode) {
                newPincode = await getNearestPincode(lat, lng);
            }

            setPincode(newPincode);
            setIsLocation(newPincode);
            localStorage.setItem('selectedPincode', newPincode);
            localStorage.setItem('selectedLocation', JSON.stringify({ lat, lng, address: place.formatted_address }));
        }
    };
    
    const getCoordinates = () => {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by this browser.');
            return;
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const coords = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                setSelectedPlace({ lat: coords.lat, lng: coords.lng });

                try {
                    const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
                    const response = await axios.get(
                        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${apiKey}`
                    );

                    if (response.data.results.length > 0) {
                        const addressComponents =
              response.data.results[0].address_components;

                        const postalCode =
              addressComponents.find((comp) =>
                  comp.types.includes('postal_code')
              )?.long_name || '';

                        const houseNumber =
              addressComponents.find((comp) =>
                  comp.types.includes('street_number')
              )?.long_name || '';
                        const street =
              addressComponents.find((comp) => comp.types.includes('route'))
                  ?.long_name || '';
                        const city =
              addressComponents.find((comp) => comp.types.includes('locality'))
                  ?.long_name || '';
                        const village =
              addressComponents.find((comp) =>
                  comp.types.includes('sublocality')
              )?.long_name || '';
                        const state =
              addressComponents.find((comp) =>
                  comp.types.includes('administrative_area_level_1')
              )?.long_name || '';
                        const country =
              addressComponents.find((comp) => comp.types.includes('country'))
                  ?.long_name || '';

                        const fullAddress = `${houseNumber} ${street}, ${
                            village || city
                        }, ${state} ${postalCode}, ${country}`;

                        setAddress(fullAddress);
                        setInputValue(fullAddress.replace(/^,\s*/, ''));
                        setPincode(postalCode);
                        setIsLocation(postalCode);
                        localStorage.setItem('selectedPincode', postalCode);
                        localStorage.setItem('selectedLocation', JSON.stringify({ lat: coords.lat, lng: coords.lng, address: fullAddress }));
                    } else {
                        setAddress('Turn on location');
                        setInputValue('Turn on location');
                        setPincode(null);
                        setIsLocation('');
                    }
                } catch (error) {
                    console.error('Error fetching address:', error);
                    setAddress('Error fetching address');
                    setInputValue('Error fetching address');
                    setPincode(null);
                    setIsLocation('');
                }
            },
            (error) => {
                console.error('Geolocation error:', error);
                alert('Unable to fetch location. Please enable location services.');
            }
        );
    };

    useEffect(() => {
        const savedPincode = localStorage.getItem('selectedPincode');
        const savedLocation = localStorage.getItem('selectedLocation');

        if (savedPincode && savedLocation) {
            setPincode(savedPincode);
            setIsLocation(savedPincode);
        
            const { lat, lng, address } = JSON.parse(savedLocation);
            setSelectedPlace({ lat, lng });
            setInputValue(address);
        } else {
            getCoordinates();
        }
    }, []);

    const getAddressFromCoordinates = async (lat, lng) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
            );
    
            if (response.data.results.length > 0) {
                const addressComponents = response.data.results[0].address_components;
    
                const postalCode = addressComponents.find(comp => 
                    comp.types.includes('postal_code')
                )?.long_name || '';
     
                const houseNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
                const street = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
                const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || '';
                const village = addressComponents.find(comp => comp.types.includes('sublocality'))?.long_name || '';
                const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '';
                const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || '';
    
                const fullAddress = `${houseNumber} ${street}, ${village || city}, ${state} ${postalCode}, ${country}`;
    
                setAddress(fullAddress);
                setInputValue(fullAddress.replace(/^,\s*/, ''));
                setPincode(postalCode);
                setIsLocation(postalCode);
                localStorage.setItem('selectedPincode', postalCode);
                localStorage.setItem('selectedLocation', JSON.stringify({ lat, lng, address: fullAddress }));
            } else {
                setAddress('Turn on location');
                setInputValue('Turn on location');
                setPincode(null);
                setIsLocation('');
            }
        } catch (error) {
            console.error('Error fetching address:', error);
            setAddress('Error fetching address');
            setInputValue('Error fetching address');
            setPincode(null);
            setIsLocation('');
        }
    };

    useEffect(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
            console.log('Google Places API loaded!');
            setAutocompleteService(new window.google.maps.places.AutocompleteService());
        } else {
            console.error('Google Maps API not loaded yet.');
        }
    }, []);
    
    useEffect(() => {
        if (tabName) {
            setIsClicked(true);
            setSearchQuery('');
        }
    }, [tabName]);

    const getSpeechRecognition = () => {
        const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            alert('Sorry, your browser does not support Speech Recognition.');
            return null;
        }
        return new SpeechRecognition();
    };

    const handleSearchClick = () => {
        setIsClicked(true);
        navigate('/search');
    };

    const handleInputChange = (e) => {
        const isMobile = window.innerWidth < 600;
        const value = e.target.value;
        
        if (isMobile) {
            setSearchQuery(value);
        } else {
            const prefix = tabName ? `${tabName} | ` : '';
            if (value.startsWith(prefix)) {
                setSearchQuery(value.slice(prefix.length));
            }
        }
    };

    const handleMicClick = () => {
        const recognition = recognitionRef.current;
        
        if (!recognition) {
            recognitionRef.current = getSpeechRecognition();
        }
    
        if (!recognitionRef.current) return;
    
        const startRecognition = () => {
            if (isListening) {
                recognitionRef.current.stop();
                setIsListening(false);
            } else {
                startVoiceRecognition();
                setIsListening(true);
            }
        };
    
        if (location.pathname === '/search') {
            startRecognition();
        } else {
            navigate('/search');  
        }
    };
    const startVoiceRecognition = () => {
        const recognition = recognitionRef.current;
        if (!recognition) return;
    
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = 'en-US';
    
        recognition.onstart = () => {
            setIsListening(true);
        };
    
        recognition.onresult = (event) => {
            let voiceQuery = event.results[0][0].transcript.trimStart();
    
            setSearchQuery((prevQuery) => `${prevQuery}${voiceQuery}`);
            stopVoiceRecognition();
        };
    
        recognition.onerror = (event) => {
            console.error('Voice recognition error:', event.error);
            stopVoiceRecognition();
        };
    
        recognition.onend = () => {
            setIsListening(false);
        };
    
        recognition.start();
    };
    
    const stopVoiceRecognition = () => {
        if (recognitionRef.current) {
            recognitionRef.current.stop();
            recognitionRef.current.onresult = null;
            recognitionRef.current.onerror = null;
            recognitionRef.current.onend = null;
        }
        setIsListening(false);
    };
    

    const handleOpenModal = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                console.log('User location allowed:', position);
                setIsModalOpen(true);
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setIsNotificationOpen(true);
                    setTimeout(() => setIsNotificationOpen(false), 3000);
                }
            }
        );
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    const handleProfile = () => {
        navigate('/profile');
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 601);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOpen = () => {
        setIsOpen(true);
        setTimeout(() => {
            setAnimateModal(true);
            setClosing(false);
        }, 100);
    };
    const handleClose = () => {
        setClosing(true);
        setAnimateModal(false);
        setTimeout(() => {
            setIsOpen(false);
        }, 300);
    };

    const goBack = () => {
        navigate('/dashboard');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const handleSignup = () => {
        navigate('/signup');
    };

    const getNearestPincode = async (lat, lng) => {
        try {
            const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
            );
    
            if (response.data.results.length > 0) {
                // Loop through results to find the first available pincode
                for (const result of response.data.results) {
                    const postalCode = result.address_components.find(comp =>
                        comp.types.includes('postal_code')
                    )?.long_name;
    
                    if (postalCode) {
                        return postalCode; // Return the first valid pincode
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching nearest pincode:', error);
        }
        return 'Unknown'; // If no pincode is found
    };
    

    return (
        <>
            <div className="topbar-container">
                {backIconVisible && <img className="back-icon" src={backIcon} alt="backIcon" onClick={() => (window.location.pathname == '/Payment' ? goBackCart() : goBack())} />}
                <div className="location-container" onClick={handleOpenModal} >
                    {isMobile ? (
                        <img
                            src={whiteLocationIcon}
                            alt="location"
                            className="mobile-location-icon"
                        />
                    ) : (
                        <LocationIcon className={'location-svg-icon'} />
                    )}

                    <div className="turn-on-location-container">
                        <p className="turn-on-location" title={address}>
                            {pincode ? isLocation : 'select Location'}
                        </p>
                        <RoundDownArrow />
                    </div>
                </div>

                {isModalOpen && (
                    <div
                        className="location-popup-container"
                        onClick={handleOutsideClick}
                    >
                        <div className="location-popup" ref={modalRef}>
                            <div className="title-and-cross-container">
                                <div></div>
                                <h1 className="select-location-heading">Select Location</h1>
                                <CloseIcon
                                    onClick={() => setIsModalOpen(false)}
                                    className="cross-icon-location"
                                />
                            </div>
 
                            <div
                                className="location-gps-container"
                                onClick={() => getCoordinates()}
                            >
                                <LocationIcon className={'location-svg-icon-location-modal'} />
 
                                <div className="get-location-text-container">
                                    <h4 className="location-text">Get Current Location</h4>
                                    <p className="gps-text">Using GPS</p>
                                </div>
                            </div>
 
                            <LoadScript
                                googleMapsApiKey="AIzaSyC59za_py8WPH2WdqAoiepkGsVPyrSCYuI"
                                libraries={['places']}
                                onLoad={() => {
                                    setAutocompleteService(new window.google.maps.places.AutocompleteService());
                                }}                            
                            >
                                <div style={{ width: '100%' }}>
                                    <StandaloneSearchBox
                                        onLoad={(ref) => (inputRef.current = ref)}
                                        onPlacesChanged={handlePlaceChanged}
                                    >
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <input
                                                type="text"
                                                placeholder="Start typing your address"
                                                value={inputValue} 
                                                onChange={(e) => setInputValue(e.target.value)}
 
                                                style={{
                                                    boxSizing: 'border-box',
                                                    border: '1px solid transparent',
                                                    width: '100%',
                                                    padding: '15px',
                                                    borderRadius: '3px',
                                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                                    fontSize: '16px',
                                                    outline: 'none',
                                                    textOverflow: 'ellipses',
                                                    marginTop: '10px',
                                                }}
                                            />
                                            {isMobile && (
                                                <div className='location-search-icon'> 
                                                    <SearchIconSvg
                                                        className="search-icon"
                                                        color={'white'}
                                                        onClick={() => {
                                                            console.log('Search icon clicked!');

                                                            if (!autocompleteService) {
                                                                console.error('Autocomplete service not available. Retrying...');
                                                                setAutocompleteService(new window.google.maps.places.AutocompleteService());  // Try reinitializing
                                                                return;
                                                            }

                                                            if (!inputValue.trim()) {
                                                                console.error('Input field is empty.');
                                                                return;
                                                            }

                                                            autocompleteService.getPlacePredictions(
                                                                { input: inputValue },
                                                                (predictions, status) => {
                                                                    console.log('Predictions received:', predictions, status);

                                                                    if (
                                                                        status !== window.google.maps.places.PlacesServiceStatus.OK ||
                    !predictions ||
                    predictions.length === 0
                                                                    ) {
                                                                        console.error('No place predictions found.');
                                                                        return;
                                                                    }

                                                                    const placeId = predictions[0].place_id;
                                                                    const placesService = new window.google.maps.places.PlacesService(
                                                                        document.createElement('div')
                                                                    );

                                                                    placesService.getDetails({ placeId }, (place, status) => {
                                                                        console.log('Place details received:', place, status);

                                                                        if (
                                                                            status !== window.google.maps.places.PlacesServiceStatus.OK ||
                        !place ||
                        !place.geometry
                                                                        ) {
                                                                            console.error('No place details found.');
                                                                            return;
                                                                        }

                                                                        const lat = place.geometry.location.lat();
                                                                        const lng = place.geometry.location.lng();

                                                                        setSelectedPlace({ lat, lng });
                                                                        getAddressFromCoordinates(lat, lng);
                                                                    });
                                                                }
                                                            );
                                                        }}                  
                                                    />


                                                </div>
                                            )}
                                        </div>
                                    </StandaloneSearchBox>
                                </div>
                                <div style={{color:'rgb(116, 110, 110)',fontSize:'13px', width:'100%', marginTop:'10px'}}>Drag the map to change the location as needed</div>
                                
                                {selectedPlace && (
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <div style={{ position: 'relative', height: '100%', width: '100%',outline: 'none', border: 'none' }}>
                                            <GoogleMap
                                                mapContainerStyle={{
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                                center={selectedPlace}
                                                zoom={15}
                                                onLoad={(map) => setMapRef(map)}
                                                onDragEnd={() => {
                                                    if (mapRef) {
                                                        const newCenter = mapRef.getCenter();
                                                        const newLat = newCenter.lat();
                                                        const newLng = newCenter.lng();

                                                        setSelectedPlace({ lat: newLat, lng: newLng });

                                                        getAddressFromCoordinates(newLat, newLng);
                                                    }
                                                }}
                                                options={{
                                                    gestureHandling: 'greedy',
                                                }}
                                            />
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -100%)',
                                                    pointerEvents: 'none',
                                                }}
                                            >
                                                <FaLocationDot fill='#e13535' fontSize={'25px'}/>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </LoadScript>
                        </div>
                    </div>
                )}

                <div className={`search-container ${location.pathname.includes('Dashboard') ? 'wide' : ''}`}   onClick={handleSearchClick}>
                    <SearchIconSvg  
                        className={`search-icon ${tabName ? 'search-icon-mobile' : ''}`}
                        color={'#464B59'}
                    />
                    <div className="search-tab">
                        <input
                            type="text"
                            className="input-field"
                            value={
                                isClicked
                                    ? `${tabName ? tabName : ''}${
                                        tabName ? ' | ' : ''
                                    }${searchQuery}`
                                    : ''
                            }
                            onChange={handleInputChange}
                            onClick={handleSearchClick}
                            placeholder={t('topmenubar.input-placeholder')}
                        />
                    </div>
                    <div className="search-tab-mobile">
                        {tabName == 'Restaurant_food' && (
                            <img
                                className="food-icon-search-overlay-search"
                                src={foodicon}
                                alt="food"
                            />
                        )}
                        {tabName == 'restaurant' && (
                            <img
                                className="food-icon-search-overlay-search"
                                src={restauranticon}
                                alt="restaurant"
                            />
                        )}
                        {tabName == 'Posts' && (
                            <img
                                className="food-icon-search-overlay-search"
                                src={reelspostsicon}
                                alt="restaurant"
                            />
                        )}
                        {tabName == 'Users' && (
                            <img
                                className="food-icon-search-overlay-search"
                                src={bloggersicon}
                                alt="restaurant"
                            />
                        )}
                        {tabName == 'Offer' && (
                            <img
                                className="food-icon-search-overlay-search"
                                src={offericon}
                                alt="restaurant"
                            />
                        )}
                        {tabName && (
                            <span className="verticle-line" style={{ marginLeft: '5px' }}>
                                {tabName ? ' | ' : ''}
                            </span>
                        )}

                        <input
                            type="text"
                            className="input-field"
                            value={isClicked ? `${searchQuery}` : ''}
                            onChange={handleInputChange}
                            onClick={handleSearchClick}
                            placeholder={t('topmenubar.input-placeholder')}
                        />
                    </div>

                    <span className="verticle-line">|</span>

                    <div className={`mic-container ${isListening ? 'listening' : ''}`}>
                        {isListening && (
                            <div className="wave-container">
                                <div className="wave"></div>
                                <div className="wave"></div>
                                <div className="wave"></div>
                            </div>
                        )}
                        <MicIconSvg isListening={isListening} onClick={handleMicClick} />
                    </div>
                </div>

                {isMobile ? (
                    <div>
                        <img
                            src={threeDot}
                            alt="menu"
                            className="three-dot-menu"
                            onClick={handleOpen}
                        />
                    </div>
                ) : (
                    <div className="user-modal-web">
                        {isNotificationOpen && (
                            <div
                                style={{
                                    position: 'fixed',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: '#ffffff',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    zIndex: 2000,
                                    textAlign: 'center',
                                }}
                            >
                                <p style={{ color: '#d8000c', margin: 0 }}>
                  Please allow location access to use.
                                </p>
                            </div>
                        )}
                        {currentLoginUserId && 
                            <div className='notification-profile'>
                                <div>
                                    <NotificationIcon color={'#2C3142'} className="notification-icon" notificationcount={notificationCountvalue}  onClick={() => setNotificationOverlay(!NotificationOverlay)}/>
                                </div>
                                {NotificationOverlay && (
                                    <>
                                        <div className="arrow-to-point-notification"></div>
                                        <div className="notification-overlay" ref={overlayRef}>
                                            <Notification closeOverlay={closenotificationoverlay} />
                                        </div>
                                    </>
                                )}

                                {((userData?.first_name && userData?.last_name) || userData?.first_name || userData?.last_name || profileImage) && (
                                    <ProfileAvatar
                                        className={'profile-icon'}
                                        firstName={userData?.first_name || ''}
                                        lastName={userData?.last_name || ''}
                                        profilePic={profileImage}
                                        height={30}
                                        width={30}
                                        fontSize={16}
                                        onClick={() => handleProfile()}
                                    />
                                )}
                            </div>
                        }
                    </div>
                )}

                <Modal open={isOpen} onClose={handleClose}>
                    <Box
                        className={`modal-container ${animateModal ? 'animate' : ''} ${
                            closing ? 'close' : ''
                        }`}
                    >
                        <div className="user-modal-mobile">
                            {isNotificationOpen && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: '#ffffff',
                                        padding: '20px',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                        zIndex: 2000,
                                        textAlign: 'center',
                                    }}
                                >
                                    <p style={{ color: '#d8000c', margin: 0 }}>
                    Please allow location access to use.
                                    </p>
                                </div>
                            )}
                            {currentLoginUserId ? (
                                <div className='notification-profile'>
                                    <div className="mobile-notification-profile-common" onClick={() => setNotificationOverlay(!NotificationOverlay)}>
                                        <NotificationIcon color={'white'} width={38} height={38} className="notification-icon" notificationcount={notificationCountvalue}/>
                                        <p className='mobile-notification-profile-text'>Notifications</p>
                                    </div>
                                    {NotificationOverlay && (
                                        <>
                                            <div className="arrow-to-point-notification"></div>
                                            <div className="notification-overlay" ref={overlayRef}>
                                                <Notification closeOverlay={closenotificationoverlay} />
                                            </div>
                                        </>
                                    )}

                                    <div className="profile-icon">
                                        <div
                                            className="mobile-notification-profile-common"
                                            onClick={() => handleProfile()}
                                        >
                                            {((userData?.first_name && userData?.last_name) || userData?.first_name || userData?.last_name ||
                        profileImage) && (
                                                <ProfileAvatar
                                                    className={'profile-icon'}
                                                    firstName={userData?.first_name}
                                                    lastName={userData?.last_name}
                                                    profilePic={profileImage}
                                                    height={30}
                                                    width={30}
                                                    fontSize={16}
                                                />
                                            )}
                                            <p className="mobile-notification-profile-text">
                        Profile
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="notification-profile">
                                    <p
                                        className="mobile-notification-profile-common mobile-notification-profile-text"
                                        onClick={handleLogin}
                                    >
                    Login
                                    </p>
                                    <p
                                        className="mobile-notification-profile-common mobile-notification-profile-text"
                                        onClick={handleSignup}
                                    >
                    SignUp
                                    </p>
                                </div>
                            )}
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default TopMenuBar;
