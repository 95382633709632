import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './Redux/Store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CartProvider } from './CartProvider';
import { NotificationProvider } from '../src/Views/User/Notification/NotificationContext'; // Import the Notification Provider

// import { OrderProvider } from './ordersCount';

import { AuthProvider } from './AuthProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {/* <React.StrictMode> */}
        <Provider store={store}>

            {/* <OrderProvider> */}

            <AuthProvider>
            
                <CartProvider>
                    <NotificationProvider>
                        <App />
                    </NotificationProvider>
                </CartProvider>
            </AuthProvider>
            {/* </OrderProvider> */}
            <ToastContainer />
           
 
        </Provider>
        {/* </React.StrictMode>  */}

    </> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
