import React, { useState } from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import './Profilesettings.css';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import { Link } from 'react-router-dom';


import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import './Profilesettings.css';
import { toast } from 'react-toastify';
import axiosUserInstance from '../../../axiosUserInstance';

function Changelanguage() {
    const languages =[
        {
            code:'en',
            lang:'English'
        },
        {
            code:'fr',
            lang:'French'
        },
        {
            code:'zh_CN',
            lang:'China'
        }
    ];

    const [value, setValue] = useState('en');
    const {t} = useTranslation();

    const handleChange = (event) => {
        setValue(event.target.value);
        
    };
    

    
    const handleClick = (language) => {
        i18n.changeLanguage(language);

        // Make API call to update language for the user account
        axiosUserInstance.put('/customers/language/account/2', 
            { Language: language },{skipAuth: true}
        )
            .then(response => {
            // Handle success
                console.log('Language updated successfully:', response.data);
                toast.success('Language updated successfully');
            })
            .catch((error) => {
            // Handle error
                console.error('Error updating language:', error);
                toast.error('Error updating language');
            });
    };
    return (
        <div className='editprofile-top-main'>
            <div className='sidebar'>
                <SideMenuBar></SideMenuBar>
            </div>
            <div className='top-bar'><TopMenuBar></TopMenuBar></div>
            <div className='change-language-main'>
                <div className='change-lang-top1'>
                    <Link to='/profilesettings'>
                        <div className='ico1'>                             <svg width="22" height="25" viewBox="0 0 42 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.9909 3L3 39L39 75" stroke="#464B59" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg></div>

                    </Link>
                    <h5 className='change-language-txt'>{t('changelanguage.change-language-title')}</h5>
                </div>


                {
                    languages.map((language,index)=>{
                        return (
                            <div key={index}>
                                <FormControl >
                                    <RadioGroup
                                        aria-label="Change Language"
                                        name="Change Language"
                                        value={value}
                                        onChange={handleChange}
                                        className="fieldset1"
                                        
                                    >
                                        <FormControlLabel value={language.code} control={<Radio />} label={language.lang} onClick={()=>{
                                            handleClick(language.code);
                                    
                                        }} />
                                    
                                    </RadioGroup>
                                </FormControl>

                            </div>
                        );
                    })
                }

            </div></div>
    );
}

export default Changelanguage;
