import React, { useEffect, useState, useRef } from 'react';
import '../../../Styles/Reels/ReelsIndex.css';
import VideoCard from '../../../Components/VideoCard';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import { useCart } from '../../../CartProvider';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import axiosUserInstance from '../../../axiosUserInstance';

function Reels() {
    const {coordinate} = useCart();
    const [postData, setPostData] = useState([]);
    const [restaurant, setRestaurant] = useState([]);

    const [ismuted, setIsMuted] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(true); 
    const [globalMuteIcon, setGlobalMuteIcon] = useState(true); 
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const videoRefs = useRef([]);
    const {reelsId} = useParams();
    const handleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };

    const toggleMuteIcon = () => {
        setGlobalMuteIcon((prevMute) => !prevMute);
    };

    const parseAvatarKey = (key) => {
        try {
            return JSON.parse(key.replace(/\\/g, '')) || [];
        } catch (error) {
            return [];
        }
    };

    const getPosts = async () => {
        try {
            setLoading(true);
    
            let apiURL;
            if (reelsId) {
                apiURL = `/posts/sharepost/${reelsId}/0,0`;
            } else {
                apiURL = `/posts/location?pageNo=${page}&pageSize=10&location=${coordinate?.lat},${coordinate?.lng}`;
            }
    
            const response = await axiosUserInstance.get(apiURL);
    
            const postInformation = response.data.posts[0]?.Post_Information || [];
    
            if (reelsId) {
                setPostData(postInformation);
                setRestaurant(postInformation);
            } else {
                if (postInformation.length > 0) {
                    setPostData((prevData) => (page === 1 ? [...postInformation] : [...prevData, ...postInformation]));
                    setRestaurant((prevData) => (page === 1 ? [...postInformation] : [...prevData, ...postInformation]));
                } else {
                    setHasMoreData(false);
                }
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                if (hasMoreData) {
                    setPage((prev) => prev + 1);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPosts(); 
    }, [page, coordinate?.lat, coordinate?.lng, reelsId]);

    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        return () => window.removeEventListener('scroll', handleInfiniteScroll);
    }, [hasMoreData]);

    useEffect(() => {
        if (!postData.length) return; 
    
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const video = entry.target;
                    if (entry.isIntersecting) {
                        if (video.readyState >= 3) { 
                            video.play().catch((error) => console.error('Video play error:', error));
                        }
                    } else {
                        video.pause();
                    }
                });
            },
            {threshold: 0.75}
        );
    
        videoRefs.current.forEach((videoRef) => {
            if (videoRef && videoRef.src) {
                observer.observe(videoRef);
            } else {
                console.warn('Video source is missing', videoRef);
            }
        });
    
        return () => observer.disconnect();
    }, [postData]);
    

    const handleVideoRef = (ref, index) => {
        if (ref) {
            videoRefs.current[index] = ref;
        }
    };

    return (
        <>
            <div className="reels-dashboard">
                <div className="topmenubar">
                    <SideMenuBar getPosts={getPosts}></SideMenuBar>
                    <div className="top-bar-mobile">
                        <TopMenuBar />
                    </div>
                </div>

                <div className="main-container">
                    <div className="video-container">
                        {postData.length === 0 &&  !loading &&
                                <div className="no-posts-message" 
                                    style={{ textAlign: 'center', marginTop: '100px', fontSize: '25px', color: '#888'}}>
                               No Posts Found
                                </div>
                        }
                        {postData?.map((post, index) => (
                            (post?.Attachment_keys || []).map((attachment) => {
                                const avatarUrls = parseAvatarKey(attachment.Avatar_key || '[]');
                                return avatarUrls.map((url) => (                                 
                                    <VideoCard                               
                                        key={post?.Post_ID}
                                        id={post?.Post_ID}
                                        vendorID={post?.Post_Vendor_id}
                                        AccountID={post?.User_Info?.User_ID}
                                        url={url}
                                        attachmentKeys={post?.Attachment_keys || []}
                                        profilePic={post?.User_Info?.Profile_Picture || ''}
                                        firstName = {post?.User_Info?.First_Name || ''}
                                        lastName = {post?.User_Info?.Last_Name || ''}
                                        userName={post?.User_Info?.Username || ''}
                                        restaurantName={post?.Vendor_Info?.[0]?.Vendor_Name || ''}
                                        starPic={post?.Restaurant_Info?.[0]?.Star_Image || ''}
                                        rating={post?.Vendor_Info?.[0]?.Vendor_Avg_Rating || 0}
                                        distance={post?.Vendor_Info?.[0]?.Vendor_Distance || 0}
                                        description={post?.Post_Description}
                                        likes={post?.Post_Likes_count}
                                        shares={post?.Post_Shares_count}
                                        comments={post?.Post_Comments_count}
                                        setLike={() => {}}
                                        handleMute={handleMute}
                                        ismuted={ismuted}
                                        globalMuteIcon={globalMuteIcon}
                                        toggleMuteIcon={toggleMuteIcon}
                                        setVideoRef={(ref) => handleVideoRef(ref,index)}
                                        autoplay={index === 0}
                                        restaurant={restaurant}
                                        coordinate={coordinate}
                                        likeFlag={post?.LikedFlag}
                                        saveFlag={post?.SavedFlag}
                                        reelsId={post?.Post_ID}
                                        itemName={post?.Menu_items_Info?.[0]?.Menu_item_Name || ''}
                                        vendorDescription={post?.Vendor_Info?.[0]?.Vendor_Description || ''}
                                        followFlag={post?.FollowFlag}
                                        filter={post?.Post_filter || ''}
                                    />
                                ));
                            })
                        ))}
                        {loading && <div style={{ textAlign: 'center', padding: '20px', display: 'flex', justifyContent: 'center'  }}>
                            <CircularProgress />
                        </div>}
                    </div>
                </div>

                <div className="right-side-bar">
                    <RightSideBar></RightSideBar>
                </div>
            </div>
        </>
    );
}

export default Reels;

