import React, { useState, useEffect } from 'react';
import './PreviousOrders.css';
import { useTranslation } from 'react-i18next';
import image1 from '../../../assets/Image1.png';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { Skeleton } from '@mui/material';
import useWindowSize from './useWindowSize';
import Loader from '../../../Components/Loader/Loader';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import { useCart } from '../../../CartProvider';

function PrevOrders() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [previousOrders, setPreviousOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const windowSize = useWindowSize();
    const [loading, setLoading] = useState(false);
    const { fetchCartCount } = useCart();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    useEffect(() => {
        const fetchPreviousOrders = async () => {
            try {
                const response = await axiosUserInstance.get(
                    '/orders/previous-orders-with-vendor?pageNo=1&pageSize=10'
                );
                console.log('response order', response);
                setPreviousOrders(response?.data?.orders);
            } catch (error) {
                console.error('Error fetching previous orders', error);
            }
        };
        fetchPreviousOrders();
    }, []);

    const handlePreviousOrderPage = (orderId) => {
        navigate(`/orders/${orderId}`);
    };

    const handleOrderAgain = async (e, orderId) => {
        e.stopPropagation();

        try {
            setLoading(true);
            const response = await axiosVendorInstance.get(
                `/order/reorder/ordersdata?order_id=${orderId}`, {skipAuth: true}
            );
            if(response?.status == 200){
                setTimeout(() => {
                    setLoading(false);
                }, 2000);
                navigate('/cart');
                await fetchCartCount();
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching previous orders again', error);
        }
    };

    const NextArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-next ${isDisabled ? 'disabled-arrow' : ''}`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowRight />
        </div>
    );
        
    const PrevArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-prev ${isDisabled ? 'disabled-arrow' : ''}`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowLeft />
        </div>
    );

    let slidesToShow = 8;
    let showSlider = previousOrders?.length > 8;
    const totalSlides = previousOrders?.length || 0;
    let variableWidth = false;

    // Apply responsive settings in correct order (smallest to largest)
    if (windowSize.width <= 600) {
        slidesToShow = 3;
        showSlider = previousOrders?.length >= 3;
        variableWidth = true;
    } else if (windowSize.width <= 768) {
        slidesToShow = 3;
        showSlider = previousOrders?.length >= 3;
    } else if (windowSize.width <= 1165) {
        slidesToShow = 4;
        showSlider = previousOrders?.length >= 4;
    } else if (windowSize.width <= 1363) {
        slidesToShow = 5;
        showSlider = previousOrders?.length >= 5;
    } else if (windowSize.width <= 1677) {
        slidesToShow = 7;
        showSlider = previousOrders?.length >= 7;
    } else if (windowSize.width <= 2560) {
        slidesToShow = 8;
        showSlider = previousOrders?.length >= 8;
    }

    const settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: true,
        initialSlide: 0,
        nextArrow: !isLoading && windowSize.width > 600 ? <NextArrow isDisabled={currentSlide >= totalSlides - slidesToShow} /> : <></>,
        prevArrow: !isLoading && windowSize.width > 600 ? <PrevArrow isDisabled={currentSlide === 0} /> : <></>,
        swipe: true,
        variableWidth,
        afterChange: (index) => setCurrentSlide(index),
    };

    return (
        previousOrders &&
    previousOrders.length > 0 && (
            <div className='Prev-orders-Main-container'>
                {loading && (
                    <div>
                        <Loader/>
                    </div>
                )}
                <div className='title-and-arrows'>
                    <h5 className='previous-orders-title-homepage'>
                        {t('homescreen.previous-orders-title')}
                    </h5>
                </div>

                <div className='order-cards-container'>
                    {isLoading ? (
                        <Slider {...settings} className='slider-component'>
                            {[...Array(10)].map((_, index) => (
                                <div key={index} className='order-card'>
                                    <Skeleton variant='rounded' width={100} height={100} />
                                    <Skeleton variant='text' width={80} height={20} />
                                    <Skeleton variant='rounded' width={100} height={25} />
                                </div>
                            ))}
                        </Slider>
                    ) : showSlider ? (
                        <Slider {...settings} className='slider-component'>
                            {previousOrders.map((item, index) => (
                                <div
                                    key={index}
                                    className='order-card'
                                    onClick={() =>handlePreviousOrderPage(item?.OrderInfo?.OrderID)}
                                >
                                    <div className='previous-order-image'>
                                        <img
                                            className='item-image'
                                            src={
                                                item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]
                                                    ?.MenuItemAvatarKey || image1
                                            }
                                            alt='item'
                                            onError={(e) => {
                                                e.target.src = image1; // Set default image when loading fails
                                            }}
                                        />
                                    </div>
                                    <h6 className='items-name'>
                                        {item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]?.MenuItemName
                                            ?.length > 15
                                            ? item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]?.MenuItemName?.slice(0, 15) + '...'
                                            : item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]
                                                ?.MenuItemName || 'pav bhaji'}
                                    </h6>
                                    <div className='button-text-container'>
                                        <button className='Order-Button' onClick={(e) => handleOrderAgain(e,item?.OrderInfo?.OrderID)}>
                                            <p className='Button-text'>
                                                {t('homescreen.order-again-button')}
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className='flex-orders-container'>
                            {previousOrders.map((item, index) => (
                                <div
                                    key={index}
                                    className='order-card'
                                    onClick={() =>handlePreviousOrderPage(item?.OrderInfo?.OrderID)}
                                >
                                    <div className='previous-order-image'>
                                        <img
                                            className='item-image'
                                            src={
                                                item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]
                                                    ?.MenuItemAvatarKey || image1
                                            }
                                            alt='item'
                                            onError={(e) => {
                                                e.target.src = image1; // Set default image when loading fails
                                            }}
                                        />
                                    </div>
                                    <h6 className='items-name'>
                                        {item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]?.MenuItemName
                                            ?.length > 12
                                            ? item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]?.MenuItemName?.slice(0, 12) + '...'
                                            : item?.OrderInfo?.OrderInfo?.[0]?.MenuItems[0]
                                                ?.MenuItemName || 'pav bhaji'}
                                    </h6>
                                    <div className='button-text-container'>
                                        <button className='Order-Button' onClick={(e) => handleOrderAgain(e,item?.OrderInfo?.OrderID)}>
                                            <p className='Button-text'>
                                                {t('homescreen.order-again-button')}
                                            </p>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

export default PrevOrders;
