import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';

import { useTranslation } from 'react-i18next';

import './../../../Styles/Reels/Share.css';
import whatsup from './../../../../src/assets/whatsapp .png';
import twitter from './../../../../src/assets/twitter.png';
import facebook from './../../../../src/assets/facebook.png';
import telegram from './../../../../src/assets/telegram.png';

function Share({ handleClose, open, url}) {
    const { t } = useTranslation();

    const [openBox, setOpenBox] = useState(false);

    const handleClickCopy = () => {
        setOpenBox(true);
    };

    const handleCloseCopy = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenBox(false);
    };

    let socialMediaShare = {
        whatsapp: 'https://api.whatsapp.com/send?text=' + url,
        facebook: 'https://facebook.com/sharer/sharer.php?u=' + url,
        twitter: 'https://twitter.com/share?url=' + url,
        telegram: 'https://telegram.me/share/url?url=' + url,
    };

    const sharePage = (site) => {
        window.open(socialMediaShare[site]);
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="reel-share-container">
                    <Box className="share-style">
                        <div className="comment-and-crossIcon-container">
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t('reels.share-title')}
                            </Typography>

                            <svg
                                width="25"
                                height="18"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="cross-icon"
                                onClick={handleClose}
                            >
                                <path
                                    d="M14.7904 12.5002L24.5249 2.76519C25.1584 2.13197 25.1584 1.10814 24.5249 0.474916C23.8917 -0.158305 22.8679 -0.158305 22.2346 0.474916L12.4999 10.2099L2.76535 0.474916C2.13185 -0.158305 1.10834 -0.158305 0.475128 0.474916C-0.158376 1.10814 -0.158376 2.13197 0.475128 2.76519L10.2096 12.5002L0.475128 22.2352C-0.158376 22.8684 -0.158376 23.8922 0.475128 24.5255C0.790694 24.8413 1.20562 25 1.62024 25C2.03487 25 2.44949 24.8413 2.76535 24.5255L12.4999 14.7905L22.2346 24.5255C22.5505 24.8413 22.9651 25 23.3798 25C23.7944 25 24.209 24.8413 24.5249 24.5255C25.1584 23.8922 25.1584 22.8684 24.5249 22.2352L14.7904 12.5002Z"
                                    fill="white"
                                />
                            </svg>
                        </div>

                        <br />

                        <div className="share">
                            <button onClick={() => sharePage('whatsapp')} className="button">
                                <img className="img" src={whatsup} alt=""></img>
                            </button>
                            <button onClick={() => sharePage('twitter')} className="button">
                                <img className="img" src={twitter} alt=""></img>
                            </button>
                            <button onClick={() => sharePage('telegram')} className="button">
                                <img className="img" src={telegram} alt=""></img>
                            </button>
                            <button onClick={() => sharePage('facebook')} className="button">
                                <img className="img" src={facebook} alt=""></img>
                            </button>
                          
                        </div>

                        <div className="copy-text-container">
                            <input
                                className="copy-input"
                                contentEditable="false"
                                value={url}
                            ></input>

                            <CopyToClipboard text={url}>
                                <Button
                                    variant="contained"
                                    className="copy-button"
                                    onClick={() => handleClickCopy()}
                                >
                                    {t('reels.copy-title')}
                                </Button>
                            </CopyToClipboard>
                            <Snackbar
                                open={openBox}
                                autoHideDuration={2000}
                                onClose={handleCloseCopy}
                                message="Link Copied to clipboard"
                            />
                        </div>
                    </Box>
                </div>

            </Modal>
        </div>
    );
}

export default Share;
