import React from 'react';

const EditProfileIcon = ({onClick = () =>{}}) => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 48 41'
            fill='none'
            className='menuicon'
            xmlns='http://www.w3.org/2000/svg'
            onClick={onClick}
        >
            <path
                d='M38.6667 29.041H9.33333C8.59695 29.041 8 29.5509 8 30.1799C8 30.8089 8.59695 31.3188 9.33333 31.3188H38.6667C39.403 31.3188 40 30.8089 40 30.1799C40 29.5509 39.403 29.041 38.6667 29.041Z'
                fill='white'
            />
            <path
                d='M38.6667 19.3613H9.33333C8.59695 19.3613 8 19.8712 8 20.5002C8 21.1292 8.59695 21.6391 9.33333 21.6391H38.6667C39.403 21.6391 40 21.1292 40 20.5002C40 19.8712 39.403 19.3613 38.6667 19.3613Z'
                fill='white'
            />
            <path
                d='M38.6667 9.68164H9.33333C8.59695 9.68164 8 10.1915 8 10.8205C8 11.4495 8.59695 11.9594 9.33333 11.9594H38.6667C39.403 11.9594 40 11.4495 40 10.8205C40 10.1915 39.403 9.68164 38.6667 9.68164Z'
                fill='white'
            />
        </svg>
    );
};

export default EditProfileIcon;
