import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../User/profilepage/Profilesettings.css';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';
import axiosUserInstance from '../../axiosUserInstance';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ChangePassword = () => {
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [showPassword3, setShowPassword3] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [credentials, setCredentials] = useState({ oldPassword: '', password: '', confirmPassword: '' });
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);

    const handleValidation = (name, value) => {
        setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

            if (name === 'oldPassword') {
                if (!value) {
                    newErrors.oldPassword = 'Please enter your current password.';
                } else {
                    delete newErrors.oldPassword;
                }
            }
    
            if (name === 'password') {
                if (!value) {
                    newErrors.password = 'Please enter your password.';
                } else if (value.length < 8 || value.length > 20) {
                    newErrors.password = 'Password must be 8-20 characters long.';
                } else if (!passwordRegex.test(value)) {
                    newErrors.password =
                            'Password must include uppercase, lowercase, number, and special character.';
                } else {
                    delete newErrors.password;
                }
            }
    
            if (name === 'confirmPassword') {
                if (!value) {
                    newErrors.confirmPassword = 'Please enter your confirm password.';
                } else if (value !== credentials.password) {
                    newErrors.confirmPassword = 'Passwords do not match.';
                } else {
                    delete newErrors.confirmPassword;
                }
            }
    
            return newErrors;
        });
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
        handleValidation(name, value);
    };

    useEffect(() => {
        const isValid = Object.values(credentials).every(val => val.trim() !== '') &&
                        Object.keys(errors).length === 0;
        setIsFormValid(isValid);
    }, [credentials, errors]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isFormValid) return;

      

        const data = {
            oldPassword: credentials?.oldPassword,
            password: credentials?.password,
            confirmPassword: credentials?.confirmPassword,
        };

        try {
            setIsLoading(true);
            const res = await axiosUserInstance.put('/auth/change-password', data);
           
            setIsLoading(false);
            if (res?.statusText === 'OK') {
                toast.success(res?.data?.message);
            }
            setCredentials({oldPassword: '', password: '', confirmPassword: '' });
            setErrors({});
        } catch (error) {
           
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
            console.error(error.response?.data.error || 'Unable to connect to the server.');
        }
    };

    return (
        <div className='editprofile-top-main'>
            {isLoading && <Loader/>}
            {/* <div className='sidebar'>
                <SideMenuBar />
            </div>
            <div className='top-bar'>
                <TopMenuBar />
            </div> */}

            <div className='update-password-cont'>
                <div className='update-pass-top'>
                    <Link to='/profilesettings'>
                        <div className='ico1'>
                            <svg width="22" height="25" viewBox="0 0 42 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M38.9909 3L3 39L39 75" stroke="#464B59" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Link>
                    <div className='Update-Password-txt-layout'>
                        <h5 className='Update-Password-txt'>{t('changepassword.update-password-title')}</h5>
                    </div>
                </div>
                <div className='change-password-container'>
                    <form onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label className='label-text' htmlFor='oldPassword'>{t('changepassword.previous-password-title')}</label>
                            <div className='password-container'>
                                <input
                                    type={showPassword1 ? 'text' : 'password'}
                                    id='password'
                                    name='oldPassword'
                                    placeholder={t('changepassword.previous-password-placeholder')}
                                    value={credentials.oldPassword}
                                    onChange={handleOnChange}
                                />
                                <span
                                    className='toggle-password'
                                    onClick={() => setShowPassword1(!showPassword1)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {showPassword1 ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='label-text' htmlFor='password'>{t('changepassword.new-password-title')}</label>
                            <div className='password-container'>
                                <input
                                    type={showPassword2 ? 'text' : 'password'}
                                    id='password'
                                    name='password'
                                    placeholder={t('changepassword.new-password-placeholder')}
                                    value={credentials.password}
                                    onChange={handleOnChange}
                                    className={errors.password ? 'input-error' : ''}
                                />
                                <span
                                    className='toggle-password'
                                    onClick={() => setShowPassword2(!showPassword2)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {showPassword2 ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {errors.password && <p className="error-message">{errors.password}</p>}
                        </div>
                        <div className='form-group'>
                            <label className='label-text' htmlFor='confirmPassword'>{t('changepassword.confirm-password-title')}</label>
                            <div className='password-container'>
                                <input
                                    type={showPassword3 ? 'text' : 'password'}
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    placeholder={t('changepassword.confirm-password-placeholder')}
                                    value={credentials.confirmPassword}
                                    onChange={handleOnChange}
                                    className={errors.confirmPassword ? 'input-error' : ''}
                                />
                                <span
                                    className='toggle-password'
                                    onClick={() => setShowPassword3(!showPassword3)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {showPassword3 ? <FaEye /> : <FaEyeSlash />}
                                </span>
                            </div>
                            {errors.confirmPassword && (
                                <p className="error-message">{errors.confirmPassword}</p>
                            )}
                        </div>
                        <button type='submit' className="change-password-btn" disabled={!isFormValid} style={{
                            backgroundColor:'var(--color4)' , 
                            color: '#FFFFFF',
                            cursor: isFormValid ? 'pointer' : 'not-allowed',
                            opacity: isFormValid ? 1 : 0.7 
                        }}>
                            {t('changepassword.verify-and-update-button')}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;