const EditPencilIconSvg = ({ width = 16, height = 16, className = 'menu-edit-icon', onClick, clipPathId = 'clip0', color = '#CED1DC' }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <g clipPath={`url(#${clipPathId})`}>
                <path
                    d="M8.11133 1.53589L10.4644 3.88897"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.65527 7.99121L4.01065 10.3425"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6704 1.3295C10.355 1.01549 9.9273 0.840534 9.4823 0.843544C9.03725 0.846559 8.6119 1.02729 8.30085 1.34554L1.655 7.99138L0.84375 11.1562L4.00854 10.3445L10.6544 3.69862C10.9727 3.38769 11.1535 2.96239 11.1566 2.51741C11.1596 2.07243 10.9845 1.64471 10.6704 1.3295Z"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id={clipPathId}>
                    <rect width="12" height="12" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditPencilIconSvg;
