import React, { useState, useEffect } from 'react';
import '../../VendorStyles/Preparing.css';
import axiosVendorInstance from '../../axiosVendorInstance';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import  { OrderContextuseOrders} from '../../ordersCount';
import Loader from '../../Components/Loader/Loader';
import { useNotifications } from '../User/Notification/NotificationContext';

function InTransit() {
    const { addNotification } = useNotifications();  
    const {inTransitCount,setInTransitCount} = OrderContextuseOrders();
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1); 
    const [loading, setLoading] = useState(false); 
    console.log(page);
    const [orders, setOrders] = useState([]);
    const pageSize = 10; 
    console.log(orders); 
    const [vendorID,setVendorID] = useState();
    console.log(vendorID);
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchVendorID = async () => {
        setLoading(true);
        try {
            setLoading(true);
            const vendorResponse = await axiosVendorInstance.get('/vendors/account');
            const fetchedVendorID = vendorResponse?.data?.getVendorInfo[0]?.ID;
       
            if (fetchedVendorID) {
                setVendorID(fetchedVendorID);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching vendor ID:', error);
        }finally {
            setLoading(false); 
        }
    };
    const fetchNewOrders = async () => {
        setLoading(true);
        try {
            const response = await axiosVendorInstance.get(
                `/Order/vendor/${vendorID}?pageSize=${pageSize}&pageNumber=${page}&statusId=7`
            );
            const incomingData = response.data.allOrdersDetails;       
            if (incomingData.length === 0 && page > 1) {
                setPage(page - 1); 
            } else {
                setOrders(incomingData);
                setTotalPages(response.data?.totalPages || 1);
            }
    
            setLoading(false);
        } catch (error) {
            console.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    
    useEffect(() => {
        fetchVendorID();
    }, []);
    
    useEffect(() => {
        if (vendorID) {
            fetchNewOrders();
        }
    }, [page, vendorID]);

    const handleReadyToInTransit = async (order) => {
        try {
            const vendorResponse = await axiosVendorInstance.get(
                `/Order/vendor/particularordersdetail/${order.ID}`
            );
            const userId = vendorResponse?.data?.order?.user_id;
 
            if (!userId) {
                console.warn('User ID not found for order:', order.ID);
                return;
            }
 
            console.log('Vendor user ID:', userId);
 
            await axiosVendorInstance.put(
                `/Order/Vendor/${vendorID}/order/${order.ID}/status`,
                { status_id: 6 }
            );

            setOrders(orders.filter((o) => o.ID !== order.ID));
            setInTransitCount(inTransitCount - 1);
 
            // Update the local state to reflect the change
            setOrders(orders.filter((o) => o.id !== order.ID));
 
            const notificationPayload = {
                from_user_id: userId,
                to_user_id: vendorID,
                type: 'order_delivered',
                action_id: order.ID,
                notification_boundary: 'private',
                is_read: false,
            };
 
            try {
                const notificationRes = await addNotification(notificationPayload);
                console.log('Notification response:', notificationRes);
            } catch (notifError) {
                console.error(
                    'Error sending notification:',
                    notifError.response ? notifError.response.data : notifError.message
                );
            }
        } catch (error) {
            console.error(
                'Error handling ready-to-pickup:',
                error.response ? error.response.data : error.message
            );
        }

        fetchNewOrders();
    };

    return (
        <div className="new-orders-main">
            {loading ? (
                <Loader/>
            ) : (
                <div>
                    {orders && orders.length > 0 ? (
                        orders.map((order) => {
                            const createdAt = new Date(order.Created_at);
                            const time = createdAt.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            });
                            const date = `${createdAt.getDate()} ${createdAt.toLocaleString(
                                'en-US',
                                { month: 'short' }
                            )}`;

                            return (
                                <div key={order.id} className="preparing-single-order">
                                    <div className="preparing-orderid">
                                        <p className="preparing-order-details1">
                              Order ID:<span className="preparing-order-id">{order.ID}</span>
                                        </p>
                                    </div>
                                    <div className="preparing-date-and-time">
                                        <p className="preparing-order-details2">{time}</p>
                                        <p className="preparing-order-details3">{date}</p>
                                    </div>
                                    <button
                                        className="push-to-pickup-btn"
                                        onClick={() => handleReadyToInTransit(order)}
                                    >
                                        <p className="push-to-pickup-text">Push to Delivered</p>
                                    </button>
                                </div>
                            );
                        })
                           
                    ) :(orders.length === 0 &&
                            <div className='no-data-message'>No data</div>
                    )}


                    {orders.length > 0 && totalPages > 1 && (
                        <div className="pagination-container">
                            <Stack spacing={2} >
                                <Pagination   sx={{
                                    '& .MuiPagination-ul': {
                                
                                        color: '#333',
                                        padding: 1,
                                        borderRadius: 2,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        listStyleType: 'none',
                                    }
                            
                                }}count={totalPages} page={page} onChange={handlePageChange}  showFirstButton showLastButton />
                            </Stack>
                        </div>                     
                    )} 
                </div>
            )}
        </div>
    );
}

export default InTransit;
