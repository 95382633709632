import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import './../../../Styles/Cart/SubTotal.css';
import { useNavigate } from 'react-router-dom';
import CookingInstructionModal from './CookingInstModal';
import DeliveryInstModal from './DeliveryInstModal';


function SubTotal({ totalPrice }) {
    const { t } = useTranslation();

    const [openCookingInstructions, setOpenCookingInstructions] = useState(false);
    const [cookingInstructionValue, setCookingInstructionValue] = useState('');
    const [cookingErrorMessage, setCookingErrorMessage] = useState('');

    const [openDeliveryInstructions, setOpenDeliveryInstructions] = useState(false);
    const [selectedInstructions, setSelectedInstructions] = useState([]);
    const navigate = useNavigate();

    const handleOpenCookingInst = () => {
        setCookingErrorMessage('');
        setOpenCookingInstructions(true);
    };
    const handleCloseCookingInst = () => setOpenCookingInstructions(false);
    
    const handleOpenDeliveryInst = () => setOpenDeliveryInstructions(true);
    const handleCloseDeliveryInst = () => setOpenDeliveryInstructions(false);

    const handleCookingInputChange = (event) => {
        const value = event.target.value;
        setCookingInstructionValue(value);
    
        if (value.trim() === '') {
            setCookingErrorMessage('Cooking instructions cannot be empty.');
        } else if (value.length > 300) {
            setCookingErrorMessage('Cooking instructions cannot exceed 300 characters.');
        } else {
            setCookingErrorMessage('');
        }
    };
    

    const handleSubmitCookingInst = () => {
        const trimmedValue = cookingInstructionValue.trim();
    
        if (!trimmedValue) {
            setCookingErrorMessage('Cooking instructions cannot be empty.');
            return;
        }
    
        if (trimmedValue.length > 300) {
            setCookingErrorMessage('Cooking instructions cannot exceed 300 characters.');
            return;
        }
    
        setCookingErrorMessage('');
        handleCloseCookingInst();
    };
    

    const handleInstructionClick = (instruction) => {
        if (selectedInstructions.includes(instruction)) {
            setSelectedInstructions(
                selectedInstructions.filter((item) => item !== instruction)
            );
        } else {
            setSelectedInstructions([...selectedInstructions, instruction]);
        }
    };

    const handlePlaceOrder = () => {
        navigate('/Payment', { state: { cookingInstructionValue: cookingInstructionValue,  selectedInstructions: selectedInstructions} });
    };

    return (
        <div className="sub-total">
            <div className="sub-total-area">
                <h3>{t('cart.total-title')}</h3>

                <h3>${totalPrice}</h3>
            </div>
            <div className="instruction-btns">
                <button
                    className="cooking-inst-btn"
                    onClick={() => handleOpenCookingInst()}
                >
                    {t('cart.cooking-instructions')}
                </button>
                {openCookingInstructions && (
                    <CookingInstructionModal
                        open={openCookingInstructions}
                        handleClose={handleCloseCookingInst}
                        cookingInstructionValue= {cookingInstructionValue}
                        handleCookingInputChange= {handleCookingInputChange}
                        handleSubmitCookingInst= {handleSubmitCookingInst}
                        cookingErrorMessage= {cookingErrorMessage}
                    />
                )}
                <button
                    className="delivery-inst-btn"
                    onClick={() => handleOpenDeliveryInst()}
                >
                    {t('cart.delivery-instructions')}
                </button>
                {openDeliveryInstructions && (
                    <DeliveryInstModal
                        open={openDeliveryInstructions}
                        handleClose={handleCloseDeliveryInst}
                        selectedInstructions = {selectedInstructions}
                        handleInstructionClick = {handleInstructionClick}
                    />
                )}
            </div>
            <button className="place-order-btn" onClick={handlePlaceOrder}>
                {t('cart.place-order-button')}
            </button>
        </div>
    );
}

export default SubTotal;
