import React, { Suspense} from 'react';
import './App.css';
import MainNavigation from './MainNavigation';
import { NotificationProvider } from './Views/User/Notification/NotificationContext';




import InternetStatus from './Components/InternetStatus';

function App() {

    return (
   

        <div className="App">
            <InternetStatus/>
            <Suspense
                fallback={
                    <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                }
            >
                <NotificationProvider>
                    <MainNavigation></MainNavigation>
                </NotificationProvider>
            </Suspense>
        </div>
    );
}

export default App;