import React from 'react';

function UserOrderIcon() {
    return (
        <svg
            width="30"
            height="40"
            viewBox="0 0 24 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="list-svg-icon fill-red"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.4701 13.5818C22.4701 15.7397 20.6901 17.4919 18.5048 17.4919H5.49517C3.30986 17.4919 1.52992 15.7392 1.52992 13.5818V5.41873C1.52992 4.6986 1.72983 4.02238 2.07701 3.44148L8.42495 9.70112C9.37438 10.6403 10.6462 11.1579 12.0015 11.1579C13.3568 11.1579 14.6256 10.6403 15.575 9.70112L21.923 3.44148C22.2702 4.02188 22.4701 4.6981 22.4701 5.41873V13.5813V13.5818ZM18.5048 1.50864H5.49517C4.5928 1.50864 3.75977 1.81007 3.09325 2.31112L9.50496 8.63664C10.1684 9.2884 11.0546 9.64972 12.0015 9.64972C12.9484 9.64972 13.8316 9.2884 14.495 8.63664L20.9068 2.31112C20.2402 1.81007 19.4072 1.50864 18.5048 1.50864ZM18.5048 0H5.49517C2.46569 0 0 2.43139 0 5.41873V13.5813C0 16.5716 2.46569 19 5.49517 19H18.5048C21.5343 19 24 16.5711 24 13.5813V5.41873C24 2.43139 21.5343 0 18.5048 0Z"
            />
        </svg>
    );
}

export default UserOrderIcon;