import React from 'react';

const CouponIcon = () => {
    return (
        <svg
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.6875 18.6875C31.6875 20.93 33.5075 22.75 35.75 22.75V24.375C35.75 30.875 34.125 32.5 27.625 32.5H11.375C4.875 32.5 3.25 30.875 3.25 24.375V23.5625C5.4925 23.5625 7.3125 21.7425 7.3125 19.5C7.3125 17.2575 5.4925 15.4375 3.25 15.4375V14.625C3.25 8.125 4.875 6.5 11.375 6.5H27.625C34.125 6.5 35.75 8.125 35.75 14.625C33.5075 14.625 31.6875 16.445 31.6875 18.6875Z"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.625 15.0313L24.375 24.7812"
                stroke="white"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.3661 15.0312H24.3807"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6161 23.9687H14.6307"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CouponIcon;
