import React, { useState, useEffect } from 'react';
import './../../../Styles/Cart/AddAddress.css';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axiosUserInstance from '../../../axiosUserInstance';
import CloseIcon from '../../../assets/svg/closeIcon';

function AddNewAddress({ handleClose, getAddress, editAddress }) {
    const [deliveryName, setDeliveryName] = useState('');
    const [streetName, setStreetName] = useState('');
    const [areaCityState, setAreaCityState] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        if (editAddress && Object.keys(editAddress).length > 0) {
            console.log('Edit Address Data:', editAddress.Delivery_name
            ); // Debugging
            setDeliveryName(editAddress.Delivery_name || '');
            setStreetName(editAddress.Address_line_1 || '');
            setAreaCityState(editAddress.City || '');
            setState(editAddress.State || '');
            setZipcode(editAddress.ZIP || '');
        }
    }, [editAddress]); // Depend on `editaddress`
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
    
        if (!deliveryName.trim()) newErrors.deliveryName = 'Name is required';
        if (!streetName.trim()) newErrors.streetName = 'Street is required';
        if (!areaCityState.trim()) newErrors.areaCityState = 'City is required';
        if (!state.trim()) newErrors.state = 'State is required';
        if (!zipcode.trim()) newErrors.zipcode = 'ZIP code is required';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        const addressData = {
            Delivery_name: deliveryName,
            Address_line_1: streetName,
            Address_line_2: '',
            City: areaCityState,
            State: state,
            ZIP: zipcode,
            Customer_id: 3, // Change to dynamic if needed
        };
    
        try {
            if (editAddress && editAddress.ID) {
                await axiosUserInstance.put(`address/account/${editAddress.ID}`, addressData);
                toast.success('Address updated successfully');
            } else {
                await axiosUserInstance.post('address/account/account_id', addressData);
                toast.success('Address added successfully');
            }
    
            await getAddress(); // Ensure the list updates first
            handleClose(); // Close the modal after updating
        } catch (error) {
            console.error('Error updating/adding address:', error);
            toast.error('Failed to save address. Please try again.');
        }
    };
    
    
    

    return (
        <div>
            <div className="title-cross-svg-container">
                <h1 className="add-new-address-title">{t('cart.add-new-address-title')}</h1>
                <CloseIcon onClick={handleClose} className="cross-icon-location" />
            </div>

            <form onSubmit={handleSubmit}>
                <div className="delivery-name-container">
                    <label className="name-label">{t('cart.delivery-name-title')}</label>
                    <input
                        className="add-address-input"
                        value={deliveryName}
                        onChange={(e) => setDeliveryName(e.target.value)}
                    />
                    {errors.deliveryName && <p className="error-message">{errors.deliveryName}</p>}
                </div>

                <div className="delivery-name-container">
                    <label className="name-label">{t('cart.street-title')}</label>
                    <input
                        className="add-address-input"
                        value={streetName}
                        onChange={(e) => setStreetName(e.target.value)}
                    />
                    {errors.streetName && <p className="error-message">{errors.streetName}</p>}
                </div>

                <div className="delivery-name-container">
                    <label className="name-label">{t('cart.area-and-city-and-state')}</label>
                    <input
                        className="add-address-input"
                        value={areaCityState}
                        onChange={(e) => setAreaCityState(e.target.value)}
                    />
                    {errors.areaCityState && <p className="error-message">{errors.areaCityState}</p>}
                </div>

                <div className="delivery-name-container">
                    <label className="name-label">State</label>
                    <input
                        className="add-address-input"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                    {errors.state && <p className="error-message">{errors.state}</p>}
                </div>

                <div className="delivery-name-container">
                    <label className="name-label">{t('cart.zip-code-title')}</label>
                    <input
                        className="add-address-input"
                        value={zipcode}
                        onChange={(e) => setZipcode(e.target.value)}
                    />
                    {errors.zipcode && <p className="error-message">{errors.zipcode}</p>}
                </div>

                <button type="submit" className="add-address-btn">
                    {editAddress ? 'Submit' : t('cart.add-address-title')}
                </button>

            </form>
        </div>
    );
}

export default AddNewAddress;
