import React from 'react';

function VendorNotificationIcon({notificationcount, onClick,className}) {
    const count = notificationcount || 0;
    return (
        <>
            <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className= {className}
                onClick={onClick}
            >
                <path
                    d="M15.8316 37.9986C12.776 37.9986 10.2897 35.513 10.2897 32.4567C10.2897 32.0196 10.6445 31.6648 11.0816 31.6648C11.5188 31.6648 11.8735 32.0196 11.8735 32.4567C11.8735 34.6387 13.6503 36.4148 15.8316 36.4148C18.0129 36.4148 19.7897 34.638 19.7897 32.4567C19.7897 32.0196 20.1444 31.6648 20.5816 31.6648C21.0187 31.6648 21.3735 32.0196 21.3735 32.4567C21.3735 35.5123 18.8879 37.9986 15.8316 37.9986Z"
                    fill="white"
                />
                <path
                    d="M29.2917 33.2519H2.37498C1.06577 33.2519 0 32.1861 0 30.8769C0 30.1815 0.302068 29.5246 0.831244 29.0719C0.863158 29.0452 0.89804 29.0199 0.932923 28.9992C3.35986 26.8921 4.74996 23.8551 4.74996 20.6489V15.8358C4.74996 10.6235 8.45048 6.06203 13.55 4.9918C13.9708 4.90497 14.3968 5.17512 14.4874 5.60484C14.5779 6.03234 14.3041 6.45167 13.8744 6.54222C9.50438 7.45881 6.33304 11.3679 6.33304 15.8366V20.6496C6.33304 24.3576 4.7084 27.8681 1.87475 30.2779C1.851 30.2987 1.82725 30.3158 1.80202 30.3336C1.661 30.4806 1.58382 30.6758 1.58382 30.8784C1.58382 31.3155 1.93858 31.6703 2.37572 31.6703H29.2924C29.7296 31.6703 30.0843 31.3155 30.0843 30.8784C30.0843 30.6743 30.0049 30.4791 29.8661 30.3321C29.8424 30.3165 29.8186 30.2987 29.7964 30.2802C28.3209 29.037 27.1668 27.4807 26.3668 25.6534C26.1909 25.2526 26.3734 24.7858 26.7735 24.6099C27.1772 24.437 27.6411 24.6181 27.817 25.0166C28.5057 26.5856 29.4876 27.9252 30.7382 28.9999C30.7731 29.0207 30.8065 29.0444 30.8362 29.0712C31.3654 29.5254 31.6674 30.1829 31.6674 30.8776C31.6674 32.1868 30.6016 33.2526 29.2924 33.2526L29.2917 33.2519Z"
                    fill="white"
                />
                <path
                    d="M27.7066 20.5829C22.0319 20.5829 17.4148 15.9658 17.4148 10.2911C17.4148 4.61637 22.0319 0 27.7066 0C33.3814 0 37.9985 4.61711 37.9985 10.2918C37.9985 15.9666 33.3814 20.5837 27.7066 20.5837V20.5829Z"
                    fill="#E13535"
                />
                
                <text
                    x="27.5"
                    y="11.5"
                    textAnchor="middle"
                    alignmentBaseline="middle"
                    fill="white"
                    fontSize="15"
                    fontWeight="bold"
                >
                    {count}
                </text>
                
            </svg>
        </>
    );
}

export default VendorNotificationIcon;