import React from 'react';
import '../../../Styles/Cart/DeliveryInstruction.css';
import { useTranslation } from 'react-i18next';
import GreenCrossIcon from '../../../assets/svg/GreenCrossIcon';
import CloseIcon from '../../../assets/svg/closeIcon';

function DeliveryInstruction({
    handleClose,
    selectedInstructions,
    handleInstructionClick,
}) {
    const { t } = useTranslation();

    const handleSubmit = () => {
        if (selectedInstructions.length > 0) {
            handleClose();
        }
    };

    return (
        <>
            <div className="cook-inst-main-container">
                <div className="title-cross-container">
                    <h1 className="title-cooking-inst">
                        {t('cart.add-delivery-nstructions')}
                    </h1>
                    <CloseIcon
                        onClick={() => handleClose()}
                        className="cross-icon-location"
                    />
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Avoid Ringing Bell')}
                    style={{
                        border: selectedInstructions.includes('Avoid Ringing Bell')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p
                        className="instructions-text"
                        style={{
                            color: selectedInstructions.includes('Avoid Ringing Bell')
                                ? '#444444'
                                : '#94A3B8',
                        }}
                    >
                        {t('cart.avoid-ringing-bell')}
                    </p>
                    {selectedInstructions.includes('Avoid Ringing Bell') && (
                        <GreenCrossIcon />
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Leave at the Gate')}
                    style={{
                        border: selectedInstructions.includes('Leave at the Gate')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p
                        className="instructions-text"
                        style={{
                            color: selectedInstructions.includes('Leave at the Gate')
                                ? '#444444'
                                : '#94A3B8',
                        }}
                    >
                        {t('cart.leave-at-the-gate')}
                    </p>
                    {selectedInstructions.includes('Leave at the Gate') && (
                        <GreenCrossIcon />
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Leave at security')}
                    style={{
                        border: selectedInstructions.includes('Leave at security')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p
                        className="instructions-text"
                        style={{
                            color: selectedInstructions.includes('Leave at security')
                                ? '#444444'
                                : '#94A3B8',
                        }}
                    >
                        {t('cart.leave-at-security')}
                    </p>
                    {selectedInstructions.includes('Leave at security') && (
                        <GreenCrossIcon />
                    )}
                </div>

                <div
                    className="instruction-container"
                    onClick={() => handleInstructionClick('Do not call')}
                    style={{
                        border: selectedInstructions.includes('Do not call')
                            ? '2px solid #02A750'
                            : '2px solid #E2E8F0',
                    }}
                >
                    <p
                        className="instructions-text"
                        style={{
                            color: selectedInstructions.includes('Do not call')
                                ? '#444444'
                                : '#94A3B8',
                        }}
                    >
                        {t('cart.do-not-call')}
                    </p>
                    {selectedInstructions.includes('Do not call') && <GreenCrossIcon />}
                </div>

                <button
                    className="submit-btn-cooking-inst"
                    onClick={() => handleSubmit()}
                    disabled={selectedInstructions.length === 0}
                    style={{
                        cursor:
              selectedInstructions.length === 0 ? 'not-allowed' : 'pointer',
                        opacity: selectedInstructions.length === 0 ? 0.7 : 1,
                    }}
                >
                    {t('cart.submit-button')}
                </button>
            </div>
        </>
    );
}

export default DeliveryInstruction;
