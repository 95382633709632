import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal';
import '../../VendorStyles/AddMenuType.css';
import CloseIcon from '../../assets/svg/closeIcon';

function AddMenuType({
    onClose,
    open,
    onAddMenuType,
    isEditingMenu,
    newMenuType,
    setNewMenuType,
    menuId,
}) {
    console.log(isEditingMenu);

    const [menuNameError, setMenuNameError] = useState('');

    const handleMenuNameChange = (e) => {
        const value = e.target.value;
        setNewMenuType(value);

        if (!value.trim()) {
            setMenuNameError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{4,}$/.test(value)) {
            setMenuNameError('Menu name must be 4 characters.');
        } else {
            setMenuNameError('');
        }
    };

    const handleAdd = () => {
        if (!newMenuType) {
            setMenuNameError('Field is required.');
            toast.error('Please enter menu name');
            return;
        }

        onAddMenuType(newMenuType, menuId);
        console.log(newMenuType);
        onClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="menu-Model-container">
                    <div className="add-edit-menu-close-icon">
                        <CloseIcon
                            className={'menu-add-menu-popup-cross-icon'} 
                            onClick={onClose} 
                            color='#e13535'
                            height='25'
                            width='25'
                        />
                    </div>

                    <div className="add-menu-popup">
                        <div className="add-menu-name-input">
                            <p className="add-menu-field-txt">Name</p>
                            <input
                                value={newMenuType}
                                onChange={handleMenuNameChange}
                                maxLength={30}
                            />
                        </div>
                        {menuNameError && (
                            <p style={{ fontSize: '12px', color: 'red' }}>{menuNameError}</p>
                        )}

                        <Button className="add-menu-popup-next-btn" onClick={handleAdd}>
                            {isEditingMenu ? 'Upate Menu' : 'Add'}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default AddMenuType;
