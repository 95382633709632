import { createContext, useContext, useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode';

// Create context
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
    const [userID, setUserID] = useState(null);

    const loadUserFromToken = () => {
        const token = localStorage.getItem('authToken');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setUserID(decodedToken?.id);
            } catch (error) {
                console.error('Invalid token:', error);
                setUserID(null);
            }
        } else {
            setUserID(null);
        }
    };

    useEffect(() => {
        loadUserFromToken();
    }, []);

    const login = (token) => {
        localStorage.setItem('authToken', token);
        loadUserFromToken(); // Update user info immediately
    };
    
    // Function to handle logout (clear token & reset userID)
    const logout = () => {
        localStorage.removeItem('authToken');
        setUserID(null);
    };
    

    return (
        <AuthContext.Provider value={{ userID, setUserID , login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the context
export const useAuth = () => useContext(AuthContext);
