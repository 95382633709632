import React from 'react';

// import { useSelector } from 'react-redux';
import Vendorrouter from './Vendorrouter';
import Userrouter from './Userrouter';
import Deliveryrouter from './Deliveryrouter';
import { useCart } from './CartProvider';
import { jwtDecode } from 'jwt-decode';



function MainNavigation() {
     
    

    // const selectedRole = useSelector((state) =>{

    //     return state.ItemReducer.userRole; 
    // });
    // // console.log(selectedRole);

    // const role = selectedRole;
    let { role } = useCart();
    
    if (!role) {
        const token = localStorage.getItem('authToken');
        
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                role = decodedToken?.role;
            } catch (error) {
                console.error('Invalid token:', error);
                // localStorage.removeItem('authToken'); 
            }
        }
    }
    return (
        <div>

            

         


            {/* {
                role === 'restaurantOwner' ? (
                     
                    <Vendorrouter></Vendorrouter>
                        
                ):(
                    <Userrouter></Userrouter>
                )
            } */}


            {
                role === 'vendor' ? (
                    <Vendorrouter />
                ) : role === 'delivery_partner' ? (
                    <Deliveryrouter/>
                ) : (
                    <Userrouter/>
                )
            }  

           
          
        </div>
    );
}

export default MainNavigation;
