import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../../VendorStyles/VendorSideMenuBar.css';
import '../../../Styles/Colors.css';
import UserHome from '../../../assets/svg/userHomeIcon';
import MenuIconSvg from '../../../assets/svg/menuIcon';
import UserOrderIcon from '../../../assets/svg/userOrderIcon';
import CoupenIconSvg from '../../../assets/coupenIcon';
import SettingIconSvg from '../../../assets/svg/settingIcon';
import VendorLogoutIconSvg from '../../../assets/svg/vendorLogoutIcon';
import menuIcon from '../../../assets/svg/menuIcon.svg';
function SideMenuBar() {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const mobileMenuRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        window.location.href = '/login';
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
                setIsMobileMenuOpen(false);
            }
        };
    
        if (isMobileMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMobileMenuOpen]);
    
    return (
        <>
            <div className="vendor-menu-container">
                <div className="vendor-sidebar-scrollable">
                    <ul className="vendor-menu-items">
                        <li>
                            <NavLink className="vendor-list-container" to="/Home?tab=0">
                                <div className="vendor-sidebar-menu">
                                    <UserHome 
                                        height={25}
                                        width={22}
                                    />
                                </div>
                                <h3 className="vendor-list-text">Home</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container" to="/Menu">
                                <div className="vendor-sidebar-menu">
                                    <MenuIconSvg 
                                        height={40}
                                        width={22}
                                    />
                                </div>
                                <h3 className="vendor-list-text">Menu</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container" to="/Orders">
                                <div className="vendor-sidebar-menu">
                                    <UserOrderIcon
                                        height={40}
                                        width={22}    
                                    />
                                </div>
                                <h3 className="vendor-list-text">Orders</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container" to="/VendorCouponsOffers">
                                <div className="vendor-sidebar-menu">
                                    <CoupenIconSvg 
                                        height={40}
                                        width={30}
                                    />
                                </div>
                                <h3 className="vendor-list-text">Coupons</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container" to="/Vendorsettings">
                                <div className="vendor-sidebar-menu">
                                    <SettingIconSvg 
                                        height={40}
                                        width={22}
                                    />
                                </div>
                                <h3 className="vendor-list-text">Settings</h3>
                            </NavLink>
                        </li>
                        <li>
                            <div className="vendor-list-container" onClick={handleLogout}>
                                <div className="vendor-sidebar-menu">
                                    <VendorLogoutIconSvg 
                                        height={40}
                                        width={22}
                                    />
                                </div>
                                <h3 className="vendor-list-text">Logout</h3>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="vendor-bottom-menu">
                <button className="menu-toggle-btn" onClick={toggleMobileMenu}>
                    <img className='vendor-list-container' src={menuIcon} alt="menuIcon" />
                </button>
                <NavLink className="vendor-list-container" to="/Home?tab=0">
                    <UserHome />
                    <h3 className="vendor-list-text">Home</h3>
                    <p className='icon-underline'></p>

                </NavLink>
                <NavLink className="vendor-list-container" to="/Menu">
                    <MenuIconSvg />
                    <h3 className="vendor-list-text">Menu</h3>
                    <p className='icon-underline'></p>
  
                </NavLink>
                <NavLink className="vendor-list-container" to="/Orders">
                    <UserOrderIcon />
                    <h3 className="vendor-list-text">Orders</h3>
                    <p className='icon-underline'></p>
                </NavLink>
            </div>

            {isMobile && (
                <div ref={mobileMenuRef} className={`vendor-mobile-sidebar ${isMobileMenuOpen ? 'open' : ''}`}>
                    <button className="close-btn" onClick={toggleMobileMenu}>✖</button>
                    <ul className="vendor-menu-items-mobile">
                        <li>
                            <NavLink className="vendor-list-container-mobile" to="/Home?tab=0">
                                <UserHome />
                                <h3 className="vendor-list-text-mobile">Home</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container-mobile" to="/Menu">
                                <MenuIconSvg />
                                <h3 className="vendor-list-text-mobile">Menu</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container-mobile" to="/Orders">
                                <UserOrderIcon />
                                <h3 className="vendor-list-text-mobile">Orders</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container-mobile" to="/VendorCouponsOffers">
                                <CoupenIconSvg />
                                <h3 className="vendor-list-text-mobile">Coupons</h3>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink className="vendor-list-container-mobile" to="/Vendorsettings">
                                <SettingIconSvg />
                                <h3 className="vendor-list-text-mobile">Settings</h3>
                            </NavLink>
                        </li>
                        <li>
                            <div className="vendor-list-container-mobile" onClick={handleLogout}>
                                <VendorLogoutIconSvg />
                                <h3 className="vendor-list-text-mobile">Logout</h3>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default SideMenuBar;
