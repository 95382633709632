import React, { useState, useRef, useEffect } from 'react';
import '../../VendorStyles/Menu.css';
import DeleteItem from './DeleteItem';
import '../../VendorStyles/EditPopup.css';
import UpdateItem from './UpdateItem';
import AddMenuType from './AddMenuType';
import { toast } from 'react-toastify';
import axiosVendorInstance from '../../axiosVendorInstance';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MenuItemsDetailsModal from './MenuItemsDetailsModal';
import greenDot from '../../assets/greendot.svg';
import redDot from '../../assets/reddot.svg';
import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
import DeleteMenu from './DeleteMenu';
import Loader from '../../Components/Loader/Loader';
import EditPencilIconSvg from '../../assets/svg/editPencilIcon';
import DeleteIconSvg from '../../assets/svg/deleteIcon';
import PlusIconSvg from '../../assets/svg/plusIcon';
import VendorImageMenuPlaceholderSvg from '../../assets/svg/vendorImageMenuPlaceholder';
import { useNavigate, useLocation } from 'react-router-dom';
import LeftArrow from '../../assets/backIcon-white.png';

function Menu() {
    const navigate = useNavigate();
    const location = useLocation();
    const isUpdateMode = location.pathname.includes('/menu/updateItem');
    const [vendorID, setVendorID] = useState(null);
    const [menuID, setMenuID] = useState();
    console.log(menuID);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [newMenuType, setNewMenuType] = useState('');
    const [isEditingMenu, setIsEditingMenu] = useState(false);
    const [isAddingItem, setIsAddingItem] = useState(false);
    const [uploadedFile, setUploadedFile] = useState('');
    const [previewFile, setPreviewFile] = useState(null);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemIngredients, setItemIngredients] = useState('');
    const [menuType, setMenuType] = useState();
    const [selectedMenuType, setSelectedMenuType] = useState('');
    const [addMenuPopupOpen, setAddMenuPopupOpen] = useState(false);
    const [deleteMenuPopupOpen, setDeleteMenuPopupOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [itemVegetarian, setItemVegetarian] = useState('');
    const [menuTypes, setMenuTypes] = useState([]);
    const [Items, setItems] = useState([]);
    const [fileType, setFileType] = useState(null);
    const [updatedImage, setUpdatedImage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);
    const [openFoodDetail, setOpenFoodDetail] = useState(false);
    const [itemNameError, setItemNameError] = useState('');
    const [itemPriceError, setItemPriceError] = useState('');
    const [menuTypeError, setMenuTypeError] = useState();
    const [itemVegetarianError, setItemVegetarianError] = useState();
    const [itemDescriptionError, setItemDescriptionError] = useState('');
    const [itemIngredientsError, setItemIngredientsError] = useState('');
    const [imageError, setImageError] = useState('');
    const [menuId, setMenuId] = useState(null);
    const [menuIdToDelete, setMenuIdToDelete] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const openDeletePopUp = (id) => {
        setShowDeletePopup(true);
        setDeleteItemId(id);
    };
    const closeDeletePopUp = () => {
        setShowDeletePopup(false);
    };
    const handleClose = () => {
        setOpenFoodDetail(false);
        setSelectedFood(null);
    };

    const fetchFoodDetails = async (itemId) => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`
            );
            setSelectedFood(response?.data?.menuItem);
            setOpenFoodDetail(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const validate = () => {
        let isValid = true;
        const nameRegex = /^[a-zA-Z0-9\s]{4,}$/;

        const priceRegex = /^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?))$/;

        if (!itemName.trim()) {
            setItemNameError('Food name is required.');
            isValid = false;
        } else if (!nameRegex.test(itemName)) {
            setItemNameError('Enter valid food name.');
            isValid = false;
        } else {
            setItemNameError('');
        }

        // Price Validation
        if (!itemPrice.trim()) {
            setItemPriceError('Field is required.');
            isValid = false;
        } else if (!priceRegex.test(itemPrice)) {
            setItemPriceError('Enter a valid price (e.g., 20 or 20.50).');
            isValid = false;
        } else {
            setItemPriceError('');
        }

        // Description Validation
        const descriptionText = itemDescription.replace(/<(.|\n)*?>/g, '').trim();
        if (!descriptionText) {
            setItemDescriptionError('Description is required.');
            isValid = false;
        } else if (/(\s{3,})/.test(descriptionText)) {
            setItemDescriptionError(
                'Description cannot have more than 2 consecutive spaces.'
            );
            isValid = false;
        } else if (/([a-zA-Z0-9]{20,})/.test(descriptionText)) {
            setItemDescriptionError(
                'Description cannot contain long continuous text without spaces.'
            );
            isValid = false;
        } else {
            setItemDescriptionError('');
        }

        // Ingredients Validation
        const ingredientsText = itemIngredients.replace(/<(.|\n)*?>/g, '').trim();
        if (!ingredientsText) {
            setItemIngredientsError('Ingredients is required.');
            isValid = false;
        } else if (/(\s{3,})/.test(ingredientsText)) {
            setItemIngredientsError(
                'Ingredients cannot have more than 2 consecutive spaces.'
            );
            isValid = false;
        } else if (/([a-zA-Z0-9]{20,})/.test(ingredientsText)) {
            setItemIngredientsError(
                'Ingredients cannot contain long continuous text without spaces.'
            );
            isValid = false;
        } else {
            setItemIngredientsError('');
        }

        if (!selectedMenuType) {
            setMenuTypeError('Please select a menuType.');
            isValid = false;
        } else {
            setMenuTypeError('');
        }

        if (!itemVegetarian) {
            setItemVegetarianError('Please select a foodType.');
            isValid = false;
        } else {
            setItemVegetarianError('');
        }

        if (!uploadedFile) {
            setImageError('Image is required.');
            isValid = false;
        }

        return isValid;
    };

    const fetchVendorID = async () => {
        try {
            const response = await axiosVendorInstance.get('/vendors/account');
            const apiResponse = response.data.getVendorInfo;

            setVendorID(apiResponse[0].ID);
        } catch (error) {
            console.error('Error fetching vendor id:', error);
        }
    };

    useEffect(() => {
        fetchVendorID();
        fetchMenuTypes();
        fetchMenuItems();
    }, [vendorID]);

    const fetchMenuTypes = async () => {
        if (vendorID !== null) {
            try {
                const response = await axiosVendorInstance.get(`/vendors/${vendorID}`, {
                    skipAuth: true,
                });
                const apiResponse = response.data;

                setMenuID(apiResponse);

                setMenuTypes(apiResponse);
            } catch (error) {
                console.error('Error fetching menu types:', error);
            }
        }
    };

    const fetchMenuItems = async () => {
        if (vendorID !== null) {
            try {
                const response = await axiosVendorInstance.get(
                    `/vendors/menuitems/${vendorID}`,
                    { skipAuth: true }
                );
                const { menuItems } = response.data;

                const groupedItems = {};
                menuItems.forEach((item) => {
                    const menuId = item.vendor_menu.ID;
                    if (!groupedItems[menuId]) {
                        groupedItems[menuId] = [];
                    }
                    groupedItems[menuId].push(item);
                });

                setItems(groupedItems);
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        }
    };

    const ToggleButton = ({ status, onToggle }) => {
        const [isOn, setIsOn] = useState(status === 'active');

        useEffect(() => {
            setIsOn(status === 'active');
        }, [status]);

        const handleToggle = () => {
            const newStatus = !isOn;
            setIsOn(newStatus);
            onToggle(newStatus ? 'active' : 'deactive');
        };

        return (
            <div
                className={`menu-toggle-switch ${isOn ? 'on' : 'off'}`}
                onClick={handleToggle}
            >
                <div className="menu-toggle-switch-inner">
                    <div
                        className="menu-toggle-switch-circle"
                        style={{ right: isOn ? 3 : 'auto', left: isOn ? 'auto' : 3 }}
                    ></div>
                </div>
                <span className={`menu-toggle-state-text ${isOn ? 'on' : 'off'}`}>
                    {isOn ? 'on' : 'off'}
                </span>
            </div>
        );
    };

    const handleToggleChange = async (itemId, newStatus) => {
        try {
            await axiosVendorInstance.put(
                `/vendors/menuitems/status/${itemId}`,
                { Status: newStatus },
                { skipAuth: true }
            );

            setItems((prevMenuItems) => {
                const updatedMenuItems = { ...prevMenuItems };

                Object.keys(updatedMenuItems).forEach((menuId) => {
                    updatedMenuItems[menuId] = updatedMenuItems[menuId].map((item) => {
                        if (item.ID === itemId) {
                            return { ...item, Status: newStatus };
                        }
                        return item;
                    });
                });

                return updatedMenuItems;
            });

            toast.success(`Status updated to ${newStatus}`);
        } catch (error) {
            toast.error(`Error updating status: ${error.message}`);
        }
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setItemName(value);

        if (!value.trim()) {
            setItemNameError('Field is required.');
        } else if (!/^[a-zA-Z0-9\s]{4,}$/.test(value)) {
            setItemNameError('Food name must be 4 characters.');
        } else {
            setItemNameError('');
        }
    };

    const handleDescriptionChange = (value) => {
        setItemDescription(value);

        const plainText = value.replace(/<[^>]*>/g, '').trim();

        if (!plainText) {
            setItemDescriptionError('Field is required.');
        } else if (plainText.length < 10) {
            setItemDescriptionError('Description must be at least 10 characters.');
        } else if (/(\s{3})/.test(plainText)) {
            // Check for more than 2 consecutive spaces
            setItemDescriptionError('Description cannot have more than 2 spaces.');
        } else if (/([a-zA-Z0-9]{20,})/.test(plainText)) {
            // Check for long continuous alphanumeric text without spaces
            setItemDescriptionError(
                'Description cannot contain long continuous text without spaces.'
            );
        } else {
            setItemDescriptionError('');
        }
    };

    const handleItemIngredients = (value) => {
        setItemIngredients(value);

        const plainText = value.replace(/<[^>]*>/g, '').trim();

        if (!plainText) {
            setItemIngredientsError('Field is required.');
        } else if (plainText.length < 10) {
            setItemIngredientsError('Ingredients must be at least 10 characters.');
        } else if (/(\s{3})/.test(plainText)) {
            // Check for more than 2 consecutive spaces
            setItemIngredientsError('Ingredients cannot have more than 2 spaces.');
        } else if (/([a-zA-Z0-9]{20,})/.test(plainText)) {
            // Check for long continuous alphanumeric text without spaces
            setItemIngredientsError(
                'Ingredients cannot contain long continuous text without spaces.'
            );
        } else {
            setItemIngredientsError('');
        }
    };

    const handlePriceChange = (e) => {
        const value = e.target.value;
        setItemPrice(value);

        // Check if the value is empty
        if (!value.trim()) {
            setItemPriceError('Field is required.');
            // Check if the value exceeds 100
        } else if (parseFloat(value) > 100) {
            setItemPriceError('Price cannot exceed 100.');
            // Check if the value is a valid price format
        } else if (!/^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?))$/.test(value)) {
            setItemPriceError('Enter a valid price (e.g., 20 or 20.50).');
        } else {
            setItemPriceError('');
        }
    };

    const handleMenuTypeChange = (event) => {
        setSelectedMenuType(event.target.value);
        if (selectedMenuType) {
            setMenuTypeError('');
        }
    };
    const handleVegetarianChange = (event) => {
        setItemVegetarian(event.target.value);
        if (event.target.value) {
            setItemVegetarianError('');
        }
    };
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        setImageError('');
        setPreviewFile(null);

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();

            const validImageTypes = [
                'jpg',
                'jpeg',
                'png',
                'webp',
                'tiff',
                'gif',
                'bmp',
            ];
            const validVideoTypes = ['mp4'];
            const validHtmlTypes = ['html'];

            if (
                (validImageTypes.includes(fileExtension) &&
          file.type.startsWith('image/')) ||
        (validVideoTypes.includes(fileExtension) &&
          file.type.startsWith('video/')) ||
        (validHtmlTypes.includes(fileExtension) && file.type === 'text/html')
            ) {
                const fileSizeInMB = file.size / (1024 * 1024);
                if (fileSizeInMB > 10) {
                    alert('File size should not exceed 10MB.');
                    return;
                }

                const fileType = file.type.split('/')[0];
                setFileType(fileType);
                setUploadedFile(file);

                const previewUrl = URL.createObjectURL(file);
                setPreviewFile(previewUrl);
            } else {
                alert('upload valid Images or videos.');
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 601);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddItemClick = () => {
        setIsAddingItem(true);
    };

    const handleAddItem = async () => {
        if (validate()) {
            if (isLoading) return;
            setIsLoading(true);

            const itemNameCheckUrl = `/vendors/menuitems/api/menu/${selectedMenuType}/items/check/${itemName}/vendor/${vendorID}`;

            try {
                const checkResponse = await axiosVendorInstance.get(itemNameCheckUrl, {
                    skipAuth: true,
                });
                if (checkResponse.data.exists) {
                    toast.error(
                        'Item name already exists. Please choose a different name.'
                    );
                    return;
                }
                const cleanPrice = parseFloat(itemPrice).toString();

                const formData = new FormData();
                formData.append('Avatar_key', uploadedFile);
                formData.append('Item_name', itemName);
                formData.append('Created_by', 1);
                formData.append('Menu_id', selectedMenuType);
                formData.append('Vendor_ID', vendorID);
                formData.append('Price', cleanPrice);
                formData.append('Description', itemDescription);
                formData.append('Ingridents', itemIngredients);
                formData.append('Vegetarian', itemVegetarian);
                formData.append('Status', 'active');

                try {
                    await axiosVendorInstance.post(
                        `/vendors/menuitems/menu/${selectedMenuType}`,
                        formData,
                        { skipAuth: true }
                    );

                    toast.success('Item Added Successfully');
                    fetchMenuTypes();
                    fetchMenuItems();
                } catch (error) {
                    toast.error('Error adding item');
                } finally {
                    setIsLoading(false);
                    setIsAddingItem(false);

                    setUploadedFile('');
                    setItemName('');
                    setItemDescription('');
                    setItemIngredients('');
                    setItemPrice('');
                    setMenuType('');
                    setSelectedMenuType('');
                    setItemVegetarian('');
                    fetchMenuTypes();
                    fetchMenuItems();
                    setPreviewFile(null);
                }
            } catch (error) {
                toast.error('Error checking item name.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleEditClick = (menu, id) => {
        setNewMenuType(menu);
        setMenuId(id);

        setIsEditingMenu(true);
        setAddMenuPopupOpen(true);
    };

    const resetForm = () => {
        setNewMenuType('');
    };

    const handleDeleteMenuClick = (id) => {
        setMenuIdToDelete(id);
        setDeleteMenuPopupOpen(true);
    };

    const openEditPopup = (item) => {
        setSelectedItem(item);
        setSelectedOption('Update Item');
        navigate(`/menu/updateItem/${item}`);
    };

    const handleBack = () => {
        navigate('/menu');
    };

    const handleAddMenuClick = () => {
        resetForm();
        setAddMenuPopupOpen(true);
        setIsEditingMenu(false);
    };
    const closeAddMenuPopup = () => {
        setAddMenuPopupOpen(false);
    };

    const closeDeleteMenuPopup = () => {
        setDeleteMenuPopupOpen(false);
    };
    const handleSave = async (updatedItem) => {
        setIsLoading(true);

        console.log(updatedItem);

        const formData = new FormData();
        formData.append('Updated_at', new Date().toISOString());
        formData.append('Updated_by', 1);

        if (updatedImage) {
            formData.append('Avatar_key', updatedImage);
        }

        formData.append('Item_name', updatedItem.name);
        formData.append('Offer_percentage', 10);
        formData.append('Menu_id', updatedItem.menuType);
        formData.append('Vendor_ID', updatedItem.Vendor_ID);
        formData.append('Vegetarian', updatedItem.vegetarian);
        formData.append('Price', Number(updatedItem.price));
        formData.append('Description', updatedItem.description);
        formData.append('Ingridents', updatedItem.Ingredients);

        try {
            await axiosVendorInstance.put(
                `/vendors/menuitems/${updatedItem.id}`,
                formData
            );

            toast.success('Item Updated Successfully');

            fetchMenuTypes();
            fetchMenuItems();
        } catch (error) {
            toast.error(`Error updating item: ${error.message}`);
        } finally {
            setIsLoading(false);
            setIsAddingItem(false);
            setSelectedOption('');
        }

        fetchMenuTypes();
        fetchMenuItems();
    };

    const handleAddMenuType = async (newMenuType, id) => {
        console.log(newMenuType);
        try {
            if (isEditingMenu) {
                const updateMenuUrl = `/vendors/menu/${id}`;
                const updateResponse = await axiosVendorInstance.put(updateMenuUrl, {
                    Menu_name: newMenuType,
                });

                if (updateResponse.status === 200) {
                    toast.success('Menu updated successfully');
                    fetchMenuTypes();
                }
            } else {
                const checkMenuUrl = `/vendors/${vendorID}/menu/check/${newMenuType.newMenuType}`;

                const checkResponse = await axiosVendorInstance.get(checkMenuUrl, {
                    skipAuth: true,
                });

                if (checkResponse.data.exists) {
                    toast.error(
                        'Menu name already exists. Please choose a different name.'
                    );
                    return;
                }

                await axiosVendorInstance.post(
                    `/vendors/${vendorID}`,
                    {
                        Menu_name: newMenuType,
                    },
                    { skipAuth: true }
                );

                toast.success('Menu added successfully');
                fetchMenuTypes();
            }
        } catch (error) {
            toast.error('Failed to add or update menu type');
        }
    };

    const deleteMenuType = async (id) => {
        try {
            const deleteMenuUrl = `/vendors/menu/${id}`;

            const response = await axiosVendorInstance.delete(deleteMenuUrl, {
                skipAuth: true,
            });

            if (response.status === 200) {
                toast.success('Menu deleted successfully');
                fetchMenuTypes();
            }
        } catch (error) {
            toast.error('Failed to delete menu');
        }
    };

    return (
        <>
            <VendorSideMenuBar />
            <VendorTopMenuBar />
            <div className="menu-main-container-vendor">
                <div className="vendor-common-container">
                    <div className="vendor-menu-dashboard">
                        <div className="menu-top-1-cont">
                            <button
                                className="menu-add-item-button"
                                onClick={() => handleAddMenuClick()}
                            >
                                <div className="menu-button-content">
                                    <PlusIconSvg />
                                    <div className="menu-add-item-vertical-line"></div>
                                    <span className="menu-add-item-text">Add Menu</span>
                                </div>
                            </button>
                            <button
                                className="menu-add-item-button"
                                onClick={() => handleAddItemClick()}
                            >
                                <div className="menu-button-content">
                                    <PlusIconSvg />
                                    <div className="menu-add-item-vertical-line"></div>
                                    <span className="menu-add-item-text">Add Item</span>
                                </div>
                            </button>
                        </div>

                        {!isAddingItem ? (
                            selectedOption === 'Update Item' && isUpdateMode ? (
                                <UpdateItem
                                    itemId={selectedItem}
                                    handleFileChange={handleFileChange}
                                    handleSave={handleSave}
                                    menuTypes={menuTypes}
                                    previewFile={previewFile}
                                    setPreviewFile={setPreviewFile}
                                    fileType={fileType}
                                    setFileType={setFileType}
                                    uploadedFile={uploadedFile}
                                    setUploadedFile={setUploadedFile}
                                    setUpdatedImage={setUpdatedImage}
                                    isLoading={isLoading}
                                    menuTypesForEdit={menuType}
                                    handleBack={handleBack}
                                />
                            ) : menuTypes.length === 0 ? (
                                <Loader />
                            ) : (
                                menuTypes.map((menu, index) => (
                                    <div key={menu.ID} className="menu-bottom-container">
                                        <div
                                            className="menu-name-and-edit-delete-icons"
                                            style={{
                                                marginTop: index === 0 ? (isMobile ? '55px' : '75px') : '0',
                                            }}
                                        >
                                            <h2 className="menu-name-category">{menu.Menu_name}</h2>
                                            <div className="menu-edit-and-delete-icons-container">
                                                <EditPencilIconSvg
                                                    width={18}
                                                    height={30}
                                                    onClick={() => handleEditClick(menu.Menu_name, menu.ID)}
                                                    clipPathId="clip0_5217_5102"
                                                />
                                                <DeleteIconSvg
                                                    onClick={() => handleDeleteMenuClick(menu.ID)}
                                                />
                                            </div>
                                        </div>

                                        {Items[menu.ID]?.length > 0 ? (
                                            <ul className="menu-starter-container-vendor">
                                                {Items[menu.ID].map((item) => {
                                                    const isVideo = /\.(mp4|webm|ogg)$/i.test(
                                                        item.Avatar_key
                                                    );

                                                    const cleanPrice = parseFloat(item.Price).toString();

                                                    return (
                                                        <div key={item.ID} className="menu-item">
                                                            {isVideo ? (
                                                                <div className="menu-starters-image-vendor">
                                                                    <video controls src={item.Avatar_key} />
                                                                </div>
                                                            ) : (
                                                                <div className="menu-starters-image-vendor">
                                                                    <img src={item.Avatar_key} alt={item.Item_name} />
                                                                </div>
                                                            )}
                                                            <div className="menu-description-container-vendor">
                                                                <div className="vendor-menu-name-description">
                                                                    <div className="svg-veg-non-veg-and-name-container">
                                                                        {item.Vegetarian === false && (
                                                                            <img src={redDot} alt="red dot" />
                                                                        )}

                                                                        {item.Vegetarian === true && (
                                                                            <img src={greenDot} alt="green dot" />
                                                                        )}
                                                                        <h3 className="menu-item-head-text">
                                                                            {item.Item_name}
                                                                        </h3>
                                                                    </div>

                                                                    <p className="menu-description">
                                                                        <span
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: item.Description,
                                                                            }}
                                                                        />
                                                                        <b
                                                                            className="more-btm-menuitems"
                                                                            onClick={() => fetchFoodDetails(item.ID)}
                                                                        >
                                  More...
                                                                        </b>
                                                                    </p>
                                                                </div>

                                                                <div className="menu-item-bottom-container">
                                                                    <h6 className="menu-item-price-text">
                                ${cleanPrice}
                                                                    </h6>
                                                                    <div className="menu-edit-and-toggle">
                                                                        <button
                                                                            className="menu-edit-button"
                                                                            onClick={() => openEditPopup(item.ID)}
                                                                        >
                                                                            <div className="menu-button-content">
                                                                                <EditPencilIconSvg
                                                                                    width={16}
                                                                                    height={16}
                                                                                    clipPathId="clip0_88_1492"
                                                                                />
                                                                            </div>
                                                                        </button>

                                                                        <button
                                                                            className="menu-edit-button"
                                                                            onClick={() => openDeletePopUp(item.ID)}
                                                                        >
                                                                            <div className="menu-button-content">
                                                                                <DeleteIconSvg />
                                                                            </div>
                                                                        </button>

                                                                        <ToggleButton
                                                                            status={item.Status}
                                                                            onToggle={(newStatus) =>
                                                                                handleToggleChange(item.ID, newStatus)
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <p className="no-items-message">No items in this menu</p>
                                        )}
                                    </div>
                                ))
                            )
                        ) : (
                            <div className="update-menu-items-main-container">
                                <div
                                    className="back-btn-menuItems"
                                    type="button"
                                    onClick={() => setIsAddingItem((prev) => !prev)}
                                >
                                    <div className="back-arrow-image-vendor">
                                        <img src={LeftArrow} alt="" />
                                    </div>
                                </div>
                                <div className="menu-new-div">
                                    <div className="menu-image-container">
                                        <div className="edit-image-or-video-svg-and-btn">
                                            <VendorImageMenuPlaceholderSvg
                                                onClick={() => fileInputRef.current.click()}
                                            />
                                            <h5 className="menu-add-item-update-photo-heading">
                    Click to add Photo
                                            </h5>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                accept="image/*,video/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>

                                        <div className="image-or-video-preview-menuitems">
                                            {previewFile && fileType === 'image' && (
                                                <img
                                                    src={previewFile}
                                                    alt="Selected Preview"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            )}
                                            {previewFile && fileType === 'video' && (
                                                <video width="100%" height="100%" controls>
                                                    <source src={previewFile} type="video/mp4" />
                      Your browser does not support the video tag.
                                                </video>
                                            )}
                                        </div>

                                        {imageError && (
                                            <p
                                                style={{
                                                    fontSize: '16px',
                                                    color: 'red',
                                                    marginTop: '30px',
                                                    backgroundColor: 'white',
                                                    marginBottom: '20px',
                                                    padding: '10px',
                                                    borderRadius: '6px',
                                                }}
                                            >
                                                {imageError}
                                            </p>
                                        )}
                                    </div>
                                    <div className="menu-additem-rightside-content">
                                        <div className="menu-additem-rightside-content-1">
                                            <div className="menu-additem-food-name-container">
                                                <div className="add-menu-name-input">
                                                    <p className="menu-additem-food-name-text">
                        Food Name <span className="asterisk">*</span>
                                                    </p>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter Food Name"
                                                        className="menu-additem-food-name-inputbox"
                                                        value={itemName}
                                                        onChange={handleNameChange}
                                                        maxLength={30}
                                                        required
                                                    ></input>
                                                </div>
                                                {itemNameError && (
                                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                                        {itemNameError}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="menu-additem-food-name-container">
                                                <div className="add-menu-name-input">
                                                    <p className="menu-additem-food-name-text">
                        Menu Type <span className="asterisk">*</span>
                                                    </p>
                                                    <select
                                                        value={selectedMenuType}
                                                        onChange={handleMenuTypeChange}
                                                        className="menu-additem-menu-type-inputbox"
                                                    >
                                                        <option value="">select menu</option>
                                                        {menuTypes.map((menu) => (
                                                            <option key={menu.ID} value={menu.ID}>
                                                                {menu.Menu_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {menuTypeError && (
                                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                                        {menuTypeError}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="menu-additem-rightside-content-1">
                                            <div className="menu-additem-food-name-container">
                                                <div className="add-menu-name-input">
                                                    <p className="menu-additem-food-name-text">
                        Price <span className="asterisk">*</span>
                                                    </p>
                                                    <input
                                                        placeholder="$ 20"
                                                        value={itemPrice}
                                                        className="menu-additem-food-name-inputbox"
                                                        onChange={handlePriceChange}
                                                        maxLength={3}
                                                        required
                                                    ></input>
                                                </div>
                                                {itemPriceError && (
                                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                                        {itemPriceError}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="menu-additem-food-name-container ">
                                                <div className="add-menu-name-input">
                                                    <p className="menu-additem-food-name-text">
                        Food Type <span className="asterisk">*</span>
                                                    </p>
                                                    <select
                                                        value={itemVegetarian}
                                                        onChange={handleVegetarianChange}
                                                        className="menu-additem-menu-type-inputbox"
                                                    >
                                                        <option value="">select</option>
                                                        <option value="false">Non-Veg</option>
                                                        <option value="true">Veg</option>
                                                    </select>
                                                </div>
                                                {itemVegetarianError && (
                                                    <p style={{ fontSize: '12px', color: 'red' }}>
                                                        {itemVegetarianError}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="react-quill-description-ingrediants">
                                            <div className="add-menu-name-input">
                                                <p className="menu-additem-food-name-text">
                      Description <span className="asterisk">*</span>
                                                </p>
                                                <ReactQuill
                                                    theme="snow"
                                                    value={itemDescription}
                                                    onChange={handleDescriptionChange}
                                                    placeholder="Enter Description"
                                                    required
                                                />
                                            </div>
                                            {itemDescriptionError && (
                                                <p style={{ fontSize: '12px', color: 'red' }}>
                                                    {itemDescriptionError}
                                                </p>
                                            )}
                                        </div>
                                        <div className="react-quill-description-ingrediants">
                                            <div className="add-menu-name-input">
                                                <p className="menu-additem-food-name-ingridents">
                      Ingredients <span className="asterisk">*</span>
                                                </p>

                                                <ReactQuill
                                                    theme="snow"
                                                    value={itemIngredients}
                                                    onChange={handleItemIngredients}
                                                    placeholder="Enter Ingridents"
                                                    required
                                                />
                                            </div>
                                            {itemIngredientsError && (
                                                <p style={{ fontSize: '12px', color: 'red' }}>
                                                    {itemIngredientsError}
                                                </p>
                                            )}
                                        </div>
                                        <button className="menu-additem-btn" onClick={handleAddItem}>
                                            {' '}
                                            {isLoading ? 'Adding...' : 'Add Items'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {addMenuPopupOpen && (
                            <AddMenuType
                                open={addMenuPopupOpen}
                                onClose={closeAddMenuPopup}
                                onAddMenuType={handleAddMenuType}
                                isEditingMenu={isEditingMenu}
                                newMenuType={newMenuType}
                                setNewMenuType={setNewMenuType}
                                menuId={menuId}
                            />
                        )}

                        {selectedFood && (
                            <MenuItemsDetailsModal
                                open={openFoodDetail}
                                handleClose={handleClose}
                                food={selectedFood}
                                orderBtn={false}
                            />
                        )}

                        {showDeletePopup && (
                            <DeleteItem
                                onClose={closeDeletePopUp}
                                deleteID={deleteItemId}
                                fetchMenuTypes={fetchMenuTypes}
                                fetchMenuItems={fetchMenuItems}
                            />
                        )}

                        {deleteMenuPopupOpen && (
                            <DeleteMenu
                                open={deleteMenuPopupOpen}
                                onClose={closeDeleteMenuPopup}
                                newMenuType={newMenuType}
                                setNewMenuType={setNewMenuType}
                                menuId={menuIdToDelete}
                                deleteMenuType={deleteMenuType}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Menu;
