import React, { useState, useEffect } from 'react';
import '../../VendorStyles/Delivered.css';
import axiosVendorInstance from '../../axiosVendorInstance';


import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Loader from '../../Components/Loader/Loader';

function Delivered() {



    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

  

    const [showPopup, setShowPopup] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const [orders, setOrders] = useState([]);
    const pageSize = 10;

  

    const [vendorID, setVendorID] = useState();
    

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const [items,setItems] = useState([]);

    const fetchVendorID = async () => {
        try {
            setLoading(true);
            const vendorResponse = await axiosVendorInstance.get('/vendors/account');
            const fetchedVendorID = vendorResponse?.data?.getVendorInfo[0]?.ID;
    
            if (fetchedVendorID) {
                setVendorID(fetchedVendorID);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching vendor ID:', error);
            setLoading(false);
        }
    };
    

    const fetchNewOrders = async () => {
        if (!vendorID) return;
        setLoading(true);
        try {
            const response = await axiosVendorInstance.get(
                `/Order/vendor/${vendorID}?pageSize=${pageSize}&pageNumber=${page}&statusId=6`,{skipAuth:true}
            );
           
            const incomingData = response.data.allOrdersDetails || [];

            if (incomingData.length === 0 && page > 1) {
                setPage(page - 1);
            } else {
                setOrders(incomingData);
                setTotalPages(response.data?.totalPages || 1);
            }

            setLoading(false);
        } catch (error) {
            console.error(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!vendorID) {
            fetchVendorID();
        }
    }, [vendorID]);

    useEffect(() => {
        if (vendorID) {
            fetchNewOrders();
        }
    }, [page, vendorID]);

    const handleOrderClick = (id) => {
        const selected = orders.filter((o) => o.ID === id);
        axiosVendorInstance
            .get(`/Order/user/orderinvoice/${id}`)
            .then((response) => {
           
                const selectedOrderDetails =  response.data.invoice[0]['0'].Invoice_Details.Order_Details;
                setItems(selectedOrderDetails);
            })
            .catch((error) => console.error('Error fetching customer data:', error));
        setSelectedOrder(selected);
        setShowPopup(true);
    };

    return (
        <div className="delivered-main">
            {loading ? (
                <Loader/>
            ) : (
                <div>
                    {orders && orders.length > 0 ? (
                        orders.map((order) => {
                            const createdAt = new Date(order.Created_at);
                            const time = createdAt.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            });
                            const date = `${createdAt.getDate()} ${createdAt.toLocaleString(
                                'en-US',
                                { month: 'short' }
                            )}`;
                            const status =
                order.Status_id === 4 ? 'Ready to Pickup' : 'See More';

                            return (
                                <div
                                    key={order.ID}
                                    className="delivered-single-order"
                                    onClick={() => handleOrderClick(order.ID)}
                                >
                                    <div className="delivered-orderid">
                                        <p className="delivered-order-details1">
                      Order ID:
                                            <span className="delivered-order-id">{order.ID}</span>
                                        </p>
                                    </div>
                                    <div className="delivered-date-and-time">
                                        <p className="delivered-order-details2">{time}</p>
                                        <p className="delivered-order-details3">{date}</p>
                                    </div>
                                    <button className="status-btn">{status}</button>{' '}
                                </div>
                            );
                        })
                    ) : (orders.length === 0 &&
                        <div className='no-data-message'>No data</div>
                    )}

                    {showPopup && (
                        <div className="delivered-popup-container">
                            <svg
                                width="26"
                                height="26"
                                viewBox="0 0 36 36"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="delivered-popup-cross-icon"
                                onClick={() => setShowPopup(false)}
                            >
                                <path
                                    d="M21.2981 18.0003L35.3158 3.98187C36.2281 3.07003 36.2281 1.59572 35.3158 0.683879C34.404 -0.22796 32.9297 -0.22796 32.0179 0.683879L17.9998 14.7023L3.98211 0.683879C3.06986 -0.22796 1.596 -0.22796 0.684185 0.683879C-0.228062 1.59572 -0.228062 3.07003 0.684185 3.98187L14.7019 18.0003L0.684185 32.0187C-0.228062 32.9305 -0.228062 34.4048 0.684185 35.3167C1.1386 35.7715 1.73609 36 2.33315 36C2.93021 36 3.52727 35.7715 3.98211 35.3167L17.9998 21.2983L32.0179 35.3167C32.4727 35.7715 33.0698 36 33.6669 36C34.2639 36 34.861 35.7715 35.3158 35.3167C36.2281 34.4048 36.2281 32.9305 35.3158 32.0187L21.2981 18.0003Z"
                                    fill="white"
                                />
                            </svg>

                            <div className="delivered-popup">
                                {selectedOrder.map((order, index) => {
                                    const createdAt = new Date(order.Created_at);
                                    const time = createdAt.toLocaleTimeString('en-US', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: true,
                                    });
                                    const date = `${createdAt.getDate()} ${createdAt.toLocaleString(
                                        'en-US',
                                        { month: 'short' }
                                    )}`;
                                    const status =
                    order.Status_id === 3 ? 'Declined' : 'Delivered';
                                    const price = order.Total_price;
                                    return (
                                        <div
                                            key={index}
                                            className="delivered-orders-single-container"
                                        >
                                            <div className="delivered-orders-single-top1">
                                                <p className="delivered-orders-order-id-text">
                          Order ID:{' '}
                                                    <span className="delivered-orders-order-id">
                                                        {order.ID}
                                                    </span>
                                                </p>
                                                <div className="delivered-orders-order-date-and-time">
                                                    <p className="delivered-orders-order-time">{time}</p>
                                                    <p className="delivered-orders-order-date">{date}</p>
                                                </div>
                                            </div>
                                            <div className="delivered-orders-dotted-line"></div>
                                            <div className="delivered-order-items-container">
                                                {items.map((item, index) => (
                                                    <p key={index} className="delivered-order-items">
                                                        {item.Quantity}X {item.Menu_Item_Name}
                                                    </p>
                                                ))}
                                            </div>
                                            <div className="delivered-orders-dotted-line"></div>
                                            <div className="delivered-order-bottom-container">
                                                <div className="delivered-order-bottom-container1">
                                                    <div className="delivered-orders-delivered-circle">
                                                        <svg
                                                            width="11"
                                                            height="9"
                                                            viewBox="0 0 11 9"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1 4L4 7L10 1"
                                                                stroke="white"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                            />
                                                        </svg>
                                                    </div>

                                                    <p className="delivered-order-status-text">
                                                        {status}
                                                    </p>
                                                </div>
                                                <p className="delivered-order-price-text">$ {price}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="new-orders-blur-background"></div>
                        </div>
                    )}

                    {orders.length > 0 && totalPages > 1 && (
                        <div className="pagination-container">
                            <Stack spacing={2}>
                                <Pagination
                                    sx={{
                                        '& .MuiPagination-ul': {
                                            color: '#333',
                                            padding: 1,
                                            borderRadius: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            listStyleType: 'none',
                                        },
                                    }}
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Delivered;