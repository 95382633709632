import React, { useState, useRef, useEffect } from 'react';
import { Button, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import SideMenuBar from '../../../Components/Layouts/SideMenuBar';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar';
import RightSideBar from '../../../Components/Layouts/RightSideBar';
import img3 from '../../../assets/onlinestatus.png';
import '../../../Styles/Reels/ReelsIndex.css';
import './Profilescreen.css';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import VideoCard from '../../../Components/VideoCard';
import { useCart } from '../../../CartProvider';
import Loader from '../../../Components/Loader/Loader';
import authMiddleware from '../../../Components/authMiddleware';
import logoIcon from '../../../assets/svg/logo.svg';
import axiosUserInstance from '../../../axiosUserInstance';
import BackButton from '../../../Components/BackButton';
import BackIcon from '../../../assets/svg/backIcon';
import EditProfileIcon from '../../../assets/svg/EditProfileIcon';

const Profilescreen = () => {
    const { t } = useTranslation();
    const [contentType, setContentType] = useState('posts');
    const navigate = useNavigate();
    const [buttonWidth, setButtonWidth] = useState(window.innerWidth < 601 ? '50%' : '33%');
    const [Saved, setSaved] = useState(null);
    const [savePost, setSavePost] = useState([]);
    const [userPosts, setUserPost] = useState([]);
    const containerRef = useRef(null);
    const [ismuted, setIsMuted] = useState(true);
    const [globalMuteIcon, setGlobalMuteIcon] = useState(true);
    const videoRefs = useRef([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState();
    const [followData, setFollowData] = useState();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const handleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };  

    const toggleMuteIcon = () => {
        setGlobalMuteIcon((prevMute) => !prevMute);
    };

    const handleVideoRef = (ref, index) => {
        if (ref) {
            videoRefs.current[index] = ref;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setButtonWidth(window.innerWidth < 601 ? '50%' : '33%');
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [profileImagePreview, setProfileImagePreview] = useState();
    const {currentLoginUserId} = useCart();
    const {userName} = useParams();

    const handleContentTypeChange = async (type) => {
        setContentType(type);
        
    };

    const handleSavePost = async () => {
        if (!userName) {
            try {
                const res = await axiosUserInstance.get('/post/savedposts');
    
                if (res?.statusText === 'OK') {
                    const data = res?.data?.savedPosts?.[0];
                    setSavePost(data?.Saved_Post_Information);
                }
            } catch (error) {
                console.error(
                    error.response?.data.error || 'Unable to connect to the server.'
                );
            }
        }
    };

    useEffect(() => {
        handleSavePost();
    }, [savePost?.Saved_flag]);
    
    const handleDashboardNavigate = () =>{
        navigate('/dashboard');
    };

    useEffect(() => {
        setIsLoading(true);
        axiosUserInstance
            .get(`/customers/user/${userName || currentLoginUserId}`)
            .then((response) => {
                setIsLoading(false);
                const data = response?.data?.posts?.[0];
                setUserPost(data?.Post_Information);
                setUserData(data);
                setFollowData(response?.data);
                setProfileImagePreview(data?.User_Information?.User_Profile_Picture);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Error fetching user data:', error);
            });
    }, [userName]);

    const handleFollow = async () => {
        if (!authMiddleware()) {  
            return;
        }

        const Data = {
            user_id: userData?.User_Information?.User_ID,
        };
    
        try {
            let res;
            if (followData?.followFlag == 'false') {
                res = await axiosUserInstance.post('/follow/user/userid', Data);
                if (res.status === 201) {
                    setFollowData((prevUserData) => ({
                        ...prevUserData,
                        followFlag: 'true',
                        followCountValue: prevUserData.followCountValue + 1,
                    }));
                }
            } else {
                res = await axiosUserInstance.delete(`/follow/user/userid/${userData?.User_Information?.User_ID}`);
                if (res.status === 200) {
                    setFollowData((prevUserData) => ({
                        ...prevUserData, 
                        followFlag: 'false',
                        followCountValue: prevUserData.followCountValue - 1,
                    }));
                }
            }
        } catch (error) {
            console.error('Error in follow/unfollow action:', error);
        }
    };

    const handleSavedClick = (saved) => {
        setSaved(saved);
    };

    const handleNavigate = () => {
        navigate('/profilesettings');
    };

    const parseAvatarKey = (key) => {
        try {
            const parsedKey = JSON.parse(key); // First parse
            return Array.isArray(parsedKey) ? parsedKey : JSON.parse(parsedKey); // Handle nested parsing
        } catch (error) {
            console.error('Failed to parse Attachment_Keys:', error);
            return [];
        }
    };

    const imageURL = profileImagePreview?.trim() ? profileImagePreview : null;
    const [bgColor, setBgColor] = useState(window.innerWidth < 601 ? '#2C3142' : 'white');
    const fallbackLetter = (userData?.User_Information?.User_FirstName?.charAt(0) + (userData?.User_Information?.User_LastName?.charAt(0) || '')).toUpperCase();

    useEffect(() => {
        const handleResize = () => {
            const isMobileView = window.innerWidth <= 600;
            setIsMobile(isMobileView);
            setBgColor(isMobileView ? '#2C3142' : 'white');
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className='profile-dashboard'>
            {isLoading && (
                <div>
                    <Loader/>
                </div>
            )}
            <div className='profile-side-bar'>
                <SideMenuBar></SideMenuBar>
            </div>
            <div className='profile-home'>
                <div className='profile-Topbar-cont'>
                    <TopMenuBar></TopMenuBar>
                </div>
                <div className='profile-page-cont'>
                    <div className='profile-page' ref={containerRef}>
                        <div className='profilepage-main-cont'>
                            <div className='Topofpage-Main-container'>
                                <div className='Top1-layout'>
                                    {isMobile && (
                                        <BackIcon onClick={() =>handleDashboardNavigate()} />
                                    )}
                                    <BackButton/>
                                    {!userName &&<div className='Edit-button-layout'>
                                        <EditProfileIcon onClick={() =>handleNavigate()}/>
                                    </div>}
                                </div>
                                <div className="profile-container-center">
                                    <div className='profile-container-2'>
                                        <div className='profile-status-layout'>
                                            {isLoading ? (
                                                <Skeleton variant="circular" width={130} height={130} />
                                            ) : (
                                                imageURL ? (
                                                    <img src={imageURL} alt="Profile" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain', border: '2px solid rgb(225, 53, 53)',backgroundColor : imageURL ? bgColor : 'red' }} onError={(e) => {
                                                        e.target.src = logoIcon; 
                                                    }}/>
                                                ) : (
                                                    <span>{fallbackLetter}</span>
                                                )
                                            )}
                                            <img src={img3} alt='status-symbol' className='profile-status-icon' />
                                        </div>
                                        <div className='Top-right-layout'>
                                            <div className='profile-name-layout'>
                                                <pre className='profile-name-text'>
                                                    {isLoading ? <Skeleton variant="text" width={120} /> :
                                                        `${userData?.User_Information?.User_FirstName || ''} ${userData?.User_Information?.User_LastName || ''}`
                                                    }
                                                </pre>
                                                {(currentLoginUserId !== userData?.User_Information?.User_ID) &&
                                                 <div className='follow-button-layout'>
                                                     <button className="follow-button" onClick={() => handleFollow()}>
                                                         {followData?.followFlag == 'true' ? 'Unfollow' : 'Follow'}
                                                     </button>
                                                 </div>
                                                }
                                            </div>
                                            <div className='profile-description-layout'>
                                                <p className='profile-desc-line1'>
                                                    {isLoading ? <Skeleton variant="text" width={200} /> : userData?.User_Information?.User_Description}
                                                </p>
                                            </div>
                                            <div className='profile-info-layout'>
                                                <div className="user-profile-follow-like-posts-heading">
                                                    <p className='user-profile-above-data'>
                                                        {isLoading ? <Skeleton variant="text" width={30} className='skeleton-profile-follow' /> : followData?.followCountValue || 0}
                                                    </p>
                                                    <p className='user-profile-below-text'>{t('profilescreen.followers-heading')}</p>
                                                </div>
                                                <div className="user-profile-follow-like-posts-heading">
                                                    <p className='user-profile-above-data'>
                                                        {isLoading ? <Skeleton variant="text" width={30} className='skeleton-profile-follow' /> : followData?.followingCountValue || 0}
                                                    </p>
                                                    <p className='user-profile-below-text'>{t('profilescreen.following-heading')}</p>
                                                </div>
                                                <div className="user-profile-follow-like-posts-heading">
                                                    <p className='user-profile-above-data'>
                                                        {isLoading ? <Skeleton variant="text" width={30} className='skeleton-profile-follow' /> : userData?.RecordCount || 0}
                                                    </p>
                                                    <p className='user-profile-below-text'>{t('profilescreen.posts-heading')}</p>
                                                </div>
                                                <div className="user-profile-follow-like-posts-heading">
                                                    <p className='user-profile-above-data'>
                                                        {isLoading ? <Skeleton variant="text" width={30} className='skeleton-profile-follow' /> : userData?.Total_Likes || 0}
                                                    </p>
                                                    <p className='user-profile-below-text'>{t('profilescreen.likes-heading')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='Buttons-layout'>
                                    {!userName && (
                                        <>
                                            <Button
                                                className={`Posts-button ${contentType === 'posts' ? 'activeBtn' : 'inactive'}`}
                                                style={{ borderRadius: '0', width: buttonWidth }}
                                                variant='contained'
                                                onClick={() => handleContentTypeChange('posts')}
                                            >
                                                {t('profilescreen.posts-button')}
                                            </Button>
                                            <Button
                                                className={`saved-button ${contentType === 'saved' ? 'activeBtn' : 'inactive'}`}
                                                style={{ borderRadius: '0', width: buttonWidth }}
                                                variant='contained'
                                                onClick={() => {
                                                    handleContentTypeChange('saved');
                                                    handleSavePost();
                                                } }
                                            >
                                                {t('profilescreen.saved-button')}
                                            </Button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='Bottompage-container' style={{marginTop:'12px'}}>
                                {contentType === 'posts' && (
                                    <div className='main-container profile-main-container'>
                                        <div className='video-container profile-video-container'>
                                            {userPosts?.length > 0 ? (
                                                userPosts.map((item,index) => {

                                                    const attachmentKeys = parseAvatarKey(item?.Attachment_Keys || '[]');
                                                    if (!Array.isArray(attachmentKeys)) return null;

                                                    return attachmentKeys.map((url) => (

                                                        <>
                                                            <div
                                                                className={`profile-post ${
                                                                    Saved === item.Post_ID ? 'selected' : ''
                                                                }`}
                                                                key={item.Post_ID}
                                                                onClick={() => {
                                                                    handleSavedClick(userPosts);
                                                                    // handleOpenSavedModal();
                                                                }}
                                                            >
                                                                <VideoCard                               
                                                                    key={item?.Post_ID}
                                                                    id={item?.Post_ID}
                                                                    vendorID={item?.Post_Vendor_id}
                                                                    AccountID={item?.Post_Created_by}
                                                                    url={url}
                                                                    attachmentKeys={item?.Attachment_keys || []}
                                                                    profilePic={profileImagePreview || ''}
                                                                    firstName = {userData?.User_Information?.User_FirstName || ''}
                                                                    lastName = {userData?.User_Information?.User_LastName || ''}
                                                                    userName={item?.User_Username || ''}
                                                                    restaurantName={item?.Post_Vendor_Name || ''}
                                                                    starPic={item?.Restaurant_Info?.[0]?.Star_Image || ''}
                                                                    rating={item?.Vendor_Info?.[0]?.Vendor_Avg_Rating || 0}
                                                                    distance={item?.Vendor_Info?.[0]?.Vendor_Distance || 0}
                                                                    description={item?.Post_Description}
                                                                    likes={item?.Post_Likes_count}
                                                                    shares={item?.Post_Shares_count}
                                                                    comments={item?.Post_Comments_count}
                                                                    setLike={() => {}}
                                                                    handleMute={handleMute}
                                                                    ismuted={ismuted}
                                                                    globalMuteIcon={globalMuteIcon}
                                                                    toggleMuteIcon={toggleMuteIcon}
                                                                    setVideoRef={(ref) => handleVideoRef(ref,index)}
                                                                    autoplay={index === 0}
                                                                    likeFlag={item?.Like_flag}
                                                                    saveFlag={item?.Saved_flag}
                                                                    followFlag={followData?.followFlag}
                                                                    filter={item?.Post_filter || ''}
                                                                    reelsId={item?.Post_ID}
                                                                />
                                                            </div>
                                                        </>
                                                    ));

                                                
                                                })
                                            ) : (
                                                <div className='no-reels-and-images-message'>
                                            No Posts Available.
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                )}
                                {contentType === 'saved' &&
                                    <div className='main-container profile-main-container'>
                                        <div className='video-container profile-video-container'>
                                            {savePost?.length > 0 ? (
                                                savePost.map((item,index) => {

                                                    const attachmentKeys = parseAvatarKey(item?.Attachment_Keys || '[]');
                                                    if (!Array.isArray(attachmentKeys)) return null;

                                                    return attachmentKeys.map((url) => (

                                                        <>
                                                            <div
                                                                className={`profile-post ${
                                                                    Saved === item.Post_ID ? 'selected' : ''
                                                                }`}
                                                                key={item.Post_ID}
                                                                onClick={() => {
                                                                    handleSavedClick(userPosts);
                                                                    // handleOpenSavedModal();
                                                                }}
                                                            >
                                                                <VideoCard                               
                                                                    key={item?.Post_ID}
                                                                    id={item?.Post_ID}
                                                                    vendorID={item?.Post_Vendor_id}
                                                                    AccountID={item?.Post_Created_by}
                                                                    url={url}
                                                                    attachmentKeys={item?.Attachment_keys || []}
                                                                    profilePic={profileImagePreview || ''}
                                                                    firstName = {userData?.User_Information?.User_FirstName || ''}
                                                                    lastName = {userData?.User_Information?.User_LastName || ''}
                                                                    userName={item?.User_Username || ''}
                                                                    restaurantName={item?.Vendor_Name || ''}
                                                                    starPic={item?.Restaurant_Info?.[0]?.Star_Image || ''}
                                                                    rating={item?.Vendor_Info?.[0]?.Vendor_Avg_Rating || 0}
                                                                    distance={item?.Vendor_Info?.[0]?.Vendor_Distance || 0}
                                                                    description={item?.Post_Description}
                                                                    likes={item?.Post_Likes_count}
                                                                    shares={item?.Post_Shares_count}
                                                                    comments={item?.Post_Comments_count}
                                                                    setLike={() => {}}
                                                                    handleMute={handleMute}
                                                                    ismuted={ismuted}
                                                                    globalMuteIcon={globalMuteIcon}
                                                                    toggleMuteIcon={toggleMuteIcon}
                                                                    setVideoRef={(ref) => handleVideoRef(ref,index)}
                                                                    autoplay={index === 0}
                                                                    likeFlag={item?.Like_flag}
                                                                    saveFlag={item?.Saved_flag}
                                                                    followFlag={item?.Follow_Flag}
                                                                    filter={item?.Post_filter || ''}
                                                                    reelsId={item?.Post_ID}
                                                                />
                                                            </div>
                                                        </>
                                                    ));

                                                
                                                })
                                            ) : (
                                                <div className='no-reels-and-images-message'>
                                            No Posts Available.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='profile-right-side-bar'>
                        <RightSideBar></RightSideBar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profilescreen;
