import React from 'react';
import './commonButton.css';

function CommonButton({onClick, label, style}) {
    return (
        <button onClick={onClick} className="add-button" style={style}>
            {label}
        </button>
    );
}

export default CommonButton;