import React from 'react';

function RoundDeleteIconSvg() {
    return (
        <svg width="98" height="91" viewBox="0 0 128 121" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.384766" y="0.884766" width="126.711" height="119.538" rx="59.7692" fill="#2C3142"/>
            <path d="M77.1118 86.1552H47.9763C47.3629 86.1407 46.7585 86.0055 46.1974 85.7573C45.6364 85.5092 45.1297 85.1529 44.7064 84.7088C44.2831 84.2648 43.9514 83.7417 43.7303 83.1694C43.5092 82.5972 43.403 81.9869 43.4179 81.3736V49.8633H46.6056V81.3736C46.5903 81.5684 46.6138 81.7642 46.6745 81.9499C46.7353 82.1356 46.8321 82.3074 46.9595 82.4556C47.0869 82.6037 47.2423 82.7252 47.4168 82.813C47.5913 82.9009 47.7814 82.9534 47.9763 82.9675H77.1118C77.3066 82.9534 77.4968 82.9009 77.6713 82.813C77.8458 82.7252 78.0012 82.6037 78.1286 82.4556C78.2559 82.3074 78.3528 82.1356 78.4135 81.9499C78.4743 81.7642 78.4977 81.5684 78.4825 81.3736V49.8633H81.6702V81.3736C81.685 81.9869 81.5788 82.5972 81.3578 83.1694C81.1367 83.7417 80.805 84.2648 80.3817 84.7088C79.9583 85.1529 79.4517 85.5092 78.8906 85.7573C78.3296 86.0055 77.7251 86.1407 77.1118 86.1552Z" fill="white"/>
            <path d="M82.9137 46.3088H41.8243C41.4016 46.3088 40.9962 46.1409 40.6973 45.842C40.3984 45.5431 40.2305 45.1377 40.2305 44.7149C40.2305 44.2922 40.3984 43.8868 40.6973 43.5879C40.9962 43.289 41.4016 43.1211 41.8243 43.1211H82.9137C83.3364 43.1211 83.7418 43.289 84.0407 43.5879C84.3396 43.8868 84.5075 44.2922 84.5075 44.7149C84.5075 45.1377 84.3396 45.5431 84.0407 45.842C83.7418 46.1409 83.3364 46.3088 82.9137 46.3088Z" fill="white"/>
            <path d="M67.3257 52.6846H70.5134V76.5923H67.3257V52.6846Z" fill="white"/>
            <path d="M54.5752 52.6846H57.7629V76.5923H54.5752V52.6846Z" fill="white"/>
            <path d="M70.5137 41.3041H67.4854V38.3395H57.6035V41.3041H54.5752V38.3395C54.5742 37.521 54.8881 36.7335 55.4518 36.14C56.0156 35.5466 56.786 35.1928 57.6035 35.1519H67.4854C68.3029 35.1928 69.0733 35.5466 69.637 36.14C70.2008 36.7335 70.5147 37.521 70.5137 38.3395V41.3041Z" fill="white"/>
        </svg>
    );
}

export default RoundDeleteIconSvg;