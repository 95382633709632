import React from 'react';

import SideMenuBar from '../../../Components/Layouts/SideMenuBar.jsx';
import TopMenuBar from '../../../Components/Layouts/TopMenuBar.jsx';
import ProfilesettingsMain from './ProfilesettingsMain.jsx';
import './Profilesettings.css';

export default function Profilesettings() {
    return (
        <div className="profile-settings-main">
            <div className="profile-settings-sidebar">
                <SideMenuBar></SideMenuBar>
            </div>
            <div className="profile-settings-topbar-and-home">
                <div className="profile-settings-topbar">
                    <TopMenuBar></TopMenuBar>
                </div>
                <div className="profile-settings-home-and-rightbar">
                    <ProfilesettingsMain />
                </div>
            </div>
        </div>
    );
}
