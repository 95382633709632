import React, { useState, useEffect } from 'react';
import './MenuItem.css';
import {
    Box,
    Modal,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import filterIcon from '../../../assets/Filtericon.png';
import greenDot from '../../../assets/greendot.svg';
import redDot from '../../../assets/reddot.svg';
import image1 from '../../../assets/Image1.png';
import { useTranslation } from 'react-i18next';
import FoodMenuDetailsModal from '../Search/FoodMenuDetailsModal';
import axiosVendorInstance from '../../../axiosVendorInstance';
import BackButton from '../../../Components/BackButton';
import { useNavigate } from 'react-router-dom';
const MenuItem = ({ restaurantData, searchQuery }) => {
    const isDesktop = useMediaQuery('(min-width:601px)'); 
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [animateModal, setAnimateModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('Relevance');
    const [filteredMenus, setFilteredMenus] = useState([]);
    const [selectedMenuName, setSelectedMenuName] = useState(null);
    const [allMenuItems, setAllMenuItems] = useState([]);
    const [selectedFood, setSelectedFood] = useState(null);
    const [openFoodDetail, setOpenFoodDetail] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setOpenFoodDetail(false);
        setSelectedFood(null);
    };

    // Handle filter change
    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value);
        handleFilterPopupClose(); 
    };
    
    const handleOpen = () => {
        setOpen(true);
    };

    const handleFilterPopupClose = () => {
        setOpen(false);
    };

    const handleDashboardNavigate = () =>{
        navigate('/dashboard');
    };

    const fetchFoodDetails = async (itemId) => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`, {skipAuth: true}
            );
            setSelectedFood(response?.data?.menuItem);
            setOpenFoodDetail(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    useEffect(() => {
        if (!restaurantData?.vendor?.vendorMenus) return;

        const items = restaurantData.vendor.vendorMenus.flatMap(menu => 
            menu.menu_items.map(item => ({
                ...item,
                menuName: menu.Menu_name
            }))
        );
        
        setAllMenuItems(items);
    }, [restaurantData]);

    // Apply both filters (type filter and menu name filter)
    useEffect(() => {
        if (!allMenuItems.length) return;
    
        let filteredItems = [...allMenuItems];
    
        // Apply search filter
        if (searchQuery) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            filteredItems = filteredItems.filter(item => 
                item.Item_name.toLowerCase().includes(lowerCaseQuery) ||  // Search by Item Name
                (item.Description && item.Description.toLowerCase().includes(lowerCaseQuery)) || // Search by Description
                item.menuName.toLowerCase().includes(lowerCaseQuery)  // Search by Menu Name
            );
        }
    
        // Apply menu selection filter
        if (selectedMenuName) {
            filteredItems = filteredItems.filter(item => item.menuName === selectedMenuName);
        }
    
        // Apply sorting & dietary filters
        switch (selectedFilter) {
        case 'Pure Veg':
            filteredItems = filteredItems.filter(item => item.Vegetarian === true);
            break;
        case 'Pure Non-Veg':
            filteredItems = filteredItems.filter(item => item.Vegetarian === false);
            break;
        case 'priceLowToHigh':
            filteredItems.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
            break;
        case 'priceHighToLow':
            filteredItems.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
            break;
        default:
            break;
        }
    
        // Convert filtered items back into menu structure
        const groupedMenus = restaurantData.vendor.vendorMenus
            .map(menu => ({
                ...menu,
                menu_items: filteredItems.filter(item => item.menuName === menu.Menu_name),
            }))
            .filter(menu => menu.menu_items.length > 0);
    
        setFilteredMenus(groupedMenus);
    }, [selectedFilter, selectedMenuName, allMenuItems, searchQuery]);
    

    setTimeout(() => {
        setAnimateModal(true);
    }, 100);

    const menuNames = restaurantData?.vendor?.vendorMenus 
        ? restaurantData.vendor.vendorMenus.map(menu => menu.Menu_name)
        : [];

    return (
        <div className='menu-main-container'>
            <div className="filters-scroll">
                <div className="filters">
                    {menuNames.map((menuName, index) => (
                        <p
                            key={index}
                            className={`bg-css ${selectedMenuName === menuName ? 'active-filter' : ''}`}
                            onClick={() => setSelectedMenuName(prev => (prev === menuName ? null : menuName))}
                        >
                            {menuName}
                        </p>

                    ))}
                </div>
            </div>
            <div className="restaurant-common-filter">
                <BackButton onClick={() =>handleDashboardNavigate()}/>
                <p className='bg-css'
                    onClick={handleOpen}
                >
                    <img src={filterIcon} alt="filter-icon" className='filter-icon' />
                Filter
                </p>
            </div>
            
            {/* Mobile Modal */}
            {!isDesktop && (
                <Modal open={open} onClose={handleFilterPopupClose}>
                    <Box className={`style1 ${animateModal ? 'animate' : ''}`}>
                        <h2 className='filter-title'>{t('homescreen.filter-title')}</h2>
                        <Grid container spacing={2} className="width-of-dialog-box">
                            <Grid item xs={isMobile ? 6 : 7}>
                                <div className="css-mhc70k-MuiGrid-root MuiGrid-item">
                                    <RadioGroup value={selectedFilter} onChange={handleFilterChange}>
                                        <FormControlLabel 
                                            value="Relevance"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val1')} />
                                        <FormControlLabel
                                            value="priceLowToHigh"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val4')} />
                                        <FormControlLabel
                                            value="priceHighToLow"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val5')} />
                                        <FormControlLabel
                                            value="Pure Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg')} />
                                        <FormControlLabel
                                            value="Pure Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.nonveg')} />
                                        <FormControlLabel
                                            value="Veg and Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg-or-nonveg-tab-val3')} />
                                    </RadioGroup>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )}

            {/* Desktop Dialog */}
            {isDesktop && (
                <Dialog open={open} onClose={handleFilterPopupClose} width="70%" height="300px" >
                    <DialogTitle className="filters-title">
                        {t('homescreen.filter-title')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} className="width-of-dialog-box">
                            <Grid item xs={isMobile ? 6 : 7}>
                                <div className="css-mhc70k-MuiGrid-root MuiGrid-item">
                                    <RadioGroup value={selectedFilter} onChange={handleFilterChange}>
                                        <FormControlLabel
                                            value="Relevance"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val1')} />
                                        <FormControlLabel
                                            value="priceLowToHigh"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val4')} />
                                        <FormControlLabel
                                            value="priceHighToLow"
                                            control={<Radio />}
                                            label={t('homescreen.sort-by-tab-val5')} />
                                        <FormControlLabel
                                            value="Pure Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg')} />
                                        <FormControlLabel
                                            value="Pure Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.nonveg')} />
                                        <FormControlLabel
                                            value="Veg and Non-Veg"
                                            control={<Radio />}
                                            label={t('homescreen.veg-or-nonveg-tab-val3')} />
                                    </RadioGroup>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
            
            {/* Display menu items with applied filters */}
            <div className="all-menu-item">
                {restaurantData && filteredMenus?.length > 0 ? filteredMenus.map((menuname) => (
                    <div key={menuname.ID} className='menu-bottom-container'>
                        <h3 className='menu-starter-text'>
                            {menuname.Menu_name.toUpperCase()}
                        </h3>
                        <div className='menu-starter-container'>
                            {menuname.menu_items.map((item) => (
                                <div key={item.ID} className='menu-item' onClick={() => fetchFoodDetails(item.ID)}>
                                    <div className='menu-starters-image'>
                                        <img
                                            src={item.Avatar_key || image1}
                                            alt={item.Item_name}
                                            onError={(e) =>{
                                                e.target.src = image1;
                                            }}
                                        />
                                    </div>
                                    <div className='menu-description-container'>
                                        <div className='heading-container'>
                                            {/* Display veg/non-veg indicator */}
                                            <img className="item-reddot-order" src={item.Vegetarian === true ? greenDot : item.Vegetarian === false ? redDot : ''} alt="dot" />
                                            <h3 className='menu-item-head-text'>
                                                {item.Item_name}
                                            </h3>
                                        </div>
                                        <p className='menu-description' dangerouslySetInnerHTML={{ __html: item.Description.length > 100 
                                            ? item.Description.slice(0, 100) + '...' 
                                            : item.Description }} />
                                        <div className='menu-item-bottom-container'>
                                            <h6 className='menu-item-price-text'>{item.Price}$</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )) :  restaurantData ? (
                    <p className='no-item-found'>No Items Found</p>
                ) : null}
            </div>

            {selectedFood && (
                <FoodMenuDetailsModal
                    open={openFoodDetail}
                    handleClose={handleClose}
                    food={selectedFood}
                    orderBtn = {false}
                />
            )}
        </div>
    );
};

export default MenuItem;