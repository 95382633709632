import React, { useState, useEffect } from 'react';
import './FollowToWatchReels.css';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

import Slider from 'react-slick';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar';
import { useNavigate } from 'react-router-dom';
import authMiddleware from '../../../Components/authMiddleware';
import { Skeleton } from '@mui/material';
import useWindowSize from './useWindowSize';
import axiosUserInstance from '../../../axiosUserInstance';
import { useNotifications } from '../Notification/NotificationContext';

function FollowToWatchReels() {
    const { t } = useTranslation();
    // eslint-disable-next-line no-unused-vars
    const { notifications, addNotification, removeNotification } =
    useNotifications();

    const [followers, setFollowers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loadingUsers, setLoadingUsers] = useState({});
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    const fetchFollowToWatch = async () => {
        try {
            const response = await axiosUserInstance.get(
                '/follow/watch-reels?pageNo=1&pageSize=10'
            );
            setFollowers(response.data.followInfo.result[0].Follow_Information);

        } catch (error) {
            console.error('Error fetching follow data', error);
        }
    };

    useEffect(() => {
        fetchFollowToWatch();
    }, []);

    const handleFollow = async (id, followFlag) => {
        if (!authMiddleware()) {
            return;
        }
        setLoadingUsers((prev) => ({ ...prev, [id]: true }));
        try {
            if (followFlag === 'false') {
                const response = await axiosUserInstance.post('/follow/user/userid', {
                    user_id: id,
                });
                if (response.status === 201) {
                    setFollowers((prevFollowers) =>
                        prevFollowers.map((profile) =>
                            profile.User_id === id ? { ...profile, IsFollowed: 'true',Follower_count: profile.Follower_count + 1 } : profile
                        )
                    );
                }
    
                const notificationPayload = {
                    to_user_id: id,
                    type: 'follow',
                    action_id: id,
                    notification_boundary: 'private',
                    is_read: false,
                };
                await addNotification(notificationPayload);
            } else {
                const response = await axiosUserInstance.delete(`/follow/user/userid/${id}`);
                if (response.status === 200) {
                    setFollowers((prevFollowers) =>
                        prevFollowers.map((profile) =>
                            profile.User_id === id ? { ...profile, IsFollowed: 'false',Follower_count: Math.max(0, profile.Follower_count - 1) } : profile
                        )
                    );
                }
            }
        } catch (error) {
            setLoadingUsers((prev) => ({ ...prev, [id]: false }));
            console.error('Error in follow/unfollow', error);
        } finally {
            setLoadingUsers((prev) => ({ ...prev, [id]: false }));
        }
    };
    
    const NextArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-next ${
                isDisabled ? 'disabled-arrow' : ''
            }`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowRight />
        </div>
    );

    const PrevArrow = ({ onClick, isDisabled }) => (
        <div
            className={`custom-arrow custom-prev ${
                isDisabled ? 'disabled-arrow' : ''
            }`}
            onClick={!isDisabled ? onClick : undefined}
        >
            <FaArrowLeft />
        </div>
    );

    const formatFollowers = (count) => {
        if (count >= 1000000) {
            return (count / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else if (count >= 1000) {
            return (count / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return count;
    };

    let slidesToShow = 8;
    let showSlider = followers?.length > 8;
    let variableWidth = false;
    const totalSlides = followers?.length || 0;

    if (windowSize.width <= 600) {
        slidesToShow = 3;
        showSlider = followers?.length >= 3;
        variableWidth = true;
    } else if (windowSize.width <= 768) {
        slidesToShow = 3;
        showSlider = followers?.length >= 3;
    } else if (windowSize.width <= 1165) {
        slidesToShow = 4;
        showSlider = followers?.length >= 4;
    } else if (windowSize.width <= 1363) {
        slidesToShow = 5;
        showSlider = followers?.length >= 5;
    } else if (windowSize.width <= 1677) {
        slidesToShow = 7;
        showSlider = followers?.length >= 7;
    } else if (windowSize.width <= 2560) {
        slidesToShow = 8;
        showSlider = followers?.length >= 8;
    }

    const settings = {
        dots: false,
        infinite: false,
        centerMode: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: true,
        initialSlide: 0,
        nextArrow:
      !isLoading && windowSize.width > 600 ? (
          <NextArrow isDisabled={currentSlide >= totalSlides - slidesToShow} />
      ) : (
          <></>
      ),
        prevArrow:
      !isLoading && windowSize.width > 600 ? (
          <PrevArrow isDisabled={currentSlide === 0} />
      ) : (
          <></>
      ),
        swipe: true,
        variableWidth,
        afterChange: (index) => setCurrentSlide(index),
    };

    const handleProfilePage = (userName) => {
        navigate(`/profile/${userName}`);
    // setVisitedUserId(id);
    };

    return (
        followers &&
    followers?.length > 0 && (
            <div className="Follow_To_Watch_Reels_Container header-top-border">
                <div className="title-and-arrows">
                    <h5 className="follow_To_Watch_Reels">
                        {t('homescreen.Follow-to-watch-reels-title')}
                    </h5>
                </div>

                <div className="slider-container">
                    {isLoading ? (
                        <Slider {...settings}>
                            {[...Array(10)].map((_, index) => (
                                <div key={index} className="person-card">
                                    <Skeleton
                                        variant="circular"
                                        width={windowSize.width > 600 ? 100 : 60}
                                        height={windowSize.width > 600 ? 100 : 60}
                                    />
                                    <Skeleton variant="text" width={80} height={20} />
                                    <Skeleton variant="text" width={40} height={20} />
                                    <Skeleton
                                        variant="rounded"
                                        width={windowSize.width > 600 ? 100 : 60}
                                        height={25}
                                    />
                                </div>
                            ))}
                        </Slider>
                    ) : showSlider ? (
                        <Slider {...settings}>
                            {followers?.map((profile) => (
                                <div key={profile?.User_id} className="person-card">
                                    {console.log('profile.User_id',profile?.User_id)}
                                    <ProfileAvatar
                                        firstName={profile?.first_name}
                                        lastName={profile?.last_name}
                                        profilePic={profile?.profile_picture}
                                        height={windowSize.width > 600 ? 100 : 60}
                                        width={windowSize.width > 600 ? 100 : 60}
                                        fontSize={windowSize.width > 600 ? 40 : 25}
                                        onClick={() => handleProfilePage(profile?.username)}
                                    />
                                    <h6 className="channel-name">
                                        {profile?.first_name} {profile?.last_name}
                                    </h6>
                                    <p className="followers-count">
                                        {formatFollowers(profile?.Follower_count)}
                                    </p>
                                    <button
                                        className="Follow_Button"
                                        onClick={() => handleFollow(profile?.User_id,profile?.IsFollowed)}
                                    >
                                        {loadingUsers[profile?.User_id]
                                            ? <><i className="fa fa-spinner fa-spin"></i>&nbsp;Loading</>
                                            : profile?.IsFollowed == 'true' ? 'Unfollow' : 'Follow'}
                                        {/* {profile?.IsFollowed == 'true' ? 'Unfollow' : 'Follow'} */}
                                    </button>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <div className="flex-followers-container">
                            {followers?.map((profile) => (
                                <div key={profile?.User_id} className="person-card">
                                    <ProfileAvatar
                                        firstName={profile?.first_name}
                                        lastName={profile?.last_name}
                                        profilePic={profile?.profile_picture}
                                        height={100}
                                        width={100}
                                        fontSize={40}
                                        onClick={() => handleProfilePage(profile?.username)}
                                    />
                                    <h6 className="channel-name">
                                        {profile?.first_name} {profile?.last_name}
                                    </h6>
                                    <p className="followers-count">
                                        {formatFollowers(profile?.Follower_count)}
                                    </p>
                                    <button
                                        className="Follow_Button"
                                        onClick={() => handleFollow(profile?.User_id,profile?.IsFollowed)}
                                    >
                                        {loadingUsers[profile?.User_id]
                                            ? <><i className="fa fa-spinner fa-spin"></i>&nbsp;Loading</>
                                            : profile?.IsFollowed == 'true' ? 'Unfollow' : 'Follow'}
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    );
}

export default FollowToWatchReels;
