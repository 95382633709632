import React, {  useRef, useState } from 'react';

import './Post.css';
import VideoCard from '../../../Components/VideoCard';
import { useCart } from '../../../CartProvider';
import { CircularProgress } from '@mui/material';

const parseAvatarKey = (key) => {
    try {
        const parsedKey = JSON.parse(key); 
        return Array.isArray(parsedKey) ? parsedKey : JSON.parse(parsedKey);
    } catch (error) {
        console.error('Failed to parse Attachment_Keys:', error);
        return [];
    }
};

function Post({results, searchTerm, lastElementRef, loading }) {
    const validResults = results || [];
    const [ismuted, setIsMuted] = useState(true);
    const [globalMuteIcon, setGlobalMuteIcon] = useState(true);
    const videoRefs = useRef([]);
    const {coordinate} = useCart();
    console.log('lastElementRef',lastElementRef);
    
    
    const filteredPosts = validResults.filter((post) =>
        post?.Vendor_Info?.[0]?.Vendor_Name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleMute = () => {
        setIsMuted((prevMuted) => !prevMuted);
    };  

    const toggleMuteIcon = () => {
        setGlobalMuteIcon((prevMute) => !prevMute);
    };

    const handleVideoRef = (ref, index) => {
        if (ref) {
            videoRefs.current[index] = ref;
        }
    };

    return (
        <div className="media-post-reels">
            <div className='main-container profile-main-container'>
                <div className='video-container profile-video-container'>
                    {filteredPosts?.length > 0 ? (
                        filteredPosts?.map((post, index) => (
                            (post?.Attachment_keys || []).map((attachment) => {
                                const avatarUrls = parseAvatarKey(attachment.Avatar_key || '[]');

                                return avatarUrls.map((url,avatarIndex) => {
                                    const isLastElement = index === filteredPosts.length - 1 && avatarIndex === avatarUrls.length - 1;
                                    return(<div className="profile-post" key={post?.Post_ID} ref={isLastElement ? lastElementRef : null}>
                                        <VideoCard
                                            key={post?.Post_ID}
                                            id={post?.Post_ID}
                                            vendorID={post?.Post_Vendor_id}
                                            AccountID={post?.User_Info?.User_ID}
                                            url={url}
                                            attachmentKeys={post?.Attachment_keys || []}
                                            profilePic={post?.User_Info?.Profile_Picture || ''}
                                            firstName={post?.User_Info?.First_Name || ''}
                                            lastName={post?.User_Info?.Last_Name || ''}
                                            userName={post?.User_Info?.Username || ''}
                                            restaurantName={post?.Vendor_Info?.[0]?.Vendor_Name || ''}
                                            starPic={post?.Restaurant_Info?.[0]?.Star_Image || ''}
                                            rating={post?.Vendor_Info?.[0]?.Vendor_Avg_Rating || 0}
                                            distance={post?.Vendor_Info?.[0]?.Vendor_Distance || 0}
                                            description={post?.Post_Description}
                                            likes={post?.Post_Likes_count}
                                            shares={post?.Post_Shares_count}
                                            comments={post?.Post_Comments_count}
                                            setLike={() => {}}
                                            handleMute={handleMute}
                                            ismuted={ismuted}
                                            globalMuteIcon={globalMuteIcon}
                                            toggleMuteIcon={toggleMuteIcon}
                                            setVideoRef={(ref) => handleVideoRef(ref, index)}
                                            autoplay={index === 0}
                                            restaurant={filteredPosts}
                                            coordinate={coordinate}
                                            likeFlag={post?.LikedFlag}
                                            saveFlag={post?.SavedFlag}
                                            followFlag={post?.FollowFlag}
                                            filter={post?.Post_filter || ''}
                                            reelsId={post?.Post_ID}
                                        />
                                    </div>);
                                });
                            })
                        ))
                    ) : (
                        <div className="no-reels-and-images-message no-post" >
        No Posts Available.
                        </div>
                    )}

                </div>
            </div>
            {loading && <div style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress />
            </div>}
        </div>
    );
}
export default Post;
