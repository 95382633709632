import React from 'react';

function CommentIcon({ handleOpenCommentPopup }) {
    return (
        <svg
            width="30"
            height="35"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="comment-svg-icon"
            onClick={handleOpenCommentPopup}
            style={{ cursor: 'pointer' }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.1052 2.38462C8.36574 2.38462 2.90232 8.25657 2.90232 15.5C2.90232 18.6 3.25135 20.5865 4.71676 22.6834C4.93745 22.9991 4.99833 23.4118 4.87933 23.7856C4.53748 24.859 4.02839 26.4606 3.60469 27.7943L3.51421 28.0792L4.46982 27.8046C6.01314 27.3611 7.67541 26.883 8.15996 26.7425C8.44502 26.6598 8.74899 26.7026 9.00457 26.8616C10.7983 27.9768 12.8807 28.6154 15.1052 28.6154C21.8448 28.6154 27.3081 22.7433 27.3081 15.5C27.3081 8.25657 21.8448 2.38462 15.1052 2.38462ZM1.79296 29.8077C0.743053 29.4227 0.743068 29.4227 0.743068 29.4225L1.50502 27.0237C1.85782 25.9133 2.27001 24.6164 2.5956 23.5931C1.01774 21.0903 0.683606 18.6248 0.683606 15.5C0.683606 6.93958 7.14038 0 15.1052 0C23.0701 0 29.5268 6.93958 29.5268 15.5C29.5268 24.0605 23.0701 31 15.1052 31C12.6472 31 10.3301 30.3382 8.30271 29.1705C7.57476 29.3805 6.26953 29.7559 5.04291 30.1083L2.08033 30.9593L1.79296 29.8077ZM1.79296 29.8077L2.08033 30.9593C1.68469 31.073 1.26196 30.9432 0.982378 30.6216C0.702805 30.3002 0.610818 29.8392 0.743068 29.4225L1.79296 29.8077Z"
                fill="white"
            />
        </svg>
    );
}

export default CommentIcon;