import React from 'react';
import Box from '@mui/material/Box';


import Modal from '@mui/material/Modal';



import '../../VendorStyles/CouponModal.css';
import AddCouponForm from './AddCouponForm';

function CouponModal({ handleClose,open,description,setDescription,shortDescription,setShortDescription,title,setTitle,validFrom,setValidFrom,validTo,setValidTo,setOfferPercentage,couponCode,setCouponCode,autoGenerateCode,handleAddOrUpdateCoupon,handleAutoGenerateCodeChange,isEditing,status,setStatus,noBoundaries,setNoBoundaries,maxAmount,setMaxAmount,redeemLimit,setRedeemLimit,titleError,descriptionError,shortDescriptionError,percentageError,maxAmountError,redeemLimitError,handleTitleChange,handleDescriptionChange,handleShortDescriptionChange,handleRedeemLimit,handleMaxAmount,handleValidFromChange,validFromError, validToError,couponError}) {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='coupon-Model-container'>


                    <AddCouponForm 
                        handleClose={handleClose} 
                        handleAddOrUpdateCoupon={handleAddOrUpdateCoupon}
                        handleAutoGenerateCodeChange={handleAutoGenerateCodeChange}
                        title={title}
                        setTitle={setTitle}
                        validFrom={validFrom}
                        setValidFrom={setValidFrom}
                        validTo={validTo}
                        setValidTo={setValidTo}
                        
                        setOfferPercentage={setOfferPercentage}
                        couponCode={couponCode}
                        setCouponCode={setCouponCode}
                        autoGenerateCode={autoGenerateCode}
                        isEditing={isEditing}
                        description={description}
                        setDescription={setDescription}
                        shortDescription={shortDescription}
                        setShortDescription={setShortDescription}
                        status={status}
                        setStatus={setStatus}
                        noBoundaries={noBoundaries}
                        setNoBoundaries={setNoBoundaries}
                       
                        maxAmount={maxAmount}
                        setMaxAmount={setMaxAmount}
                        redeemLimit={redeemLimit}
                        setRedeemLimit={setRedeemLimit}
                        titleError={titleError}
                        descriptionError={descriptionError}
                        shortDescriptionError={shortDescriptionError}
                        percentageError={percentageError}
                        maxAmountError={maxAmountError}
                        redeemLimitError={redeemLimitError}

                        handleTitleChange={handleTitleChange}
                        handleDescriptionChange={handleDescriptionChange}
                        handleShortDescriptionChange={handleShortDescriptionChange}
                        handleMaxAmount={handleMaxAmount}
                       
                        handleRedeemLimit={handleRedeemLimit}
                        handleValidFromChange={handleValidFromChange}
                        validFromError={validFromError}
                        validToError={validToError}
                        couponError={couponError}
                    />


                </Box>
            </Modal>
        </div>
    );
}

export default CouponModal;