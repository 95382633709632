import React from 'react';

const BackIcon = ({color = '#2C3142', onClick = () =>{}}) => {
    return (
        <svg
            width='30'
            height='30'
            viewBox='0 0 16 27'
            fill='none'
            className='profile-vector-icon'
            xmlns='http://www.w3.org/2000/svg'
            onClick={onClick}
        >
            <path
                d='M13.5881 27L-2.36041e-06 13.5L13.5881 2.37582e-06L16 2.39625L4.82378 13.5L16 24.6038L13.5881 27Z'
                fill={color}
            />
        </svg>
    );
};

export default BackIcon;
