import '../../VendorStyles/PreparingRightbar.css';
import React from 'react';

import  { OrderContextuseOrders} from '../../ordersCount';
function VendorRightSidebar(){

    const {preparingCount, deliveredCount} = OrderContextuseOrders();

 
    return(
        
        <><div className="preparing-container1">
           
          
            <h5 className="preparing-container1-text-1">{preparingCount}</h5>  
          
            <h5 className="preparing-container1-text-2">Preparing Orders</h5>
        </div><div className="preparing-container1">
          
           
            <h5 className="preparing-container1-text-1">{deliveredCount}</h5>  
           
            <h5 className="preparing-container1-text-2">Orders Delivered</h5>
        </div></> 
    );
}
export default VendorRightSidebar;