import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import '../../../Styles/Cart/AddressList.css';
import AddAddressModel from './AddAddressModel';
import { useTranslation } from 'react-i18next';
import axiosUserInstance from '../../../axiosUserInstance';
import CloseIcon from '../../../assets/svg/closeIcon';
import WhiteLocationIcon from '../../../assets/svg/WhiteLocationIcon';
import DownArrowIcon from '../../../assets/svg/DownArrowIcon';

function AdressList({ handleClose, handleUpdateDeliveryAddress, selectedAddressIndex, setSelectedAddressIndex }) {
    const { t } = useTranslation();
    const [addAddress, setAddAddress] = useState(false);
    const [editAddress, setEditAddress] = useState(null); // Store selected address for editing
    const [addressList, setAddressList] = useState([]);

    const handleOpenAddress = () => {
        setEditAddress(null);
        setAddAddress(true);
    };
    const handleCloseAddress = () => {
        setAddAddress(false);
        setEditAddress(null);
    };
    const handleDeleteAddress = async (id) => {
        try {
            await axiosUserInstance.delete(`/address/account/${id}`);
            
            getAddress(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting address:', error);
            
        }
    };
    
    const getAddress = async () => {
        try {
            const response = await axiosUserInstance.get('/address/account/account_id');
            setAddressList(response.data.addresses);
        } catch (error) {
            console.error('Error fetching addresses:', error);
        }
    };

    useEffect(() => {
        getAddress();
    }, []);

    const handleCheckboxChange = (index) => {
        if (selectedAddressIndex === index) {
            setSelectedAddressIndex(null); // Unselect if the same index is clicked
            localStorage.removeItem('selectedAddressIndex');
        } else {
            setSelectedAddressIndex(index);
            localStorage.setItem('selectedAddressIndex', index);
        }
    };
    

    useEffect(() => {
        const storedIndex = localStorage.getItem('selectedAddressIndex');
        if (storedIndex !== null) {
            setSelectedAddressIndex(parseInt(storedIndex, 10));
        } else if (addressList.length > 0) {
            setSelectedAddressIndex(0); // Default to the first address
        }
    }, [addressList]);

    const getAddressType = () => {
        if (selectedAddressIndex !== 0) {
            return addressList[selectedAddressIndex]?.Delivery_name;
        }
        return 'Home';
    };

    const handleAddAddress = (newAddress) => {
        setAddressList([...addressList, newAddress]);
    };

    const handleEditAddress = (address) => {
        console.log('Editing Address:', address); // Debugging
        setEditAddress(address);
        setAddAddress(true);
    };

    return (
        <div className="adress-list-main-container">
            <div className="hide-horizantal-line">
                <div className="horizantal-line-mobile"></div>
            </div>

            <div className="comment-and-crossIcon-container">
                <Typography className='address-title' variant="h6">
                    {t('cart.address-title')}
                </Typography>
                <CloseIcon onClick={handleClose} className="cross-icon-location" />
            </div>

            <div className="address-container-in-model">
                <div className="delivery-location-home-desc-container">
                    <WhiteLocationIcon />
                    <div className="delivert-to-home-desc-container">
                        <h3 className="delivery-to-home-text">
                            {t('cart.delivery-to')} {getAddressType()}
                        </h3>
                        <p className="delivery-to-home-address">
                            {t('cart.for-near-by-restaurants-and-quick-delivery')}
                        </p>
                    </div>
                </div>
                <DownArrowIcon />
            </div>

            <div className="save-address-heading">
                <p className="saved-address">{t('cart.saved-address-title')}</p>
                <button className="add-new-address-btn" onClick={handleOpenAddress}>
                    {t('cart.add-new-address-title')}
                </button>
            </div>

            {addAddress && (
                <AddAddressModel
                    open={addAddress}
                    handleCloseAddress={handleCloseAddress}
                    handleAddAddress={handleAddAddress}
                    getAddress={getAddress}
                    editAddress={editAddress} // Ensure this is passed
                />
            )}

            <div className="address-list-scrollable">
                {addressList.map((address, index) => (
                    <div key={index} className="address-type-container">
                        <input
                            type="checkbox"
                            className="address-checkbox"
                            checked={selectedAddressIndex === index}
                            onChange={() => handleCheckboxChange(index)}
                        />

                        <div className="address-details">
                            <h4 className="address-type">{address.Delivery_name}</h4>
                            <p className="address-type-address">
                                {`${address.Address_line_1}, ${address.City}`}
                            </p>
                            <p className="address-type-address">
                                {`${address.State}-${address.ZIP}`}
                            </p>
                        </div>
                        <div className="button-container">
                            <button 
                                className="edit-btn" 
                                onClick={() => handleEditAddress(address)}
                                disabled={selectedAddressIndex !== index} // Disable if not selected
                            >
        Edit
                            </button>
                            <button 
                                className="delete-btn" 
                                onClick={() => handleDeleteAddress(address.ID)}
                                disabled={selectedAddressIndex !== index} // Disable if not selected
                            >
        Delete
                            </button>
                        </div>

                    </div>
                ))}
            </div>

            <button
                className="update-delivery-address"
                onClick={() => {
                    handleUpdateDeliveryAddress(getAddressType());
                    handleClose();
                }}
                disabled={selectedAddressIndex === null || selectedAddressIndex === undefined} // Disable if no address selected
            >
                {t('cart.update-delivery-address')}
            </button>

        </div>
    );
}

export default AdressList;
