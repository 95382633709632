
import VendorRightSidebar from './VendorRightSideBar';
import '../../VendorStyles/NewOrdersRightbar.css';
function NewOrdersRightbar() {
    

    return (
        <>
            <div className="vertical-line"></div>
            <div className="rightsidebar-main-cont">
                <VendorRightSidebar></VendorRightSidebar>
                
            </div>
        </>
    );
}

export default NewOrdersRightbar;
