




import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Link } from 'react-router-dom';
import '../../Views/User/profilepage/Profilesettings.css';
import bell from '../../assets/notification.png';
import gal from '../../assets/gallery.png';
import { useTranslation } from 'react-i18next';
import axiosUserInstance from '../../axiosUserInstance';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

export default function VendorEditprofile() {
    const [profileImage, setProfileImage] = useState(null); 
    const [userImagePreview, setUserImagePreview] = useState();

   
    const { t } = useTranslation();
   

    const [isLoading, setIsLoading] = useState(false); 

    const [firstName, setFirstName] = useState('');

    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState(null);
    const [username, setUsername] = useState('');

    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [dobError, setDobError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const nameRegex = /^[A-Za-z]{2,}$/;
   

    const [disableButton, setDisableButton] = useState(true);

    useEffect(() => {
    
        if (firstNameError || lastNameError || usernameError || dobError || !firstName || !lastName || !username || !dob) {
            setDisableButton(true);
        } else {
            setDisableButton(false);
        }
    }, [firstNameError, lastNameError, usernameError, dobError, firstName, lastName, username, dob]);

    



    useEffect(() => {
        
        axiosUserInstance
            .get('/auth/user/userbyId')
            .then((response) => {
                const data = response.data.user;
                console.log(data);
                setFirstName(data.first_name);
                setLastName(data.last_name);
                setUsername(data.username);
                const formattedDate = data.DOB
                    ? data.DOB.split('T')[0]
                    : '';
                setDob(formattedDate);
                setUserImagePreview(data.profile_picture);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);

    const handleGalleryClick = (e) => {
        const file = e.target.files[0]; 
        if (file) {
          
            setProfileImage(file);

           
            const reader = new FileReader();
            reader.onload = () => {
                
                setUserImagePreview(reader.result); 
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setFirstNameError('First name cannot be blank');
        } else if (!nameRegex.test(value)) {
            setFirstNameError('Invalid First name');
        } else if (value.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
        } else {
            setFirstNameError('');
        }
        setFirstName(value);
    };

    const handleLastNameChange = (e) => {
        const value = e.target.value;
        if (!value) {
            setLastNameError('Last name cannot be blank');
        } else if (!nameRegex.test(value)) {
            setLastNameError('Invalid Last name');
        }else if (value.length > 25) {
            setLastNameError('Last name cannot be longer than 25 characters');
        } else {
            setLastNameError('');
        }
        setLastName(value);
    };

    const handleUsernameChange = (e) => {
        const value = e.target.value;
        const usernameRegex = /^[a-z0-9_]+$/;    
        if (!value) {
            setUsernameError('Please enter username');
        } else if (!usernameRegex.test(value)) {
            setUsernameError('Invalid username. Only a-z ,0-9 & UnderScores "_" are allowed.');
        } else {
            setUsernameError('');
        }
        setUsername(value);
    };

    const handleDobChange = (newValue) => {
        if (!newValue) {
            setDob('');
            setDobError('');
        } else {
            try {
                const formattedDate = dayjs(newValue).format('YYYY-MM-DD');
                setDob(formattedDate);
                setDobError('');
            } catch (error) {
                setDobError('Invalid date format');
            }
        }
    };
    

    const handleSaveChanges = () => {
        let isValid = true;
        const usernameRegex = /^[a-zA-Z0-9_]+$/;


        if (!firstName) {
            setFirstNameError('First name cannot be blank');
            isValid = false;
        } else if (!nameRegex.test(firstName)) {
            setFirstNameError('Invalid First name');
            isValid = false;
        }else if (firstName.length > 50) {
            setFirstNameError('First name cannot be longer than 50 characters');
            isValid = false;
        }

        if (!lastName) {
            setLastNameError('Last name cannot be blank');
            isValid = false;
        } else if (!nameRegex.test(lastName)) {
            setLastNameError('Invalid Last name');
        }else if (lastName.length > 25) {
            setLastNameError('Last name cannot be longer than 25 characters');
            isValid = false;
        }

        if (!username) {
            setUsernameError('Please enter username');
            isValid = false;
        } else if (!usernameRegex.test(username)) {
            setUsernameError('Invalid username. Only a-z ,0-9 & UnderScores "_" are allowed.');
        }

        if (!dob) {
            setDobError('Please enter date of birth');
            isValid = false;
        }

        if (isValid) {

            if (isLoading) return; 
            setIsLoading(true); 
           
            const formData = new FormData();
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('username', username);
            formData.append('DOB', dob);

            
            if (profileImage) {
                formData.append('profile_picture', profileImage); 
            }

           

            axiosUserInstance
                .put('/auth/update/updatebyuserid', formData
                   
                    
                )
                .then((response) => {
                    console.log('Response data:', response.data);
                    toast.success('Your profile updated successfully');
                })
                .catch((error) => {
                
                
                    console.error('Error saving changes:', error);
        
        
                }).finally(()=>{
                    setIsLoading(false); // Reset loading to false when the request ends
                });
        }
    };

    const imageURL = userImagePreview?.trim() ? userImagePreview : null;
    const fallbackLetter = (firstName.charAt(0) + (lastName?.charAt(0) || '')).toUpperCase();

    return (
        <>
            <div className="editprofile-top-main">
                <div className="edit-profile-main-c">
                    <div className="edit-profile-top1">
                        <Link to="/profilesettings">
                            <div className="ico1">
                                <svg
                                    width="22"
                                    height="25"
                                    viewBox="0 0 42 78"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M38.9909 3L3 39L39 75"
                                        stroke="#464B59"
                                        strokeWidth="5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </Link>
                        <div className="edit-profile-text-layout">
                            <h5 className="edit-profile-text">
                                {t('editprofile.editprofile-title')}
                            </h5>
                        </div>
                        <img src={bell} alt="bell" className="msg-icon" />
                    </div>
                    <div className="edit-profile-bottom">
                        <div className="profile-gallary-container">
                            
                            <div className='edit-profile-photo'>
                                {imageURL ? (
                                    <img src={userImagePreview} className="profile-photo" alt="Profile" style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit:'cover' }} />
                                ) : (
                                    <span>{fallbackLetter}</span>
                                )}
                            </div>
                            <div className="gallary-container">
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleGalleryClick}
                                />
                                <label htmlFor="fileInput" className="edit-profile-upload">
                                    <img src={gal} alt="gallery-icon" className="gallary-img" />
                                </label>
                            </div>
                        </div>
                        <form className="person-data-container">
                            <div className="name-container">
                                <div className="firstname-container">
                                    <p className="profile-head-text">
                                        {t('editprofile.first-name-title')}
                                    </p>
                                    <TextField
                                        className="txtfld-1"
                                        placeholder={t('editprofile.first-name-placeholder')}
                                        value={firstName}
                                        onChange={handleFirstNameChange}
                                        error={!!firstNameError}
                                        helperText={firstNameError}
                                    />
                                </div>
                                <div className="lastname-container">
                                    <p className="profile-head-text">
                                        {t('editprofile.last-name-title')}
                                    </p>
                                    <TextField
                                        placeholder={t('editprofile.last-name-placeholder')}
                                        value={lastName}
                                        onChange={handleLastNameChange}
                                        error={!!lastNameError}
                                        helperText={lastNameError}
                                    />
                                </div>
                            </div>
                            <div className="username-container">
                                <p className="profile-head-text">
                                    {t('editprofile.username-title')}
                                </p>
                                <TextField
                                    placeholder={t('editprofile.username-placeholder')}
                                    value={username}
                                    onChange={handleUsernameChange}
                                    error={!!usernameError}
                                    helperText={usernameError}
                                />
                            </div>
                            <div className="dob-container">
                                <p className="profile-head-text">
                                    {t('editprofile.dob-title')}
                                </p>
                                
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={dob ? dayjs(dob, 'YYYY-MM-DD') : null}
                                        onChange={handleDobChange}
                                        slotProps={{
                                            textField: {
                                                error: !!dobError,
                                                helperText: dobError,
                                                fullWidth: true
                                            }
                                        }}
                                        maxDate={dayjs()}
                                        format="DD-MM-YYYY"
                                    />
                                </LocalizationProvider>
                            </div>
                            <div className="save-changes-layout">
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: disableButton ? 'gray' : 'var(--color4)', 
                                        color: '#FFFFFF',
                                        cursor: disableButton ? 'not-allowed' : 'pointer',
                                        opacity: disableButton ? 0.7 : 1 
                                    }}
                                    onClick={handleSaveChanges}
                                    className="save-changes-button"
                                    disabled={disableButton}
                                >
                                    {/* {t('editprofile.save-changes-button')} */}
                                    {isLoading ? ' Updating....' : 'Save Changes'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

