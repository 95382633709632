import React, { useEffect, useState } from 'react';
import img1 from '../../../assets/nbrestaurants1.png';

import star from '../../../assets/Star.png';
import { useTranslation } from 'react-i18next';
import Filters from './filters';
import './Restaurantnearyou.css';
import { useCart } from '../../../CartProvider';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import axiosUserInstance from '../../../axiosUserInstance';


export default function Restaurantnearyou() {
    const {coordinate} = useCart();
    const { t } = useTranslation();

    const [restaurantData, setRestaurantData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rating, setRating] = useState(''); 
    const [vegNonVeg, setVegNonVeg] = useState('');
    const navigate = useNavigate();

    const handleNavigation = (id) => {
        navigate(`/restaurant/${id}`);
    };

    useEffect(() =>{
        setTimeout(() =>{
            setIsLoading(false);
        },3000);
    },[]);

    useEffect(() => {
        const getRestaurantNearYou = async () => {
            try {
                const ratingQuery = rating ? rating : ''; 
                const vegQuery = vegNonVeg || '';
                const response = await axiosUserInstance.get(
                    `/posts/nearbyRestaurants?location=${coordinate?.lat},${coordinate?.lng}&pageNo=1&pageSize=10&vegNonVeg=${vegQuery}&rating=${ratingQuery}`, {skipAuth: true}
                );
                setRestaurantData(response.data.vendors);
            } catch (error) {
                console.error('Error fetching restaurants', error);
            }
        };
        getRestaurantNearYou();
    }, [rating, vegNonVeg]);

    return (
        <div className="restaurantnearyou-Main-container-11">
            <div className="restaurantnearyou-head-container-11">
                <span className="restaurantnearyou-head-text-111">
                    {t('homescreen.restaurant-near-you-title')}
                </span>
                <Filters setRating={setRating} setVegNonVeg={setVegNonVeg}></Filters>
            </div>
            <div className="restaurantnearyou-card-container-111">
                {isLoading
                    ? [...Array(10)].map((_, index) => (
                        <div key={index} className="restaurantnearyou-card-111">
                            <div className="restaurantnearyou-image-container-111">
                                <Skeleton variant="rounded" width={180} height={150} />
                            </div>
                            <div className="restaurants-data">
                                <Skeleton variant="text" width={80} height={20} />
                                <Skeleton variant="text" width={50} height={20} />
                            </div>
                        </div>
                    ))
                    : restaurantData && restaurantData.length > 0 ? (
                        restaurantData.map((restaurant, index) => (
                            <div
                                key={index}
                                className="restaurantnearyou-card-111"
                                onClick={() => handleNavigation(restaurant?.VendorID)}
                            >
                                <div className="restaurantnearyou-image-container-111">
                                    <img
                                        className="restaurantnearyou-image-111"
                                        src={restaurant?.Banner_Image || img1}
                                        alt="restaurant img"
                                    />
                                    <div className="restaurantnearyou-offer-bg-111">
                                        <span className="restaurantnearyou-offer-percentage-111">
                                            {restaurant?.Offer_percent || 0}% OFF
                                        </span>
                                    </div>
                                </div>
                                <div className="restaurants-data">
                                    <h6 className="restaurantnearyou-name-11">
                                        <span className="restaurantnearyou-text-111">
                                            {restaurant?.Vendor_Name}
                                        </span>
                                    </h6>
                                    <div className="restaurantnearyou-image-footer-111">
                                        <img
                                            className="restaurantnearyou-star-icon-image-11"
                                            src={star}
                                            alt="star icon"
                                        />
                                        <span className="restaurantnearyou-reviews-11">
                                            {restaurant?.AvgRating || 0.0} reviews
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ))
                    ):(
                        <div className="no-data-found" style={{margin:'50px 0'}}>No Restaurants found</div>
                    )
                }
            </div>
        </div>
    );
}
