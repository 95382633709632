import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import axiosUserInstance from './axiosUserInstance';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartCount, setCartCount] = useState(0);
    const [role, setRole] = useState(null);
    const [coordinate, setCoordinate] = useState({
        lat: 0,
        lng: 0,
    });
    const [currentLoginUserId, setCurrentLoginUserId] = useState(null);
    const [visitedUserId, setVisitedUserId] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [userData, setUserData] = useState(null);
    const [isLocation, setIsLocation] = useState(null);

    const token = localStorage.getItem('authToken');
    useEffect(() => {
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setCurrentLoginUserId(decodedToken?.id);
            } catch (error) {
                console.error('Invalid token:', error);
                setCurrentLoginUserId(null);
            }
        } else {
            setCurrentLoginUserId(null);
        }
    }, [token]);


    const fetchUserProfile = async () => {

        try {
            const response = await axiosUserInstance.get('/customers/account');
            const data = response.data.profileDetails[0];
            setUserData(data);
            setProfileImage(data.profile_picture);
        } catch (error) {
            console.log('Error fetching user data:', error);
        }
    };

    useEffect(() => {
        fetchUserProfile();
    }, [token]);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
    
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                setCurrentLoginUserId(decodedToken?.id);
            } catch (error) {
                console.error('Invalid token:', error);
                setCurrentLoginUserId(null);
            }
        }
    }, [currentLoginUserId]);

    const location = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coords = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setCoordinate(coords);
                },
                (error) => {
                    console.error('Error fetching geolocation:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const fetchCartCount = async () => {
        if(!token){
            setCartCount(0);
            return;
        }

        try {
            const response = await axiosUserInstance.get('/cartdetails/cartsp');
            setCartCount(response?.data?.cartDetails[0]?.Cart_Information?.length);
        } catch (error) {
            console.log('Failed to fetch cart count:', error);
        }
    };

    useEffect(() => {
        fetchCartCount();
        location();
    }, [token,cartCount]);

    return (
        <CartContext.Provider value={{ cartCount, setCartCount, fetchCartCount, role, setRole,coordinate, currentLoginUserId, setCurrentLoginUserId, visitedUserId, setVisitedUserId,profileImage, userData, setUserData, fetchUserProfile, isLocation, setIsLocation}}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};