import React from 'react';

function DeleteIconSvg({onClick,className='menu-delete-icon',color='#6D7383'}) {
    return (
        <svg
            width="18"
            height="25"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onClick={onClick}
        >
            <path
                d="M10.524 13.8689H3.43554C3.28632 13.8653 3.13926 13.8322 3.00275 13.7714C2.86625 13.7106 2.74298 13.6233 2.63999 13.5144C2.537 13.4056 2.4563 13.2774 2.40251 13.1372C2.34873 12.9969 2.3229 12.8474 2.3265 12.6971V4.97461H3.10205V12.6971C3.09835 12.7448 3.10405 12.7928 3.11883 12.8383C3.13361 12.8838 3.15717 12.9259 3.18816 12.9622C3.21915 12.9985 3.25696 13.0283 3.29942 13.0498C3.34187 13.0713 3.38813 13.0842 3.43554 13.0877H10.524C10.5714 13.0842 10.6177 13.0713 10.6602 13.0498C10.7026 13.0283 10.7404 12.9985 10.7714 12.9622C10.8024 12.9259 10.826 12.8838 10.8407 12.8383C10.8555 12.7928 10.8612 12.7448 10.8575 12.6971V4.97461H11.6331V12.6971C11.6367 12.8474 11.6108 12.9969 11.5571 13.1372C11.5033 13.2774 11.4226 13.4056 11.3196 13.5144C11.2166 13.6233 11.0933 13.7106 10.9568 13.7714C10.8203 13.8322 10.6733 13.8653 10.524 13.8689Z"
                fill={color}
            />
            <path
                d="M11.9354 4.10349H1.93855C1.83571 4.10349 1.73708 4.06234 1.66436 3.98909C1.59164 3.91583 1.55078 3.81648 1.55078 3.71288C1.55078 3.60928 1.59164 3.50993 1.66436 3.43667C1.73708 3.36342 1.83571 3.32227 1.93855 3.32227H11.9354C12.0382 3.32227 12.1368 3.36342 12.2096 3.43667C12.2823 3.50993 12.3231 3.60928 12.3231 3.71288C12.3231 3.81648 12.2823 3.91583 12.2096 3.98909C12.1368 4.06234 12.0382 4.10349 11.9354 4.10349Z"
                fill={color}
            />
            <path
                d="M8.14355 5.66602H8.9191V11.5252H8.14355V5.66602Z"
                fill={color}
            />
            <path
                d="M5.04102 5.66602H5.81656V11.5252H5.04102V5.66602Z"
                fill={color}
            />
            <path
                d="M8.91875 2.87691H8.18198V2.15037H5.77779V2.87691H5.04102V2.15037C5.04077 1.94977 5.11713 1.75676 5.25429 1.61132C5.39145 1.46589 5.57889 1.37917 5.77779 1.36914H8.18198C8.38087 1.37917 8.56832 1.46589 8.70548 1.61132C8.84263 1.75676 8.919 1.94977 8.91875 2.15037V2.87691Z"
                fill={color}
            />
        </svg>
    );
}

export default DeleteIconSvg;