import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ic1 from '../../../assets/editprofile.png';
import ic2 from '../../../assets/paymentsettings.png';
// import ic3 from '../../../assets/changelanguage.png';
// import ic4 from '../../../assets/chatwithus.png';
// import ic5 from '../../../assets/privacypolicy.png';
// import ic6 from '../../../assets/Termsandconditions.png';
// import ic8 from '../../../assets/reportaproblem.png';
// import ic9 from '../../../assets/Logout.png';
// import ic10 from '../../../assets/update adrress.png';
import Editprofile from './Editprofile.jsx';
import Changelanguage from './changelanguage.jsx';
import ChangePassword from './ChangePassword.jsx';
import UpdateAddresses from './UpdateAddresses.jsx';
import './Profilesettings.css';
import { useCart } from '../../../CartProvider.jsx';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 6 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function ProfilesettingsMain() {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mobtabs = [
        'edit-profile',
        'change-password',
        'payment-settings',
        'change-language',
        'update-addresses',
        'chat-with-us',
        'privacy-policy',
        'terms-and-conditions',
        'report-a-problem',
        'logout',
    ];

    const [value, setValue] = useState(isDesktop ? 0 : undefined);
    const { setRole, setCurrentLoginUserId } = useCart();

    useEffect(() => {
    // Update value based on current path when the component mounts
        const path = window.location.pathname.split('/').pop();
        const index = mobtabs.indexOf(path);
        if (index !== -1) {
            setValue(index);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (!isDesktop) {
            navigate(`/${mobtabs[newValue]}`);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        setRole('customer');
        setCurrentLoginUserId(null);
        navigate('/');
        window.location.reload();
    };

    return (
        <div className="profile-settings-home">
            <div className="Tabs-container">
                <Box className="left-panel">
                    <Tabs
                        orientation="vertical"
                        // variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        className="Tabs-css"
                    >
                        <Tab
                            icon={<img src={ic1} alt="profile" className="tab-icon-img" />}
                            label={t('profilesettings.edit-profile-tab')}
                            className="left-tabs1"
                            {...a11yProps(0)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic2}
                                    alt="Password"
                                    width="22px"
                                    height="17.71px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.change-password-tab')}
                            className="left-tabs1"
                            {...a11yProps(1)}
                        />
                        {/* <Tab
                            icon={
                                <img
                                    src={ic2}
                                    alt="Payment"
                                    width="22px"
                                    height="17.71px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.payment-settings-tab')}
                            className="left-tabs1"
                            {...a11yProps(2)}
                        /> */}
                        {/* <Tab
                            icon={
                                <img
                                    src={ic3}
                                    alt="language"
                                    width="22px"
                                    height="22px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.change-language-tab')}
                            className="left-tabs1"
                            {...a11yProps(3)}
                        /> */}
                        {/* <Tab
                            icon={
                                <img
                                    src={ic10}
                                    alt="Addresses"
                                    width="20px"
                                    height="22.23px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.update-addresses-tab')}
                            className="left-tabs1"
                            {...a11yProps(4)}
                        /> */}
                        {/* <Tab
                            icon={
                                <img
                                    src={ic4}
                                    alt="Chat"
                                    width="20px"
                                    height="20px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.chat-with-us-tab')}
                            className="left-tabs1"
                            {...a11yProps(5)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic5}
                                    alt="policy"
                                    width="18.65px"
                                    height="25px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.privacy-policy-tab')}
                            className="left-tabs1"
                            {...a11yProps(6)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic6}
                                    alt="T&C"
                                    width="24px"
                                    height="24px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.terms-and-conditions-tab')}
                            className="left-tabs1"
                            {...a11yProps(7)}
                        />
                        <Tab
                            icon={
                                <img
                                    src={ic8}
                                    alt="Report"
                                    width="28px"
                                    height="25.17px"
                                    className="tab-icon-img"
                                />
                            }
                            label={t('profilesettings.report-a-problem-tab')}
                            className="left-tabs1"
                            {...a11yProps(9)}
                        /> */}
                        <Tab
                            icon={
                                <svg
                                    width="22"
                                    height="22"
                                    viewBox="0 0 30 30"
                                    fill="red"
                                    xmlns="http://www.w3.org/2000/svg"
                                    stroke='red'

                                >
                                    <path
                                        d="M20.3609 10.8875L23.2359 13.75L11.2484 13.75C10.9169 13.75 10.599 13.8817 10.3645 14.1161C10.1301 14.3505 9.99842 14.6685 9.99842 15C9.99842 15.3315 10.1301 15.6495 10.3645 15.8839C10.599 16.1183 10.9169 16.25 11.2484 16.25L23.2359 16.25L20.3609 19.1125C20.2438 19.2287 20.1508 19.367 20.0873 19.5193C20.0238 19.6716 19.9912 19.835 19.9912 20C19.9912 20.165 20.0238 20.3284 20.0873 20.4807C20.1508 20.633 20.2438 20.7713 20.3609 20.8875C20.4771 21.0047 20.6154 21.0977 20.7677 21.1611C20.92 21.2246 21.0834 21.2572 21.2484 21.2572C21.4134 21.2572 21.5768 21.2246 21.7291 21.1611C21.8815 21.0977 22.0197 21.0047 22.1359 20.8875L27.1359 15.8875C27.2497 15.7686 27.3389 15.6284 27.3984 15.475C27.5234 15.1707 27.5234 14.8293 27.3984 14.525C27.3389 14.3716 27.2497 14.2314 27.1359 14.1125L22.1359 9.1125C22.0194 8.99595 21.881 8.9035 21.7287 8.84043C21.5765 8.77735 21.4132 8.74489 21.2484 8.74489C21.0836 8.74489 20.9204 8.77735 20.7681 8.84043C20.6158 8.9035 20.4775 8.99595 20.3609 9.1125C20.2444 9.22905 20.1519 9.36741 20.0888 9.51969C20.0258 9.67197 19.9933 9.83518 19.9933 10C19.9933 10.1648 20.0258 10.328 20.0888 10.4803C20.1519 10.6326 20.2444 10.771 20.3609 10.8875ZM14.9984 26.25C14.9984 25.9185 14.8667 25.6005 14.6323 25.3661C14.3979 25.1317 14.0799 25 13.7484 25H6.24842C5.9169 25 5.59896 24.8683 5.36454 24.6339C5.13012 24.3995 4.99842 24.0815 4.99842 23.75L4.99842 6.25C4.99842 5.91848 5.13012 5.60054 5.36454 5.36612C5.59896 5.1317 5.9169 5 6.24842 5L13.7484 5C14.0799 5 14.3979 4.8683 14.6323 4.63388C14.8667 4.39946 14.9984 4.08152 14.9984 3.75C14.9984 3.41848 14.8667 3.10054 14.6323 2.86612C14.3979 2.6317 14.0799 2.5 13.7484 2.5L6.24842 2.5C5.25386 2.5 4.30003 2.89509 3.59677 3.59835C2.89351 4.30161 2.49842 5.25544 2.49842 6.25L2.49842 23.75C2.49842 24.7446 2.89351 25.6984 3.59677 26.4017C4.30003 27.1049 5.25386 27.5 6.24842 27.5H13.7484C14.0799 27.5 14.3979 27.3683 14.6323 27.1339C14.8667 26.8995 14.9984 26.5815 14.9984 26.25Z"
                                        fill="red"
                                    />
                                </svg>
                            }
                            label={t('profilesettings.logout-tab')}
                            className="left-tabs1"
                            {...a11yProps(10)}
                            onClick={handleLogout}
                           
                        />
                    </Tabs>
                </Box>
                <div className="vertical-line"></div>
                {isDesktop && (
                    <Box
                        className="editprofile-main"
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <TabPanel value={value} index={0} sx={{ gap: '20px' }}>
                            <Editprofile />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ChangePassword />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
              Payment settings
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Changelanguage />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <UpdateAddresses />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
              Chat with us
                        </TabPanel>
                        <TabPanel value={value} index={6}>
              Privacy policy
                        </TabPanel>
                        <TabPanel value={value} index={7}>
              Terms and conditions
                        </TabPanel>
                        <TabPanel value={value} index={8}>
              Report a problem
                        </TabPanel>
                        <TabPanel  value={value} index={9} >
              Logout
                        </TabPanel>
                    </Box>
                )}
            </div>
        </div>
    );
}
