import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import greenDot from '../../../src/assets/greendot.svg';
import redDot from '../../../src/assets/reddot.svg';
import star from '../../../src/assets/Star.png'; 


const MenuItemsDetailsModal = ({ open = false, handleClose = () => {}, food, handleOrderClick = () => {}, orderBtn = true }) => {

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 500,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                    textAlign: 'center',
                    outline:'none',
                    maxHeight: '80vh', 
                    overflowY: 'auto'
                }}
            >
                {food?.Avatar_key && <img
                    src={food?.Avatar_key}
                    alt={food?.name}
                    style={{ width: '100%',height:'300px', borderRadius: '8px',objectFit:'cover' }}
                />}
                <Typography variant="h6" sx={{ mt: 2, fontWeight: 'bold' }}>
                    {food?.Item_name}
                </Typography>

                <div className="reddot-iten-name">
                    <img className="item-reddot-order" src={food.vegetarian ? greenDot : redDot} alt="dot" />
                    <h5 className="items-name-order">{food.menuItemName}</h5>
                </div>
                <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    <b style={{color:'black'}}>Restaurant : </b> {food?.VendorName}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    <b style={{color:'black'}}>Address : </b> {food?.VendorAddress}
                </Typography>
                <div className="Food-dist-layout">
                    <img
                        className="Food-search-rest-star-icon-image"
                        src={star}
                        alt="search-rest-star icon"
                    />
                    <span className="Food-search-rest-reviews">
                        {food?.VendorAvgRating || 0} reviews
                    </span>
                </div>
                <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    <b style={{color:'black'}}> Description : </b><span dangerouslySetInnerHTML={{ __html: food?.Description }}></span>
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    <b style={{color:'black'}}>Ingredients : </b> <span dangerouslySetInnerHTML={{ __html: food?.Ingridents }}></span>
                </Typography>
                <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                    <b style={{color:'black'}}> Price :</b> ${food?.Price}
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '16px' }}>
                    {orderBtn && <Button
                        variant="contained"
                        onClick={handleOrderClick}
                    >
          Order
                    </Button>}
                    <Button
                        variant="contained"
                        onClick={handleClose}
                    >
          Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default MenuItemsDetailsModal;
