import React from 'react';

function MenuIconSvg({ width = 30, height = 40 }){
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="vendor-list-svg-icon"
        >
            <path
                d="M24.2645 2.92381H20.5883V0.731219C20.5883 0.518127 20.4959 0.315997 20.3325 0.175535C20.1705 0.0364425 19.9562 -0.0245389 19.7411 0.00903512L0.623156 2.93271C0.592136 2.93683 0.56732 2.95464 0.537679 2.96355C0.489426 2.97657 0.44393 2.99095 0.399813 3.01288C0.358453 3.03481 0.321918 3.06084 0.286762 3.09031C0.250227 3.11977 0.215072 3.14855 0.184052 3.18418C0.153032 3.21912 0.129594 3.25749 0.106157 3.29792C0.0827198 3.33903 0.0620399 3.37808 0.0475639 3.42194C0.0310199 3.46853 0.0255053 3.51581 0.0179226 3.56377C0.0151653 3.59597 0 3.62406 0 3.65421V29.7371C0 30.1407 0.329501 30.4682 0.735517 30.4682H24.2645C24.6705 30.4682 25 30.1407 25 29.7371V3.6549C25 3.25132 24.6705 2.92381 24.2645 2.92381ZM19.1172 1.58359V2.92381H10.351L19.1172 1.58359ZM23.529 29.0067H1.47034V4.3853H23.529V29.0067Z"
                fill="#6D7383"
                className="fill-red"
            />
            <path
                d="M5.14832 15.5718H19.8538C20.2599 15.5718 20.5894 15.2443 20.5894 14.8407C20.5894 14.4372 20.2599 14.1096 19.8538 14.1096H19.1025C19.0197 11.0996 16.4816 8.66037 13.2362 8.33011V7.53187C13.2362 7.1283 12.9067 6.80078 12.5007 6.80078C12.0947 6.80078 11.7652 7.1283 11.7652 7.53187V8.33011C8.51984 8.66037 5.98172 11.1003 5.899 14.1096H5.14763C4.74161 14.1096 4.41211 14.4372 4.41211 14.8407C4.41211 15.2443 4.74161 15.5718 5.14763 15.5718H5.14832ZM12.5014 9.72515C15.2822 9.72515 17.5425 11.6807 17.6321 14.1103H7.37003C7.45965 11.6807 9.71997 9.72515 12.5007 9.72515H12.5014Z"
                className="fill-red"
            />
            <path
                d="M5.14763 25.3723H19.8531C20.2592 25.3723 20.5887 25.0448 20.5887 24.6412C20.5887 24.2377 20.2592 23.9102 19.8531 23.9102H5.14763C4.74161 23.9102 4.41211 24.2377 4.41211 24.6412C4.41211 25.0448 4.74161 25.3723 5.14763 25.3723Z"
                className="fill-red"
            />
            <path
                d="M5.14763 22.4495H11.7652C12.1712 22.4495 12.5007 22.122 12.5007 21.7184C12.5007 21.3148 12.1712 20.9873 11.7652 20.9873H5.14763C4.74161 20.9873 4.41211 21.3148 4.41211 21.7184C4.41211 22.122 4.74161 22.4495 5.14763 22.4495Z"
                className="fill-red"
            />
        </svg>
    );
}

export default MenuIconSvg;