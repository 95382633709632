import React, { useState } from 'react';
import './Food.css';
import star from '../../../assets/Star.png'; 
import img1 from '../../../assets/offersforyou1.png';
import OfferDetailsModal from './OfferDetailsModal';
import { CircularProgress } from '@mui/material';
import axiosVendorInstance from '../../../axiosVendorInstance';

function Offer({ searchTerm, results, lastElementRef, loading }) {
    const validResults = results || [];
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setSelectedOffer(null);
    };

    const fetchOfferDetails = async (offerId) => {
        try {
            const response = await axiosVendorInstance.get(`/vendor/offers/offferbyid/${offerId}`,{skipAuth: true}); 
            setSelectedOffer(response?.data?.offer);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };


    const filteredOffers = validResults.filter((offers) =>
        offers?.Vendor_Name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <div className="food-container-fake">
            <div className="Food-Nearby-restaurants-cards-container">
                {filteredOffers.length > 0 ? (
                    filteredOffers?.map((offer, index) => {
                        const isLastElement = index === filteredOffers.length - 1;
                        return(<div key={index} className="Food-search-restaurants-card" ref={isLastElement ? lastElementRef : null}>
                            <div className="img-and-offer-percentage" onClick={() => {
                                fetchOfferDetails(offer?.OfferID);
                            }}>
                                <img
                                    className="Food-search-restaurants-image"
                                    src={offer?.Vendor_Banner_Image || img1}
                                    alt="Food img"
                                    onError={(e) => {
                                        e.target.src = img1; 
                                    }}
                                />
                                <h5 className="offer-percentage">{offer.Offer_Percentage || 0}% OFF</h5>
                            </div>
                            <div className="Food-search-restaurants-content">
                                <span className="Food-search-restaurants-text">
                                    {offer.Vendor_Name}
                                </span>
                                <div className="Food-search-restaurant-footer">
                                    <div className="Food-dist-layout">
                                        <img
                                            className="Food-search-rest-star-icon-image"
                                            src={star}
                                            alt="search-rest-star icon"
                                        />
                                        <span className="Food-search-rest-reviews">
                                            {offer.Restaurant_ratings || 0} reviews
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>);
                    })
                ) : (
                    <h5 className="no-data-found">No Offer Found</h5>
                )}
            </div>
            {loading && <div style={{ textAlign: 'center', padding: '20px' }}>
                <CircularProgress />
            </div>}

            {selectedOffer && (
                <OfferDetailsModal 
                    open={open} 
                    handleClose={handleClose} 
                    offer={selectedOffer}
                />
            )}
        </div>
    );
}

export default Offer;
