import React from 'react';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '../../assets/svg/closeIcon';
import RoundDeleteIconSvg from '../../assets/svg/roundDeleteIcon';

function AddMenuType({ onClose, menuId, deleteMenuType }) {
    const deleteMenu = () => {
        deleteMenuType(menuId);
        onClose();
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="menu-Model-container">
                    <div className="add-edit-menu-close-icon">
                        <CloseIcon
                            className={'menu-add-menu-popup-cross-icon'} 
                            onClick={onClose} 
                            color='#e13535'
                            height='25'
                            width='25'
                        />
                    </div>
                    <div className="edit-popup">
                        <RoundDeleteIconSvg/>
                        <h5 className="menu-deletepopup-delete-text">
              You are about to delete an Menu?
                        </h5>
                        <p className="menu-deletepopup-small-heading">
              This Menu will be delete permanently
                        </p>
                        <div className="menu-deletepopup-btns-container">
                            <Button
                                onClick={onClose}
                                className={'menu-deletepopup-cancel-btn'}
                            >
                Cancel
                            </Button>
                            <Button
                                onClick={deleteMenu}
                                className={'menu-deletepopup-delete-btn'}
                            >
                Delete
                            </Button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default AddMenuType;
