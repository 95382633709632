import React from 'react';

const PaypalIcon = () => {
    return (
        <svg
            width="17"
            height="19.42"
            viewBox="0 0 17 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="payment-svg-icon"
        >
            <path
                d="M13.6165 1.8395C12.7327 0.811136 11.1895 0.289062 9.03322 0.289062H3.62793C2.94317 0.289062 2.3701 0.78564 2.26325 1.47041L0.0122672 16.0399C-0.0350837 16.3544 0.0559758 16.6725 0.262377 16.9129C0.465136 17.1508 0.761382 17.2868 1.0722 17.2868H4.92947L5.82186 11.5051C5.84007 11.4238 5.90684 11.3655 5.98212 11.3655H7.56777C11.4214 11.3655 13.7319 9.50791 14.43 5.84611L14.4919 5.49523C14.7226 3.91323 14.4616 2.82051 13.6165 1.8395ZM13.2924 5.30583L13.2377 5.61543C12.6562 8.66652 10.8022 10.1502 7.56898 10.1502H5.98333C5.35806 10.1502 4.82506 10.5666 4.65751 11.1616L4.6478 11.1591L4.6223 11.3255L4.62109 11.3303L3.88897 16.0727H1.23611L3.46281 1.65617C3.47617 1.56754 3.54537 1.50319 3.62793 1.50319H9.032C10.8192 1.50319 12.0527 1.88199 12.6974 2.63111C13.3045 3.33652 13.4696 4.09049 13.2924 5.30583Z"
                fill="#020BEE"
            />
            <path
                d="M16.047 4.25905C15.5686 3.71026 14.8935 3.3011 14.04 3.04492L13.6916 4.20805C14.3217 4.39746 14.8061 4.68278 15.1291 5.0543C15.7301 5.75364 15.9 6.53067 15.7204 7.72659C15.7204 7.72659 15.6876 7.92206 15.6609 8.04226C15.0854 11.0921 13.2326 12.5746 9.99333 12.5746H8.41496C7.73505 12.5746 7.16077 13.07 7.05029 13.7571L6.31695 18.4995H3.66045L3.92756 16.7706L2.728 16.5861L2.43418 18.485C2.39533 18.8006 2.49246 19.1163 2.70008 19.3518C2.90405 19.5825 3.19423 19.7136 3.49776 19.7136H7.35746L8.24863 13.9466C8.26198 13.8676 8.32269 13.7887 8.41496 13.7887H9.99333C13.8555 13.7887 16.1635 11.9311 16.8483 8.28994C16.8762 8.16974 16.9175 7.92813 16.9187 7.91721C17.153 6.36191 16.8847 5.2352 16.047 4.25905Z"
                fill="#020BEE"
            />
        </svg>
    );
};

export default PaypalIcon;
