import React, { useRef, useState, useEffect } from 'react';
import '../../VendorStyles/UpdateItem.css';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axiosVendorInstance from '../../axiosVendorInstance';
import Loader from '../../Components/Loader/Loader';
import LeftArrow from '../../assets/backIcon-white.png';
import VendorImageMenuPlaceholderSvg from '../../assets/svg/vendorImageMenuPlaceholder';
function UpdateItem({
    itemId,
    handleSave,
    handleBack,
    menuTypes,
    setUpdatedImage,
    isLoading,
}) {
    const fileInputRef = useRef(null);
    const [previewFile, setPreviewFile] = useState(null);
    const [fileType, setFileType] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        price: '',
        percentage: '',
        menuType: '',
        Ingredients: '',
        vegetarian: '',
    });
    const [formErrors, setFormErrors] = useState({
        name: '',
        description: '',
        price: '',
        menuType: '',
        Ingredients: '',
        vegetarian: '',
    });
    const [loading, setLoading] = useState(true);
    console.log(loading);
    const [error, setError] = useState(null);
    console.log(error);

    const fetchItemDetails = async () => {
        setLoading(true);
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`,
                { skipAuth: true }
            );
            const item = response.data.menuItem;
            setFormData({
                id: item.ID,
                name: item.Item_name,
                description: item?.Description || '',
                price: item.Price,
                percentage: item.percentage,
                menuType: item.Menu_id || '',
                Ingredients: item.Ingridents,
                vegetarian: item.vegetarian,
            });

            if (item.Avatar_key) {
                setPreviewFile(item.Avatar_key);
                const fileExtension = item.Avatar_key.split('.').pop().toLowerCase();
                if (
                    ['jpg', 'jpeg', 'png', 'webp', 'tiff', 'gif', 'bmp', 'html'].includes(
                        fileExtension
                    )
                ) {
                    setFileType('image');
                } else if (['mp4'].includes(fileExtension)) {
                    setFileType('video');
                } else {
                    setFileType(null);
                }
            }

            setLoading(false);
        } catch (err) {
            setError('Error fetching item details');
            console.error('Error fetching item details:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (itemId) {
            fetchItemDetails();
        }
    }, [itemId]);

    useEffect(() => {
        console.log('formData updated:', formData);
    }, []);

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    if (error) {
        return <div>{error}</div>;
    }

    console.log(formData);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        // Reset error message and preview
        setPreviewFile(null);

        if (file) {
            // Check file type
            const fileExtension = file.name.split('.').pop().toLowerCase();

            // Update valid file types array to include new formats
            const validImageTypes = [
                'jpg',
                'jpeg',
                'png',
                'webp',
                'tiff',
                'gif',
                'bmp',
            ];
            const validVideoTypes = ['mp4'];
            const validHtmlTypes = ['html']; // Added for .html files

            // Check if the file is an image or HTML file
            if (
                (validImageTypes.includes(fileExtension) &&
          file.type.startsWith('image/')) ||
        (validVideoTypes.includes(fileExtension) &&
          file.type.startsWith('video/')) ||
        (validHtmlTypes.includes(fileExtension) && file.type === 'text/html')
            ) {
                // Check file size (limit to 10MB only for images)
                const fileSizeInMB = file.size / (1024 * 1024); // Convert to MB
                if (fileSizeInMB > 10) {
                    // setImageError('Image file size should not exceed 10MB.');
                    alert('File size should not exceed 10MB.');
                    return;
                }

                // Set the file type and uploaded file state
                const fileType = file.type.split('/')[0]; // 'image' or 'video'
                setFileType(fileType);
                setUpdatedImage(file);

                // Set the preview URL
                const previewUrl = URL.createObjectURL(file);
                setPreviewFile(previewUrl);
            } else {
                // setImageError('Only .jpg, .png images, .html files, or .mp4 videos are allowed.');
                alert('upload valid Images or videos.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (formData[name] !== value) {
            // Only update if the value changes
            validateField(name, value); // Validate on change
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleIngredientsChange = (value) => {
        if (formData.Ingredients !== value) {
            // Only update if the value changes
            validateField('Ingredients', value); // Validate on change for React Quill
            setFormData({ ...formData, Ingredients: value });
        }
    };

    const handleQuillChange = (value) => {
        if (formData.description !== value) {
            // Only update if the value changes
            validateField('description', value);
            setFormData({ ...formData, description: value });
        }
    };

    const validateField = (name, value) => {
        let errorMessage = '';

        const nameRegex = /^[a-zA-Z0-9\s]{4,}$/;

        const priceRegex = /^(100(\.00?)?|(\d{1,2}(\.\d{1,2})?))$/;

        switch (name) {
        case 'name':
            if (!value) {
                errorMessage = 'Food name is required.';
            } else if (!nameRegex.test(value)) {
                errorMessage = 'Food name should be at least 4 characters.';
            }
            break;
        case 'price':
            if (!value) {
                errorMessage = 'Price is required.';
            } else if (!priceRegex.test(value)) {
                errorMessage = 'Price must be a valid number.';
            }
            break;
        case 'menuType':
            if (!value) {
                errorMessage = 'Menu Type is required.';
            }
            break;
        case 'description':
            if (!value) {
                errorMessage = 'Description is required.';
            } else if (value.length < 10) {
                errorMessage = 'Description should be at least 10 characters.';
            }
            break;
        case 'Ingredients':
            if (!value) {
                errorMessage = 'Ingredients are required.';
            } else if (value.length < 10) {
                errorMessage = 'Ingredients should be at least 10 characters.';
            }
            break;
        case 'vegetarian':
            if (value === undefined || value === null) {
                errorMessage = 'Food Type is required.';
            }
            break;
        default:
            break;
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields against their specific rules
        let isValid = true;
        const fieldsToValidate = [
            'name',
            'price',
            'menuType',
            'description',
            'Ingredients',
            'vegetarian',
        ];

        fieldsToValidate.forEach((key) => {
            validateField(key, formData[key]); // Re-validate each field
            if (formErrors[key]) {
                isValid = false; // If any field has an error, set isValid to false
            }
        });

        if (isValid) {
            handleSave({ ...formData });
        } else {
            toast.error('Please fix the errors before submitting.');
        }
    };

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (
        <div className="update-menu-items-main-container">
            <div
                className="back-btn-menuItems"
                type="button"
                onClick={handleBack}
            >
                <div className="back-arrow-image-vendor">
                    <img src={LeftArrow} alt="" />
                </div>
            </div>
            <div className="menu-new-div">
                <div className="menu-image-container">
                    <div className="edit-image-or-video-svg-and-btn">
                        <VendorImageMenuPlaceholderSvg
                            onClick={() => fileInputRef.current.click()}
                        />
                        <h5 className="menu-add-item-update-photo-heading">
              Click to add Photo
                        </h5>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept="image/*,video/*"
                            onChange={handleFileChange}
                        />
                    </div>

                    <div className="image-or-video-preview-menuitems">
                        {previewFile && fileType === 'image' && (
                            <img
                                src={previewFile}
                                alt="Selected Preview"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        )}
                        {previewFile && fileType === 'video' && (
                            <video width="100%" height="100%" controls>
                                <source src={previewFile} type="video/mp4" />
                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                </div>

                <div className="menu-additem-rightside-content">
                    <div className="menu-additem-rightside-content-1">
                        <div className="menu-additem-food-name-container">
                            <div className="add-menu-name-input">
                                <p className="menu-additem-food-name-text">Food Name</p>

                                <input
                                    type="text"
                                    placeholder="Enter Food Name"
                                    className="menu-additem-food-name-inputbox"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    maxLength={30}
                                />
                            </div>
                            {formErrors.name && (
                                <div className="error-message">{formErrors.name}</div>
                            )}
                        </div>

                        <div className="menu-additem-food-name-container">
                            <div className="add-menu-name-input">
                                <p className="menu-additem-food-name-text">Menu Type</p>
                                <select
                                    className="menu-additem-menu-type-inputbox"
                                    name="menuType"
                                    value={formData.menuType}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Menu</option>
                                    {menuTypes.map((menu) => (
                                        <option key={menu.ID} value={menu.ID}>
                                            {menu.Menu_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {formErrors.menuType && (
                                <div className="error-message">{formErrors.menuType}</div>
                            )}
                        </div>
                    </div>

                    <div className="menu-additem-rightside-content-1">
                        <div className="menu-additem-food-name-container">
                            <div className="add-menu-name-input">
                                <p className="menu-additem-food-name-text">Price</p>
                                <input
                                    type="text"
                                    placeholder="$"
                                    className="menu-additem-food-name-inputbox"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                />
                            </div>
                            {formErrors.price && (
                                <div className="error-message">{formErrors.price}</div>
                            )}
                        </div>
                        <div className="menu-additem-food-name-container">
                            <div className="add-menu-name-input">
                                <p className="menu-additem-food-name-text">Food Type</p>
                                <select
                                    className="menu-additem-menu-type-inputbox"
                                    name="vegetarian"
                                    value={formData.vegetarian}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Type</option>
                                    <option value="false">Non-Veg</option>
                                    <option value="true">Veg</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="react-quill-description-ingrediants">
                        <div className="add-menu-name-input">
                            <p className="menu-additem-food-name-text">Description</p>
                            <ReactQuill
                                theme="snow"
                                name="description"
                                value={formData.description || ''}
                                onChange={handleQuillChange}
                                placeholder="Enter Description"
                            />
                        </div>
                        {formErrors.description && (
                            <div className="error-message">{formErrors.description}</div>
                        )}
                    </div>

                    <div className="react-quill-description-ingrediants">
                        <div className="add-menu-name-input">
                            <p className="menu-additem-food-name-ingridents">Ingredients</p>
                            <ReactQuill
                                theme="snow"
                                value={formData.Ingredients || ''}
                                onChange={handleIngredientsChange}
                                placeholder="Enter Ingridents"
                            />
                        </div>
                        {formErrors.Ingredients && (
                            <div className="error-message">{formErrors.Ingredients}</div>
                        )}
                    </div>

                    <button
                        className="menu-additem-btn"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Updating...' : 'Save Update'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UpdateItem;
