import React,{ useState} from 'react';
import '../../Styles/SideMenuBar.css';
import '../../Styles/Colors.css';      
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Badge from '@mui/material/Badge';
import omfLogo from '../../assets/svg/logo.svg';
import omfLogoText from '../../assets/svg/logoText.svg';
import CreatePost from '../../Views/User/CreatePostUi/CreatePost';

import { useCart } from '../../CartProvider';
import authMiddleware from '../authMiddleware';
import UserHome from '../../assets/svg/userHomeIcon';
import UserReels from '../../assets/svg/userReelsIcon';
import UserPostIcon from '../../assets/svg/userPostIconWeb';
import UserOrderIcon from '../../assets/svg/userOrderIcon';
import UserCart from '../../assets/svg/userCart';
import UserPostIconMobile from '../../assets/svg/userPostIconMobile';

function SideMenuBar({getPosts=()=>{}}) {

    const { cartCount } = useCart();
    
    const [openInitialPopup, setOpenInitialPopup] = useState(false);
    const [openCarouselPopup, setOpenCarouselPopup] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [filters, setFilters] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const handleOpenPost = () => {
        if (!authMiddleware()) {  
            return;
        }
        setOpenInitialPopup(true);
    };    
    const {t} = useTranslation();

    const handleNavigate = () =>{
        navigate('/dashboard');
    };
    
    const handleNavigationOrders = (e) => {
        e.preventDefault(); 
        if (authMiddleware()) {
            navigate('/orders');
        } else {
            return;
        }
    };
    
    const handleNavigationCarts = (e) => {
        e.preventDefault();
        if (authMiddleware()) {
            navigate('/cart');
        } else {
            return;
        }
    };


    return (
        <>
            <div className="menu-container">
                <div className='sidebar-scrollable'>
                    <div>
                        <div className="omf-container" onClick={() => handleNavigate()}>
                            <img src={omfLogo} alt="logo" className='omf-svg-logo'/>
                            <img src={omfLogoText} alt="logo-text" className='omf-svg-title'/>
                        </div>

                        <ul className="menu-items">

                            <li>
                                <NavLink  className="list-container" to='/Dashboard' >
                                    <UserHome/>
                                    <h3 className="list-text">{t('sidemenubar.home')}</h3>
                                    <p className='icon-underline'></p>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink className="list-container" to='/reels' >
                                    <UserReels/>
                                    <h3 className="list-text" 
                                    >
                                        {t('sidemenubar.reels')}
                                    </h3>
                                    <p className='icon-underline'></p>
            
                                </NavLink>
                            </li>


                            <li className="list-container" onClick={()=>handleOpenPost()}>
                                <UserPostIcon/>
                                <UserPostIconMobile/>
                                <h3 className="list-text post">{t('sidemenubar.post')}</h3>
                            </li>

                            {
                                openInitialPopup && (
                                    <CreatePost
                                        openInitialPopup={openInitialPopup}
                                        setOpenInitialPopup={setOpenInitialPopup}
                                        openCarouselPopup={openCarouselPopup}
                                        setOpenCarouselPopup={setOpenCarouselPopup}
                                        showFilters={showFilters}
                                        setShowFilters={setShowFilters}
                                        showPostDetails={showPostDetails}
                                        setShowPostDetails={setShowPostDetails}
                                        selectedFiles={selectedFiles}
                                        setSelectedFiles={setSelectedFiles}
                                        filters={filters}
                                        setFilters={setFilters}
                                        getPosts={getPosts}   
                                    />
                                )
                            }

                            <li>
                                <div 
                                    className={`list-container ${location.pathname === '/orders' ? 'active' : ''}`}
                                    onClick={handleNavigationOrders} >
                                    <UserOrderIcon/>
                                    <h3 className="list-text">{t('sidemenubar.inbox')}</h3>
                                    <p className='icon-underline'></p>
                                </div>
                            </li>

                            <li>

                                <div  
                                    className={`list-container ${location.pathname === '/cart' ? 'active' : ''}`}
                                    onClick={handleNavigationCarts}>
                                    <Badge badgeContent={cartCount} color="primary" >
                                        <UserCart/>
                                    </Badge>
                                    <h3 className="list-text">{t('sidemenubar.cart')}</h3>
                                    <p className='icon-underline'></p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <h3 className="text-in-sidemenu">
                        {t('sidemenubar.text')}
                    </h3>
                </div>
            </div>
        </>
    );
}

export default SideMenuBar;
