import React from 'react';

function UserCart() {
    return (
        <svg
            width="24"
            height="24.51"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="list-svg-icon fill-red"
        > 
            <path
                d="M5.00391 21.9771C5.00391 23.3748 6.12718 24.5127 7.50702 24.5127C8.88687 24.5127 10.0101 23.3748 10.0101 21.9771C10.0101 20.5793 8.88687 19.4414 7.50702 19.4414C6.12718 19.4414 5.00391 20.5793 5.00391 21.9771ZM8.3414 21.9771C8.3414 22.443 7.96697 22.8223 7.50702 22.8223C7.04708 22.8223 6.67265 22.443 6.67265 21.9771C6.67265 21.5111 7.04708 21.1318 7.50702 21.1318C7.96697 21.1318 8.3414 21.5111 8.3414 21.9771Z"
            />
            <path
                d="M20.0258 21.9771C20.0258 20.5793 18.9025 19.4414 17.5226 19.4414C16.1428 19.4414 15.0195 20.5793 15.0195 21.9771C15.0195 23.3748 16.1428 24.5127 17.5226 24.5127C18.9025 24.5127 20.0258 23.3748 20.0258 21.9771ZM16.6883 21.9771C16.6883 21.5111 17.0627 21.1318 17.5226 21.1318C17.9826 21.1318 18.357 21.5111 18.357 21.9771C18.357 22.443 17.9826 22.8223 17.5226 22.8223C17.0627 22.8223 16.6883 22.443 16.6883 21.9771Z"
            />
            <path
                d="M2.58655 1.69044C2.68981 2.68886 3.84124 13.7676 4.01907 15.4791C4.15361 16.7734 5.22369 17.7496 6.50863 17.7496H19.5108C20.683 17.7496 21.7125 16.906 21.9586 15.7449L23.9627 6.271C24.0685 5.76915 23.947 5.25251 23.6279 4.8542C23.3092 4.45536 22.8362 4.2261 22.3294 4.2261H4.52803L4.16769 0.757528C4.12284 0.326466 3.76458 0 3.33749 0H0.834372C0.373903 0 0 0.378764 0 0.84522C0 1.31168 0.373903 1.69044 0.834372 1.69044H2.58655ZM22.3304 5.91654L20.3269 15.3904C20.2445 15.7776 19.9013 16.0592 19.5102 16.0592H6.50915C6.08049 16.0592 5.72379 15.7338 5.67895 15.3027L4.70377 5.91654H22.3309H22.3304Z"
            />
        </svg>
    );
}

export default UserCart;