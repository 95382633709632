import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../../VendorStyles/vendorregistrationvalidation.css';

function IdentityDetailsForm({
    formData,
    handleChange,
    handlePhoneChange2,
    errors,
    handleEINChange,
    handleDOBChange,
}) {
    return (
        <div className="identity-details-main-container">
            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="legalBusinessName">
          Legal Business Name
                </label>
                <input
                    className={`full-name-input ${
                        errors.legalBusinessName ? 'error' : ''
                    }`}
                    type="text"
                    id="legalBusinessName"
                    name="legalBusinessName"
                    placeholder="Enter your legal business name"
                    value={formData.legalBusinessName}
                    onChange={handleChange}
                    maxLength={50}
                    required
                />
                {errors.legalBusinessName && (
                    <p className="error-text">{errors.legalBusinessName}</p>
                )}
            </div>
            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="taxId">
          EIN/Tax ID Number
                </label>
                <input
                    className={`full-name-input ${errors.taxId ? 'error' : ''}`}
                    type="numeric"
                    id="taxId"
                    name="taxId"
                    placeholder="XX-XXXXXXX"
                    value={formData.taxId}
                    onChange={handleEINChange}
                    maxLength={10}
                    required
                />
                {errors.taxId && <p className="error-text">{errors.taxId}</p>}
            </div>
            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="representativeName">
          Representative Name
                </label>
                <input
                    className={`full-name-input ${
                        errors.representativeName ? 'error' : ''
                    }`}
                    type="text"
                    id="representativeName"
                    name="representativeName"
                    placeholder="Enter representative name"
                    value={formData.representativeName}
                    onChange={handleChange}
                    maxLength={50}
                    required
                />
                {errors.representativeName && (
                    <p className="error-text">{errors.representativeName}</p>
                )}
            </div>
            <div className="name-container-vendor">
                <label className="label-text-required">Contact Phone Number</label>
                <PhoneInput
                    disableCountryCode={true}
                    disableDropdown={true}
                    country="us"
                    onlyCountries={['us']}
                    placeholder="Enter Your Phone Number"
                    value={formData.phoneNumber2}
                    onChange={handlePhoneChange2}
                    inputStyle={{}}
                />
                {errors.phoneNumber2 && (
                    <p className="error-text">{errors.phoneNumber2}</p>
                )}
            </div>
            <div className="name-container-vendor">
                <label className="label-text-required" htmlFor="dob">
          Date of Birth
                </label>
                <input
                    className={`calender-input ${errors.dob ? 'error' : ''}`}
                    type="date"
                    id="dob"
                    name="dob"
                    placeholder="15-05-1995"
                    value={formData.dob}
                    onChange={handleDOBChange}
                    max={new Date().toISOString().split('T')[0]}
                    required
                />
                {errors.dob && <p className="error-text">{errors.dob}</p>}
            </div>
        </div>
    );
}

export default IdentityDetailsForm;
