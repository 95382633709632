import React from 'react';

function UnMuteIcon({onClick, className}) {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            size={30}
            className={className}
            onClick={onClick} 
        >
            <g clipPath="url(#clip0_3726_3204)">
                <rect width="100" height="100" fill="white" />
                <circle cx="50" cy="50" r="50" fill="#E4EDF9" />
                <g clipPath="url(#clip1_3726_3204)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M54.04 26.0727C53.1966 26.2266 52.3765 26.627 51.7143 27.3039L51.8979 27.1362L35.04 40.4895H27.8755C24.6538 40.4895 22 43.2022 22 46.4955V54.5035C22 57.7968 24.6538 60.5094 27.8755 60.5094H35.04L51.8979 73.8627L51.7143 73.6951C53.0387 75.0489 54.9936 75.3067 56.4992 74.6685C58.0048 74.0304 59.2117 72.4351 59.2117 70.5194V30.4796C59.2117 28.5652 58.006 26.9686 56.4992 26.3304C55.7464 26.0114 54.8822 25.9188 54.04 26.0727ZM54.9997 30.0291C55.1882 30.1092 55.2947 30.178 55.2947 30.4783V70.5182C55.2947 70.8197 55.1882 70.8885 54.9997 70.9674C54.8112 71.0474 54.6912 71.075 54.4831 70.8623C54.4244 70.8022 54.3619 70.7459 54.2958 70.6946L36.9104 56.9234C36.5664 56.6519 36.1441 56.5042 35.7096 56.5055H27.8755C26.7702 56.5055 25.917 55.6334 25.917 54.5035V46.4955C25.917 45.3656 26.7702 44.4935 27.8755 44.4935H35.7096C36.1441 44.4935 36.5664 44.3471 36.9104 44.0756L54.2958 30.3044C54.3619 30.2518 54.4244 30.1968 54.4831 30.1367C54.6912 29.924 54.8112 29.9515 54.9997 30.0316V30.0291Z"
                        fill="#2C3142"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M64.3534 45.0396C63.5113 45.5789 63.2555 46.7138 63.783 47.5746C64.3192 48.4517 64.6019 49.4653 64.6019 50.4988C64.6019 51.5323 64.3192 52.5458 63.783 53.4229C63.2555 54.2838 63.5088 55.4187 64.351 55.9592C65.1932 56.4985 66.3034 56.237 66.831 55.3761C66.831 55.3761 66.831 55.3749 66.8322 55.3736C67.7258 53.9122 68.1995 52.2217 68.1995 50.4988C68.1995 48.7758 67.7258 47.0854 66.8322 45.6239C66.3058 44.7618 65.1956 44.4991 64.3534 45.0371C64.3534 45.0371 64.3522 45.0371 64.351 45.0383L64.3534 45.0396Z"
                        fill="#2C3142"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M70.2917 41.8825C69.8266 41.7736 69.3394 41.8574 68.9354 42.1164C68.0933 42.6545 67.8374 43.7881 68.3626 44.649C69.4361 46.4032 70.0041 48.4327 70.0041 50.501C70.0041 52.5693 69.4361 54.5989 68.3626 56.3531C67.8374 57.214 68.0933 58.3476 68.9354 58.8856C69.7776 59.4224 70.8866 59.1609 71.413 58.3C72.8439 55.9615 73.6028 53.2588 73.6028 50.501C73.6028 47.7433 72.8439 45.0406 71.413 42.702C71.1608 42.2879 70.7569 41.9926 70.2917 41.8825Z"
                        fill="#2C3142"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M74.8696 38.9585C74.4045 38.8472 73.9149 38.9298 73.5097 39.1888C72.6675 39.7281 72.4117 40.8629 72.9393 41.7238C74.5477 44.3552 75.3997 47.3944 75.3997 50.4975C75.3997 53.6006 74.5477 56.6399 72.9393 59.2712C72.4117 60.1321 72.6651 61.267 73.5072 61.8075C74.3494 62.3468 75.4596 62.0853 75.9872 61.2244C75.9872 61.2244 75.9872 61.2232 75.9884 61.2219C77.9543 58.0062 78.9972 54.29 78.9972 50.4975C78.9972 46.705 77.9543 42.9888 75.9884 39.7731C75.735 39.3602 75.3323 39.0674 74.8672 38.9573L74.8696 38.9585Z"
                        fill="#2C3142"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_3726_3204">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
                <clipPath id="clip1_3726_3204">
                    <rect
                        width="57"
                        height="49"
                        fill="white"
                        transform="translate(22 26)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default UnMuteIcon;