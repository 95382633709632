import React from 'react';
import '../../../Styles/Cart/CookingInstruction.css';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../assets/svg/closeIcon';

function CookingInstruction({
    handleClose,
    cookingInstructionValue,
    handleCookingInputChange = () => {},
    handleSubmitCookingInst = () => {},
    cookingErrorMessage,
}) {
    const { t } = useTranslation();

    return (
        <>
            <div className='cook-inst-main-container'>
                <div>
                    <div className='cook-inst-title'>
                        <h1 className='title-cooking-inst'>
                            {t('cart.add-cooking-title')}{' '}
                            {t('cart.instructions-to-restaurant-title')}
                        </h1>
                        <CloseIcon
                            size={30}
                            color='black'
                            onClick={handleClose}
                            className='close-icon'
                        />
                    </div>
                </div>
                <div className='textarea-container'>
                    <textarea
                        className='input-cooking-inst'
                        placeholder={t('cart.cooking-inst-placeholder')}
                        value={cookingInstructionValue}
                        onChange={handleCookingInputChange}
                    ></textarea>
                    {cookingErrorMessage && (
                        <p className='error-message'>{cookingErrorMessage}</p>
                    )}
                </div>
                <button
                    className='submit-btn-cooking-inst'
                    onClick={() => handleSubmitCookingInst()}
                    disabled={!cookingInstructionValue || cookingErrorMessage}
                    style={{
                        backgroundColor: 'var(--color4)',
                        color: '#FFFFFF',
                        cursor:
              (!cookingInstructionValue || cookingErrorMessage) ? 'not-allowed' : 'pointer',
                        opacity: (!cookingInstructionValue || cookingErrorMessage) ? 0.7 : 1,
                    }}
                >
                    {t('cart.submit-button')}
                </button>
            </div>
        </>
    );
}

export default CookingInstruction;
