import React, { useState, useRef, useEffect } from 'react';
import '../../../VendorStyles/VendorTopMenuBar.css';
import vendorDefaultBannerImage from '../../../assets/vendortopbackground.png';
import Notification from '../../User/Notification/Notification';
import axiosUserInstance from '../../../axiosUserInstance';
import axiosVendorInstance from '../../../axiosVendorInstance';
import { Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import VendorNotificationIcon from '../../../assets/svg/vendorNotificationIcon';
import { useNotifications } from '../../User/Notification/NotificationContext';

function VendorTopMenuBar() {

    
   
    
    
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const overlayRef = useRef(null);
    const [NotificationOverlay, setNotificationOverlay] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [vendorName, setVendorName] = useState();
    const [restaurantImagePreview, setRestaurantImagePreview] = useState();
    const [bannerImagePreview, setBannerImagePreview] = useState();
    const [userImagePreview, setUserImagePreview] = useState();
    
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const { notificationCountvalue } = useNotifications();
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 601);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    
    const handleProfile = () => {
        navigate('/Vendorsettings');
    };

    const fetchVendor = async () => {
        const response = await axiosVendorInstance.get(
            '/vendors/account'
        );

        setVendorName(response.data.getVendorInfo[0].Name);
        setRestaurantImagePreview(response.data.getVendorInfo[0].Avatar_key);
        setBannerImagePreview(response.data.getVendorInfo[0].Banner_Image);
    };

    useEffect(() => {
        fetchVendor();
    }, []);

    useEffect(() => {
        axiosUserInstance
            .get('/auth/user/userbyId')
            .then((response) => {
                const data = response.data.user;
                setFirstName(data.first_name || '');
                setLastName(data.last_name || '');
                setUserImagePreview(data.profile_picture);
            })
            .catch((error) => console.error('Error fetching user data:', error));
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                setNotificationOverlay(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const closenotificationoverlay = () => {
        setNotificationOverlay(false);
    };

    const restaurantImageURL = restaurantImagePreview?.trim()
        ? restaurantImagePreview
        : null;
    const restaurantName = (vendorName || '').toUpperCase();

    const imageURL = userImagePreview?.trim() ? userImagePreview : null;
    const fallbackLetter = (
        firstName.charAt(0) + (lastName?.charAt(0) || '')
    ).toUpperCase();

    return (
        <>
            <div className="vendor-topbar-container">
                <div className="desktop-vendor-background">
                    <img
                        src={bannerImagePreview || vendorDefaultBannerImage}
                        alt="Banner"
                    />
                </div>
                <div className="vendor-restaurant-logo-container">
                    {isLoading ? (
                        <Skeleton variant="square" height={130} width="100px" />
                    ) : restaurantImageURL ? (
                        <img
                            className="vendor-restaurant-logo"
                            src={restaurantImagePreview}
                            alt="profile"
                        />
                    ) : (
                        <span className="fallback-restaurant-name">{restaurantName}</span>
                    )}
                </div>
                <div className="vendor-turn-on-location-container">
                    {isLoading ? (
                        <Skeleton variant="text" width={200} height={50} />
                    ) : (
                        <h6 className="vendor-name">{vendorName}</h6>
                    )}

                    <p className="vendor-turn-on-location-description"></p>
                </div>

                <div
                    className="vendor-profile-and-notification"
                  
                >
                    {isLoading ? (
                        <Skeleton
                            className="vendor-profile-icon"
                            variant="circle"
                            height={50}
                            width={50}
                        />
                    ) : imageURL ? (
                        <img
                            className="vendor-profile-icon"
                            src={userImagePreview}
                            alt="profile"
                            onClick={handleProfile}
                        />
                    ) : (
                        <span className="vendor-fallback-profile-photo">
                            {fallbackLetter}
                        </span>
                    )}

                    <div className="vendor-topbar-top-icons">
                        {isLoading ? (
                            <Skeleton
                                className="vendor-notification-icon"
                                variant="rounded"
                                height={50}
                                width={50}
                            />
                        ) : (
                            <VendorNotificationIcon  
                                onClick={() => setNotificationOverlay(!NotificationOverlay)}
                                notificationcount={notificationCountvalue}  // Ensure it shows "0" when no notifications
                                className={'vendor-notification-icon'}
                            />
                        
                        )}
                    </div>
                    {NotificationOverlay && (
                        <>
                            <div className="vendor-arrow-to-point-notification"></div>
                            <div className="vendor-notification-overlay" ref={overlayRef}>
                                <Notification closeOverlay={closenotificationoverlay} />
                            </div>
                        </>
                    )}
                </div>
            </div>


            {isMobile && (
                <div className="vendor-topbar-container">
                    {isLoading ? (
                        <Skeleton variant="square" height={130} width="100px" />
                    ) : restaurantImageURL ? (
                        <img
                            className="vendor-restaurant-logo"
                            src={restaurantImagePreview}
                            alt="profile"
                        />
                    ) : (
                        <span className="fallback-restaurant-name">{restaurantName}</span>
                    )}
                    <VendorNotificationIcon className='vendor-notification-icon-mobile'
                        onClick={() => setNotificationOverlay(!NotificationOverlay)}  
                        notificationcount={notificationCountvalue}  
                    />
                </div>
            )}
        </>
    );
}

export default VendorTopMenuBar;