import '../../VendorStyles/Dashboard.css';
import { useState, useEffect } from 'react';
import img1 from '../../assets/TotalOrders.png';
import img2 from '../../assets/Totaldelivered.png';
import img3 from '../../assets/cancelled.png';
import img4 from '../../assets/Totalrevenue.png';

import axiosVendorInstance from '../../axiosVendorInstance';

import { Menu, MenuItem, Button, Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, styled, Stack, Pagination } from '@mui/material';
import { FaCalendarAlt } from 'react-icons/fa';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import VendorSideMenuBar from './VendorLayouts/VendorSideMenuBar';
import VendorTopMenuBar from './VendorLayouts/VendorTopMenuBar';
import CommonButton from '../../Components/commonButton';
import Loader from '../../Components/Loader/Loader';

function Dashboard() {
    const [vendorID, setVendorID] = useState(null);
    console.log(vendorID);

    const [orderData, setOrderData] = useState({
        totalOrders: 0,
        totalDelivered: 0,
        cancelledOrders: 0,
        totalRevenue: 0,
    });
    const [tableOrderData, setTableOrderData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState('Last 7 Days');
    const [showCustom, setShowCustom] = useState(false);
    const [customDate, setCustomDate] = useState({ start: null, end: null });
    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setShowCustom(false);
    };

    const handleOptionClick = (option) => {
        if (option === 'Custom') {
            setShowCustom(true);
        } else {
            setSelectedOption(option);
            handleClose();
        }
    };

    const fetchVendorID = async () => {
        setLoading(true); 
        const vendorResponse = await axiosVendorInstance.get('/vendors/account');
        console.log('vendorResponse:', vendorResponse.data.getVendorInfo[0]);
        setVendorID(vendorResponse?.data?.getVendorInfo[0]?.ID); // Adjust based on actual response structure
    };

    useEffect(() => {
        fetchVendorID();
    }, [vendorID, pageNo]);

    const fetchOrderData = async (startDate, endDate) => {
        try {
            const response = await axiosVendorInstance.get(
                `/Order/vendor/${vendorID}${
                    startDate && endDate
                        ? `?startDate=${startDate}&endDate=${endDate}`
                        : ''
                }`
            );
            if (response.data && response.data.allOrdersDetails) {
                setTableOrderData(response.data.allOrdersDetails);
                setTotalPages(response.data.totalPages || 1);
            } else {
                setTableOrderData([]); // Set to empty array if no data
                setTotalPages(1);
            }
            const totalOrdersCount = response.data.totalOrders;
            const totalDeliveredCount = response.data.deliveredOrders;
            const totalDeclinedCount = response.data.declinedOrders;
            const totalEarnings = response.data.totalEarnings;

            console.log('response*-*-*-*-*-*-', response.data);

            // const totalOrders = orders.length;
            // const cancelledOrders = orders.filter(order => order.Status_id === 3).length;
            // const totalDelivered = orders.filter(order => order.Status_id === 6).length;

            // const totalRevenue = orders
            //     .filter(order => order.Status_id === 6)
            //     .reduce((total, order) => total + parseFloat(order.Total_price), 0);

            setOrderData({
                totalOrders: totalOrdersCount,
                totalDelivered: totalDeliveredCount,
                cancelledOrders: totalDeclinedCount,
                totalRevenue: totalEarnings,
            });
        } catch (err) {
            console.error('Error fetching order data:', err);
            setTableOrderData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleApply = () => {
        let startDate, endDate;

        switch (selectedOption) {
        case 'Today':
            startDate = endDate = dayjs().format('YYYY-MM-DD');
            break;
        case 'Yesterday':
            startDate = endDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
            break;
        case 'Last 7 Days':
            startDate = dayjs().subtract(6, 'day').format('YYYY-MM-DD');
            endDate = dayjs().format('YYYY-MM-DD');
            break;
        case 'Last 30 Days':
            startDate = dayjs().subtract(29, 'day').format('YYYY-MM-DD');
            endDate = dayjs().format('YYYY-MM-DD');
            break;
        case 'Custom':
            if (customDate.start && customDate.end) {
                startDate = dayjs(customDate.start).format('YYYY-MM-DD');
                endDate = dayjs(customDate.end).format('YYYY-MM-DD');
            } else {
                return;
            }
            break;
        default:
            return;
        }

        fetchOrderData(startDate, endDate);
        handleClose();
    };

    const handlePageChange = (event, value) => {
        setPageNo(value);
    };
    useEffect(() => {
        if (vendorID) {
            setLoading(true);
            fetchOrderData();
        }
    }, [vendorID]);

    const Orders = [
        { image: img1, count: orderData.totalOrders ?? 0, type: 'Total Orders' },
        { image: img2, count: orderData.totalDelivered ?? 0, type: 'Total Delivered' },
        { image: img3, count: orderData.cancelledOrders ?? 0, type: 'Cancelled' },
        { image: img4, count: `$${orderData.totalRevenue ?? 0}`, type: 'Total Revenue' },
    ];

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#e5e8f1',
        color: theme.palette.common.black,
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif',
    }));

    return (
        <>
            <VendorSideMenuBar />
            <VendorTopMenuBar />
            <div className="menu-main-container-vendor">
                <div className="vendor-common-container">
                    <div className="orders-page">
                      
                        <div className="dashboard-top1-container">
                            <div className="dashboard-new-orders-container">
                                <div className="dashboard-new-orders-count-container">
                                    <p className="dashboard-new-orders-count">14</p>
                                </div>

                                <div className="dashboard-new-orders-and-manage-orders">
                                    <p className="dashboard-new-orders-text">New Orders</p>
                                    <button className="dashboard-manage-orders-btn">
        Manage Orders
                                    </button>
                                </div>
                            </div>
                            <div className="order-filter-apply-container">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                                        {/* Dropdown Toggle Button */}
                                        <Button
                                            variant="outlined"
                                            startIcon={<FaCalendarAlt />}
                                            onClick={handleClick}
                                            sx={{
                                                textTransform: 'none',
                                                padding: '8px 12px',
                                                borderRadius: '5px',
                                            }}
                                        >
                                            {selectedOption}
                                        </Button>

                                        {/* Dropdown Menu */}
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleClose}
                                        >
                                            {!showCustom ? (
                                                <>
                                                    {[
                                                        'Today',
                                                        'Yesterday',
                                                        'Last 7 Days',
                                                        'Last 30 Days',
                                                        'Custom',
                                                    ].map((option) => (
                                                        <MenuItem
                                                            key={option}
                                                            onClick={() => handleOptionClick(option)}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </>
                                            ) : (
                                                <Box sx={{ p: 2, width: 300 }}>
                                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                                        <DatePicker
                                                            label="Start Date"
                                                            value={customDate.start}
                                                            onChange={(date) =>
                                                                setCustomDate({ ...customDate, start: date })
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField {...params} fullWidth />
                                                            )}
                                                        />
                                                        <DatePicker
                                                            label="End Date"
                                                            value={customDate.end}
                                                            onChange={(date) =>
                                                                setCustomDate({ ...customDate, end: date })
                                                            }
                                                            minDate={customDate.start}
                                                            renderInput={(params) => (
                                                                <TextField {...params} fullWidth />
                                                            )}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            mt: 2,
                                                        }}
                                                    >
                                                        <Button variant="outlined" onClick={handleClose}>
                    Cancel
                                                        </Button>
                                                        <Button variant="contained" onClick={handleApply}>
                    Apply
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Menu>
                                    </Box>
                                </LocalizationProvider>

                                <CommonButton label={'Apply'} onClick={handleApply} />
                            </div>
                        </div>
                        {loading ? (
                            <Loader/>) : 
                            (
                                <>
                                    <div className="dashboard-bottom-container">
                                        {Orders.map((order, index) => (
                                            <div key={index} className="dashboard-bottom-single-container">
                                                <div className="dashboard-order-img">
                                                    <img src={order.image}></img>
                                                </div>
                                                <div className="dashboard-order-count-and-type">
                                                    <h5 className="dashboard-order-count">
                                                        {order.count}
                                                    </h5>
                                                    <h5 className="dashboard-order-type">{order.type}</h5>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {tableOrderData && tableOrderData.length > 0 &&
                                            <>
                                                <TableContainer
                                                    component={Paper}
                                                    sx={{
                                                        borderRadius: 2,
                                                        boxShadow: 3,
                                                        maxHeight: 500,
                                                        overflowX: 'auto',
                                                        overflowY: 'auto',
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Table stickyHeader sx={{ minWidth: 900 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <StyledTableCell>Order ID</StyledTableCell>
                                                                <StyledTableCell>Items</StyledTableCell>
                                                                <StyledTableCell>Customer Name</StyledTableCell>
                                                                <StyledTableCell>Date</StyledTableCell>
                                                                <StyledTableCell>Status</StyledTableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {tableOrderData.map((order) => (
                                                                <TableRow
                                                                    key={order.id}
                                                                >
                                                                    <TableCell>{order.ID}</TableCell>
                                                                    <TableCell>{order.order_info?.length}</TableCell>
                                                                    <TableCell>{order.user ? `${order.user.first_name} ${order.user.last_name}` : 'N/A'}</TableCell>
                                                                    <TableCell>
                                                                        {new Date(order.Created_at).getDate().toString().padStart(2, '0')}-
                                                                        {(new Date(order.Created_at).getMonth() + 1).toString().padStart(2, '0')}-
                                                                        {new Date(order.Created_at).getFullYear()}
                                                                        {' '}
                                                                        {new Date(order.Created_at).toLocaleTimeString()}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {{
                                                                            1: 'Order Placed',
                                                                            2: 'Preparing',
                                                                            3: 'Declined',
                                                                            5: 'Pickup',
                                                                            6: 'Delivered',
                                                                            7: 'In-Transit'
                                                                        }[order.Status_id] || 'Unknown'}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer><Stack spacing={2}>
                                                    <Pagination
                                                        sx={{
                                                            '& .MuiPagination-ul': {
                                                                color: '#333',
                                                                padding: 1,
                                                                borderRadius: 2,
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                listStyleType: 'none',
                                                            },
                                                        }}
                                                        count={totalPages}
                                                        page={pageNo}
                                                        onChange={handlePageChange}
                                                        showFirstButton
                                                        showLastButton />
                                                </Stack>
                                            </>
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
