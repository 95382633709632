import React, { useEffect, useState } from 'react';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';
import { useParams } from 'react-router-dom';
import Loader from '../../../Components/Loader/Loader';
import axiosVendorInstance from '../../../axiosVendorInstance';

const ViewMenu = () => {
    const [restaurantData,setRestaurantData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); 
    const {id} = useParams();

    useEffect(() => {
        fetchMenuTypes();
    }, []);

    const fetchMenuTypes = async () => {
        try {
            setIsLoading(true);
            const response = await axiosVendorInstance.get(`/vendors/vendor/${id}`, {skipAuth: true});
            setIsLoading(false);
            const apiResponse = response.data;
            setRestaurantData(apiResponse);
        } catch (error) {
            setIsLoading(false);
            console.error('Error fetching menu types:', error);
        }
    };

    return (
        <>
            {isLoading && <Loader/>}
            <MenuHeader restaurantData={restaurantData} searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
            <MenuItem restaurantData={restaurantData} searchQuery={searchQuery} />
        </>
    );
};

export default ViewMenu;
