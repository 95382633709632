import React from 'react';
import '../../../VendorStyles/VendorRegistrationSideMenuBar.css';
import '../../../Styles/Colors.css';

function VendorRegistrationSideMenuBar() {
    const handleLogout = () => {
        localStorage.removeItem('authToken');

        window.location.href = '/login';
    };

    return (
        <>
            <div className="vendor-register-menu-container">
                <div className="vendor-register-sidebar-scrollable">
                    <ul className="vendor-register-menu-items">
                        <li>
                            <div
                                className="vendor-register-list-container"
                                onClick={() => handleLogout()}
                            >
                                <svg
                                    width="35"
                                    height="45"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="vendor-register-list-svg-icon"
                                >
                                    <path
                                        d="M16.2891 8.71L18.5891 11L8.99913 11C8.73391 11 8.47956 11.1054 8.29202 11.2929C8.10448 11.4804 7.99913 11.7348 7.99913 12C7.99913 12.2652 8.10448 12.5196 8.29202 12.7071C8.47956 12.8946 8.73391 13 8.99913 13L18.5891 13L16.2891 15.29C16.1954 15.383 16.121 15.4936 16.0702 15.6154C16.0195 15.7373 15.9933 15.868 15.9933 16C15.9933 16.132 16.0195 16.2627 16.0702 16.3846C16.121 16.5064 16.1954 16.617 16.2891 16.71C16.3821 16.8037 16.4927 16.8781 16.6146 16.9289C16.7364 16.9797 16.8671 17.0058 16.9991 17.0058C17.1311 17.0058 17.2618 16.9797 17.3837 16.9289C17.5056 16.8781 17.6162 16.8037 17.7091 16.71L21.7091 12.71C21.8002 12.6149 21.8715 12.5028 21.9191 12.38C22.0191 12.1365 22.0191 11.8635 21.9191 11.62C21.8715 11.4972 21.8002 11.3851 21.7091 11.29L17.7091 7.29C17.6159 7.19676 17.5052 7.1228 17.3834 7.07234C17.2616 7.02188 17.131 6.99591 16.9991 6.99591C16.8673 6.99591 16.7367 7.02188 16.6149 7.07234C16.4931 7.1228 16.3824 7.19676 16.2891 7.29C16.1959 7.38324 16.1219 7.49393 16.0715 7.61575C16.021 7.73757 15.995 7.86814 15.995 8C15.995 8.13186 16.021 8.26243 16.0715 8.38425C16.1219 8.50607 16.1959 8.61676 16.2891 8.71ZM11.9991 21C11.9991 20.7348 11.8938 20.4804 11.7062 20.2929C11.5187 20.1054 11.2643 20 10.9991 20H4.99913C4.73391 20 4.47956 19.8946 4.29202 19.7071C4.10448 19.5196 3.99913 19.2652 3.99913 19L3.99913 5C3.99913 4.73478 4.10448 4.48043 4.29202 4.29289C4.47956 4.10536 4.73391 4 4.99913 4L10.9991 4C11.2643 4 11.5187 3.89464 11.7062 3.70711C11.8938 3.51957 11.9991 3.26522 11.9991 3C11.9991 2.73478 11.8938 2.48043 11.7062 2.29289C11.5187 2.10536 11.2643 2 10.9991 2L4.99913 2C4.20348 2 3.44041 2.31607 2.87781 2.87868C2.3152 3.44129 1.99913 4.20435 1.99913 5L1.99913 19C1.99913 19.7956 2.3152 20.5587 2.87781 21.1213C3.44041 21.6839 4.20348 22 4.99913 22H10.9991C11.2643 22 11.5187 21.8946 11.7062 21.7071C11.8938 21.5196 11.9991 21.2652 11.9991 21Z"
                                        fill="#6A7082"
                                    />
                                </svg>

                                <h3 className="vendor-register-list-text">Log out</h3>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default VendorRegistrationSideMenuBar;
