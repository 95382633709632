import React, { useState, useEffect } from 'react';
import '../../../Styles/Notification/Notification.css';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // Using named export from jwt-decode

import CloseIcon from '../../../assets/svg/closeIcon';
import { useNotifications } from './NotificationContext';
function Notification({ closeOverlay }) {
    const { t } = useTranslation();
    const [notification, setNotification] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [ setDotColor] = useState('red');

    const {  removeNotification, setNotificationCount,notifcationvalue } = useNotifications();
    //const [notificationType, setnotificationType] = useState();
    const [loading, setLoading] = useState(true); // State to handle loading

    // Decode token and set currentUserId
    useEffect(() => {
        const token = localStorage.getItem('authToken');

        if (token) {
            try {
                const decoded = jwtDecode(token);
                if (decoded && decoded.id) {
                    setCurrentUserId(decoded.id);
                    //setnotificationType(decoded.role);
                    console.log('Decoded user id:', decoded.id);
                }
            } catch (error) {
                console.error('Failed to decode token:', error);
            }
        } else {
            console.warn('No token found in localStorage under key \'authToken\'.');
        }
    }, []);

    const updateSingleNotification = async (id, type) => {
        if (!currentUserId) {
            console.warn('Cannot update notification: currentUserId is null.');
            return;
        }
    
        const updateUrl = `https://customers-api.omyfood.io/api/notifications/notifications/singlenotificationstatus?uid=${currentUserId}&type=${type}&id=${id}`;
        console.log('Updating single notification:', updateUrl);
    
        try {
            await axios.put(updateUrl, { is_read: true });
    
            setNotification((prevNotifications) =>
                prevNotifications.map((notif) =>
                    notif.id === id ? { ...notif, is_read: true } : notif
                )
            );
    
            setDotColor('gray'); // Update UI dot color
        } catch (error) {
            console.error('Error updating notification:', error);
        }
    };
    
    // Fetch notifications once currentUserId is set
    
    // Fetch notifications once currentUserId is set
    useEffect(() => {
        if (!currentUserId) return;
    
        console.log('Updating notifications from context:', notifcationvalue);
        setLoading(true);
        if (notifcationvalue && notifcationvalue.length > 0) {
            setNotification(notifcationvalue);
            setLoading(false);
            //setNotificationCount(notifcationvalue.length);
        } else {
            setNotification([]);
            removeNotification();
        }
        setLoading(false);
    }, [currentUserId, notifcationvalue]);

    console.log('Current User Id (state):', currentUserId);
    console.log('Notifications:', notification);

    const markAllAsRead = () => {
        if (!currentUserId) {
            console.warn(
                'Cannot mark notifications as read because currentUserId is null.'
            );
            return;
        }
        const updateUrl = `https://customers-api.omyfood.io/api/notifications/notifications/status?uid=${currentUserId}`;
        console.log('Marking notifications as read via URL:', updateUrl);
        axios
            .put(updateUrl, { is_read: true })
            .then((response) => {
                console.log('Mark All as Read Response:', response.data);
                setNotification((prevNotifications) =>
                    prevNotifications.map((notif) => ({ ...notif, is_read: true }))
                );
                setDotColor('gray');
                
            })
            .catch((error) => {
                console.error('Error updating notifications:', error);
            });
    };
 
    const clearNotifications = async () => {
        if (!currentUserId) {
            console.warn('Cannot clear notifications because currentUserId is null.');
            return;
        }
    
        try {
            // Call the removeNotification action to clear all notifications
            await removeNotification(currentUserId);  // This will handle removal from both the server and local state
    
            // Optionally reset notifications and count after deletion
            setNotification([]);  // Clear the local notifications state
            setNotificationCount(0);  // Reset the notification count
    
            console.log('All notifications cleared successfully.');
        } catch (error) {
            console.error('Error clearing notifications:', error);
        }
    };
    
    return (
        <div className="notification-container-overall">
            <div className="notification-close-icon">
                <CloseIcon
                    height="25"
                    width="25"
                    onClick={closeOverlay}
                    color="black"
                />
            </div>
            <div className="main-title-container">
                <h1 className="main-title-notification">
                    {t('notification.notifications-title')}
                </h1>
                <p className="mark-all-as-read" onClick={markAllAsRead}>
                    {t('notification.mark-all-as-read-title')}
                </p>
                <p className="mark-all-as-read" onClick={clearNotifications}>
                    {t('notification.clear-all-title')}
                </p>
            </div>
            <div className="notification-messages-main-container">
                {loading ? ( // If loading, display loading indicator
                    <p>Loading...</p>
                ) : notification.length > 0 ? (
                    notification.map((item, index) => (
                        <div key={index}>
                            <div className="title-time-and-date-container">
                                <div className="svg-title-notification">
                                    <svg
                                        width="11"
                                        height="11"
                                        viewBox="0 0 11 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="red-dot-in-notification"
                                    >
                                        <rect
                                            width="11"
                                            height="11"
                                            rx="5.5"
                                            fill={item.is_read ? '#CBD5E1' : '#E13535'}
                                        />
                                    </svg>
                                    <h1 className="notification-title">{item.notes}</h1>
                                </div>
                                <div className="time-date-notification">
                                    <p className="date-in-notification">{item.created_at}</p>
                                </div>
                            </div>
                            <p className="notification-description">{item.notes}</p>
                            <button
                                onClick={() => updateSingleNotification(item.ID, item.type)}
                                disabled={item.is_read === true}
                            >
                                {item.is_read === true ? 'Already Read' : 'Mark as Read'}
                            </button>
                            <div className="horizantal-line-notification"></div>
                        </div>
                    ))
                ) : (
                    <p>No notifications available</p>
                )}
            </div>
        </div>
    );
}

export default Notification;
