import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const LoginRedirect = () => {
    const navigate = useNavigate();

    useEffect(() => {
    // Clear session if necessary
        navigate('/login');
    }, [navigate]);

    return null;
};
