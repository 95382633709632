import React, { useState, useEffect } from 'react';
import '../../VendorStyles/Preparing.css';
import axiosVendorInstance from '../../axiosVendorInstance';


import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Loader from '../../Components/Loader/Loader';

import  { OrderContextuseOrders} from '../../ordersCount';
import { useNotifications } from '../User/Notification/NotificationContext';

function Preparing() {

    const { addNotification } = useNotifications();

    const {preparingCount,setPreparingCount} = OrderContextuseOrders();

    const [statusID,setStatusID] = useState();
   
    

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [orders, setOrders] = useState([]);
    const pageSize = 10;

    const [vendorID, setVendorID] = useState();
    console.log(vendorID);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchVendorID = async () => {
        const vendorResponse = await axiosVendorInstance.get(
            '/vendors/account'
        );

        setVendorID(vendorResponse?.data?.getVendorInfo[0]?.ID);
    };

    const fetchNewOrders = async () => {
        setLoading(true);

        try {
            const response = await axiosVendorInstance.get(
                `/Order/vendor/${vendorID}?pageSize=${pageSize}&pageNumber=${page}&statusId=2`
                
            );

            const incomingData = response.data.allOrdersDetails;

            if (incomingData.length === 0 && page > 1) {
                setPage(page - 1);
            } else {
                setOrders(incomingData);
                setTotalPages(response.data?.totalPages || 1);
            }

            setLoading(false);
        } catch (error) {
            console.error(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVendorID();
    }, [vendorID]);

    useEffect(() => {
        if (vendorID) {
            fetchNewOrders();
        }
    }, [page, vendorID]);

    const handlePushToPickup = async (order) => {

        // Fetch vendor data
        const vendorResponse = await axiosVendorInstance.get(
            `/Order/vendor/particularordersdetail/${order.ID}`
        );
        const userId = vendorResponse?.data?.order?.user_id;

        if (!userId) {
            console.warn('User ID not found for order:', order.id);
            return;
        }

        console.log('Vendor user ID:', userId);


        // Determine next status based on order_type_id
           
        if (order.Order_type_id === 1) {
            setStatusID(6); // Self Pickup → Directly to Delivered
        } else if (order.Order_type_id === 2) {
            setStatusID(5); // Delivery → In Transit
        } else {
            console.warn(`Unknown order type for order ${order.ID}:`, order.Order_type_id);
            return;
        }

        axiosVendorInstance
            .put(
                `/Order/Vendor/${vendorID}/order/${order.ID}/status`,
                {
                    status_id: statusID,
                }
                
            )
            .then(() => {
                setOrders(orders.filter((o) => o.ID !== order.ID));
                setPreparingCount(preparingCount - 1);

                const notificationPayload = {
                    from_user_id: userId, 
                    to_user_id: order.ID, 
                    type: 'order_picked_up',
                    action_id: order.ID,
                    notification_boundary: 'private',
                    is_read: false,
                };
    
                console.log('Notification payload:', notificationPayload);
    
             
                addNotification(notificationPayload);
            })
            .catch((error) => console.error('Error updating order status:', error));



        fetchNewOrders();
        
    };

    return (
        <div className="new-orders-main">
            {loading ? (
                <>
                    <Loader/>
                </>
            ) : (
                <div>
                    {orders.length > 0 ? (
                        orders.map((order) => {
                            const createdAt = new Date(order.Created_at);
                            const time = createdAt.toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            });
                            const date = `${createdAt.getDate()} ${createdAt.toLocaleString(
                                'en-US',
                                { month: 'short' }
                            )}`;

                            return (
                                <div key={order.ID} className="single-order">
                                    <div className="orderid">
                                        <p className="order-details1">
                      Order ID:<span className="order-id">{order.ID}</span>
                                        </p>
                                    </div>
                                    <div className="date-and-time">
                                        <p className="order-details2">{time}</p>
                                        <p className="order-details3">{date}</p>
                                    </div>
                                    <button
                                        className="push-to-pickup-btn"
                                        onClick={() => handlePushToPickup(order)}
                                    >
                                        <p className="push-to-pickup-text">{order.Order_type_id === 2 ? 'Push to PickUp' : 'Push to Delivered' }</p>
                                    </button>
                                </div>
                            );
                        })
                    ) : (
                        <div className='no-data-message'>No data</div>
                    )}

                    {orders.length > 0 && totalPages > 1 && (
                        <div className="pagination-container">
                            <Stack spacing={2}>
                                <Pagination
                                    sx={{
                                        '& .MuiPagination-ul': {
                                            color: '#333',
                                            padding: 1,
                                            borderRadius: 2,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            listStyleType: 'none',
                                        },
                                    }}
                                    count={totalPages}
                                    page={page}
                                    onChange={handlePageChange}
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default Preparing;
