import React, { useEffect, useState } from 'react';
import './../../../Styles/Cart/AddOns.css';
import addImg from './../../../assets/plus.png';
import foodPlaceHolder from '../../../assets/food-img-placeholder.jpg';
import { useCart } from '../../../CartProvider';
import { toast } from 'react-toastify';
import axiosUserInstance from '../../../axiosUserInstance';
import Loader from '../../../Components/Loader/Loader';
import axiosVendorInstance from '../../../axiosVendorInstance';
import FoodMenuDetailsModal from '../Search/FoodMenuDetailsModal';

function AddOns({cartData, fetchDataFromCart = () =>{}}) {
    const { fetchCartCount } = useCart();
    const [isLoading, setIsLoading] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [selectedFood, setSelectedFood] = useState(null);
    const [open, setOpen] = useState(false);
    const vendorId = cartData?.[0]?.Vendor_Id;

    const handleClose = () => {
        setOpen(false);
        setSelectedFood(null);
    };

    const fetchMenuItems = async () => {
        setIsLoading(true);

        try {
            const response = await axiosVendorInstance.get(`/vendors/menuitems/${vendorId}`, {
                params: {
                    page_no: 1,
                    page_size: 100,
                }, 
            }, {skipAuth: true}
            );

            if (response.data && response.data.menuItems) {
                const menuItems = response.data.menuItems.map((item) => ({
                    menuItemId: item?.ID,
                    vendorDesc: item?.Vendor_Info?.Vendor_Description || 'N/A',
                    vendorRating: item?.vendor_menu?.Rating || 'N/A',
                    vendorReview: item?.vendor_menu?.Review || 'N/A',
                    foodImage: item?.Avatar_key,
                    vegetarian: item?.Vegetarian,
                    menuItemName: item?.Item_name,
                    menuItemDescription: item?.Description,
                    menuItemPrice: item?.Price,
                    menuName: item?.vendor_menu?.Menu_name,
                    menuIngridents : item?.Ingridents,
                }));
                setFilteredItems(menuItems);
            }
        } catch (error) {
            console.error('Error fetching restaurant details:', error);
            setFilteredItems([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMenuItems();
    }, [vendorId]);
    
    
    const addOnItems = filteredItems.filter(
        item => !cartData.some(cartItem => cartItem?.Item_ID === item?.menuItemId)
    );

    const addToCart = async (itemID, restaurantID) => {
        localStorage.setItem('itemID',  itemID);
    
        try {
            const itemIdToSend = {
                Item_id: itemID,
                Quantity: 1,
            };
            setIsLoading(true);
            await axiosUserInstance.post(
                `/cartdetails/customer/vendor/${restaurantID}/item/${itemID}`,
                itemIdToSend
            );
            setIsLoading(false);
            await fetchDataFromCart();
            await fetchCartCount();
    
        } catch (error) {
            setIsLoading(false);
            toast.error('Failed to add item');
        }
    };

    const fetchItemDetails = async (itemId) => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`, {skipAuth: true}
            );
            setSelectedFood(response?.data?.menuItem);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };
       
    return (
        <>
            {isLoading && (
                <div>
                    <Loader/>
                </div>
            )}
            <h1 className="add-ons-title">Add-ons</h1>

            <div className="add-ons-container">
                {addOnItems?.map((item) => {
                    return (
                        <div className="items-container" key={item?.menuItemId}>
                            <div className="image-and-title-container">
                                <img className="item-img" src={item?.foodImage || foodPlaceHolder} alt={item?.menuItemName} onClick={() => fetchItemDetails(item?.menuItemId)} onError={(e) => (e.target.src = foodPlaceHolder)}/>
                                <img className="plus-img" src={addImg} alt="plus-img" onClick={() => addToCart(item?.menuItemId, vendorId )}/>
                            </div>
                            <div className="title-price-rating-container">
                                <h3 className="item-title">{item?.menuItemName && item?.menuItemName.length > 17 ? item?.menuItemName.slice(0,17) + '...' : item?.menuItemName}</h3>
                                <div className="price-and-rating-container">
                                    <p className="item-price-addons">${item?.menuItemPrice}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {selectedFood && (
                <FoodMenuDetailsModal
                    open={open}
                    handleClose={handleClose}
                    food={selectedFood}
                    orderBtn = {false}
                />
            )}
        </>
    );
}

export default AddOns;
