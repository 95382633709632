import React, { useEffect, useRef, useState, memo } from 'react';
import ReelsLeftMemo from './ReelsLeft';
import ReelsRight from './ReelsRight';
import '../Styles/Reels/VideoCard.css';
import { BsHeartFill, BsPause } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import axiosVendorInstance from '../axiosVendorInstance';
import axiosUserInstance from '../axiosUserInstance';

let currentlyPlayingVideo = null;

function VideoCard(props) {
    const {
        url,
        id,
        reelsId,
        vendorID,
        AccountID,
        video,
        profilePic,
        userName,
        restaurantName,
        starPic,
        rating,
        distance,
        description,
        order,
        likes,
        comments,
        shares,
        setVideoRef,
        ismuted, 
        handleMute,
        globalMuteIcon,
        toggleMuteIcon,
        restaurant,
        coordinate,
        saveFlag,
        likeFlag,
        firstName,
        lastName,
        itemName,
        vendorDescription,
        followFlag,
        filter,
    } = props;

    const videoRef = useRef(null);

    const [like, setLike] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [isHeartActive, setIsHeartActive] = useState(false);

    const [likesCount, setLikesCount] = useState(likes);
    const [isLike, setIsLike] = useState(false);
    const [commentsCount, setCommentsCount] = useState(comments);
    const [commentsList, setCommentsList] = useState([]);
    const [animateModal, setAnimateModal] = useState(false);
    const [closing, setClosing] = useState(false);
    const location = useLocation();
    const isSearchPage = location.pathname === '/search';
    const [isPlaying, setIsPlaying] = useState(
        window.innerWidth <= 800 || location.pathname === '/Dashboard' || location.pathname === '/reels'
    );

    const getLikes = async () => {
        try {
            const response = await axiosUserInstance.get(`/customers/likes/${id}`, {
                skipAuth: true,
            });

            if (response?.statusText == 'OK') {
                setLikesCount(response.data.likeCount);
            } else {
                return;
            }
        } catch (error) {
            console.error('Error from all like count', error);
        }
    };

    useEffect(() => {
        getLikes();
    }, [id, likesCount]);

    useEffect(() => {
        setIsLike(likeFlag === true || likeFlag === 'true');  
    }, [likeFlag]);

    const getComments = async () => {
        await axiosVendorInstance
            .get(`/comments/post/${id}`, { skipAuth: true })
            .then((response) => {
                setCommentsList(response.data.comments);
                setCommentsCount(response.data.commentCount);
            })
            .catch((error) => {
                console.error('There was an error fetching the comments!', error);
            });
        setClosing(false);
        setTimeout(() => {
            setAnimateModal(true);
        }, 100);
    };

    useEffect(() => {
        getComments();
    }, [id, commentsCount]);

    const isVideoFile = (url) => {
        const videoExtensions = ['mp4', 'mov', 'webm', 'avi'];
        const extension = url.split('.').pop().toLowerCase();
        return videoExtensions.includes(extension);
    };

    const handleDoubleClick = () => {
        setLike((prevLike) => !prevLike);
        setIsHeartActive(true);
        setTimeout(() => {
            setIsHeartActive(false);
        }, 1000);
    };

    useEffect(() => {
        const video = videoRef.current;
        if (!video) return;

        const handlePlaybackChange = () => setIsPaused(video.paused);
        video.addEventListener('play', handlePlaybackChange);
        video.addEventListener('pause', handlePlaybackChange);

        return () => {
            video.removeEventListener('play', handlePlaybackChange);
            video.removeEventListener('pause', handlePlaybackChange);
        };
    }, [videoRef.current]);

    const handlePause = () => {
        const video = videoRef.current;
        if (!video) return;

        if (video.paused) {
            playVideo();
        } else {
            video.pause();
            setIsPaused(true);
        }
    };

    const playVideo = () => {
        const video = videoRef.current;
        if (!video || !video.paused || !video.src) return;

        if (currentlyPlayingVideo && currentlyPlayingVideo !== video) {
            currentlyPlayingVideo.pause();
            currentlyPlayingVideo.currentTime = 0;
        }

        currentlyPlayingVideo = video;
        video
            .play()
            .catch((error) => console.error('Play request interrupted:', error));
        setIsPaused(false);
    };

    useEffect(() => {
        const handleResize = () => {
            const shouldPlay =
        window.innerWidth <= 800 || location.pathname === '/Dashboard';
            setIsPlaying(shouldPlay);

            if (videoRef.current && videoRef.current.src) {
                if (shouldPlay) {
                    setTimeout(() => {
                        if (videoRef.current)
                            videoRef.current
                                .play()
                                .catch((err) => console.error('Play request error:', err));
                    }, 100);
                } else {
                    videoRef.current.pause();
                }
            }

            if (window.innerWidth > 600 && location.pathname !== '/Dashboard') {
                if (
                    currentlyPlayingVideo &&
          currentlyPlayingVideo !== videoRef.current
                ) {
                    currentlyPlayingVideo.pause();
                    currentlyPlayingVideo.currentTime = 0;
                    currentlyPlayingVideo = null;
                }
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [location.pathname]);

    const getFilterClass = (filter) => {
        switch (filter) {
        case 'brightness':
            return 'brightness';
        case 'contrast':
            return 'contrast';
        case 'saturation':
            return 'saturation';
        case 'hue':
            return 'hue';
        case 'blur':
            return 'blur';
        case 'sepia':
            return 'sepia';
        case 'grayscale':
            return 'grayscale';
        case 'invert':
            return 'invert';
        case 'opacity':
            return 'opacity';
        case 'sharpen':
            return 'sharpen';
        default:
            return 'original';
        }
    };

    return (
        <div className={`video ${isSearchPage ? 'video-important-reels' : ''}`}>
            {!isVideoFile(url) ? (
                <>
                    <img
                        className={`player ${getFilterClass(filter)}`}
                        src={url}
                        alt="feedimg"
                        onDoubleClick={handleDoubleClick}
                    />
                    <div className="heart-container">
                        <BsHeartFill
                            className={`heart ${isHeartActive ? 'heart-active' : ''}`}
                        />
                    </div>
                </>
            ) : (
                <>
                    <video
                        playsInline
                        className="player"
                        onClick={handlePause}
                        src={url}
                        ref={(ref) => {
                            videoRef.current = ref;
                            if (typeof setVideoRef === 'function') {
                                setVideoRef(ref);
                            }
                        }}
                        muted={ismuted}
                        loop
                        type="video/mp4"
                        autoPlay={isPlaying}
                    />
                    {isPaused ? (
                        <div className="pause-overlay" onClick={() => handlePause()}>
                            <BsPause className="pause-icon" />
                        </div>
                    ) : (
                        <div className="play-overlay" onClick={() => handlePause()}></div>
                    )}
                </>
            )}

            <div className="bottom-controls">
                <div className="reel-left">
                    <ReelsLeftMemo
                        profilePic={profilePic}
                        userName={userName}
                        restaurantName={restaurantName}
                        AccountID={AccountID}
                        starPic={starPic}
                        rating={rating}
                        distance={distance}
                        description={description}
                        restaurant={restaurant}
                        vendorID={vendorID}
                        coordinate={coordinate}
                        firstName={firstName}
                        lastName={lastName}
                        itemName={itemName}
                        followFlag={followFlag}
                    />
                </div>

                <div className="reel-right">
                    <ReelsRight
                        id={id}
                        vendorID={vendorID}
                        restaurantName={restaurantName}
                        like={like}
                        setLike={setLike}
                        handleMute={handleMute}
                        shares={shares}
                        comments={comments}
                        isLike={isLike}
                        setIsLike={setIsLike}
                        getLikes={getLikes}
                        likesCount={likesCount}
                        commentsCount={commentsCount}
                        setCommentsCount={setCommentsCount}
                        commentsList={commentsList}
                        setCommentsList={setCommentsList}
                        getComments={getComments}
                        order={order}
                        video={video}
                        url={url}
                        isVideoFile={isVideoFile}
                        globalMuteIcon={globalMuteIcon}
                        toggleMuteIcon={toggleMuteIcon}
                        restaurant={restaurant}
                        saveFlag={saveFlag}
                        likeFlag={likeFlag}
                        animateModal={animateModal}
                        setAnimateModal={setAnimateModal}
                        setClosing={setClosing}
                        closing={closing}
                        reelsId={reelsId}
                        rating={rating}
                        vendorDescription={vendorDescription}
                    />
                </div>
                <div className="video-shadow"></div>
            </div>

            <div className="pause-overlay-all"></div>
        </div>
    );
}

export default memo(VideoCard);
