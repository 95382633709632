import React, { useState, useEffect } from 'react';
import '../../VendorStyles/Offers.css';
import axiosVendorInstance from '../../axiosVendorInstance';
import { toast } from 'react-toastify';
import OfferModal from './OfferModal';
import DeleteOfferModal from './DeleteOfferModal';
import { useAuth } from '../../AuthProvider';

import CircularProgress from '@mui/material/CircularProgress'; 
import EditPencilIconSvg from '../../assets/svg/editPencilIcon';
import DeleteIconSvg from '../../assets/svg/deleteIcon';
import CommonButton from '../../Components/commonButton';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

function Offers() {
    const { userID } = useAuth();

    const [coupons, setCoupons] = useState([]);
    console.log(coupons);

    const [loading, setLoading] = useState(true);
    const [vendorID, setVendorID] = useState();

    const fetchVendorID = async () => {
        try {
            const response = await axiosVendorInstance.get(
                '/vendors/account'
               
            );
            const apiResponse = response.data.getVendorInfo;

            if (apiResponse.length > 0) {
                setVendorID(apiResponse[0].ID);
            } else {
                toast.error('Vendor ID not found.');
            }
        } catch (error) {
            toast.error('Error fetching vendor ID');
        }
    };

    useEffect(() => {
        if (userID) {
            fetchVendorID();
        }
    }, [userID]);

    const fetchOffers = async () => {
        if (!vendorID) return;

        try {
            const response = await axiosVendorInstance.get(
                `/vendor/offers/vendor/${vendorID}`,
                { skipAuth: true }
            );
            setCoupons([response.data?.offer] || []);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (vendorID) {
            fetchOffers();
        }
    }, [vendorID]);

    const [selectedOffer, setSelectedOffer] = useState(null);

    const [openForm, setOpenForm] = useState(false);

    const handleOpenForm = (offer = null) => {
        setOpenForm(true);
        setSelectedOffer(offer);
    };

    const handleCloseForm = () => {
        setOpenForm(false);
        setSelectedOffer(null);
    };

    const [deleteOfferId, setDeleteOfferId] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDeleteClick = (offerId) => {
        setDeleteOfferId(offerId);
        setShowDeleteModal(true);
    };

    const confirmDelete = async () => {
        try {
            await axiosVendorInstance.delete(`/vendor/offers/${deleteOfferId}`, {
                skipAuth: true,
            });
            setCoupons((prevCoupons) =>
                prevCoupons.filter((offer) => offer.id !== deleteOfferId)
            );
            toast.success('Offer deleted successfully!');
            fetchOffers();
        } catch (error) {
            toast.error('Error deleting offer.');
        }
        setShowDeleteModal(false);
    };

    const cancelDelete = () => {
        setDeleteOfferId(null);
        setShowDeleteModal(false);
    };

    return (
        <div className="Offers-main-div">
            <div className="add-button-coupen-offer">
                <div></div>
                <h2 className='coupons-heading'>Added Offers</h2>
                <CommonButton
                    onClick={() => handleOpenForm()}
                    label="Add Offer"
                />
            </div>
            {openForm && (
                <OfferModal
                    open={openForm}
                    handleClose={handleCloseForm}
                    initialData={selectedOffer}
                    fetchOffers={fetchOffers}
                />
            )}

            <div className="table-container">
                {/* <div className="offers-list"> */}
                {loading ? (
                    <div className="loader-container">
                        <CircularProgress />
                    </div>
                ) : coupons.length > 0 ? (
                //     <ul className="vendor-offer-main-container">
                //         {coupons[0].map((item, index) => {
                //             return (
                //                 <div key={index} className="offer-card-container-vendor">
                //                     <img
                //                         src={
                //                             item.Avatar_key ||
                //   'https://omfmedia.s3.ap-southeast-1.amazonaws.com/files/20221129181320/omg-logo-grey.png'
                //                         }
                //                         alt={item.Title}
                //                         className="offer-img"
                //                     />

                //                     <div>
                //                         <div className="title-and-status-container">
                //                             <h2 className="offer-title">
                //                                 <b>Title : </b>
                //                                 {item.Title}
                //                             </h2>
                //                             <h2 className="offer-status">
                //                                 <b>Status : </b>
                //                                 {item.status}
                //                             </h2>
                //                         </div>

                //                         <p className="vendor-offer-percentage">
                //                             <b>Percentage : </b>
                //                             {item.percentage}
                //                         </p>
                //                         <p className="offer-short-desc">
                //                             <b>Short Description : </b>
                //                             {item.shortdescription}
                //                         </p>
                //                         <p className="offer-desc">
                //                             <b>Description : </b>
                //                             {item.Description}
                //                         </p>
                //                         <p className="valid-from">
                //                             <strong>Valid From : </strong> {item.valid_from}
                //                         </p>
                //                         <p className="valid-to">
                //                             <strong>Valid To : </strong> {item.valid_to}
                //                         </p>
                //                         <p className="valid-to">
                //                             <strong>Maximum Amount : </strong> {item.Max_amount}
                //                         </p>
                //                         <p className="valid-to">
                //                             <strong> Redeem Limit : </strong> {item.Offer_redemlimit}
                //                         </p>

                //                         <div className="offers-btn-container">
                //                             <button
                //                                 className="offer-edit-btn"
                //                                 onClick={() => handleOpenForm(item)}
                //                             >
                //     Edit
                //                             </button>
                //                             <button
                //                                 className="offer-delete-btn"
                //                                 onClick={() => handleDeleteClick(item.ID)}
                //                             >
                //     Delete
                //                             </button>
                //                             {showDeleteModal && (
                //                                 <DeleteOfferModal
                //                                     open={handleDeleteClick}
                //                                     handleClose={cancelDelete}
                //                                     confirmDelete={confirmDelete}
                //                                     cancelDelete={cancelDelete}
                //                                 />
                //                             )}
                //                         </div>
                //                     </div>
                //                 </div>
                //             );
                //         })}
                //     </ul>
                    <>
                        <TableContainer component={Paper}>
                            <Table className="coupon-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Image</TableCell>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Percentage</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Short Description</TableCell>
                                        <TableCell>Valid From</TableCell>
                                        <TableCell>Valid To</TableCell>
                                        <TableCell>Maximum Amount</TableCell>
                                        <TableCell>Redeem Limit</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {coupons[0].map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <img
                                                    src={item.Avatar_key || 'https://omfmedia.s3.ap-southeast-1.amazonaws.com/files/20221129181320/omg-logo-grey.png'}
                                                    alt={item.Title}
                                                    className="table-img"
                                                    width="50"
                                                    height="50"
                                                />
                                            </TableCell>
                                            <TableCell>{item.Title}</TableCell>
                                            <TableCell>{item.percentage}%</TableCell>
                                            <TableCell>{item.Description}</TableCell>
                                            <TableCell>{item.shortdescription}</TableCell>
                                            <TableCell>{item.valid_from ? item.valid_from.split('T')[0] : 'N/A'}</TableCell>
                                            <TableCell>{item.valid_to ? item.valid_to.split('T')[0] : 'N/A'}</TableCell>
                                            <TableCell>{item.Max_amount}</TableCell>
                                            <TableCell>{item.Offer_redemlimit}</TableCell>
                                            <TableCell>{item.status}</TableCell>
                                            <TableCell>
                                                <div className="edit-and-delete-icons-container">

                                                    {/* <IconButton  color="primary"> */}
                                                    <EditPencilIconSvg 
                                                        color='green'
                                                        onClick={() => handleOpenForm(item)}
                                                    />
                                                    {/* </IconButton> */}
                                                    {/* <IconButton color="secondary"> */}
                                                    <DeleteIconSvg 
                                                        onClick={() => handleDeleteClick(item.ID)} 
                                                        color='#e13535'
                                                    />
                                                    {/* </IconButton> */}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {showDeleteModal && (
                            <DeleteOfferModal
                                open={handleDeleteClick}
                                handleClose={cancelDelete}
                                confirmDelete={confirmDelete}
                                cancelDelete={cancelDelete}
                            />
                        )}
                    </>
                ) : (
                    <p className="no-items-message">No items in this menu</p>
                )}
            </div>
        </div>
    );
}

export default Offers;
