import React from 'react';

function SaveIcon({ loading, handleSaveClick, isFilled }) {
    return (
        <svg
            aria-label="Save"
            className="x1lliihq x1n2onr6 x5n08af"
            height="35"
            role="img"
            viewBox="0 0 24 24"
            width="30"
            onClick={loading ? null : handleSaveClick}
            style={{ cursor: loading ? 'not-allowed' : 'pointer' }}
        >
            <title>Save</title>
            <polygon
                points="22 23 13 14.44 2 23 2 1 22 1 22 23"
                fill={isFilled ? 'white' : 'none'}
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </svg>
    );
}

export default SaveIcon;