// NotFound.js
import React,{useEffect} from 'react';

const PageNotFound = () => {


    const noPageFound =()=>{

        window.history.back();

    };

    useEffect(() => {
        // Change the page title
        document.title = ' OMF | Page Not Found';

    }, []);
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
            <button onClick={noPageFound} >Go Back</button>
        </div>
    );
};

export default PageNotFound;


