import React from 'react';

function MuteIcon({onClick, className}) {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            size={30}
            className={className}
            onClick={onClick}
        >
            <g clipPath="url(#clip0_3730_3217)">
                <rect width="100" height="100" fill="white" />
                <circle cx="50" cy="50" r="50" fill="#FEEEEE" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M53.7789 26.0712C52.9424 26.222 52.1289 26.6142 51.4721 27.2773L51.6542 27.1131L34.9337 40.1938H27.8276C24.6322 40.1938 22 42.8512 22 46.0772V53.9218C22 57.1478 24.6322 59.8052 27.8276 59.8052H34.9337L51.6542 72.8859L51.4721 72.7217C52.7857 74.0479 54.7246 74.3004 56.218 73.6753C57.7113 73.0502 58.9084 71.4874 58.9084 69.6109V30.3882C58.9084 28.5128 57.7125 26.9488 56.218 26.3237C55.4713 26.0111 54.6142 25.9204 53.7789 26.0712ZM54.7307 29.9469C54.9177 30.0253 55.0233 30.0928 55.0233 30.3869V69.6096C55.0233 69.905 54.9177 69.9724 54.7307 70.0497C54.5437 70.1281 54.4248 70.1551 54.2184 69.9467C54.1601 69.8879 54.0982 69.8327 54.0326 69.7825L36.7889 56.2923C36.4477 56.0263 36.0288 55.8817 35.5978 55.8829H27.8276C26.7313 55.8829 25.8851 55.0286 25.8851 53.9218V46.0772C25.8851 44.9704 26.7313 44.1161 27.8276 44.1161H35.5978C36.0288 44.1161 36.4477 43.9727 36.7889 43.7067L54.0326 30.2166C54.0982 30.1651 54.1601 30.1111 54.2184 30.0523C54.4248 29.8439 54.5437 29.8709 54.7307 29.9494V29.9469Z"
                    fill="#E13535"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M65.0711 42.4922C64.5564 42.4922 64.0622 42.6993 63.698 43.067C62.9392 43.8331 62.9392 45.0748 63.698 45.8396L67.8186 49.9997L63.698 54.1597C62.9392 54.9258 62.9392 56.1674 63.698 56.9323C64.4568 57.6983 65.6867 57.6983 66.4443 56.9323L70.5649 52.7722L74.6855 56.9323C75.4444 57.6983 76.6742 57.6983 77.4318 56.9323C78.1906 56.1662 78.1906 54.9246 77.4318 54.1597L73.3112 49.9997L77.4318 45.8396C78.1906 45.0735 78.1906 43.8319 77.4318 43.067C77.0676 42.6993 76.5735 42.4922 76.0587 42.4922C75.5439 42.4922 75.0498 42.6993 74.6855 43.067L70.5649 47.2271L66.4443 43.067C66.0801 42.6993 65.5859 42.4922 65.0711 42.4922Z"
                    fill="#E13535"
                />
            </g>
            <defs>
                <clipPath id="clip0_3730_3217">
                    <rect width="100" height="100" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default MuteIcon;