import React, { useState } from 'react';
import './Food.css';

import star from '../../../assets/Star.png'; // Assuming the star icon is stored in a file named 'star.png'
import Food1 from '../../../assets/Food1.png';
import { CircularProgress } from '@mui/material';
import FoodMenuDetailsModal from './FoodMenuDetailsModal';
import OrderPopop from '../../../Components/OrderPopop';
import axiosVendorInstance from '../../../axiosVendorInstance';

function Food({ searchTerm, results, lastElementRef, loading }) {
    const validResults = results || [];
    const [selectedFood, setSelectedFood] = useState(null);
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [vendorId, setVendorId] = useState(null);
    const [restaurantName, setRestaurantName] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedFood(null);
    };

    const fetchFoodDetails = async (itemId) => {
        try {
            const response = await axiosVendorInstance.get(
                `/vendors/menuitems/particularitem/${itemId}`,{skipAuth: true}
            );
            console.log('response menu item',response);
            setSelectedFood(response?.data?.menuItem);
            setRestaurantName(response?.data?.menuItem?.VendorName);
            setVendorId(response?.data?.menuItem?.VendorID);
            setOpen(true);
        } catch (error) {
            console.error('Error fetching offer details:', error);
        }
    };

    const filteredFoods = validResults.filter((foods) =>
        foods?.Menu_Item_Name?.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );

    const handleOrderClick = () => {
        setOpenPopup(!openPopup);
    };

    return (
        <div className='food-container-fake'>
            <div className='Food-Nearby-restaurants-cards-container'>
                {filteredFoods.length > 0 ? (
                    filteredFoods.map((food, index) => {
                        const isLastElement = index === filteredFoods.length - 1;
                        return (
                            <div
                                key={index}
                                className='Food-search-restaurants-card'
                                ref={isLastElement ? lastElementRef : null}
                                onClick={() => fetchFoodDetails(food?.Menu_Item_ID)}
                            >   
                                <img
                                    className='Food-search-restaurants-image'
                                    src={food?.Menu_Item_Avatar || Food1}
                                    alt='Food img'
                                    onError={(e) => {
                                        e.target.src = Food1;
                                    }}
                                />
                                <div className='Food-search-restaurants-content'>
                                    <span className='Food-search-restaurants-text'>
                                        {food.Menu_Item_Name}
                                    </span>
                                    <div className='Food-search-restaurant-footer'>
                                        <span className='Food-search-restaurant-dist'>
                                            {food.Distance_in_Miles} m
                                        </span>

                                        <div className='Food-dist-layout'>
                                            <img
                                                className='Food-search-rest-star-icon-image'
                                                src={star}
                                                alt='search-rest-star icon'
                                            />
                                            <span className='Food-search-rest-reviews'>
                                                {food.AvgRating || 0} reviews
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <h5 className='no-data-found'>No Item Found</h5>
                )}
            </div>
            {loading && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <CircularProgress />
                </div>
            )}

            {selectedFood && (
                <FoodMenuDetailsModal
                    open={open}
                    handleClose={handleClose}
                    food={selectedFood}
                    handleOrderClick={handleOrderClick}
                />
            )}
            <div>
                {openPopup && 
                    <OrderPopop
                        restaurantName={restaurantName}
                        handleClose={()=> {
                            setTimeout(() => {
                                setOpenPopup(!openPopup);
                            }, 500);
                        }}
                        vendorID={vendorId}
                    />
                }
            </div>
        </div>
    );
}

export default Food;
