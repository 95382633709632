import React, { useEffect, useState } from 'react';
import './ProfileAvatar.css';
import logoIocn from '../../../assets/svg/logo.svg';

const ProfileAvatar = ({ firstName, lastName, profilePic, height, width ,fontSize, onClick, className }) => {
    const [imageURL, setImageURL] = useState(profilePic?.trim() ? profilePic : null);
    const [bgColor, setBgColor] = useState(window.innerWidth < 601 ? '#2C3142' : 'white');
    const fallbackLetter = firstName 
        ? firstName.charAt(0).toUpperCase() + (lastName ? lastName.charAt(0).toUpperCase() : '') 
        : '';
    useEffect(() =>{
        setImageURL(profilePic?.trim() ? profilePic : null);
    },[profilePic]);
    
    useEffect(() => {
        const handleResize = () => {
            setBgColor(window.innerWidth <= 600 ? '#2C3142' : 'white');
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div 
            className={`profile-image-container ${className}`}
            style={{ 
                width, height, 
                backgroundColor: imageURL ? bgColor : '#e13535', fontSize: `${fontSize}px`, 
            }}
            onClick={onClick}
        >
            {imageURL ? (
                <img 
                    src={imageURL} 
                    alt="Profile" 
                    style={{ width: '100%', height: '100%', borderRadius: '50%', objectFit: 'contain' }}
                    onError={() => setImageURL(logoIocn)} 

                />
            ) : (
                <span>{fallbackLetter}</span>
            )}
        </div>
    );
};

export default ProfileAvatar;
